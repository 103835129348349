import React, { FC, useRef, useState, useEffect, useLayoutEffect } from 'react';
import { Step, CreateEditReport, reportsWizardResources } from 'src/components';
import { Flex, CloseButton, Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useDocumentTitle } from 'src/hooks';
import { manageLayoutsResources } from 'src/helpers';
import { useReportWizardActionsContext, useReportWizardContext } from 'src/context';

const reportWizardVariants = {
  hidden: {
    x: '100vw',
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 50,
    },
  },
  visible: {
    x: 0,
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 50,
    },
  },
};

export const ReportsWizard: FC = () => {
  const header = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const { onWizardClose } = useReportWizardActionsContext();
  const { currentStep, reportId } = useReportWizardContext();
  const changeDocumentTitle = useDocumentTitle(
    reportId ? reportsWizardResources.createEditReport.editTitle : reportsWizardResources.createEditReport.addTitle
  );

  const isDisabled = currentStep > 2;

  useEffect(() => {
    return () => {
      onWizardClose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (header.current !== null) {
      setHeaderHeight(header.current.clientHeight);
    }
  }, [setHeaderHeight]);

  return (
    <Flex
      as={motion.div}
      variants={reportWizardVariants}
      initial="hidden"
      animate="visible"
      exit="hidden"
      className="new-reports-wizard"
      position="fixed"
      top="0"
      left="0"
      flexDirection="column"
      h="100%"
      w="100%"
      bg="utils.background"
      overflowY="hidden"
      zIndex={999}
    >
      <Flex
        className="reports-wizard__header"
        alignItems="center"
        bg={isDisabled ? 'input.disabledBg' : 'typo.white'}
        boxShadow="0px 3px 12px #00000029"
        zIndex="1"
        ref={header}
      >
        <CloseButton
          className="reports-wizard__close-button"
          size="lg"
          fontSize="1.5rem"
          mx={5}
          onClick={() => {
            onWizardClose();
            changeDocumentTitle(manageLayoutsResources.documentTitle);
          }}
          isDisabled={isDisabled}
        />
        <Flex className="reports-wizard__steps" flex="1">
          <Step
            isActive={Boolean(currentStep === 1)}
            stepNumber={1}
            isCompleted={Boolean(currentStep > 1)}
            title={reportId ? reportsWizardResources.steps.editReport : reportsWizardResources.steps.addReport}
            isDisabled={isDisabled}
            isAnimated
          />
        </Flex>
      </Flex>
      <Flex
        className="reports-wizard__body"
        flexDirection="column"
        bg="utils.background"
        h={`calc(100% - ${headerHeight}px)`}
      >
        <Box className="reports-wizard__configurator" position="relative" h="100%">
          {currentStep === 1 && <CreateEditReport />}
        </Box>
      </Flex>
    </Flex>
  );
};
