export const licenseConsumeModalResources = {
  title: 'Your license will be consumed',
  content:
    'Are you sure you have defined all nanoform values correctly?\nYou will not be able to change them within this license any more.',
  secondaryButtonText: 'Back',
  primaryButtonText: 'Save this prediction',
  outOfDomainInfo:
    'In case your prediction was successful, but was out of the domain of applicability of the model, you can perform -1 additional predictions before this license becomes inactive.',
  inDomainInfo:
    'Otherwise your license will be consumed and you will not be able to change this nanoform values within this license any more.',
} as const;
