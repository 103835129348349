import React from 'react';
import { Flex, Box, useRadioGroup, Text, useTheme } from '@chakra-ui/react';
import { IoEllipse } from 'react-icons/io5';
import { RadioOption } from 'src/components';
import { ICore } from 'src/model';

interface ICoreRadioOptionGroupProps {
  controllerRef: React.MutableRefObject<HTMLDivElement>;
  onChange: (...event: unknown[]) => void;
  name: string;
  invalid: boolean;
  defaultValue: string;
  onChangeCore: (coreName: string) => void;
  options: ICore[];
}

const WrapperStyles = {
  '&:not(:last-child)': {
    marginRight: '1rem',
  },
};

export const ControlledCoreRadioGroup: React.FC<ICoreRadioOptionGroupProps> = ({
  name,
  controllerRef,
  invalid,
  onChange,
  defaultValue,
  onChangeCore,
  options,
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    onChange: (value: string) => {
      onChange(value);
      onChangeCore(value);
    },
  });

  const {
    colors: { button, utils },
  } = useTheme();

  const group = getRootProps();

  return (
    <Flex {...group} flexDirection="row" ref={controllerRef}>
      <Flex wrap="wrap">
        {options &&
          options.map((option: ICore) => {
            const radio = getRadioProps({ value: option.name });
            return (
              <Box key={`core-${option.name}`} display="inline-block" sx={WrapperStyles} margin={2}>
                <RadioOption {...radio}>
                  <Flex alignItems="center">
                    <IoEllipse color={option.color} size="2rem" style={{ stroke: button.white, strokeWidth: '40' }} />
                    <Text ml={4} color={invalid && utils.alert}>
                      {option.name}
                    </Text>
                  </Flex>
                </RadioOption>
              </Box>
            );
          })}
      </Flex>
    </Flex>
  );
};
