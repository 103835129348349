import React from 'react';
import { Flex, Heading, Text, Icon, Box, Link as ChakraLink } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { qsarMail, contactUsRoute } from 'src/helpers';
import { ReactComponent as AlertIcon } from 'src/assets/triangle-alert.svg';
import { predictionFailedResources } from './predictionFailedResources';

export const PredictionEndpointFailedAlert: React.FC = () => {
  const {
    predictionFailedAdminAlertHeader,
    yourPredictionProcess,
    orUse,
    toReport,
    contactForm,
  } = predictionFailedResources;

  return (
    <Flex
      flexDirection="column"
      w="100%"
      h="100%"
      py="4rem"
      px="2.5rem"
      justifyContent="center"
      alignItems="center"
      bgColor="typo.white"
      borderColor="utils.boxBorder"
      borderWidth="1px"
      borderRadius="md"
    >
      <Icon as={AlertIcon} w="4rem" h="4rem" mb={10} />
      <Heading as="h3" fontSize="1.5rem" fontWeight="700" mb={5}>
        {predictionFailedAdminAlertHeader}
      </Heading>
      <Box color="typo.grey" fontWeight="500" whiteSpace="pre-wrap" textAlign="center">
        <Text>
          {yourPredictionProcess}
          <ChakraLink color="typo.green" fontWeight="700" textDecoration="none" href={`mailto: ${qsarMail}`}>
            {qsarMail}
          </ChakraLink>
          {orUse}
          <ChakraLink as={Link} color="typo.green" fontWeight="700" to={contactUsRoute.path}>
            {contactForm}
          </ChakraLink>
          {toReport}
        </Text>
      </Box>
    </Flex>
  );
};
