export const buyLicensesSummaryViewResources = {
  documentTitle: 'Buy license',
  main: 'Price',
  sub: 'Depends on how many endpoints you choose',
  buyText: 'Go to payment',
  toasts: {
    error: {
      title: 'An error occurred',
      description: 'Payment failed',
    },
  },
  advantageousOfferText: 'If you want to use all endpoints this license should be more beneficial for you:',
} as const;

export const manageModelsViewResources = {
  documentTitle: 'Manage models',
  heading: 'Manage models',
  newModelButtonText: 'New model',
  toasts: {
    error: {
      title: 'An error occurred',
      description: 'Data download failed',
    },
  },
};

export const modelDetailsViewResources = {
  documentTitle: 'Model details',
  newVersionText: 'New version',
  versionsHeading: 'Versions',
  licensesHeading: 'Licenses',
  modifyModelText: 'Modify model',
  fields: {
    endpoint: 'Endpoint name',
    type: 'Report type',
    date: 'Date',
    group: 'Property group',
    dependentVariable: 'Dependent variable',
    order: 'Order',
    core: 'Core',
    oneNanoformPrice: 'Price – one nanoform',
    manyNanoformsPrice: 'Price – many nanoforms',
    isDemo: 'Free of charge Model',
  },
  toasts: {
    error: {
      title: 'An error occurred',
      description: 'Data download failed',
    },
  },
  deleteModel: {
    button: 'Delete model',
    popupProblem: 'Delete this model?',
    popupSolve: "All system users won't be able to use this model in their predictions any more.",
    popupButton: 'Delete',
    toasts: {
      success: {
        title: 'Success',
        description: 'Model has been deleted correctly',
      },
    },
  },
} as const;

export const manageCoresViewResources = {
  documentTitle: 'Manage cores',
  heading: 'Core list',
  newCoreButtonText: 'New core',
  toasts: {
    error: {
      title: 'An error occurred',
      description: 'Data download failed',
    },
  },
} as const;

export const afterCheckoutViewResources = {
  infoText: 'Waiting for payment',
} as const;

export const licenseBuyOptionsViewResources = {
  toasts: {
    error: {
      title: 'An error occurred',
      description: 'Data download failed',
    },
  },
} as const;

export const buyLicensesViewResources = {
  headingBuy: 'Buy license',
  headingExpand: 'Expand license',
  toasts: {
    error: {
      title: 'An error occurred',
      description: 'Data download failed',
    },
  },
} as const;

export const userLicensesViewResources = {
  documentTitle: 'Licenses',
  heading: 'Your licenses',
  licenseInfo: {
    active: 'You have active licenses',
    inActive: 'You have no active licenses',
    waiting: 'We are waiting for payment',
  },
  active: 'Active',
  inActive: 'Inactive',
  waiting: 'Waiting',
  toasts: {
    error: {
      title: 'An error occurred',
      description: 'Data download failed',
    },
    errorPayment: {
      title: 'An error occurred',
      description: 'Payment failed',
    },
    errorCancelPayment: {
      title: 'An error occurred',
      description: 'Cancel failed',
    },
  },
  payment: {
    goToPayment: 'Go to payment',
    cancel: 'Cancel waiting payment',
  },
  licenseDeactivation: {
    deactivate: 'Deactivate',
    reactivate: 'Reactivate',
    popupProblem: 'Deactivate this license',
    popupSolve: "You won't be able to create new predictions using this license",
  },
  outOfDomainAttemptsInfo: `In case your prediction was successful, but was out of the domain of applicability of the model (estimation may be burdened with a larger error), this license lets you modify your nanoform and perform a limited number of additional predictions until the prediction resides within model’s applicability domain`,
  modelDeactivated: `This model has been temporarily deactivated as we’re currently improving it`,
} as const;

export const usersViewResources = {
  documentTitle: 'Manage licenses',
  heading: 'Licenses',
  yes: 'Yes',
  no: 'No',
  toasts: {
    error: {
      title: 'An error occurred',
      description: 'Data download failed',
    },
  },
} as const;

export const laboratoryViewResources = {
  documentTitle: 'Laboratory',
  heading: 'Predictions overview',
  newExperimentButtonText: 'New prediction',
  toasts: {
    licenseInActive: {
      title: 'An error occurred',
      description: 'Your license has expired',
    },
  },
  predictionStatus: {
    done: 'Done',
    inprogress: 'In Progress',
    initial: 'Initial',
    failed: 'Failed',
  },
} as const;

export const modelsVersionDetailsViewResources = {
  documentTitle: 'Version details',
  heading: 'Version',
  deleteButton: 'Delete this version',
  downloadFunctionButton: 'Download Azure function',
  versionAttributesHeading: 'Version attributes',
  attributesLabels: {
    versionNumber: 'Version number',
    date: 'Date',
    functionName: 'Function name',
    author: 'Author',
    changeLog: 'Change log',
    usedAttributes: 'Used attributes',
    referenceToQmrf: 'Reference to qmrf',
  },
  toasts: {
    success: {
      title: 'Success',
      description: "Model's version has been deleted correctly",
    },
  },
} as const;

export const predictionDetailsViewResources = {
  documentTitle: 'Prediction details',
  waitingForPrediction: 'Your prediction results are almost ready!',
  deletePrediction: 'Delete prediction',
  downloadReport: 'Download report',
  testReportTemplates: 'Test report templates',
  predictionInDomain: 'Prediction in domain',
  predictionOutOfDomain: 'Prediction is out of domain',
  endpoints: 'Endpoints',
  endpointDetails: 'Endpoint details',
  dependentVariable: 'Dependent variable',
  predictionDetails: 'Prediction details',
  predictedValue: 'Predicted value',
  readAcrossAssessmentResult: 'read-across assessment result',
  negativeMutagenicResponse: 'Negative mutagenic response',
  positiveMutagenicResponse: 'Positive mutagenic response',
  descriptorValues: 'Descriptor values',
  aspectRatio: 'Aspect ratio',
  ssa: 'ssa',
  totalNon: 'total non',
  content: 'content',
  nanoform: 'nanoform',
  crystalStructure: 'crystal structure',
  coreModification: 'core modification',
  shape: 'Shape',
  particleSizeDistribution: 'PARTICLE SIZE DISTRIBUTION [nm]',
  d10: 'd10',
  d50: 'd50',
  d90: 'd90',
  surfaceArea: 'SURFACE AREA [m²/g]',
  surfaceFunctionalization: 'surface functionalization',
  structuralAnalogues: 'Structural analogues',
  backToPredictionList: 'Back to predictions list',
  toasts: {
    deleteSuccess: {
      title: 'Success',
      description: 'Prediction has been deleted correctly',
    },
    adminSomeEndpointsFailed: {
      title: 'Error',
      description: 'We had trouble generating results for some endpoints. Check report for more details',
      id: 'adminEndpointError',
    },
  },
  outOfDomain: {
    attemptsAvailable:
      'Your prediction was successful, however due to being out of the domain of applicability of the model, the estimation may be burdened with a larger error. License that were used  lets you modify your nanoform and perform additional predictions -1 more times or until the prediction resides within model’s applicability domain',
    attemptsNotAvailable:
      'Your prediction was successful, however due to being out of the domain of applicability of the model, the estimation may be burdened with a larger error. Unfortunately, license that were used for a prediction doesn’t allow to perform additional predictions anymore. If you want to try further, you need to select other license or buy a new one',
  },
} as const;

export const contactUsViewResources = {
  documentTitle: 'Contact us',
  inputs: {
    name: {
      label: 'Name',
      placeholder: 'Name',
    },
    email: {
      label: 'Email address',
      placeholder: 'Email',
    },
    category: {
      label: 'Category',
      placeholder: 'Pick category',
      options: ["User's account", 'Licenses', 'Payments', 'Predictions', 'Technical problems', 'Others'],
    },
    message: {
      label: 'Message',
      placeholder: 'Your message',
    },
  },
  toasts: {
    success: {
      title: 'Success',
      description: 'Your message has been sent',
    },
  },
  send: 'Send',
  emailSend: 'Thank you, your message has been sent',
  youCanContactUsViaEmail: 'You can contact us via email - ',
  orUseTheBelowContactForm: ' or use the below contact form.',
} as const;

export const manageLayoutsResources = {
  documentTitle: 'Reports versions',
  heading: 'Reports versions',
  newRaReport: 'New RA report',
  newQsarReport: 'New QSAR report',
  downloadContent: 'Download content version',
  downloadFooter: 'Download footer version',
  noReports: 'No reports',
  popUp: {
    title: 'You are modifying the version',
    description:
      'Template version should be modified only in extraordinary situations as it may cause the reports for the predictions that have been already performed will change. Do you want to continue?',
    button: 'Continue',
  },
} as const;
