export const scrollbar = {
  // firefox
  scrollbarColor: '#42A14D #F5F5F5',
  scrollbarWidth: 'thin',
  // webkit
  '&::-webkit-scrollbar-track': {
    WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
    backgroundColor: 'scrollBar.trackBackground',
    borderRadius: '8px',
  },
  '&::-webkit-scrollbar': {
    width: '8px',
    backgroundColor: 'scrollBar.scrollBackground',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '10px',
    backgroundColor: 'scrollBar.thumbColor',
  },
};

export const greyScrollbar = {
  // firefox
  scrollbarColor: '#E3E2E2 #FFF',
  scrollbarWidth: 'thin',
  // webkit
  '&::-webkit-scrollbar-track': {
    WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
    backgroundColor: 'scrollBar.greyTrackBackground',
    borderRadius: '8px',
  },
  '&::-webkit-scrollbar': {
    width: '8px',
    backgroundColor: 'scrollBar.greyScrollBackground',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '10px',
    backgroundColor: 'scrollBar.greyThumbColor',
  },
};
