import React from 'react';
import { Box, Heading, VStack, Text } from '@chakra-ui/react';
import { EndpointOption } from 'src/components';
import { predictionDetailsViewResources, greyScrollbar } from 'src/helpers';
import { IPredictionModelGroup, IPredictionModel } from 'src/model';

interface IPredictionEndpointsListProps {
  modelGroups: IPredictionModelGroup[];
  onEndpointSelect: (endpoint: IPredictionModel) => void;
  activeEndpointId: string;
}

export const PredictionEndpointsList: React.FC<IPredictionEndpointsListProps> = ({
  modelGroups,
  onEndpointSelect,
  activeEndpointId,
}) => {
  return (
    <Box p={5} pr="10px" bg="typo.white" borderColor="utils.boxBorder" borderWidth="1px" borderRadius="md" h="100%">
      <Heading as="h3" mb={8} fontSize="1.5rem">
        {predictionDetailsViewResources.endpoints}
      </Heading>
      <VStack
        spacing={5}
        alignItems="stretch"
        maxH={{ base: 'calc(100% - 63px)', lg: 'calc(100% - 60px)' }}
        overflowY="auto"
        sx={greyScrollbar}
        pr="10px"
      >
        {modelGroups.map((modelGroup: IPredictionModelGroup) => (
          <Box key={modelGroup.id}>
            <Text fontWeight="500" color="typo.grey" textTransform="uppercase" pb={2}>
              {modelGroup.name}
            </Text>
            {modelGroup.models.map((model: IPredictionModel) => (
              <EndpointOption
                key={model.id}
                endpointName={model.endpoint}
                isActive={model.id === activeEndpointId}
                onClick={() => onEndpointSelect(model)}
              />
            ))}
          </Box>
        ))}
      </VStack>
    </Box>
  );
};
