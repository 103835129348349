import { AxiosPromise, AxiosResponse } from 'axios';
import { UserStatus } from '../model/usersResponse.model';
import { authorizedApiService } from './api.service';

class UserService {
  // GET

  getUserById = (id: string, token: string): AxiosPromise => {
    const apiUrl = `/users/${encodeURI(id)}`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getUserLicenses = (id: string, limit: number, offset: number, token: string): AxiosPromise => {
    const apiUrl = `/users/${encodeURI(id)}/licenses?limit=${limit}&offset=${offset}`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getUsers = (limit: number, offset: number, email: string, token: string): AxiosPromise => {
    const apiUrl = `/users?limit=${limit}&offset=${offset}&email=${email}`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getUserLicensesInfo = (id: string, token: string): AxiosPromise => {
    const apiUrl = `users/${encodeURI(id)}/licenses/info`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getUserActiveLicenses = (id: string, token: string): AxiosPromise => {
    const apiUrl = `users/${encodeURI(id)}/licenses/active`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getUserLicenseDetails = (userId: string, licenseId: string, token: string): AxiosPromise => {
    const apiUrl = `users/${encodeURI(userId)}/licenses/${encodeURI(licenseId)}`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getUserActiveLicense = (userId: string, licenseId: string, token: string): AxiosPromise => {
    const apiUrl = `users/${encodeURI(userId)}/licenses/active/${encodeURI(licenseId)}`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getUserPredictions = (
    id: string,
    licenseId: string | null,
    limit: number,
    offset: number,
    token: string
  ): AxiosPromise => {
    const licenseIdString = licenseId ? `licenseId=${licenseId}&` : '';

    const apiUrl = `/users/${encodeURI(id)}/predictions?${licenseIdString}limit=${limit}&offset=${offset}`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  // POST

  // PUT

  modifyUserData = (
    companyName: string,
    companyAddress: string,
    organizationNumber: string,
    name: string,
    surname: string,
    marketingConsents: boolean,
    token: string
  ): AxiosPromise => {
    const apiUrl = `/users`;
    return authorizedApiService(token)
      .put(apiUrl, { companyName, companyAddress, organizationNumber, name, surname, marketingConsents })
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  updateUserStatus = (id: string, status: UserStatus, token: string): AxiosPromise => {
    const apiUrl = `/users/status`;
    return authorizedApiService(token)
      .put(apiUrl, { userId: id, status })
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  // DELETE
}

export const userService = new UserService();
