import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { Step, CreateCore } from 'src/components';
import { Flex, CloseButton, Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useCoreWizardActionsContext, useCoreWizardContext } from 'src/context';
import { useDocumentTitle } from 'src/hooks';
import { manageCoresViewResources } from 'src/helpers';
import { coreWizardResources } from './coreWizardResources';

const coreWizardVariants = {
  hidden: {
    x: '100vw',
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 50,
    },
  },
  visible: {
    x: 0,
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 50,
    },
  },
};

interface ICoreWizardProps {
  CoreId?: string;
}

export const CoreWizard: React.FC<ICoreWizardProps> = ({ CoreId }) => {
  const { onWizardClose } = useCoreWizardActionsContext();
  const { currentStep } = useCoreWizardContext();
  const header = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  const changeDocumentTitle = useDocumentTitle(
    CoreId ? coreWizardResources.steps.editCore : coreWizardResources.steps.createCore
  );

  const isDisabled = currentStep > 2;

  useEffect(() => {
    return () => {
      onWizardClose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (header.current !== null) {
      setHeaderHeight(header.current.clientHeight);
    }
  }, [setHeaderHeight]);

  return (
    <Flex
      as={motion.div}
      variants={coreWizardVariants}
      initial="hidden"
      animate="visible"
      exit="hidden"
      className="new-study-wizard"
      position="fixed"
      top="0"
      left="0"
      flexDirection="column"
      h="100%"
      w="100%"
      bg="utils.background"
      overflowY="auto"
      zIndex={999}
    >
      <Flex
        className="core-wizard__header"
        alignItems="center"
        bg={isDisabled ? 'input.disabledBg' : 'typo.white'}
        boxShadow="0px 3px 12px #00000029"
        zIndex="1"
        ref={header}
      >
        <CloseButton
          className="core-wizard__close-button"
          size="lg"
          fontSize="1.5rem"
          mx={5}
          onClick={() => {
            onWizardClose();
            changeDocumentTitle(manageCoresViewResources.documentTitle);
          }}
          isDisabled={isDisabled}
        />
        <Flex className="core-wizard__steps" flex="1">
          <Step
            isActive={Boolean(currentStep === 1)}
            stepNumber={1}
            isCompleted={Boolean(currentStep > 1)}
            title={CoreId ? coreWizardResources.steps.editCore : coreWizardResources.steps.createCore}
            isDisabled={isDisabled}
            isAnimated
          />
        </Flex>
      </Flex>
      <Flex
        className="core-wizard__body"
        flexDirection="column"
        bg="utils.background"
        h={`calc(100% - ${headerHeight}px)`}
      >
        <Box className="core-wizard__configurator" position="relative" h="100%">
          {currentStep === 1 && <CreateCore CoreId={CoreId} />}
        </Box>
      </Flex>
    </Flex>
  );
};
