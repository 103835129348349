import { Badge } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { userLicensesViewResources } from 'src/helpers';
import { LicenseStatus } from 'src/model';

interface ILicenseStatusBadge {
  licenseStatus: LicenseStatus;
}

export const LicenseStatusBadge: React.FC<ILicenseStatusBadge> = ({ licenseStatus }) => {
  const renderBadge = useCallback((status: LicenseStatus | undefined) => {
    switch (status) {
      case LicenseStatus.active:
        return (
          <Badge colorScheme="green" py={1} px="12px" fontSize="md" color="typo.green" fontWeight="500">
            {userLicensesViewResources.active}
          </Badge>
        );
      case LicenseStatus.waiting:
        return (
          <Badge colorScheme="orange" py={1} px="12px" fontSize="md" color="utils.gold" fontWeight="500">
            {userLicensesViewResources.waiting}
          </Badge>
        );
      default:
        return (
          <Badge colorScheme="red" py={1} px="12px" fontSize="md" color="utils.alert" fontWeight="500">
            {userLicensesViewResources.inActive}
          </Badge>
        );
    }
  }, []);

  return renderBadge(licenseStatus);
};
