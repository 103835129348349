import React from 'react';
import { Flex, Heading, Text, Icon, Box, Link as ChakraLink } from '@chakra-ui/react';
import { Link, useHistory } from 'react-router-dom';
import { qsarMail, contactUsRoute, laboratoryRoute } from 'src/helpers';
import { ReactComponent as AlertIcon } from 'src/assets/triangle-alert.svg';
import { useUserActionsContext } from 'src/context';
import { predictionFailedResources } from './predictionFailedResources';
import { PrimaryButton } from '../common';

export const PredictionFailedUserAlert: React.FC = () => {
  const {
    predictionFailedUserAlertHeader,
    pleaseBeAware,
    wasNotConsumed,
    tryAgain,
    orUse,
    toReport,
    contactForm,
    backToPredictionList,
  } = predictionFailedResources;

  const history = useHistory();

  const { fetchUserActiveLicenseData } = useUserActionsContext();

  const backToPerdictionList = () => {
    fetchUserActiveLicenseData();
    history.push({ pathname: laboratoryRoute.path });
  };

  return (
    <Flex w="100%" flex="1" justifyContent="center" alignItems="center">
      <Flex
        flexDirection="column"
        w="100%"
        maxW="900px"
        py="4rem"
        px="2.5rem"
        justifyContent="center"
        alignItems="center"
        bgColor="typo.white"
        borderColor="utils.boxBorder"
        borderWidth="1px"
        borderRadius="md"
      >
        <Icon as={AlertIcon} w="4rem" h="4rem" mb={10} />
        <Heading as="h3" fontSize="1.5rem" fontWeight="700" mb={5}>
          {predictionFailedUserAlertHeader}
        </Heading>
        <Box color="typo.grey" fontWeight="500" whiteSpace="pre-wrap" textAlign="center">
          <Text>
            {pleaseBeAware}
            <b>{wasNotConsumed}</b>
          </Text>
          <Text>
            {tryAgain}
            <ChakraLink color="typo.green" fontWeight="700" textDecoration="none" href={`mailto: ${qsarMail}`}>
              {qsarMail}
            </ChakraLink>
          </Text>
          <Text>
            {orUse}
            <ChakraLink as={Link} color="typo.green" fontWeight="700" to={contactUsRoute.path}>
              {contactForm}
            </ChakraLink>
            {toReport}
          </Text>
        </Box>
        <PrimaryButton size="sm" mt={8} onClick={() => backToPerdictionList()}>
          {backToPredictionList}
        </PrimaryButton>
      </Flex>
    </Flex>
  );
};
