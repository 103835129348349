import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Flex,
  Heading,
  SimpleGrid,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Select,
  Textarea,
  Text,
  Link as ChakraLink,
  Box,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { PrimaryButton, Spinner } from 'src/components';
import { scrollbar, contactUsViewResources, qsarMail } from 'src/helpers';
import { contactUsYupSchema } from 'src/utils';
import { useUserContext, useLoaderContext, useLoaderActionsContext } from 'src/context';
import { useSuccessToast, useDocumentTitle } from 'src/hooks';
import { utilityService } from 'src/services';

export type ContactUsFormInputs = {
  name: string;
  email: string;
  category: string;
  message: string;
};

export const ContactUs: React.FC = () => {
  useDocumentTitle(contactUsViewResources.documentTitle);

  const [showEmailSendConfirmation, setShowEmailSendConfirmation] = useState<boolean>(false);

  const {
    auth0UserData: { email },
  } = useUserContext();

  const isLoading = useLoaderContext();
  const setLoading = useLoaderActionsContext();

  const { getAccessTokenSilently } = useAuth0();

  const {
    inputs,
    toasts: { success },
    send,
    emailSend,
  } = contactUsViewResources;

  const { register, handleSubmit, formState } = useForm<ContactUsFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(contactUsYupSchema()),
    defaultValues: {
      name: '',
      email: email ?? '',
      category: '',
      message: '',
    },
  });

  const successToast = useSuccessToast(success.title, success.description);

  const onSubmit = async (values: ContactUsFormInputs) => {
    const { name, email: emailFromForm, category, message } = values;
    setLoading(true);

    try {
      const token = await getAccessTokenSilently();

      const response = await utilityService.sendEmail(name, emailFromForm, category, message, token);

      if (response) {
        successToast();
        setShowEmailSendConfirmation(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex
      className="contact-us"
      w="100%"
      minH="100%"
      py={10}
      px="15%"
      flexDirection="column"
      overflowY="auto"
      sx={scrollbar}
    >
      {(isLoading || showEmailSendConfirmation) && (
        <Flex w="100%" minH="100%" flex={1} justifyContent="center" alignItems="center">
          {isLoading ? (
            <Spinner thickness="8px" speed="1s" size="xl" w="100px" h="100px" label="Sending..." />
          ) : (
            <Heading fontWeight="700">{emailSend}</Heading>
          )}
        </Flex>
      )}
      {!isLoading && !showEmailSendConfirmation && (
        <>
          <Flex as="header" className="contact-us__header" justifyContent="space-between" alignItems="center" pb={5}>
            <Heading className="header__title" fontWeight="700" size="xl">
              Contact us
            </Heading>
          </Flex>
          <Box color="typo.grey" fontWeight="500" whiteSpace="pre-wrap" textAlign="left" pb={5}>
            <Text>
              {contactUsViewResources.youCanContactUsViaEmail}
              <ChakraLink color="typo.green" fontWeight="700" textDecoration="none" href={`mailto: ${qsarMail}`}>
                {qsarMail}
              </ChakraLink>
              {contactUsViewResources.orUseTheBelowContactForm}
            </Text>
          </Box>
          <form name="contact-us">
            <SimpleGrid
              className="model-creator-version-fields-grid"
              spacing={16}
              columns={[1, 2, 2, 2, 2]}
              minChildWidth="200px"
            >
              <VStack>
                <FormControl mb={4} isInvalid={!!formState.errors?.name?.message}>
                  <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
                    {inputs.name.label}
                  </FormLabel>
                  <Input
                    type="text"
                    name="name"
                    ref={register}
                    errorBorderColor="utils.alert"
                    placeholder={inputs.name.placeholder}
                  />
                  <FormErrorMessage>{formState.errors?.name?.message}</FormErrorMessage>
                </FormControl>
                <FormControl mb={4} isInvalid={!!formState.errors?.email?.message}>
                  <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
                    {inputs.email.label}
                  </FormLabel>
                  <Input
                    type="text"
                    name="email"
                    ref={register}
                    errorBorderColor="utils.alert"
                    placeholder={inputs.email.placeholder}
                  />
                  <FormErrorMessage>{formState.errors?.email?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!formState.errors?.category?.message}>
                  <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
                    {inputs.category.label}
                  </FormLabel>
                  <Select ref={register} name="category" errorBorderColor="utils.alert">
                    <option key="category-option-empty" value="">
                      {inputs.category.placeholder}
                    </option>
                    {inputs.category.options.map((option: string) => (
                      <option key={`category-option-${option}`} value={option}>
                        {option}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{formState.errors?.category?.message}</FormErrorMessage>
                </FormControl>
              </VStack>
              <VStack>
                <FormControl isInvalid={!!formState.errors?.message?.message}>
                  <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
                    {inputs.message.label}
                  </FormLabel>
                  <Textarea
                    variant="outline"
                    type="text"
                    ref={register}
                    name="message"
                    borderWidth="1px"
                    borderRadius="0.25rem"
                    borderColor="input.border"
                    errorBorderColor="utils.alert"
                    resize="vertical"
                    bg="white"
                    size="lg"
                    minH="300px"
                    placeholder={inputs.message.placeholder}
                    _placeholder={{ color: 'input.placeholder' }}
                  />
                  <FormErrorMessage>{formState.errors?.message?.message}</FormErrorMessage>
                </FormControl>
              </VStack>
            </SimpleGrid>
            <Flex justifyContent="flex-end" pt={12}>
              <PrimaryButton minW="150px" onClick={handleSubmit(onSubmit)}>
                {send}
              </PrimaryButton>
            </Flex>
          </form>
        </>
      )}
    </Flex>
  );
};
