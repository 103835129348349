import React, { useState, createContext, useContext, useCallback } from 'react';
import { IModelVersion, INewModel, INewAttributeDefinition, IAttributeModel, ModelStatus } from 'src/model';

interface IProps {
  children: JSX.Element;
}

export interface INewModelWizardContextModel {
  isWizardOpen: boolean;
  currentStep: number;
  model: INewModel;
  version: IModelVersion;
  initialModelStage: INewModel;
  initialVersionStage: IModelVersion;
  attributeDefinition: INewAttributeDefinition;
  attributeModels: IAttributeModel[];
  attributeTypes: string[];
  isModelEdited: boolean;
  activeTabIndex: number;
}

export interface INewModelWizardActionsContextModel {
  onWizardOpen: () => void;
  onWizardClose: () => void;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setModel: React.Dispatch<React.SetStateAction<INewModel>>;
  setVersion: React.Dispatch<React.SetStateAction<IModelVersion>>;
  setAttributeDefinition: React.Dispatch<React.SetStateAction<INewAttributeDefinition>>;
  setAttributeModels: React.Dispatch<React.SetStateAction<IAttributeModel[]>>;
  setIsModelEdited: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  resetAttributeDefinition: () => void;
  reset: () => void;
}

const initialModelStage: INewModel = {
  id: '',
  status: ModelStatus.Active,
  name: '',
  coreId: '',
  coreName: '',
  isDemo: false,
  endpoint: '',
  order: 0,
  modelGroupId: '',
  groupName: '',
  dependentVariable: '',
  manyNanoformsPrice: 0,
  oneNanoformPrice: 0,
  reportType: 'QSAR',
  createdDate: new Date(),
};

const initialVersionStage: IModelVersion = {
  id: '',
  author: '',
  version: '001',
  changeLog: '',
  qmrfReference: '',
  createdDate: new Date(),
  functionName: '',
  useShape: true,
};
const initialAttributeDefinition: INewAttributeDefinition = {
  id: '',
  name: '',
  label: '',
  type: '',
  attributePickerOptions: [],
  order: 0,
  isEnabled: true,
};

const initialAttributeModels: IAttributeModel[] = [];

const NewModelWizardContext = createContext<INewModelWizardContextModel>({} as INewModelWizardContextModel);
const NewModelWizardActionsContext = createContext({} as INewModelWizardActionsContextModel);

export const useNewModelWizardContext: () => INewModelWizardContextModel = () => useContext(NewModelWizardContext);
export const useNewModelWizardActionsContext: () => INewModelWizardActionsContextModel = () =>
  useContext(NewModelWizardActionsContext);

export const NewModelWizardContextProvider: React.FC<IProps> = ({ children }) => {
  const [isWizardOpen, setIsWizardOpen] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [model, setModel] = useState<INewModel>(initialModelStage);
  const [version, setVersion] = useState<IModelVersion>(initialVersionStage);
  const [attributeDefinition, setAttributeDefinition] = useState<INewAttributeDefinition>(initialAttributeDefinition);
  const [attributeModels, setAttributeModels] = useState<IAttributeModel[]>(initialAttributeModels);
  const [isModelEdited, setIsModelEdited] = useState<boolean>(false);
  const [activeTabIndex, setActiveIndex] = useState<number>(0);
  const attributeTypes: string[] = ['Integer', 'Float', 'Boolean', 'SinglePicker', 'MultiPicker'];
  const reset = () => {
    setModel(initialModelStage);
    setVersion(initialVersionStage);
    setAttributeModels(initialAttributeModels);
    setIsModelEdited(false);
    setCurrentStep(1);
  };

  const resetAttributeDefinition = () => {
    setAttributeDefinition(initialAttributeDefinition);
  };

  const onWizardOpen = useCallback(() => {
    document.body.style.overflowY = 'hidden';
    setIsWizardOpen(true);
  }, []);

  const onWizardClose = useCallback(() => {
    document.body.style.overflowY = 'auto';
    setIsModelEdited(false);
    setIsWizardOpen(false);
  }, []);

  return (
    <NewModelWizardContext.Provider
      value={{
        isWizardOpen,
        currentStep,
        model,
        version,
        initialModelStage,
        initialVersionStage,
        attributeDefinition,
        attributeModels,
        attributeTypes,
        isModelEdited,
        activeTabIndex,
      }}
    >
      <NewModelWizardActionsContext.Provider
        value={{
          onWizardOpen,
          onWizardClose,
          setCurrentStep,
          setModel,
          setVersion,
          setAttributeDefinition,
          setAttributeModels,
          resetAttributeDefinition,
          setIsModelEdited,
          setActiveIndex,
          reset,
        }}
      >
        {children}
      </NewModelWizardActionsContext.Provider>
    </NewModelWizardContext.Provider>
  );
};
