import React, { useEffect, useState } from 'react';
import { Box, Heading, FormControl, FormLabel, Input, Flex, Checkbox } from '@chakra-ui/react';
import {
  useNewExperimentWizardContext,
  useNewExperimentWizardActionsContext,
  useNanoformContext,
  useNanoformActionsContext,
  useUserContext,
  IGroupSelector,
} from 'src/context';
import { scrollbar } from 'src/helpers';
import { WizardNav, Spinner, PopupBackground, Popup } from 'src/components';
import {
  ICore,
  IActiveLicenseModelGroup,
  IActiveLicenseModel,
  LicenseTypes,
  ICoreWithModels,
  ModelStatus,
} from 'src/model';
import { useDocumentTitle } from 'src/hooks';
import { coreService } from 'src/services';
import { UncontrolledCoreRadioGroup } from 'src/components/common/UncontrolledCoreRadioGroup';
import { useAuth0 } from '@auth0/auth0-react';
import { newExperimentWizardResources } from '../newExperimentWizardResources';

export const SelectSubstance: React.FC = () => {
  useDocumentTitle(newExperimentWizardResources.steps.selectSubstance);

  const { getAccessTokenSilently } = useAuth0();

  const {
    licenseSkipped,
    groupSelectors,
    demoCores,
    availableModelGroups,
    availableCores,
    experimentData: { researchName, coreName, showOnlyFreeModels },
  } = useNewExperimentWizardContext();
  const {
    setExperimentData,
    setCurrentStep,
    setGroupSelectors,
    setDisablingCondition,
    setDemoCores,
    setAvailableModelGroups,
    setAvailableCores,
    onWizardClose,
  } = useNewExperimentWizardActionsContext();

  const { userActiveLicenseData, userActiveLicenses } = useUserContext();

  const {
    nanoformData: { core: currentCoreValue },
  } = useNanoformContext();
  const { setNanoformData } = useNanoformActionsContext();

  const { selectSubstance } = newExperimentWizardResources;
  const [coresLoading, setCoresLoading] = useState<boolean>(true);
  const [popupVisible, setPopupVisible] = useState<boolean>(false);

  const filterGroupsForSelectedCore = (modelGroups: IActiveLicenseModelGroup[]): IActiveLicenseModelGroup[] => {
    const matchingGroups: IActiveLicenseModelGroup[] = JSON.parse(JSON.stringify(modelGroups));

    for (let i = 0; i < matchingGroups.length; i += 1) {
      matchingGroups[i].models = matchingGroups[i].models.filter(
        (model: IActiveLicenseModel) => model.coreName === coreName
      );
    }

    return matchingGroups.filter((gr) => gr.models.length !== 0);
  };

  const onChangeCore = (core: string) => {
    const matchingCore: ICore | undefined = availableCores?.find((item: ICore) => item.name === core);

    if (matchingCore === undefined) return;

    setNanoformData((prevState) => ({ ...prevState, core: matchingCore }));
    setExperimentData((prevState) => ({ ...prevState, coreName: matchingCore.name }));
  };

  const onChangeResearchName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setExperimentData((prevState) => ({ ...prevState, researchName: value }));
  };

  const onChangeShowDemoModels = () => {
    if (demoCores.length === 0) return;

    setExperimentData((prevState) => ({ ...prevState, showOnlyFreeModels: !showOnlyFreeModels }));

    const core = demoCores.find((c) => c.name === coreName);

    if (core) return;

    setExperimentData((prevState) => ({ ...prevState, coreName: demoCores[0].name }));
  };

  const shouldGroupsBeUpdated = (groups: IActiveLicenseModelGroup[]): boolean => {
    if (groupSelectors.length === 0) return true;

    const newGroups = groups.flatMap((g) => g.models.map((m) => m.id)).sort();
    const oldGroups = groupSelectors.flatMap((g) => g.group.models.map((m) => m.id)).sort();

    if (newGroups.length !== oldGroups.length) return true;

    const groupsMatch = newGroups.some((ng) => oldGroups.some((og) => og === ng));

    if (!groupsMatch) return true;

    return false;
  };

  const onNext = () => {
    const newGroups = filterGroupsForSelectedCore(availableModelGroups);
    setDisablingCondition(
      userActiveLicenseData.type === LicenseTypes.OneNanoformAllModels ||
        userActiveLicenseData.type === LicenseTypes.OneNanoformSpecificModels
    );

    if (!shouldGroupsBeUpdated(newGroups)) {
      setCurrentStep(3);
      return;
    }

    const newGroupSelectors: IGroupSelector[] = newGroups.map((g) => {
      return { selectedModelsIds: [], selectedDemoModelsIds: [], group: g };
    });

    setGroupSelectors(newGroupSelectors);
    setCurrentStep(3);
  };

  const updateAvailableGroups = (newCores: ICoreWithModels[]) => {
    const newAvailableModelGroups = [...availableModelGroups];

    const demoGroups = newCores.flatMap((c) => c.groups);

    const groupsIds: string[] = demoGroups.map((g) => g.id).filter((value, idx, arr) => arr.indexOf(value) === idx);

    groupsIds.forEach((demoGroupId) => {
      const foundGroups = newCores
        .flatMap((core) => core.groups)
        .filter((modelGroupDemo) => modelGroupDemo.id === demoGroupId);

      const newModels: IActiveLicenseModel[] = foundGroups
        .flatMap((g) => g.models)
        .map((demoModel) => {
          return {
            id: demoModel.id,
            endpoint: demoModel.endpoint,
            order: demoModel.order,
            coreName: demoModel.coreName,
            useShape: demoModel.useShape,
            isDemo: demoModel.isDemo,
            status: demoModel.status,
          };
        });

      const idx = newAvailableModelGroups.findIndex((g) => g.id === demoGroupId);
      if (idx !== -1) {
        newModels.forEach((newModel) => {
          if (!newAvailableModelGroups[idx].models.find((m) => m.id === newModel.id)) {
            newAvailableModelGroups[idx].models.push(newModel);
          }
        });
      } else {
        const newGroup = demoGroups.find((g) => g.id === demoGroupId);
        if (newGroup) {
          newAvailableModelGroups.push({
            id: newGroup.id,
            name: newGroup.name,
            order: newGroup.order,
            models: newModels,
          });
        }
      }
    });

    return newAvailableModelGroups;
  };

  useEffect(() => {
    if (demoCores.length !== 0) {
      setCoresLoading(false);
      return;
    }

    const fetchCoresWithDemoModels = async () => {
      const token = await getAccessTokenSilently();
      const cores: ICoreWithModels[] = await coreService
        .getAllCoresWithDemoModels(token)
        .then((response) => response.data);

      cores.sort((a, b) => {
        if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) return -1;
        if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) return 1;
        return 0;
      });

      setDemoCores(cores);
      setCoresLoading(false);
    };

    fetchCoresWithDemoModels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (demoCores.length === 0) return;

    const newCores = [...demoCores].filter((c) => !availableCores.find((ac) => ac.id === c.id));

    setAvailableModelGroups(updateAvailableGroups(demoCores));
    if (newCores.length === 0) return;

    const newAvailableCores = [...availableCores];

    newCores.forEach((core) => {
      newAvailableCores.push({ id: core.id, name: core.name, color: core.color });
    });
    newAvailableCores.sort((a, b) => {
      if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) return -1;
      if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) return 1;
      return 0;
    });

    setAvailableCores(newAvailableCores);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demoCores]);

  useEffect(() => {
    if (availableCores.length === 0) {
      setAvailableCores(userActiveLicenseData.cores);
    }
    if (availableModelGroups.length === 0) {
      setAvailableModelGroups(userActiveLicenseData.modelGroups);
    }
    if (
      (userActiveLicenseData.type === LicenseTypes.OneNanoformSpecificModels ||
        userActiveLicenseData.type === LicenseTypes.OneNanoformAllModels) &&
      userActiveLicenseData.modelGroups
        .flatMap((g) => g.models)
        .filter((m) => m.status === ModelStatus.Deactivated && !m.isDemo).length > 0
    ) {
      setPopupVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userActiveLicenseData]);

  return (
    <Box className="select-substance" px={10} pt={20} h="100%" overflowY="auto" sx={scrollbar}>
      {popupVisible && (
        <PopupBackground position="fixed">
          <Popup
            problemMessage={selectSubstance.tryAgainLaterPopup.titleMessage}
            solveMessage={selectSubstance.tryAgainLaterPopup.descriptionMessage}
            buttonMessage={selectSubstance.tryAgainLaterPopup.buttonMessage}
            setVisibility={() => {}}
            onButtonClick={userActiveLicenses.length > 1 ? () => setCurrentStep(1) : () => onWizardClose()}
            canClose={false}
          />
        </PopupBackground>
      )}
      <Flex>
        <Heading fontSize="2xl" fontWeight="bold" my="auto">
          {selectSubstance.core.label}
        </Heading>
        {demoCores.length > 0 && (
          <>
            <Checkbox
              my="auto"
              ml="auto"
              mr="4"
              size="lg"
              isChecked={showOnlyFreeModels}
              onChange={() => onChangeShowDemoModels()}
            />
            <Box fontWeight="bold" my="auto">
              {selectSubstance.showOnlyFreeOfChargeModels}
            </Box>
          </>
        )}
      </Flex>
      <Box mb={4} />
      {availableCores.length !== 0 && !coresLoading && (
        <UncontrolledCoreRadioGroup
          defaultValue={coreName || (showOnlyFreeModels ? demoCores[0].name : availableCores[0].name)}
          onChange={(value) => onChangeCore(value as string)}
          options={showOnlyFreeModels ? demoCores : availableCores}
        />
      )}
      {availableCores.length === 0 && coresLoading && <Spinner />}
      <FormControl id="research-name" mb={6} mt={10}>
        <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
          {selectSubstance.researchName.label}
        </FormLabel>
        <Input
          variant="outline"
          type="text"
          name="researchName"
          borderWidth="1px"
          value={researchName}
          onChange={onChangeResearchName}
          placeholder={selectSubstance.researchName.placeholder}
        />
      </FormControl>
      <Box position="absolute" w="100%" p={5} left="0" bottom="0">
        <WizardNav
          nextLabel="Next"
          backLabel={licenseSkipped ? '' : 'Back'}
          onNext={() => onNext()}
          onBack={() => setCurrentStep(1)}
          nextDisableCondition={researchName.length === 0 || currentCoreValue?.name.length === 0}
        />
      </Box>
    </Box>
  );
};
