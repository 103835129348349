import React from 'react';
import { Flex, Box, useTheme } from '@chakra-ui/react';
import { IoEllipse } from 'react-icons/io5';
import { ICore } from 'src/model';

interface ICoreLabel {
  value: ICore;
}

export const CoreLabel: React.FC<ICoreLabel> = ({ value }) => {
  const {
    colors: { button },
  } = useTheme();

  return (
    <Box key={`core-${value.name}`} display="inline-block">
      <Box
        cursor="default"
        color="typo.blue"
        background="radio.bg"
        fontWeight="700"
        borderWidth="0.5px"
        borderRadius="0.35rem"
        borderColor="radio.border"
        minWidth="128px"
        px={5}
        py={2}
      >
        <Flex alignItems="center">
          <IoEllipse color={value.color} size="2rem" style={{ stroke: button.white, strokeWidth: '40' }} />
          {value.name}
        </Flex>
      </Box>
    </Box>
  );
};
