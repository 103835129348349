import React, { useState, createContext, useContext, useCallback } from 'react';
import { useNanoformActionsContext, useUserActionsContext } from 'src/context';
import {
  IUserActiveLicense,
  IUserActiveLicenseResponse,
  IActiveLicenseModelGroup,
  ICoreWithModels,
  ICore,
} from 'src/model';
import { useAuth0 } from '@auth0/auth0-react';
import { userService } from 'src/services';

interface IProps {
  children: JSX.Element;
}

export interface IExperimentData {
  researchName: string;
  selectedModelsIds: string[];
  selectedModelsNames: string[];
  coreName: string;
  useShape: boolean;
  showOnlyFreeModels: boolean;
  onlyDemoModelsSelected: boolean;
}

export interface IDynamicAttribute {
  name: string;
  type: string;
  values: string[];
}

export interface IAttributeInfo {
  label: string;
  nanoformDynamicAttribute: IDynamicAttribute;
  pickerOptions: string[];
}

export interface IGroupSelector {
  group: IActiveLicenseModelGroup;
  selectedModelsIds: string[];
  selectedDemoModelsIds: string[];
}

export interface INewExperimentWizardContextModel {
  isWizardOpen: boolean;
  currentStep: number;
  attributesInfo: IAttributeInfo[];
  experimentData: IExperimentData;
  chosenLicense: IUserActiveLicense;
  licenseSkipped: boolean;
  disablingCondition: boolean;
  groupSelectors: IGroupSelector[];
  demoCores: ICoreWithModels[];
  availableModelGroups: IActiveLicenseModelGroup[];
  availableCores: ICore[];
  activeIndex: number;
}

export interface INewExperimentWizardActionsContextModel {
  onWizardOpen: () => void;
  onWizardClose: () => void;
  reset: () => void;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setAttributesInfo: React.Dispatch<React.SetStateAction<IAttributeInfo[]>>;
  setExperimentData: React.Dispatch<React.SetStateAction<IExperimentData>>;
  setChosenLicense: React.Dispatch<React.SetStateAction<IUserActiveLicense>>;
  setLicenseSkipped: React.Dispatch<React.SetStateAction<boolean>>;
  setDisablingCondition: React.Dispatch<React.SetStateAction<boolean>>;
  setGroupSelectors: React.Dispatch<React.SetStateAction<IGroupSelector[]>>;
  setDemoCores: React.Dispatch<React.SetStateAction<ICoreWithModels[]>>;
  setAvailableModelGroups: React.Dispatch<React.SetStateAction<IActiveLicenseModelGroup[]>>;
  setAvailableCores: React.Dispatch<React.SetStateAction<ICore[]>>;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  fetchUserChosenLicense: (licenseId: string) => Promise<void>;
}

const initialExperimentData: IExperimentData = {
  researchName: '',
  selectedModelsIds: [],
  selectedModelsNames: [],
  coreName: '',
  useShape: true,
  showOnlyFreeModels: false,
  onlyDemoModelsSelected: false,
};

const initChosenLicense: IUserActiveLicense = {
  id: '',
  type: '',
  cores: [],
  models: [],
  predictionAttemptsCount: 0,
};

const NewExperimentWizardContext = createContext<INewExperimentWizardContextModel>(
  {} as INewExperimentWizardContextModel
);
const NewExperimentWizardActionsContext = createContext({} as INewExperimentWizardActionsContextModel);

export const useNewExperimentWizardContext: () => INewExperimentWizardContextModel = () =>
  useContext(NewExperimentWizardContext);
export const useNewExperimentWizardActionsContext: () => INewExperimentWizardActionsContextModel = () =>
  useContext(NewExperimentWizardActionsContext);

export const NewExperimentWizardContextProvider: React.FC<IProps> = ({ children }) => {
  const [isWizardOpen, setIsWizardOpen] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [attributesInfo, setAttributesInfo] = useState<IAttributeInfo[]>([]);
  const [experimentData, setExperimentData] = useState<IExperimentData>(initialExperimentData);
  const [chosenLicense, setChosenLicense] = useState<IUserActiveLicense>(initChosenLicense);
  const [licenseSkipped, setLicenseSkipped] = useState<boolean>(false);
  const [disablingCondition, setDisablingCondition] = useState<boolean>(false);
  const [groupSelectors, setGroupSelectors] = useState<IGroupSelector[]>([]);
  const [demoCores, setDemoCores] = useState<ICoreWithModels[]>([]);
  const [availableModelGroups, setAvailableModelGroups] = useState<IActiveLicenseModelGroup[]>([]);
  const [availableCores, setAvailableCores] = useState<ICore[]>([]);
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const { user, getAccessTokenSilently } = useAuth0();

  const { resetNanoform } = useNanoformActionsContext();
  const { setUserData } = useUserActionsContext();

  const reset = useCallback(() => {
    resetNanoform();
    setAttributesInfo([]);
    setExperimentData(initialExperimentData);
    setChosenLicense(initChosenLicense);
    setGroupSelectors([]);
    setDemoCores([]);
    setAvailableModelGroups([]);
    setActiveIndex(-1);
  }, [resetNanoform]);

  const onWizardOpen = useCallback(() => {
    document.body.style.overflowY = 'hidden';
    setIsWizardOpen(true);
  }, []);

  const onWizardClose = useCallback(() => {
    document.body.style.overflowY = 'auto';
    setCurrentStep(licenseSkipped ? 2 : 1);
    setIsWizardOpen(false);
    setChosenLicense(initChosenLicense);
    setGroupSelectors([]);
    setDemoCores([]);
    setAvailableModelGroups([]);
    setActiveIndex(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenseSkipped]);

  const fetchUserChosenLicense = async (licenseId: string) => {
    if (!user && !user.sub) {
      return;
    }
    try {
      const token = await getAccessTokenSilently();
      const userActiveLicenseData: IUserActiveLicenseResponse = await userService
        .getUserActiveLicense(user.sub, licenseId, token)
        .then((response) => response.data);

      userActiveLicenseData.modelGroups.sort((a, b) => (a.order > b.order ? 1 : -1));
      userActiveLicenseData.modelGroups.forEach((modelGroup) =>
        modelGroup.models.sort((a, b) => (a.order > b.order ? 1 : -1))
      );
      setUserData((prevState) => ({
        ...prevState,
        userActiveLicenseData,
      }));
      setCurrentStep(2);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <NewExperimentWizardContext.Provider
      value={{
        isWizardOpen,
        currentStep,
        attributesInfo,
        experimentData,
        chosenLicense,
        licenseSkipped,
        groupSelectors,
        disablingCondition,
        demoCores,
        availableModelGroups,
        availableCores,
        activeIndex,
      }}
    >
      <NewExperimentWizardActionsContext.Provider
        value={{
          onWizardOpen,
          onWizardClose,
          setCurrentStep,
          setAttributesInfo,
          reset,
          setExperimentData,
          setChosenLicense,
          setLicenseSkipped,
          fetchUserChosenLicense,
          setGroupSelectors,
          setDisablingCondition,
          setDemoCores,
          setAvailableModelGroups,
          setAvailableCores,
          setActiveIndex,
        }}
      >
        {children}
      </NewExperimentWizardActionsContext.Provider>
    </NewExperimentWizardContext.Provider>
  );
};
