import React from 'react';
import { Flex, useRadioGroup } from '@chakra-ui/react';
import { IShapeOption } from 'src/model';
import { ShapePickerCategory } from 'src/components';

interface IShapePickerGroup {
  value: string | number;
  invalid: boolean;
  spheroidalOptions: IShapeOption[];
  elongatedOptions: IShapeOption[];
  plateletOptions: IShapeOption[];
  otherOptions: IShapeOption[];
  spheroidalTitle: string;
  elongatedTitle: string;
  plateletTitle: string;
  otherTitle: string;
  onChangeShape: (value: string | number) => void;
}

export const ShapePickerGroup: React.FC<IShapePickerGroup> = ({
  value,
  invalid,
  spheroidalOptions,
  elongatedOptions,
  plateletOptions,
  otherOptions,
  spheroidalTitle,
  elongatedTitle,
  plateletTitle,
  otherTitle,
  onChangeShape,
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    onChange: (newValue: string | number) => {
      onChangeShape(newValue);
    },
    value,
  });

  const group = getRootProps();
  return (
    <Flex {...group} flexDirection="column">
      <ShapePickerCategory
        title={spheroidalTitle}
        options={spheroidalOptions}
        invalid={invalid}
        getRadioProps={getRadioProps}
      />
      <ShapePickerCategory
        title={elongatedTitle}
        options={elongatedOptions}
        invalid={invalid}
        getRadioProps={getRadioProps}
      />
      <ShapePickerCategory
        title={plateletTitle}
        options={plateletOptions}
        invalid={invalid}
        getRadioProps={getRadioProps}
      />
      <ShapePickerCategory title={otherTitle} options={otherOptions} invalid={invalid} getRadioProps={getRadioProps} />
    </Flex>
  );
};
