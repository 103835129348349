import React from 'react';
import { Flex } from '@chakra-ui/react';
import { LogoHeader, Spinner } from 'src/components';
import { useDocumentTitle } from 'src/hooks';

export const RedirectLoading: React.FC = () => {
  useDocumentTitle('Redirecting...');

  return (
    <Flex className="redirect-loading" w="100vw" h="100vh" direction="column">
      <LogoHeader />
      <Flex w="100%" flex="1" justifyContent="center" alignItems="center">
        <Spinner thickness="8px" speed="1s" size="xl" w="100px" h="100px" label="Redirecting.." />
      </Flex>
    </Flex>
  );
};
