import React, { useEffect, useState, useRef } from 'react';
import { Box, Flex, Spacer } from '@chakra-ui/react';
import { ReactComponent as ChakraSelectIcon } from 'src/assets/chakra-select.svg';
import { AiOutlineCheck } from 'react-icons/ai';

interface IOption {
  option: string;
  enabled: boolean;
}

interface IUncontrolledMultiPickerProps {
  defaultTitle: string;
  options: string[];
  values?: string[];
  isDisabled?: boolean;
  isInvalid?: boolean;
  selectHoverBg?: string;
  selectedBg?: string;
  selectedTextColor?: string;
  selectHoverTextColor?: string;
  selectHoverBorder?: boolean;
  onChange?: (index: number, option: string, enable: boolean) => void;
  onChangeNoIdx?: (option: string, enable: boolean) => void;
}

export const UncontrolledMultiPicker: React.FC<IUncontrolledMultiPickerProps> = ({
  defaultTitle,
  options,
  values,
  isInvalid,
  selectHoverBg,
  selectedBg,
  selectedTextColor,
  selectHoverTextColor,
  selectHoverBorder,
  onChange,
  onChangeNoIdx,
}) => {
  const [allOptions, setAllOptions] = useState<IOption[]>([]);
  const [focus, setFocus] = useState<boolean>(false);
  const [mouseOver, setMouseOver] = useState<boolean>(false);
  const [selectedBackground, setSelecedBackground] = useState<string>('#FFFFFF');
  const [selectedTextColour, setSelectedTextColour] = useState<string>('');
  const [selectHoverBackground, setSelectHoverBackground] = useState<string>('#E0E4EB');
  const [selectHoverTextColour, setSelectHoverTextColour] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const ref = useRef<HTMLDivElement>(null);

  const handleFocusChange = (value: boolean) => {
    if (!mouseOver) {
      setFocus(value);
    }
  };

  const handleClick = () => {
    setFocus(!focus);
  };

  const getTitle = (): string => {
    if (selectedOptions.length === 0) return defaultTitle;

    return selectedOptions.join(', ');
  };

  const onClickItem = (index: number, option: string, enabled: boolean) => {
    const newAllOptions = [...allOptions];

    newAllOptions[index].enabled = !enabled;

    if (selectedOptions.includes(option)) {
      const newSelectedOptions = selectedOptions.filter((opt) => opt !== option);
      setSelectedOptions(newSelectedOptions);
    } else {
      const newSelectedOptions = [...selectedOptions];
      newSelectedOptions.push(option);
      newSelectedOptions.sort();
      setSelectedOptions(newSelectedOptions);
    }

    setAllOptions(newAllOptions);

    if (onChange) {
      onChange(index, option, enabled);
    }

    if (onChangeNoIdx) {
      onChangeNoIdx(option, enabled);
    }
  };

  const GetBorderColor = (): string => {
    if (isInvalid && !focus) {
      return 'utils.alert';
    }

    if (focus) {
      return '#A1AAC7';
    }

    return '#B4B7BA';
  };

  const GetBoxShadow = (): string => {
    if (isInvalid && !focus) {
      return '0 0 0 1px #FF2138';
    }

    if (focus) {
      return '0 0 0 1px #3182ce';
    }

    return 'none';
  };

  useEffect(() => {
    if (options.filter((val, idx, arr) => arr.indexOf(val) === idx).length !== options.length) {
      setErrorMessage('Options are not unique');
      return;
    }
    if (allOptions.length === 0) {
      let newAllOptions: IOption[] = [];
      newAllOptions = options.map((o) => {
        return { option: o, enabled: false };
      });
      setAllOptions(newAllOptions);
    }
    if (selectHoverBg) {
      setSelectHoverBackground(selectHoverBg);
    }
    if (selectHoverTextColor) {
      setSelectHoverTextColour(selectHoverTextColor);
    }
    if (selectedBg) {
      setSelecedBackground(selectedBg);
    }
    if (selectedTextColor) {
      setSelectedTextColour(selectedTextColor);
    }
    if (allOptions.length === 0) {
      return;
    }

    const newAllOptions: IOption[] = [];
    for (let i = 0; i < options.length; i += 1) {
      for (let j = 0; j < allOptions.length; j += 1) {
        if (options[i] === allOptions[j].option) {
          const res = values?.find((v) => v === options[i]);
          allOptions[j].enabled = res !== undefined;
          newAllOptions.push(allOptions[j]);
          break;
        } else if (j === allOptions.length - 1) {
          newAllOptions.push({ option: options[i], enabled: false });
        }
      }
    }
    setAllOptions(newAllOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, values]);

  useEffect(() => {
    if (!values) return;
    setSelectedOptions(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <Box position="relative">
      <Flex
        borderColor={GetBorderColor()}
        boxShadow={GetBoxShadow()}
        borderRadius="0.25rem"
        border={focus ? '' : ''}
        borderWidth={focus ? '3px' : '1px'}
        outlineColor={focus ? '#1b4380' : ''}
        minHeight="3rem"
        bg="white"
        paddingLeft={4}
        paddingRight={2.5}
        fontSize="1.125rem"
        fontWeight="normal"
        tabIndex={-1}
        transition="all"
        transitionDuration="0.2s"
        zIndex={focus ? 1 : 0}
        justifyContent="center"
        userSelect="none"
        width="100%"
        position="relative"
        onClick={() => handleClick()}
        onMouseOver={() => setMouseOver(true)}
        onMouseOut={() => setMouseOver(false)}
        ref={ref}
      >
        <Box
          cursor="pointer"
          textAlign="left"
          my="auto"
          paddingY={2}
          textColor={selectedOptions.length === 0 ? 'dimgrey' : ''}
        >
          {getTitle()}
        </Box>
        <Spacer />
        <Box
          my="auto"
          fontSize="1.25rem"
          transform={focus ? 'rotate(180deg)' : ''}
          transition="all"
          transitionDuration="0.2s"
        >
          <ChakraSelectIcon />
        </Box>
      </Flex>
      {errorMessage && <Box textColor="red">{errorMessage}</Box>}
      {focus && !errorMessage && (
        <Box
          position="relative"
          zIndex={1}
          onBlur={() => handleFocusChange(false)}
          tabIndex={-1}
          ref={(elem) => elem?.focus?.()}
        >
          <Box position="absolute" width="100%">
            <Box bg="white" mt="2px" boxShadow="2xl" border="solid" borderWidth="1px">
              {allOptions.map((option, index) => (
                <Box
                  key={option.option}
                  boxSizing="border-box"
                  bg={option.enabled ? selectedBackground : 'white'}
                  textColor={option.enabled ? selectedTextColour : ''}
                  border="solid"
                  borderColor="transparent"
                  borderWidth="1px"
                  userSelect="none"
                  _hover={{
                    bg: selectHoverBackground.length ? selectHoverBackground : selectedBackground,
                    textColor: selectHoverTextColor ? selectHoverTextColour : '',
                    border: 'solid',
                    borderColor: selectHoverBorder ? '#000000' : 'transparent',
                    borderWidth: '1px',
                    borderTopColor: index !== 0 && selectHoverBorder ? '#000000' : 'transparent',
                    borderBottomColor: index !== allOptions.length - 1 && selectHoverBorder ? '#000000' : 'transparent',
                    borderLeftColor: 'transparent',
                    borderRightColor: 'transparent',
                  }}
                  cursor="pointer"
                  onClick={() => onClickItem(index, option.option, option.enabled)}
                >
                  <Flex>
                    <Box visibility={option.enabled ? 'visible' : 'hidden'} my="auto" mx={2}>
                      <AiOutlineCheck />
                    </Box>
                    <Box pr="1rem">{option.option}</Box>
                  </Flex>
                </Box>
              ))}
            </Box>
            <Box opacity={1} minH={10} />
          </Box>
        </Box>
      )}
    </Box>
  );
};
