import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';

export interface IPopupBackgroundProps {
  position: 'absolute' | 'fixed';
  children: JSX.Element;
}

export const PopupBackground: FC<IPopupBackgroundProps> = ({ position, children }) => {
  return (
    <Flex
      width="full"
      height="100%"
      position={position}
      bg="rgba(0,0,0,0.5)"
      left={0}
      top={0}
      zIndex={position === 'fixed' ? 1000 : 997}
      flexDirection="column"
    >
      {children}
    </Flex>
  );
};
