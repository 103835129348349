import * as yup from 'yup';
import { maxLengthValidationText, requiredValidationText } from 'src/utils';
import { newModelWizardResources } from 'src/components';

export const createEditAttributeYupSchema = (): yup.AnyObjectSchema =>
  yup.object().shape({
    name: yup
      .string()
      .max(50, ({ max }) => maxLengthValidationText(max))
      .required(({ label }) => requiredValidationText(label))
      .label(newModelWizardResources.createAttribute.name.label),
    label: yup
      .string()
      .max(50, ({ max }) => maxLengthValidationText(max))
      .required(({ label }) => requiredValidationText(label))
      .label(newModelWizardResources.createAttribute.label.label),
    type: yup
      .string()
      .required(({ label }) => requiredValidationText(label))
      .label(newModelWizardResources.createAttribute.type.label),
  });
