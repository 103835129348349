import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  Spinner,
  SimpleGrid,
  useTheme,
  VStack,
  FormErrorMessage,
  Switch,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import {
  ReportTypeRadioOptionGroup,
  ControlledInput,
  ControlledNumberInput,
  ControlledSelect,
  ControlledCoreRadioGroup,
  WizardNav,
} from 'src/components';
import { DATE_FORMAT, scrollbar, PRICE_REGEX } from 'src/helpers';
import {
  useNewModelWizardActionsContext,
  useNewModelWizardContext,
  useLoaderContext,
  useLoaderActionsContext,
} from 'src/context';
import { IModelGroup, ReportType, CreateModelFormInputs, INameUniquenessResponse, ICore, ModelStatus } from 'src/model';
import { coreService, modelService, modelGroupsService } from 'src/services';
import { createModelYupSchema, patternValidationText } from 'src/utils';
import { useErrorToast, useDocumentTitle } from 'src/hooks';
import { newModelWizardResources } from './newModelWizardResources';

interface ICreateModelProps {
  onNext: () => void;
}

export const CreateEditModel: React.FC<ICreateModelProps> = ({ onNext }) => {
  useDocumentTitle(newModelWizardResources.steps.createModel);

  const [isNameUnique, setIsNameUnique] = useState<boolean>(true);
  const [isEndpointUnique, setIsEndpointUnique] = useState<boolean>(true);
  const [statusError, setStatusError] = useState<string>('');

  const { getAccessTokenSilently } = useAuth0();

  const isLoading = useLoaderContext();
  const setIsLoading = useLoaderActionsContext();

  const { createModel, toasts } = newModelWizardResources;
  const { validationErrorToast } = toasts;
  const formValidationErrorToast = useErrorToast(validationErrorToast.title, validationErrorToast.description);
  const onError = () => formValidationErrorToast();
  const [oneNanoPriceErrorMessage, setOneNanoPriceErrorMessage] = useState<string>('');
  const [manyNanoPriceErrorMessage, setManyNanoPriceErrorMessage] = useState<string>('');

  const {
    colors: { input },
  } = useTheme();

  const {
    model: {
      id,
      status,
      name,
      coreId,
      coreName,
      isDemo,
      endpoint,
      order,
      modelGroupId,
      groupName,
      dependentVariable,
      manyNanoformsPrice,
      oneNanoformPrice,
      reportType,
      createdDate,
    },
    isModelEdited,
  } = useNewModelWizardContext();

  const methods = useForm<CreateModelFormInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(createModelYupSchema()),
    defaultValues: {
      name,
      status: status.toString(),
      endpoint,
      order: order.toString(),
      modelGroupId,
      dependentVariable,
      manyNanoformsPrice: manyNanoformsPrice.toString(),
      oneNanoformPrice: oneNanoformPrice.toString(),
      reportType,
      coreId: ' ',
      createdDate: dayjs(createdDate).format(DATE_FORMAT).toString(),
    },
    shouldFocusError: false,
  });

  const { handleSubmit, control, setValue, errors } = methods;

  const doPricesHaveErrors = (): boolean => {
    if (isDemo) return false;
    let error = false;
    if (!PRICE_REGEX.test(manyNanoformsPrice.toString())) {
      setManyNanoPriceErrorMessage(patternValidationText);
      error = true;
    } else {
      setManyNanoPriceErrorMessage('');
    }

    if (!PRICE_REGEX.test(oneNanoformPrice.toString())) {
      setOneNanoPriceErrorMessage(patternValidationText);
      error = true;
    } else {
      setOneNanoPriceErrorMessage('');
    }

    return error;
  };

  const statusRequired = `'Status' is a required field.`;

  const validateStatus = (): boolean => {
    if (isModelEdited && !status) {
      setStatusError(statusRequired);
      return false;
    }

    setStatusError('');
    return true;
  };

  const onSubmit = async () => {
    if (doPricesHaveErrors()) return;
    if (!validateStatus()) return;

    setIsLoading(true);

    try {
      const token = await getAccessTokenSilently();
      const namesUniquenessData: INameUniquenessResponse = await modelService
        .validateNameUniqueness(name, endpoint, token, isModelEdited ? id : '')
        .then((response) => response.data);

      if (namesUniquenessData.endpointIsUnique && namesUniquenessData.nameIsUnique) {
        onNext();
      } else {
        setIsNameUnique(namesUniquenessData.nameIsUnique);
        setIsEndpointUnique(namesUniquenessData.endpointIsUnique);
        formValidationErrorToast();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const { setModel } = useNewModelWizardActionsContext();

  const fetchCores = async (): Promise<ICore[]> => {
    const token = await getAccessTokenSilently();
    return coreService.getAllCores(token).then((response: AxiosResponse) => response.data);
  };

  const fetchModelGroups = async (): Promise<IModelGroup[]> => {
    const token = await getAccessTokenSilently();
    return modelGroupsService.getAllModelGroups(token).then((response: AxiosResponse) => response.data);
  };

  const { data: cores, isLoading: coresLoading } = useQuery('core-list', fetchCores);
  const { data: modelGroups, isLoading: modelGroupsLoading } = useQuery('modelGroups-list', fetchModelGroups);

  const onChangeStatus = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;

    if (value) setStatusError('');
    else setStatusError(statusRequired);

    setModel((prevState) => ({ ...prevState, status: value as ModelStatus }));
  };

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setModel((prevState) => ({ ...prevState, name: value }));
    setIsNameUnique(true);
  };

  const onChangeEndpoint = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setModel((prevState) => ({ ...prevState, endpoint: value }));
    setIsEndpointUnique(true);
  };

  const onChangeManyNanoformsPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (!PRICE_REGEX.test(value)) {
      setManyNanoPriceErrorMessage(patternValidationText);
    } else {
      setManyNanoPriceErrorMessage('');
    }
    setModel((prevState) => ({ ...prevState, manyNanoformsPrice: ((value || 0) as unknown) as number }));
  };

  const onChangeOneNanoformPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setModel((prevState) => ({ ...prevState, oneNanoformPrice: ((value || 0) as unknown) as number }));
  };

  const onChangeDependentVariable = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setModel((prevState) => ({ ...prevState, dependentVariable: value }));
  };

  const onChangeOrder = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setModel((prevState) => ({ ...prevState, order: ((value || 0) as unknown) as number }));
  };

  const onChangeGroup = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;
    if (!modelGroups) {
      return;
    }
    const groupId = modelGroups.find((modelGroup) => modelGroup.name === value)?.id;

    setModel((prevState) => ({ ...prevState, modelGroupId: groupId as string }));
  };

  const onChangeCore = async (changedName: string) => {
    const matchingCore: ICore | undefined = cores?.find((item: ICore) => item.name === changedName);

    if (matchingCore === undefined) return;

    setModel((prevState) => ({ ...prevState, coreId: matchingCore.id as string }));
  };

  const onChangeReportType = (value: string | number) => {
    setModel((prevState) => ({ ...prevState, reportType: value as ReportType }));
  };

  const onChangeDemo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModel((prevState) => ({ ...prevState, isDemo: event.target.checked as boolean }));
  };

  const getCoreName = (coreid: string): string => {
    const matchingCore: ICore | undefined = cores?.find((item: ICore) => item.id === coreid);
    return matchingCore ? matchingCore.name : '';
  };

  const getDefaultCoreValue = (): string => {
    if (!cores || cores.length === 0) return '';
    if (isModelEdited && coreName) return coreName;
    if (coreId) return getCoreName(coreId);
    return cores[0].name;
  };

  useEffect(() => {
    if (!cores || cores.length === 0 || coreId) return;

    setModel((prevState) => ({ ...prevState, coreId: cores[0].id as string }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cores]);

  useEffect(() => {
    if (!modelGroups) return;

    if (isModelEdited && !modelGroupId) {
      setModel((prevState) => ({
        ...prevState,
        modelGroupId: modelGroups.find((mg) => mg.name === groupName)?.id as string,
      }));
    }

    setValue('modelGroupId', modelGroups.find((modelGroup) => modelGroup.id === modelGroupId)?.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelGroups, modelGroupId, setValue, isModelEdited, groupName]);

  return (
    <>
      {coresLoading && modelGroupsLoading && (
        <Flex w="100%" flex="1" justifyContent="center" alignItems="center">
          <Spinner thickness="8px" speed="1s" size="xl" w="100px" h="100px" label="Loading.." />
        </Flex>
      )}
      {cores && !coresLoading && modelGroups && !modelGroupsLoading && (
        <Box h="100%" pt={16} px="14%" sx={scrollbar} overflow="auto">
          <form name="create-model">
            {isModelEdited && (
              <SimpleGrid spacing={16} columns={[1, 1, 2, 2, 2]}>
                <FormControl id="status" mb={4} isInvalid={!!statusError}>
                  <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
                    {createModel.status.label}
                  </FormLabel>
                  <ControlledSelect
                    name="status"
                    control={control}
                    placeholderName={createModel.status.placeholder}
                    onChangeSelect={onChangeStatus}
                    options={Object.values(ModelStatus)}
                  />
                  <FormErrorMessage>{statusError}</FormErrorMessage>
                </FormControl>
              </SimpleGrid>
            )}
            <FormControl id="core" mb={4} isInvalid={!!errors.coreId}>
              <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
                {createModel.core.label}
              </FormLabel>
              <Controller
                name="coreId"
                control={control}
                render={({ ref, onChange, name: nameFromController }, { invalid }) => (
                  <ControlledCoreRadioGroup
                    name={nameFromController}
                    controllerRef={ref}
                    onChange={onChange}
                    onChangeCore={onChangeCore}
                    invalid={invalid}
                    defaultValue={getDefaultCoreValue()}
                    options={isModelEdited ? cores.filter((c) => c.name === coreName) : cores}
                  />
                )}
              />
              {errors.coreId?.message && <FormErrorMessage>{errors.coreId?.message}</FormErrorMessage>}
            </FormControl>
            <SimpleGrid
              className="model-creator-version-fields-grid"
              spacing={16}
              columns={[1, 2, 2, 2, 2]}
              minChildWidth="200px"
            >
              <VStack>
                <FormControl id="name" mb={4} isInvalid={!!errors.name?.message || !isNameUnique}>
                  <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
                    {createModel.modelName.label}
                  </FormLabel>
                  <ControlledInput
                    name="name"
                    control={control}
                    onChangeInput={onChangeName}
                    placeholder={createModel.modelName.placeholder}
                  />
                  {errors.name?.message && <FormErrorMessage>{errors.name?.message}</FormErrorMessage>}
                  {!isNameUnique && <FormErrorMessage>{createModel.modelName.unique}</FormErrorMessage>}
                </FormControl>
                <FormControl id="endpoint" mb={4} isInvalid={!!errors.endpoint?.message || !isEndpointUnique}>
                  <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
                    {createModel.endpoint.label}
                  </FormLabel>
                  <ControlledInput
                    name="endpoint"
                    control={control}
                    onChangeInput={onChangeEndpoint}
                    placeholder={createModel.endpoint.placeholder}
                  />
                  {errors.endpoint?.message && <FormErrorMessage>{errors.endpoint?.message}</FormErrorMessage>}
                  {!isEndpointUnique && <FormErrorMessage>{createModel.endpoint.unique}</FormErrorMessage>}
                </FormControl>
                <FormControl id="dependentVariable" mb={4} isInvalid={!!errors.dependentVariable?.message}>
                  <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
                    {createModel.dependentVariable.label}
                  </FormLabel>
                  <ControlledInput
                    name="dependentVariable"
                    control={control}
                    onChangeInput={onChangeDependentVariable}
                    placeholder={createModel.dependentVariable.placeholder}
                  />
                  <FormErrorMessage>{errors.dependentVariable?.message}</FormErrorMessage>
                </FormControl>
                <FormControl id="createdDate" mb={4} isInvalid={!!errors.createdDate?.message}>
                  <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
                    {createModel.date.label}
                  </FormLabel>
                  <ControlledInput name="createdDate" control={control} isDisabled />
                  <FormErrorMessage>{errors.createdDate?.message}</FormErrorMessage>
                </FormControl>
              </VStack>
              <VStack>
                <FormControl id="model-group-id" mb={4} isInvalid={!!errors.modelGroupId?.message}>
                  <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
                    {createModel.propertyGroup.label}
                  </FormLabel>
                  <ControlledSelect
                    name="modelGroupId"
                    control={control}
                    placeholderName={createModel.propertyGroup.placeholder}
                    onChangeSelect={onChangeGroup}
                    options={[
                      ...modelGroups
                        .sort((a, b) => a.order - b.order)
                        .map((modelGroup: IModelGroup) => modelGroup.name),
                    ]}
                  />
                  <FormErrorMessage>{errors.modelGroupId?.message}</FormErrorMessage>
                </FormControl>
                <SimpleGrid
                  className="model-creator-fields-grid"
                  spacing="20px"
                  columns={[1, 2, 2, 2, 2]}
                  minChildWidth="320px"
                  w="100%"
                >
                  <FormControl id="one-nanoform-price" mb={1} isInvalid={!!oneNanoPriceErrorMessage && !isDemo}>
                    <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
                      {createModel.priceOneNanoform.label}
                    </FormLabel>
                    <InputGroup>
                      <ControlledNumberInput
                        name="oneNanoformPrice"
                        control={control}
                        onChangeNumberInput={onChangeOneNanoformPrice}
                        isDisabled={isDemo}
                      />
                      <InputRightElement pointerEvents="none" color={input.placeholder}>
                        EUR
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{oneNanoPriceErrorMessage}</FormErrorMessage>
                  </FormControl>
                  <FormControl id="many-nanoforms-price" mb={1} isInvalid={!!manyNanoPriceErrorMessage && !isDemo}>
                    <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
                      {createModel.priceManyNanoforms.label}
                    </FormLabel>
                    <InputGroup>
                      <ControlledNumberInput
                        name="manyNanoformsPrice"
                        control={control}
                        onChangeNumberInput={onChangeManyNanoformsPrice}
                        isDisabled={isDemo}
                      />
                      <InputRightElement pointerEvents="none" color={input.placeholder}>
                        EUR
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{manyNanoPriceErrorMessage}</FormErrorMessage>
                  </FormControl>
                  <FormControl id="order" mb={4} isInvalid={!!errors.order?.message}>
                    <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
                      {createModel.order.label}
                    </FormLabel>
                    <ControlledNumberInput name="order" control={control} onChangeNumberInput={onChangeOrder} />
                    <FormErrorMessage>{errors.order?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl mb={4} isInvalid={!!errors.reportType?.message}>
                    <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
                      {createModel.reportType.label}
                    </FormLabel>
                    <Controller
                      name="reportType"
                      control={control}
                      render={({ ref, onChange, name: nameFromController }, { invalid }) => (
                        <ReportTypeRadioOptionGroup
                          controllerRef={ref}
                          name={nameFromController}
                          onChange={onChange}
                          onChangeReportType={onChangeReportType}
                          defaultValue={reportType}
                          reportTypes={createModel.reportType.options}
                          invalid={invalid}
                        />
                      )}
                    />
                    <FormErrorMessage>{errors.reportType?.message}</FormErrorMessage>
                  </FormControl>
                </SimpleGrid>
                <FormControl id="isDemo">
                  <Box width="100%">
                    <Box fontSize="2xl" fontWeight="bold" mb="1rem">
                      Free of charge model
                    </Box>
                    <Flex
                      bgColor="white"
                      paddingX="1rem"
                      height="3rem"
                      borderRadius="0.25rem"
                      fontSize="1.125rem"
                      border="solid"
                      borderWidth="1px"
                      borderColor="#B4B7BA"
                    >
                      <Box my="auto">Free</Box>
                      <Box my="auto" ml="auto">
                        <Switch size="md" isChecked={isDemo} onChange={onChangeDemo} />
                      </Box>
                    </Flex>
                  </Box>
                </FormControl>
              </VStack>
            </SimpleGrid>
          </form>
          <Box className="spacer-for-firefox" h="6rem" w="100%" />
          <Box px="15%" py={4} position="absolute" bottom="0" left="0" w="calc(100% - 15px)">
            <Box position="absolute" top="0" left="0" right="0" bottom="0" bg="utils.background" opacity="0.7" />
            <WizardNav
              nextLabel={newModelWizardResources.buttons.next}
              onNext={() => handleSubmit(onSubmit, onError)()}
              buttonSize="lg"
              buttonMaxWidth="320px"
              isNextLoading={isLoading}
            />
          </Box>
        </Box>
      )}
    </>
  );
};
