import React, { useEffect, useCallback, useState } from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Spinner, UserLicensesList, LicenseBuyOptionsGrid } from 'src/components';
import { userService, userConfig } from 'src/services';
import {
  useUserContext,
  useUserDataModalActionsContext,
  useBuyLicenceContext,
  useManageLicensesActionsContext,
  useNewExperimentWizardContext,
} from 'src/context';
import { QUERY_LIMIT, userLicensesViewResources } from 'src/helpers';
import { LicensesResponse, LicenseStatus } from 'src/model';
import { useIsAdmin, useDocumentTitle } from 'src/hooks';
import { IUserLicensesInfoResponse } from 'src/model/userLicensesInfoResponse.model';

export const Licenses: React.FC = () => {
  useDocumentTitle(userLicensesViewResources.documentTitle);

  const { getAccessTokenSilently } = useAuth0();
  const { defaultSelectorValue, setSelector, setDefaultCoreName } = useBuyLicenceContext();
  const { experimentData } = useNewExperimentWizardContext();

  const [licenseStatus, setLicenseStatus] = useState<LicenseStatus | undefined>(undefined);
  const {
    userDataFromApi: { id, isRequiredDataFilled, canPurchaseLicense, status, email },
  } = useUserContext();

  const isAdmin = useIsAdmin();
  const { onOpen } = useUserDataModalActionsContext();
  const { setUserInfo } = useManageLicensesActionsContext();

  const { heading, licenseInfo } = userLicensesViewResources;

  const fetchLicenses = async ({ pageParam = 0 }) => {
    const token = await getAccessTokenSilently();
    return userService
      .getUserLicenses(id, QUERY_LIMIT, pageParam, token)
      .then((response: AxiosResponse) => response.data);
  };

  const fetchUserLicensesInfo = async () => {
    const token = await getAccessTokenSilently();
    return userService.getUserLicensesInfo(id, token).then((response: AxiosResponse) => response.data);
  };

  const { data, isLoading: isListLoading, isError, hasNextPage, fetchNextPage } = useInfiniteQuery(
    'user-licenses',
    fetchLicenses,
    {
      getNextPageParam: (lastPage: LicensesResponse) => (lastPage.hasNext ? lastPage.offset + QUERY_LIMIT : undefined),
      enabled: Boolean(id),
    }
  );

  const { data: userLicensesInfo, isLoading: userLicensesInfoLoading } = useQuery<IUserLicensesInfoResponse>(
    ['fetch-user-licenses-info', id],
    fetchUserLicensesInfo,
    { enabled: Boolean(id) }
  );

  const renderText = useCallback(
    (licensestatus: LicenseStatus | undefined) => {
      switch (licensestatus) {
        case LicenseStatus.active:
          return <Text color="custom.glaucous">{licenseInfo.active}</Text>;
        case LicenseStatus.waiting:
          return <Text color="custom.glaucous">{licenseInfo.waiting}</Text>;
        default:
          return <Text color="custom.glaucous">{licenseInfo.inActive}</Text>;
      }
    },
    [licenseInfo]
  );

  const backgroundColor = useCallback((licensestatus: LicenseStatus | undefined) => {
    switch (licensestatus) {
      case LicenseStatus.active:
        return 'typo.green';
      case LicenseStatus.waiting:
        return 'utils.gold';
      default:
        return 'utils.alert';
    }
  }, []);

  useEffect(() => {
    if (userLicensesInfo) {
      if (userLicensesInfo.hasPaymentInProgress) {
        setLicenseStatus(LicenseStatus.waiting);
        return;
      }
      if (userLicensesInfo.hasActiveLicense) {
        setLicenseStatus(LicenseStatus.active);
        return;
      }
      setLicenseStatus(LicenseStatus.inActive);
      return;
    }
    setLicenseStatus(undefined);
  }, [userLicensesInfo]);

  useEffect(() => {
    if (!isRequiredDataFilled && !isAdmin && !isListLoading) {
      const wasModelDisplayedLocalStorageValue = userConfig.modalDisplayedToUser(id);

      const wasModalDisplayed = wasModelDisplayedLocalStorageValue
        ? JSON.parse(wasModelDisplayedLocalStorageValue)
        : false;

      if (!wasModalDisplayed) {
        userConfig.setModalDisplayedToUser(id, JSON.stringify(true));
        onOpen();
      }
    }
  }, [isAdmin, onOpen, isRequiredDataFilled, id, isListLoading]);

  useEffect(() => {
    setUserInfo({ userId: id, status, email, role: 'User' });
    setSelector(defaultSelectorValue);

    if (experimentData.coreName) {
      setDefaultCoreName(experimentData.coreName);
    } else {
      setDefaultCoreName(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      className="user-licenses"
      w="100%"
      minH="100%"
      overflowY="auto"
      id="user-licenses"
      p={10}
      flexDirection="column"
    >
      <Box as="header" mb={4}>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading fontWeight="700" size="xl" flex={1}>
            {heading}
          </Heading>
        </Flex>
        {userLicensesInfo && (
          <Flex alignItems="center" mt={1}>
            <Box
              as="span"
              className="dot"
              w="12px"
              h="12px"
              mx="7px"
              borderRadius="50%"
              bg={backgroundColor(licenseStatus)}
            />
            {renderText(licenseStatus)}
          </Flex>
        )}
      </Box>
      {(isListLoading || userLicensesInfoLoading) && (
        <Flex w="100%" flex="1" justifyContent="center" alignItems="center">
          <Spinner thickness="8px" speed="1s" size="xl" w="100px" h="100px" label="Loading.." />
        </Flex>
      )}
      {!isListLoading && canPurchaseLicense && <LicenseBuyOptionsGrid />}
      {data && hasNextPage !== undefined && !isError && (
        <UserLicensesList
          data={[...data.pages.map((page: LicensesResponse) => page.list)].flat()}
          getMoreData={fetchNextPage}
          hasMore={hasNextPage}
          userRole="User"
          licensesUserId={id}
        />
      )}
    </Flex>
  );
};
