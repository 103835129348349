import React from 'react';
import { Controller, Control } from 'react-hook-form';
import { Textarea } from '@chakra-ui/react';

interface IControlledTextAreaProps {
  name: string;
  control: Control<Record<string, unknown>>;
  onChangeTextArea: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const ControlledTextArea: React.FC<IControlledTextAreaProps> = ({ name, control, onChangeTextArea }) => (
  <Controller
    name={name}
    control={control}
    render={({ onChange, name: renderName, value, ref }, { invalid }) => (
      <Textarea
        variant="outline"
        type="text"
        ref={ref}
        name={renderName}
        borderWidth="1px"
        value={value}
        errorBorderColor="utils.alert"
        onChange={(e) => {
          onChange(e);
          if (onChangeTextArea) {
            onChangeTextArea(e);
          }
        }}
        resize="vertical"
        bg="white"
        size="lg"
        minH="200px"
        isInvalid={invalid}
      />
    )}
  />
);
