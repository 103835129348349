import React, { useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Flex, Heading } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { AxiosResponse } from 'axios';
import { useInfiniteQuery } from 'react-query';
import { PrimaryButton, NewExperimentWizard, Spinner, PredictionsList } from 'src/components';
import {
  useNewExperimentWizardContext,
  useNewExperimentWizardActionsContext,
  useUserContext,
  useLoaderContext,
  useUserActionsContext,
  useBuyLicenceContext,
} from 'src/context';
import { laboratoryViewResources, QUERY_LIMIT } from 'src/helpers';
import { PredictionsResponse } from 'src/model';
import { userService } from 'src/services';
import { useDocumentTitle } from 'src/hooks';

export const Laboratory: React.FC = () => {
  useDocumentTitle(laboratoryViewResources.documentTitle);

  const { heading, newExperimentButtonText } = laboratoryViewResources;
  const { isWizardOpen } = useNewExperimentWizardContext();
  const {
    onWizardOpen,
    reset,
    setCurrentStep,
    fetchUserChosenLicense,
    setLicenseSkipped,
    setChosenLicense,
    onWizardClose,
  } = useNewExperimentWizardActionsContext();
  const loading = useLoaderContext();
  const { userActiveLicenses } = useUserContext();
  const { fetchUserActiveLicenses } = useUserActionsContext();
  const { setDefaultCoreName } = useBuyLicenceContext();
  const { getAccessTokenSilently } = useAuth0();

  const {
    userDataFromApi: { id },
  } = useUserContext();

  const fetchPredictions = async ({ pageParam = 0 }) => {
    const token = await getAccessTokenSilently();
    return userService
      .getUserPredictions(id, null, QUERY_LIMIT, pageParam, token)
      .then((response: AxiosResponse) => response.data);
  };

  const { data, isLoading, isError, hasNextPage, fetchNextPage } = useInfiniteQuery(
    ['predictions-list', id],
    fetchPredictions,
    {
      getNextPageParam: (lastPage: PredictionsResponse) =>
        lastPage.hasNext ? lastPage.offset + QUERY_LIMIT : undefined,
      enabled: Boolean(id),
    }
  );

  useEffect(() => {
    if (!userActiveLicenses) return;
    setDefaultCoreName(undefined);
    if (userActiveLicenses.length === 0) return;
    if (userActiveLicenses.length > 1) {
      setCurrentStep(1);
      return;
    }
    fetchUserChosenLicense(userActiveLicenses[0].id).then(() => {
      setChosenLicense(userActiveLicenses[0]);
      setLicenseSkipped(true);
      const demoItem = localStorage.getItem('demo');
      const demo: boolean = JSON.parse(demoItem ? JSON.parse(demoItem) : false);
      const stripeCallbackStatusItem = localStorage.getItem('stripeCallbackStatus');
      const stripeCallbackStatus = JSON.parse(stripeCallbackStatusItem ? JSON.parse(stripeCallbackStatusItem) : false);
      if (!demo || !stripeCallbackStatus) return;
      localStorage.setItem('demo', JSON.stringify(false));
      localStorage.setItem('stripeCallbackStatus', JSON.stringify(false));
      onWizardOpen();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userActiveLicenses]);

  useEffect(() => {
    onWizardClose();
    fetchUserActiveLicenses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex className="laboratory" w="100%" minH="100%" p={10} flexDirection="column" overflowY="auto">
      <Flex as="header" className="laboratory__header" justifyContent="space-between" alignItems="center" pb={10}>
        <Heading className="header__title" fontWeight="700" size="xl">
          {heading}
        </Heading>
        {userActiveLicenses && (
          <PrimaryButton
            className="new-study-button"
            size="sm"
            onClick={() => {
              fetchUserActiveLicenses();
              onWizardOpen();
            }}
            isLoading={loading}
            disabled={userActiveLicenses.length === 0}
          >
            {newExperimentButtonText}
          </PrimaryButton>
        )}
      </Flex>
      {(loading || isLoading) && (
        <Flex flex="1" justifyContent="center" alignItems="center">
          <Spinner thickness="8px" speed="1s" size="xl" w="100px" h="100px" label="Loading.." />
        </Flex>
      )}
      <AnimatePresence exitBeforeEnter onExitComplete={() => reset()}>
        {isWizardOpen && <NewExperimentWizard />}
      </AnimatePresence>
      {data && hasNextPage !== undefined && !isError && (
        <PredictionsList
          data={[...data.pages.map((page: PredictionsResponse) => page.list)].flat()}
          getMoreData={fetchNextPage}
          hasMore={hasNextPage}
        />
      )}
    </Flex>
  );
};
