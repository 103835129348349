import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

interface IEmptyListState {
  title: string;
}

export const EmptyListState: React.FC<IEmptyListState> = ({ title }) => (
  <Flex justifyContent="center" alignItems="center" p={10}>
    <Text fontSize="xl" fontWeight="600">
      {title}
    </Text>
  </Flex>
);
