import React, { useRef, useState, useLayoutEffect } from 'react';
import { motion } from 'framer-motion';
import { Flex, Box, CloseButton } from '@chakra-ui/react';
import {
  Step,
  MemoizedVisualisation,
  DefineNanoform,
  SelectEndpoints,
  SelectSubstance,
  SelectLicense,
} from 'src/components';
import { useNewExperimentWizardActionsContext, useNewExperimentWizardContext } from 'src/context';
import { newExperimentWizardResources } from './newExperimentWizardResources';

const newExperimentWizardVariants = {
  hidden: {
    x: '100vw',
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 50,
    },
  },
  visible: {
    x: 0,
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 50,
    },
  },
};

export const NewExperimentWizard: React.FC = () => {
  const { onWizardClose } = useNewExperimentWizardActionsContext();
  const { currentStep, licenseSkipped } = useNewExperimentWizardContext();
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  const { steps } = newExperimentWizardResources;

  const header = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (header.current !== null) {
      setHeaderHeight(header.current.clientHeight);
    }
  }, [setHeaderHeight]);

  return (
    <Flex
      as={motion.div}
      variants={newExperimentWizardVariants}
      initial="hidden"
      animate="visible"
      exit="hidden"
      className="new-study-wizard"
      position="fixed"
      top="0"
      left="0"
      flexDirection="column"
      h="100%"
      w="100%"
      bg="utils.background"
      zIndex={999}
    >
      <Flex
        className="new-study-wizard__header"
        alignItems="center"
        bg="typo.white"
        boxShadow="0px 3px 12px #00000029"
        zIndex="1"
        ref={header}
      >
        <CloseButton
          className="new-study-wizard__close-button"
          size="lg"
          fontSize="1.5rem"
          mx={5}
          onClick={() => onWizardClose()}
        />
        <Flex className="new-study-wizard__steps" flex="1" overflow="hidden">
          <Step
            isActive={Boolean(currentStep === 1)}
            isCompleted={Boolean(currentStep > 1)}
            stepNumber={1}
            title={steps.selectLicense}
            isAnimated={licenseSkipped}
          />
          <Step
            isActive={Boolean(currentStep === 2)}
            stepNumber={2}
            isCompleted={Boolean(currentStep > 2)}
            title={steps.selectSubstance}
          />
          <Step
            isActive={Boolean(currentStep === 3)}
            isCompleted={Boolean(currentStep > 3)}
            stepNumber={3}
            title={steps.selectEndpoints}
          />
          <Step
            isActive={Boolean(currentStep === 4)}
            isCompleted={Boolean(currentStep > 4)}
            stepNumber={4}
            title={steps.defineNanoform}
          />
        </Flex>
      </Flex>
      <Flex className="new-study-wizard__body" flex="1" h={`calc(100vh - ${headerHeight}px)`}>
        <Box
          className="new-study-wizard__configurator"
          minH="100%"
          width="33%"
          bg="utils.background"
          borderRightWidth="1px"
          borderColor="input.border"
          position="relative"
        >
          {currentStep === 1 && <SelectLicense />}
          {currentStep === 2 && <SelectSubstance />}
          {currentStep === 3 && <SelectEndpoints />}
          {currentStep === 4 && <DefineNanoform />}
        </Box>
        <Box className="new-study-wizard__visualization" minH="100%" width="67%">
          <MemoizedVisualisation />
        </Box>
      </Flex>
    </Flex>
  );
};
