import { AxiosResponse } from 'axios';

export const downloadFile = (response: AxiosResponse, filename: string): void => {
  const blob = new Blob([response.data], {
    type: 'application/octet-stream',
  });
  const a = document.createElement('a');
  const url = URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
};
