const tableData = {
  position: 'relative',
  fontWeight: 500,
  textAlign: 'left',
  padding: '1rem 1.5rem',
  lineHeight: '1.25rem',
  background: 'typo.white',
  borderColor: 'input.border',
  minWidth: 0,
  marginBottom: '1.25rem',
  borderWidth: '1px 0 1px 0',
};

export const structuralAnaloguesTableStyles = {
  '& table': {
    tableLayout: 'fixed',
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 10px',
    '& thead th, & tbody tr:first-of-type td, & tbody tr:first-of-type th': {
      ...tableData,
      backgroundColor: 'radio.bg',
      fontWeight: 700,
    },
    '& thead th': {
      '&:first-of-type': {
        paddingLeft: '2.5rem !important',
        borderRadius: '0.25rem 0 0 0.25rem',
        borderWidth: '1px 0 1px 1px !important',
      },
      '&:last-of-type': {
        borderRadius: '0 0.25rem 0.25rem 0',
        borderWidth: '1px 1px 1px 0 !important',
        fontWeight: 700,
      },
    },
    '& tbody': {
      '& td, & th': tableData,
      '& th:first-of-type': {
        paddingLeft: '2.5rem !important',
        borderRadius: '0.25rem 0 0 0.25rem',
        borderWidth: '1px 0 1px 1px !important',
      },
      '& td:last-of-type': {
        borderRadius: '0 0.25rem 0.25rem 0',
        borderWidth: '1px 1px 1px 0 !important',
        fontWeight: 700,
      },
    },
  },
};
