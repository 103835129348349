import { AxiosPromise, AxiosResponse } from 'axios';
import { authorizedApiService } from './api.service';

class ModelGroupsService {
  getAllModelGroups = (token: string): AxiosPromise => {
    const apiUrl = `/modelgroups`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };
}

export const modelGroupsService = new ModelGroupsService();
