import React, { useRef } from 'react';
import { useFrame } from 'react-three-fiber';
import { Html } from '@react-three/drei/web/Html';
import { Line } from '@react-three/drei/core/Line';
import { WhiteGreenDot, ModelText } from 'src/components';
import { INanoformProps } from 'src/model';
import { Blob } from 'src/components/visualisation/shapes';

export const Inconclusive: React.FC<INanoformProps> = ({
  core,
  coreModification,
  coreColor,
  surfaceFunctionalization,
  surfaceFunctionalizationColor,
  lineColor,
  baseRotation,
  isNanoformImageGenerated,
}) => {
  // eslint-disable-next-line
  const modelGroup = useRef<any>();
  const y = baseRotation[1];

  useFrame(() => {
    if (modelGroup.current) {
      if (isNanoformImageGenerated) {
        modelGroup.current.rotation.y = y;
      } else {
        modelGroup.current.rotation.y += 0.005;
      }
    }
  });

  return (
    <group>
      <Blob
        coreColor={coreColor}
        surfaceFunctionalization={surfaceFunctionalization}
        surfaceFunctionalizationColor={surfaceFunctionalizationColor}
      />
      {Boolean(core) && (
        <group position={[0, 0, 0]}>
          <Html className="core" position={[1.2, 1, 2]} zIndexRange={[0, 1]}>
            <ModelText
              dotSize="12px"
              text={coreModification ? `${core?.name}/${coreModification}` : (core?.name as string)}
              variant="right"
              dotColor={lineColor}
            />
          </Html>
          <Html className="center" position={[0, 0, 2]} zIndexRange={[0, 1]}>
            <WhiteGreenDot size="20px" dotColor={lineColor} />
          </Html>
          <Line
            points={[
              [0, 0, 2],
              [0.7, 1, 2],
              [1.2, 1, 2],
            ]}
            lineWidth={1.5}
            color={lineColor}
          />
        </group>
      )}
      {Boolean(surfaceFunctionalization) && (
        <group position={[0, 0, 0]}>
          <Html className="core--left" position={[-1.2, 0.9, 2]} zIndexRange={[0, 1]}>
            <ModelText dotSize="12px" text={surfaceFunctionalization as string} variant="left" dotColor={lineColor} />
          </Html>
          <Html className="center" position={[-0.3, 0.65, 2]} zIndexRange={[0, 1]}>
            <WhiteGreenDot size="20px" dotColor={lineColor} />
          </Html>
          <Line
            points={[
              [-0.3, 0.65, 2],
              [-0.7, 0.9, 2],
              [-1.2, 0.9, 2],
            ]}
            lineWidth={1.5}
            color={lineColor}
          />
        </group>
      )}
    </group>
  );
};
