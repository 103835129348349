import React, { useCallback } from 'react';
import dayjs from 'dayjs';
import InfiniteScroll from 'react-infinite-scroller';
import { Table, Tbody, Box, Tr, Text, Badge } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { TableHeader, TableData, EmptyListState } from 'src/components';
import { DATE_FORMAT, LicenseType, manageModelsLicensesEmptyList, userLicensesViewResources } from 'src/helpers';
import { IModelLicenseListItem, LicenseStatus, UserStatus } from 'src/model';

interface IModelLicensesListProps {
  data: IModelLicenseListItem[];
  getMoreData: () => void;
  hasMore: boolean;
}

export const ModelLicensesList: React.FC<IModelLicensesListProps> = ({ data, getMoreData, hasMore }) => {
  const history = useHistory();

  const licenseStatus = useCallback((license: IModelLicenseListItem) => {
    if (license.hasWaitingPayment && !license.isDeactivated) {
      return LicenseStatus.waiting;
    }
    if (license.isActive) {
      return LicenseStatus.active;
    }
    return LicenseStatus.inActive;
  }, []);

  const licenseStatusBadge = useCallback((status: LicenseStatus) => {
    switch (status) {
      case LicenseStatus.active:
        return (
          <Badge colorScheme="green" py={1} px="12px" fontSize="md" color="typo.green" fontWeight="500">
            {userLicensesViewResources.active}
          </Badge>
        );
      case LicenseStatus.waiting:
        return (
          <Badge colorScheme="orange" py={1} px="12px" fontSize="md" color="utils.gold" fontWeight="500">
            {userLicensesViewResources.waiting}
          </Badge>
        );
      default:
        return (
          <Badge colorScheme="red" py={1} px="12px" fontSize="md" color="utils.alert" fontWeight="500">
            {userLicensesViewResources.inActive}
          </Badge>
        );
    }
  }, []);

  return (
    <Box className="model-versions-list" w="100%">
      {data.length ? (
        <InfiniteScroll pageStart={0} hasMore={hasMore} loadMore={getMoreData} useWindow>
          <Table
            variant="unstyled"
            w="100%"
            h="100%"
            sx={{ borderCollapse: 'separate', borderSpacing: '0 10px', tableLayout: 'auto' }}
            cellSpacing="0"
          >
            <TableHeader titles={['User', 'License', 'Status', 'Purchased', 'Expiration']} />
            <Tbody>
              {data.map((license: IModelLicenseListItem) => (
                <Tr
                  key={`model-license-${license.id}`}
                  h="82px"
                  cursor="pointer"
                  onClick={() =>
                    history.push(`/licensedetails/${license.id}`, {
                      userId: '',
                      userRole: 'Admin',
                      email: license.user,
                      status: UserStatus.Active,
                    })
                  }
                >
                  <TableData w="45%">
                    <Text fontWeight="700" fontSize="1.125rem" overflowWrap="normal">
                      {`${license.user.name} ${license.user.surname}`}
                    </Text>
                    <Text whiteSpace="nowrap">{license.user.email}</Text>
                  </TableData>
                  <TableData w="35%">
                    <Text fontWeight="700" fontSize="1.125rem" whiteSpace="nowrap">
                      {LicenseType[license.type as keyof typeof LicenseType].main}
                    </Text>
                    <Text whiteSpace="nowrap">{LicenseType[license.type as keyof typeof LicenseType].sub}</Text>
                  </TableData>
                  <TableData>{licenseStatusBadge(licenseStatus(license))}</TableData>
                  <TableData w="10%">{dayjs(license.purchaseDate).format(DATE_FORMAT)}</TableData>
                  <TableData w="10%">
                    {license.expirationDate ? dayjs(license.expirationDate).format(DATE_FORMAT) : '-'}
                  </TableData>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </InfiniteScroll>
      ) : (
        <EmptyListState title={manageModelsLicensesEmptyList} />
      )}
    </Box>
  );
};
