import { extendTheme } from '@chakra-ui/react';

const Button = {
  baseStyle: {
    fontWeight: 'bold',
    borderRadius: '0.25rem',
  },
  sizes: {
    xl: {
      h: '70px',
      fontSize: '2xl',
      w: '100%',
      maxWidth: '325px',
    },
    sm: {
      h: '52px',
      fontSize: 'md',
      px: '16px',
    },
  },
  defaultProps: {
    size: 'sm',
    variant: 'solid',
  },
};

const Heading = {
  baseStyle: {
    fontFamily: 'Inter',
  },
};

const Input = {
  baseStyle: {
    fontSize: 'md',
  },
  variants: {
    outline: {
      field: {
        bg: 'input.bg',
        color: 'input.text',
        borderRadius: '0.25rem',
        borderWidth: '1px',
        borderColor: 'input.border',
        _focus: {
          borderColor: 'input.focusBorder',
          borderWidth: '2px',
        },
        _placeholder: { color: 'input.placeholder' },
        _disabled: { bg: 'input.disabledBg', text: 'input.disabledText' },
      },
    },
  },
  defaultProps: {
    size: 'lg',
    variant: 'outline',
  },
};

const FormLabel = {
  baseStyle: {
    fontSize: 'sm',
    fontWeight: 'normal',
  },
};

const Select = {
  baseStyle: {
    fontSize: 'md',
  },
  variants: {
    outline: {
      field: {
        bg: 'input.bg',
        borderColor: 'input.border',
        borderRadius: '0.25rem',
        _focus: {
          borderColor: 'input.focusBorder',
          borderWidth: '3px',
        },
      },
    },
  },
  defaultProps: {
    size: 'lg',
    variant: 'outline',
  },
};

export const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: 'utils.background',
        color: 'typo.blue',
        fontWeight: 500,
        fontFamily: 'Inter',
      },
    },
  },
  fonts: {
    heading: `"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    body: `"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  },
  colors: {
    brand: {
      50: '#e4f2ff',
      100: '#bdd4f6',
      200: '#95b7ea',
      300: '#6c9be0',
      400: '#437ed6',
      500: '#2b65bd',
      600: '#1f4e93',
      700: '#14386a',
      800: '#072242',
      900: '#000b1c',
    },
    typo: {
      green: '#42A14D',
      blue: '#1B4480',
      white: '#FFFFFF',
      grey: '#767676',
    },
    input: {
      placeholder: '#767676',
      border: '#B4B7BA',
      text: '#1B4480',
      bg: '#FFFFFF',
      focusBorder: '#A1AAC7',
      disabledBg: '#B4B7BA',
      disabledText: '#767676',
    },
    button: {
      blue: '#1B4480',
      white: '#FFFFFF',
      red: '#C21242',
      hoverPrimary: '#11356A',
      hoverCancel: '#AE103B',
      hoverSecondary: '#CCDCF5',
      disabledPrimaryBg: '#B4B7BA',
      disabledText: '#767676',
      disabledSecondaryText: 'rgba(118, 118, 118, 0.85)',
      disabledSecondaryBorder: 'rgba(137, 137, 137, 0.77)',
    },
    radio: {
      bg: '#F4F4F4',
      border: '#E3E2E2',
    },
    menuButton: {
      bg: '#F4F4F4',
      border: '#E3E2E2',
    },
    particles: {
      line: '#74BC16',
      sizeDistribution: '#435A6F',
    },
    scrollBar: {
      trackBackground: '#F5F5F5',
      greyTrackBackground: '#FFFFFF',
      scrollBackground: '#F5F5F5',
      greyScrollBackground: '#FFFFFF',
      thumbColor: '#42A14D',
      greyThumbColor: '#E3E2E2',
    },
    utils: {
      background: 'linear-gradient(51deg, #f4f8ff 0%, #FDF9F8 100%)',
      border: '#767676',
      alert: '#BE0436',
      modalOverlay: 'rgba(27, 68, 128, 0.6)',
      greenGradient: 'linear-gradient(180deg, #149645 0%, #74AF16 100%)',
      goldGradient: 'linear-gradient(180deg, #DD9B07 0%, #E3AF38 100%)',
      spinnerGreen: '#46A051',
      gold: '#DD9B07',
      boxBorder: '#B4B7BA',
      persianBlue: '#192AC4',
      middleBlue: '#85D3E4',
      midnightGreen: '#124854',
      opal: '#C0D8D5',
    },
    custom: {
      aliceBlue: '#F4F8FF',
      glaucous: '#5C7C9D',
    },
  },
  components: {
    Button,
    FormLabel,
    Input,
    Select,
    Heading,
  },
});
