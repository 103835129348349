import React from 'react';
import { Thead, Tr, Th } from '@chakra-ui/react';

interface ITableHeaderProps {
  titles: string[];
}

export const TableHeader: React.FC<ITableHeaderProps> = ({ titles }) => (
  <Thead className="table-header">
    <Tr>
      {titles.map((title: string) => (
        <Th
          key={`key-${title}`}
          color="typo.grey"
          fontFamily="inherit"
          fontSize="md"
          fontWeight="500"
          letterSpacing="0.32px"
          py={0}
          sx={{
            '&:first-of-type': {
              paddingLeft: '40px',
            },
          }}
        >
          {title}
        </Th>
      ))}
    </Tr>
  </Thead>
);
