import React, { FC, useEffect, useState } from 'react';
import { Box, Flex, Button, useTheme, FormLabel, FormControl, FormErrorMessage, Input } from '@chakra-ui/react';
import { CreateAttributeFormInputs } from 'src/model';
import { createEditAttributeYupSchema } from 'src/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNewModelWizardActionsContext, useNewModelWizardContext } from 'src/context';
import { ControlledInput, ControlledSelect, newModelWizardResources } from 'src/components';
import { useErrorToast } from 'src/hooks';
import { ReactComponent as CloseIcon } from 'src/assets/close.svg';

export interface AttributesAdderProps {
  showAdder(): void;
}

export const AttributesAdder: FC<AttributesAdderProps> = ({ showAdder }) => {
  const {
    colors: { button },
  } = useTheme();

  const [optionsErrors, setOptionsErrors] = useState<string[]>([]);
  const [pickerError, setPickerError] = useState<string>('');
  const [editedIndex, setEditedIndex] = useState<number>(-1);
  const [uniqueNameError, setUniqueNameError] = useState<string>('');
  const { createAttribute, toasts } = newModelWizardResources;
  const { validationErrorToast } = toasts;
  const formValidationErrorToast = useErrorToast(validationErrorToast.title, validationErrorToast.description);
  const onError = () => formValidationErrorToast();

  const { setAttributeModels, setAttributeDefinition, resetAttributeDefinition } = useNewModelWizardActionsContext();

  const {
    attributeDefinition: { id, name, label, type, attributePickerOptions, isEnabled },
    attributeModels,
    attributeTypes,
  } = useNewModelWizardContext();

  const methods = useForm<CreateAttributeFormInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(createEditAttributeYupSchema()),
    defaultValues: {
      name,
      label,
      type,
    },
    shouldFocusError: false,
  });

  const { handleSubmit, control, errors } = methods;

  const renderOptions = (): boolean => {
    return type === attributeTypes[3] || type === attributeTypes[4];
  };

  const validatePickerOptions = (): boolean => {
    if (!renderOptions()) {
      setPickerError('');
      return true;
    }
    if (attributePickerOptions.length === 0) {
      setPickerError(`'${type}' requires atleast one option to be specified`);
      return false;
    }
    if (attributePickerOptions.length !== 0) {
      setPickerError('');
    }
    let noError = true;
    const newOptionsErrors = optionsErrors.map(() => '');

    for (let i = 0; i < attributePickerOptions.length; i += 1) {
      if (attributePickerOptions[i].length === 0) {
        newOptionsErrors[i] = `'Option ${i + 1}' is required `;
        noError = false;
      }
      for (let j = i + 1; j < attributePickerOptions.length; j += 1) {
        if (attributePickerOptions[j].toLocaleLowerCase() === attributePickerOptions[i].toLocaleLowerCase()) {
          newOptionsErrors[j] = `'Option ${j + 1}' is duplicate of 'Option ${i + 1}'`;
          noError = false;
        } else if (!newOptionsErrors[j]) {
          newOptionsErrors[j] = '';
        }
      }
    }
    setOptionsErrors(newOptionsErrors);
    return noError;
  };

  const validateNameUniqueness = (): boolean => {
    for (let i = 0; i < attributeModels.length; i += 1) {
      if (i !== editedIndex && attributeModels[i].attributeDefinition.name === name) {
        setUniqueNameError(
          `Name '${attributeModels[i].attributeDefinition.name}' already exists on attribute with label '${attributeModels[i].attributeDefinition.label}'`
        );
        return false;
      }
    }
    setUniqueNameError('');

    return true;
  };

  const onSubmit = () => {
    if (!validateNameUniqueness() || !validatePickerOptions()) {
      return;
    }
    const newAttributeModels = [...attributeModels];
    if (editedIndex === -1) {
      newAttributeModels.push({
        attributeDefinition: {
          id: null,
          name,
          label,
          type,
          attributePickerOptions,
          order: newAttributeModels.length,
          isEnabled,
        },
        errorMessage: '',
        pickerErrorMessage: '',
        orginalType: type,
      });
    } else if (editedIndex !== -1) {
      newAttributeModels[editedIndex] = {
        attributeDefinition: {
          id,
          name,
          label,
          type,
          attributePickerOptions,
          order: editedIndex + 1,
          isEnabled,
        },
        errorMessage:
          attributeModels[editedIndex].orginalType === type ? '' : attributeModels[editedIndex].errorMessage,
        pickerErrorMessage: '',
        orginalType: newAttributeModels[editedIndex].orginalType,
      };
    }

    setAttributeModels(newAttributeModels);
    resetAttributeDefinition();
    showAdder();
  };

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setAttributeDefinition((prevState) => ({ ...prevState, name: value }));
  };

  const onChangeLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setAttributeDefinition((prevState) => ({ ...prevState, label: value }));
  };

  const onChangeType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;
    setPickerError('');
    setAttributeDefinition((prevState) => ({ ...prevState, type: value }));
  };

  const onChangeOption = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.currentTarget;
    const newAttributePickerOptions = [...attributePickerOptions];
    newAttributePickerOptions[index] = value;
    setAttributeDefinition((prevState) => ({ ...prevState, attributePickerOptions: newAttributePickerOptions }));
  };

  const onClose = () => {
    resetAttributeDefinition();
    showAdder();
  };

  const onAddOption = () => {
    const newAttributePickerOptions = [...attributePickerOptions];
    newAttributePickerOptions.push('');
    setAttributeDefinition((prevState) => ({ ...prevState, attributePickerOptions: newAttributePickerOptions }));

    const newOptionsErrors = [...optionsErrors];
    newOptionsErrors.push('');
    setOptionsErrors(newOptionsErrors);
  };

  const onDeleteOption = (idx: number) => {
    let newAttributePickerOptions = [...attributePickerOptions];
    newAttributePickerOptions = newAttributePickerOptions.filter((_value, index) => index !== idx);
    setAttributeDefinition((prevState) => ({ ...prevState, attributePickerOptions: newAttributePickerOptions }));

    let newOptionsErrors = [...optionsErrors];
    newOptionsErrors = newOptionsErrors.filter((_value, index) => index !== idx);
    setOptionsErrors(newOptionsErrors);
  };

  const keyGenerator = (index: number): string => {
    return index.toString();
  };

  useEffect(() => {
    if (!label) {
      return;
    }
    const newOptionsErrors: string[] = [];
    for (let i = 0; i < attributePickerOptions.length; i += 1) {
      newOptionsErrors.push('');
    }
    const index = attributeModels.findIndex((a) => a.attributeDefinition.name === name);
    setOptionsErrors(newOptionsErrors);
    setEditedIndex(index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      width="full"
      minHeight="full"
      height="fit-content"
      position="fixed"
      bgColor="#748DB1"
      left={0}
      top={0}
      zIndex={1000}
    >
      <Box marginX="auto" marginY="auto" width={2 / 5}>
        <Box width="100%" bg="#EDF2F7" py={6} px={8} rounded="lg">
          <Flex marginBottom={8}>
            <Box fontSize="2xl" fontWeight="bold" marginY="auto">
              {createAttribute.title}
            </Box>
            <Box marginLeft="auto" onClick={() => onClose()} cursor="pointer" marginY="auto">
              <CloseIcon />
            </Box>
          </Flex>
          <form name="create-attribute">
            <FormControl id="name" mb={4} isInvalid={!!errors.name?.message}>
              <FormLabel fontSize="xl" fontWeight="bold" mb={4}>
                {createAttribute.name.label}
              </FormLabel>
              <ControlledInput
                name="name"
                control={control}
                onChangeInput={onChangeName}
                placeholder={createAttribute.name.placeholder}
              />
              {errors.name?.message && <FormErrorMessage>{errors.name?.message}</FormErrorMessage>}
              {uniqueNameError && (
                <Box color="#E53E3E" fontSize="sm" fontWeight="medium">
                  {uniqueNameError}
                </Box>
              )}
            </FormControl>
            <FormControl id="label" mb={4} isInvalid={!!errors.label?.message}>
              <FormLabel fontSize="xl" fontWeight="bold" my={4}>
                {createAttribute.label.label}
              </FormLabel>
              <Flex>
                <ControlledInput
                  name="label"
                  control={control}
                  onChangeInput={onChangeLabel}
                  placeholder={createAttribute.label.placeholer}
                />
              </Flex>

              {errors.label?.message && <FormErrorMessage>{errors.label?.message}</FormErrorMessage>}
            </FormControl>
            <FormControl id="model-group-id" mb={4} isInvalid={!!errors.type?.message || !!pickerError}>
              <FormLabel fontSize="xl" fontWeight="bold" my={4}>
                {createAttribute.type.label}
              </FormLabel>
              <ControlledSelect
                name="type"
                control={control}
                placeholderName={createAttribute.type.placeholer}
                onChangeSelect={onChangeType}
                options={attributeTypes}
              />
              {errors.type?.message && <FormErrorMessage>{errors.type?.message}</FormErrorMessage>}
              {pickerError && (
                <Box color="#E53E3E" fontSize="sm" fontWeight="medium">
                  {pickerError}
                </Box>
              )}
            </FormControl>
            {renderOptions() &&
              attributePickerOptions.map((item, index) => (
                <Box key={keyGenerator(index)}>
                  <Flex>
                    <Input
                      key={keyGenerator(index)}
                      variant="outline"
                      type="text"
                      borderWidth="1px"
                      placeholder={`Option ${index + 1}`}
                      value={item}
                      onChange={(e) => onChangeOption(e, index)}
                      marginY={2}
                      errorBorderColor="utils.alert"
                      isInvalid={optionsErrors.length ? optionsErrors[index].length !== 0 : false}
                    />
                    <Box
                      marginLeft="auto"
                      my="auto"
                      fontSize="sm"
                      fontWeight="medium"
                      cursor="pointer"
                      onClick={() => onDeleteOption(index)}
                    >
                      <CloseIcon />
                    </Box>
                  </Flex>
                  <Flex>
                    <Box color="#E53E3E" fontSize="sm" fontWeight="medium">
                      {optionsErrors[index]}
                    </Box>
                  </Flex>
                </Box>
              ))}
            {renderOptions() && (
              <Box cursor="pointer" onClick={() => onAddOption()}>
                {newModelWizardResources.buttons.addNewOption}
              </Box>
            )}
            <Flex mt={8}>
              <Button
                marginLeft="auto"
                marginY="0"
                py="5"
                height="2"
                onClick={() => {
                  validatePickerOptions();
                  handleSubmit(onSubmit, onError)();
                }}
                variant="solid"
                color={button.white}
                bg={button.blue}
                _hover={{ bg: button.hoverPrimary }}
                _active={{ bg: button.hoverPrimary }}
              >
                {newModelWizardResources.buttons.save}
              </Button>
            </Flex>
          </form>
        </Box>
      </Box>
    </Flex>
  );
};
