import { ILicenseBuyOption, LicenseTypes } from 'src/model';

export const LicenseType = {
  OneNanoformSpecificModels: {
    main: 'One nanoform',
    sub: 'Specific endpoints',
    heading: 'Select endpoints for predictions',
    modelsSelectable: true,
    type: LicenseTypes.OneNanoformSpecificModels,
  },
  OneNanoformAllModels: {
    main: 'One nanoform',
    sub: 'All endpoints',
    heading: 'Your endpoints',
    modelsSelectable: false,
    type: LicenseTypes.OneNanoformAllModels,
  },
  ManyNanoformsSpecificModels: {
    main: 'Many nanoforms',
    sub: 'Specific endpoints',
    heading: 'Select endpoints for predictions',
    modelsSelectable: true,
    type: LicenseTypes.ManyNanoformsSpecificModels,
  },
  OneSubstanceAllModels: {
    main: 'One substance',
    sub: 'All endpoints',
    heading: '',
    modelsSelectable: false,
    type: LicenseTypes.OneSubstanceAllModels,
  },
  AllSubstancesAllModels: {
    main: 'All substances',
    sub: 'All endpoints',
    heading: '',
    modelsSelectable: false,
    type: LicenseTypes.AllSubstancesAllModels,
  },
} as const;

export const oneNanoSpecificEndpoint = {
  title: LicenseType.OneNanoformSpecificModels,
  priceBefore: 'Starts with',
  priceAfter: 'for one endpoint',
  listItems: ['Test one nanoform', 'Choose only endpoints you need'],
  buttonText: 'Choose',
  path: '/buylicense',
  licenseOptions: LicenseType.OneNanoformSpecificModels,
} as const;

export const oneNanoAllEnpoints = {
  title: LicenseType.OneNanoformAllModels,
  priceAfter: 'for all endpoints',
  listItems: ['Test one nanoform', 'Test all endpoints available for one Nanoform'],
  buttonText: 'Choose',
  path: '/buylicense',
  licenseOptions: LicenseType.OneNanoformAllModels,
} as const;

export const oneSubstanceAllModels = {
  title: LicenseType.OneSubstanceAllModels,
  priceBefore: 'Starts with',
  listItems: ['Test as many nanoforms as you want', 'Test all endpoints available for Substance'],
  buttonText: 'Choose',
  path: '/buylicense',
  licenseOptions: LicenseType.OneSubstanceAllModels,
} as const;

export const manyNanoSpecificEndpoint = {
  title: LicenseType.ManyNanoformsSpecificModels,
  priceBefore: 'Starts with',
  priceAfter: 'for one endpoint',
  listItems: ['Test as many nanoforms as you want', 'Choose only endpoints you need', 'Valid for 12 months'],
  buttonText: 'Choose',
  path: '/buylicense',
  licenseOptions: LicenseType.ManyNanoformsSpecificModels,
} as const;

export const allNanoAllEndpoints = {
  title: LicenseType.AllSubstancesAllModels,
  listItems: ['Test as many nanoforms as you want', 'Pick from all available endpoints'],
  buttonText: 'Choose',
  path: '/buylicense',
  extraInfo: { main: 'Contact us to know the price!', sub: `+77 876 555 676, ` },
  licenseOptions: LicenseType.AllSubstancesAllModels,
} as const;

export const licenseBuyOptions: ILicenseBuyOption[] = [
  oneNanoSpecificEndpoint,
  oneNanoAllEnpoints,
  oneSubstanceAllModels,
  manyNanoSpecificEndpoint,
  allNanoAllEndpoints,
];
