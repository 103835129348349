import React, { useState, createContext, useContext, useRef } from 'react';
import { ICore, Shape } from 'src/model';

interface IProps {
  children: JSX.Element;
}

export interface INanoformData {
  core: ICore | undefined;
  coreModification: string;
  shape: Shape | undefined;
  particleSizeDistributionValue: number;
  surfaceFunctionalization: string;
}

export interface INanoformContextModel {
  nanoformData: INanoformData;
  visualisationRef: React.MutableRefObject<HTMLDivElement | null>;
  isNanoformImageGenerated: boolean;
}

const initialNanoformData: INanoformData = {
  core: undefined,
  coreModification: '',
  shape: undefined,
  particleSizeDistributionValue: 0,
  surfaceFunctionalization: '',
};

export interface INanoformActionsContextModel {
  resetNanoform: () => void;
  setNanoformData: React.Dispatch<React.SetStateAction<INanoformData>>;
  setIsNanoformImageGenerated: React.Dispatch<React.SetStateAction<boolean>>;
}

const NanoformContext = createContext<INanoformContextModel>({} as INanoformContextModel);
const NanoformActionsContext = createContext({} as INanoformActionsContextModel);

export const useNanoformContext: () => INanoformContextModel = () => useContext(NanoformContext);
export const useNanoformActionsContext: () => INanoformActionsContextModel = () => useContext(NanoformActionsContext);

export const NanoformContextProvider: React.FC<IProps> = ({ children }) => {
  const [nanoformData, setNanoformData] = useState<INanoformData>(initialNanoformData);
  const resetNanoform = () => setNanoformData(initialNanoformData);
  const [isNanoformImageGenerated, setIsNanoformImageGenerated] = useState<boolean>(false);

  const visualisationRef = useRef<HTMLDivElement | null>(null);

  return (
    <NanoformContext.Provider value={{ nanoformData, visualisationRef, isNanoformImageGenerated }}>
      <NanoformActionsContext.Provider value={{ setNanoformData, resetNanoform, setIsNanoformImageGenerated }}>
        {children}
      </NanoformActionsContext.Provider>
    </NanoformContext.Provider>
  );
};
