import React from 'react';
import dayjs from 'dayjs';
import InfiniteScroll from 'react-infinite-scroller';
import { Table, Tbody, Box, Tr, Text, Badge } from '@chakra-ui/react';
import { TableHeader, TableData, EmptyListState } from 'src/components';
import { DATE_FORMAT, manageModelsEmptyList } from 'src/helpers';
import { IModelListItem, ModelStatus } from 'src/model';
import { useHistory } from 'react-router-dom';

interface IManageModelsListProps {
  data: IModelListItem[];
  getMoreData: () => void;
  hasMore: boolean;
}

export const ManageModelsList: React.FC<IManageModelsListProps> = ({ data, getMoreData, hasMore }) => {
  const history = useHistory();

  const statusBadge = (status: ModelStatus) => {
    if (status === ModelStatus.Active)
      return (
        <Badge colorScheme="green" color="green" py={1} px="12px" fontSize="md" fontWeight="500">
          Active
        </Badge>
      );
    if (status === ModelStatus.ToRemove)
      return (
        <Badge colorScheme="gray" color="gray" py={1} px="12px" fontSize="md" fontWeight="500">
          To Remove
        </Badge>
      );
    return (
      <Badge colorScheme="red" color="utils.alert" py={1} px="12px" fontSize="md" fontWeight="500">
        Deactivated
      </Badge>
    );
  };

  return (
    <Box className="manage-models-list" w="100%">
      {data.length ? (
        <InfiniteScroll pageStart={0} hasMore={hasMore} loadMore={getMoreData} useWindow>
          <Table
            variant="unstyled"
            w="100%"
            h="100%"
            sx={{ borderCollapse: 'separate', borderSpacing: '0 10px', tableLayout: 'fixed' }}
            cellSpacing="0"
          >
            <TableHeader titles={['Model name', 'Endpoint', 'Status', 'Date']} />
            <Tbody>
              {data.map((model: IModelListItem) => (
                <Tr
                  key={`manage-models-${model.endpoint}-${model.createdDate}`}
                  h="82px"
                  onClick={() => history.push(`/modeldetails/${model.id}`)}
                  cursor="pointer"
                >
                  <TableData w="25%" maxW="25%">
                    <Text fontWeight="700" fontSize="1.125rem">
                      {model.name}
                    </Text>
                  </TableData>
                  <TableData w="55%" maxW="55%">
                    <Text>{model.endpoint}</Text>
                  </TableData>
                  <TableData w="20%" maxW="20%">
                    {statusBadge(model.status)}
                  </TableData>
                  <TableData w="20%" maxW="20%">
                    {dayjs(model.createdDate).format(DATE_FORMAT)}
                  </TableData>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </InfiniteScroll>
      ) : (
        <EmptyListState title={manageModelsEmptyList} />
      )}
    </Box>
  );
};
