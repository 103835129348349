import { AxiosPromise, AxiosResponse } from 'axios';
import { authorizedApiService } from './api.service';

class UtilityService {
  sendEmail = (name: string, email: string, category: string, message: string, token: string): AxiosPromise => {
    const apiUrl = `/contact/send-message`;
    return authorizedApiService(token)
      .post(apiUrl, { name, email, category, message })
      .catch((error: AxiosResponse) => Promise.reject(error));
  };
}

export const utilityService = new UtilityService();
