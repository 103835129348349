import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { Flex, Text, Box, List, ListItem, ListIcon, Link as ChakraLink } from '@chakra-ui/react';
import { IoCheckmarkOutline } from 'react-icons/io5';
import { PrimaryButton, Spinner } from 'src/components';
import { ILicenseBuyOption, LicenseTypes } from 'src/model';
import { qsarMail } from 'src/helpers';
import { IUserLicensesInfoResponse } from 'src/model/userLicensesInfoResponse.model';
import { useUserContext, useNewExperimentWizardContext, useBuyLicenceContext } from 'src/context';

export const LicenseBuyOption: React.FC<ILicenseBuyOption> = ({
  title,
  priceAfter,
  priceBefore,
  listItems,
  buttonText,
  extraInfo,
  path,
  licenseOptions,
  advantageousPrice,
  isAdmin,
  userInfo,
}) => {
  const [price, setPrice] = useState<number | undefined>(undefined);
  const queryClient = useQueryClient();

  const {
    userDataFromApi: { id },
  } = useUserContext();

  const data = queryClient.getQueryData<IUserLicensesInfoResponse>(['fetch-user-licenses-info', id]);

  const {
    experimentData: { selectedModelsNames, coreName },
  } = useNewExperimentWizardContext();

  const { setDefaultCoreName } = useBuyLicenceContext();

  useEffect(() => {
    if (!coreName) return;
    setDefaultCoreName(coreName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coreName]);

  useEffect(() => {
    const licenseType = licenseOptions?.type;
    if (data && licenseType) {
      switch (licenseType) {
        case LicenseTypes.OneNanoformAllModels:
        case LicenseTypes.OneNanoformSpecificModels:
        case LicenseTypes.ManyNanoformsSpecificModels:
        case LicenseTypes.OneSubstanceAllModels:
          setPrice(advantageousPrice === undefined ? (data.minPrices[licenseType] as number) : advantageousPrice);
          break;
        default:
          setPrice(undefined);
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, licenseOptions]);

  return (
    <Flex
      className="license-buy-option"
      p={6}
      flexDirection="column"
      bg="typo.white"
      borderRadius="0.25rem"
      borderWidth="1px"
      borderColor="input.border"
      minH="380px"
    >
      <Flex className="license-buy-option__title" mb={7}>
        <Text fontWeight="700">{title.main}</Text>
        <Text fontWeight="500" color="typo.green" textTransform="lowercase" pl="5px">{` / ${title.sub}`}</Text>
      </Flex>
      {!data && !advantageousPrice && licenseOptions?.type !== LicenseTypes.AllSubstancesAllModels && !isAdmin && (
        <Flex w="100%" flex="1" justifyContent="center" alignItems="center">
          <Spinner thickness="8px" speed="1s" size="xl" w="100px" h="100px" label="Loading.." />
        </Flex>
      )}
      {(price || advantageousPrice) && !isAdmin && (
        <Box className="license-buy-option__price-box" position="relative">
          {priceBefore && (
            <Box
              as="span"
              className="price-box__before-info"
              color="custom.glaucous"
              position="absolute"
              left="0"
              top="-18px"
            >
              {priceBefore}
            </Box>
          )}
          <Flex className="price-box__price">
            <Box as="span" className="price-box__currency" color="custom.glaucous" fontWeight="500">
              €
            </Box>
            <Box as="span" className="price-box__amount" fontSize="2.5rem" fontWeight="700">
              {advantageousPrice === undefined ? price?.toFixed(2) : advantageousPrice.toFixed(2)}
            </Box>
            {priceAfter && (
              <Box
                as="span"
                className="price-box__after-info"
                color="custom.glaucous"
                fontWeight="500"
                mt="auto"
                pl="5px"
              >
                {priceAfter}
              </Box>
            )}
          </Flex>
        </Box>
      )}
      {listItems && (
        <Flex flex={1} mb={8} mt={8} alignItems="flex-end">
          <List className="license-buy-option__list" mt="auto">
            {listItems.map((item: string) => (
              <ListItem color="custom.glaucaus" fontWeight="500" display="flex" key={`license-buy-option_list_${item}`}>
                <ListIcon as={IoCheckmarkOutline} color="typo.blue" w="1.3rem" h="1.3rem" />
                <Text color="custom.glaucous">{item}</Text>
              </ListItem>
            ))}
          </List>
        </Flex>
      )}
      {extraInfo && !isAdmin ? (
        <Box>
          <Text fontWeight="700">{extraInfo.main}</Text>
          <Text fontWeight="500">
            {extraInfo.sub}
            {licenseOptions && licenseOptions.type === LicenseTypes.AllSubstancesAllModels && (
              <ChakraLink color="typo.green" fontWeight="700" textDecoration="none" href={`mailto: ${qsarMail}`}>
                {qsarMail}
              </ChakraLink>
            )}
          </Text>
        </Box>
      ) : (
        <Link
          to={{
            pathname: path,
            state: {
              licenseOptions,
              ownedEndpoints: [],
              endpointsToBuy: selectedModelsNames.length > 0 ? selectedModelsNames : [],
              userInfo,
            },
          }}
          style={{ width: '100%' }}
        >
          <PrimaryButton size="lg" w="100%">
            {buttonText}
          </PrimaryButton>
        </Link>
      )}
    </Flex>
  );
};
