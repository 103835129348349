import React, { useEffect } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { Flex, Heading } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import { useAuth0 } from '@auth0/auth0-react';
import { ManageModelsList, Spinner, PrimaryButton, NewModelWizard } from 'src/components';
import { IModelsListResponse, ICore } from 'src/model';
import { manageModelsViewResources, QUERY_LIMIT } from 'src/helpers';
import { modelService, coreService } from 'src/services';
import { useNewModelWizardActionsContext, useNewModelWizardContext } from 'src/context';
import { useDocumentTitle } from 'src/hooks';

export const ManageModels: React.FC = () => {
  const changeDocumentTitle = useDocumentTitle(manageModelsViewResources.documentTitle);

  const { heading, newModelButtonText } = manageModelsViewResources;

  const { isWizardOpen } = useNewModelWizardContext();
  const { onWizardOpen, reset } = useNewModelWizardActionsContext();
  const { initialModelStage } = useNewModelWizardContext();
  const { setModel } = useNewModelWizardActionsContext();
  const { getAccessTokenSilently } = useAuth0();

  const fetchCores = async (): Promise<ICore[]> => {
    const token = await getAccessTokenSilently();
    return coreService.getAllCores(token).then((response: AxiosResponse) => response.data);
  };

  const { data: cores, isLoading: coresLoading } = useQuery('core-list', fetchCores);

  const fetchModels = async ({ pageParam = 0 }) => {
    const token = await getAccessTokenSilently();
    return modelService.getAllModels(QUERY_LIMIT, pageParam, token).then((response: AxiosResponse) => response.data);
  };

  const { data, isLoading, isError, hasNextPage, fetchNextPage } = useInfiniteQuery('manage-models-list', fetchModels, {
    getNextPageParam: (lastPage: IModelsListResponse) => (lastPage.hasNext ? lastPage.offset + QUERY_LIMIT : undefined),
  });

  useEffect(() => {
    setModel(initialModelStage);
  }, [initialModelStage, setModel]);

  return (
    <Flex
      className="manage-models"
      w="100%"
      minH="100%"
      overflowY="auto"
      id="manage-models"
      p={10}
      flexDirection="column"
    >
      <Flex as="header" className="models__header" justifyContent="space-between" alignItems="center" pb={16}>
        <Heading className="header__title" fontWeight="700" size="xl">
          {heading}
        </Heading>
        {!coresLoading && (
          <PrimaryButton
            className="new-model-button"
            size="sm"
            onClick={() => onWizardOpen()}
            isDisabled={cores?.length === 0}
          >
            {newModelButtonText}
          </PrimaryButton>
        )}
      </Flex>
      <AnimatePresence
        exitBeforeEnter
        onExitComplete={() => {
          reset();
          changeDocumentTitle(manageModelsViewResources.documentTitle);
        }}
      >
        {isWizardOpen && <NewModelWizard />}
      </AnimatePresence>
      {isLoading && coresLoading && (
        <Flex w="100%" flex="1" justifyContent="center" alignItems="center">
          <Spinner thickness="8px" speed="1s" size="xl" w="100px" h="100px" label="Loading.." />
        </Flex>
      )}
      {data && hasNextPage !== undefined && !isError && (
        <ManageModelsList
          data={[...data.pages.map((page: IModelsListResponse) => page.list)].flat()}
          getMoreData={fetchNextPage}
          hasMore={hasNextPage}
        />
      )}
    </Flex>
  );
};
