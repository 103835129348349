import React from 'react';
import { Flex } from '@chakra-ui/react';

export const Navbar: React.FC = ({ children }) => (
  <Flex
    className="navbar"
    alignItems="center"
    justifyContent="space-between"
    px={5}
    boxShadow="0px 3px 40px #0000001F"
    bgColor="white"
    zIndex={998}
  >
    {children}
  </Flex>
);
