import React from 'react';
import { Plane } from '@react-three/drei/core/shapes';

export const Floor: React.FC = () => (
  <group position={[0, 0, 0]}>
    <Plane args={[1000, 1000]} position={[0, -1, -4.4]} receiveShadow>
      <meshStandardMaterial color="#fff" />
    </Plane>
    <Plane args={[1000, 1000]} position={[0, -1, -1]} receiveShadow rotation={[Math.PI + Math.PI / 12, 0, 0]}>
      <meshStandardMaterial color="#fff" />
    </Plane>
    <gridHelper rotation={[Math.PI / 12, 0, 0]} position={[0, 1, -10]} args={[25, 15, '#999', '#999']} />
  </group>
);
