export const reportsWizardResources = {
  buttons: {
    next: 'Next',
    back: 'Back',
    save: 'Save',
    create: 'Create',
    test: 'Test',
  },
  steps: {
    addReport: 'Add files',
    editReport: 'Edit report files',
  },
  createEditReport: {
    addTitle: 'Add files',
    editTitle: 'Edit files',
    heading: 'Add report files',
    qsarHeading: 'Add QSAR report files',
    raHeading: 'Add RA report files',
    qsarEditHeading: 'Edit QSAR report files',
    raEditHeading: 'Edit RA report files',
    changeButton: 'Change',
    report: {
      label: 'Report',
    },
    contentTemplate: 'Content template',
    footerTemplate: 'Footer template',
    wrongFileFormat: 'Wrong file format! Upload only html files',
    noFile: 'You need to upload content HTML file',
    uploaded: 'Uploaded',
  },
  toasts: {
    saveCoreError: {
      title: 'An error occurred',
    },
    saveReportSuccess: {
      title: 'Success',
      description: 'Report has been saved correctly',
    },
    validationErrorToast: {
      title: '',
      description: 'Please fill in all required fields',
    },
  },
} as const;
