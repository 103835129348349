import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';
import { ReactComponent as CloseIcon } from 'src/assets/close.svg';
import { PrimaryButton } from 'src/components/common';
import NoLicenseLogo from 'src/assets/no_license_logo.png';
import { useNewExperimentWizardActionsContext } from 'src/context';
import { licensesRoute } from 'src/helpers';
import { newExperimentWizardResources } from '../newExperimentWizardResources';

export interface ILicensePopupProps {
  showPopup(): void;
}

export const LicensePopup: FC<ILicensePopupProps> = ({ showPopup }) => {
  const history = useHistory();
  const {
    licensePopup: { problemMessage, solveMessage, buttonMessage },
  } = newExperimentWizardResources;

  const { onWizardClose } = useNewExperimentWizardActionsContext();

  const onClose = () => {
    showPopup();
  };

  const onExploreLicensesClick = () => {
    localStorage.setItem('demo', JSON.stringify(true));
    onWizardClose();
    history.push(licensesRoute.path);
  };

  return (
    <Flex
      width="full"
      minHeight="full"
      height="fit-content"
      position="fixed"
      bg="rgba(0,0,0,0.5)"
      left={0}
      top={0}
      zIndex={1000}
    >
      <Box marginX="auto" marginY="auto" width={5 / 20} textAlign="center">
        <Box width="100%" bg="#EDF2F7" px={6} py={6}>
          <Flex marginBottom={4}>
            <Box marginLeft="auto" onClick={() => onClose()} cursor="pointer" marginY="auto">
              <CloseIcon />
            </Box>
          </Flex>
          <Flex>
            <Box marginX="auto">
              <Flex>
                <Box marginX="auto">
                  <img src={NoLicenseLogo} alt="" />
                </Box>
              </Flex>
              <Box fontSize="2xl" fontWeight="bold" marginX={4} marginY={4}>
                {problemMessage}
              </Box>
              <Box marginX={10}>{solveMessage}</Box>
              <Flex marginTop={6} marginBottom={4}>
                <PrimaryButton marginX="auto" onClick={() => onExploreLicensesClick()}>
                  {buttonMessage}
                </PrimaryButton>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};
