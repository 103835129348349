import React, { useState, createContext, useContext } from 'react';
import { BuyLicenceSelector } from 'src/model';

interface IProps {
  children: JSX.Element;
}

export interface IBuyLicenceContextModel {
  defaultSelectorValue: BuyLicenceSelector;
  selector: BuyLicenceSelector;
  setSelector: React.Dispatch<React.SetStateAction<BuyLicenceSelector>>;
  defaultCoreName?: string;
  setDefaultCoreName: React.Dispatch<React.SetStateAction<string | undefined>>;
  licenseAdded: boolean;
  setLicenseAdded: React.Dispatch<React.SetStateAction<boolean>>;
}

const BuyLicenceContext = createContext<IBuyLicenceContextModel>({} as IBuyLicenceContextModel);

export const useBuyLicenceContext: () => IBuyLicenceContextModel = () => useContext(BuyLicenceContext);

export const BuyLicenceContextProvider: React.FC<IProps> = ({ children }) => {
  const defaultSelectorValue: BuyLicenceSelector = { cores: [], curentIndex: -1, curentName: undefined };
  const [selector, setSelector] = useState<BuyLicenceSelector>(defaultSelectorValue);
  const [defaultCoreName, setDefaultCoreName] = useState<string | undefined>();
  const [licenseAdded, setLicenseAdded] = useState<boolean>(false);

  return (
    <BuyLicenceContext.Provider
      value={{
        defaultSelectorValue,
        selector,
        setSelector,
        defaultCoreName,
        setDefaultCoreName,
        licenseAdded,
        setLicenseAdded,
      }}
    >
      {children}
    </BuyLicenceContext.Provider>
  );
};
