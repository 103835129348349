import React from 'react';
import { Box } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { AppLogo } from 'src/components';
import { laboratoryRoute } from 'src/helpers';

interface ILogoHeaderProps {
  withLink?: boolean;
}

export const LogoHeader: React.FC<ILogoHeaderProps> = ({ withLink }) => {
  return (
    <Box
      className="logo-header"
      w="100%"
      position="absolute"
      top="0"
      left="0"
      p={5}
      h="90px"
      bgColor="white"
      sx={{
        '& a': {
          display: 'inline-block',
        },
      }}
    >
      {withLink ? (
        <Link to={laboratoryRoute.path}>
          <AppLogo h="50px" />
        </Link>
      ) : (
        <AppLogo h="50px" />
      )}
    </Box>
  );
};
