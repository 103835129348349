import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Flex, Heading } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import { useAuth0 } from '@auth0/auth0-react';
import { CoreList, Spinner, PrimaryButton, CoreWizard } from 'src/components';
import { manageCoresViewResources } from 'src/helpers';
import { coreService } from 'src/services';
import { useCoreWizardActionsContext, useCoreWizardContext } from 'src/context';
import { useDocumentTitle } from 'src/hooks';
import { ICore } from 'src/model';

export const ManageCores: React.FC = () => {
  const changeDocumentTitle = useDocumentTitle(manageCoresViewResources.documentTitle);
  const [isLoading, setLoading] = useState<boolean>(false);
  const { heading, newCoreButtonText } = manageCoresViewResources;
  const { isWizardOpen, core, coresList } = useCoreWizardContext();
  const { onWizardOpen, reset, setCoresList } = useCoreWizardActionsContext();

  const { getAccessTokenSilently } = useAuth0();

  const fetchCores = async () => {
    const token = await getAccessTokenSilently();
    const cores: ICore[] = await coreService.getAllCores(token).then((response: AxiosResponse) => response.data);

    if (cores.length !== 0) {
      setCoresList(cores);
    }

    return cores;
  };

  useEffect(() => {
    setLoading(true);
    fetchCores();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      className="manage-cores"
      w="100%"
      minH="100%"
      overflowY="auto"
      id="manage-cores"
      p={10}
      flexDirection="column"
    >
      <Flex as="header" className="cores__header" justifyContent="space-between" alignItems="center" pb={16}>
        <Heading className="header__title" fontWeight="700" size="xl">
          {heading}
        </Heading>
        <PrimaryButton className="new-core-button" size="sm" onClick={() => onWizardOpen()}>
          {newCoreButtonText}
        </PrimaryButton>
      </Flex>
      <AnimatePresence
        exitBeforeEnter
        onExitComplete={() => {
          reset();
          changeDocumentTitle(manageCoresViewResources.documentTitle);
        }}
      >
        {isWizardOpen && <CoreWizard CoreId={core.id.length !== 0 ? core.id : undefined} />}
      </AnimatePresence>
      {isLoading && (
        <Flex w="100%" flex="1" justifyContent="center" alignItems="center">
          <Spinner thickness="8px" speed="1s" size="xl" w="100px" h="100px" label="Loading.." />
        </Flex>
      )}
      {coresList && !isLoading && <CoreList data={coresList} />}
    </Flex>
  );
};
