import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { ReactComponent as CloseIcon } from 'src/assets/close.svg';
import { manageLayoutsResources } from 'src/helpers';
import { PrimaryButton } from 'src/components';
import { useReportWizardActionsContext } from 'src/context';

export const EditReportPopupMessage: FC = () => {
  const { handlePopupVisibility, onWizardOpen, setReportId } = useReportWizardActionsContext();

  return (
    <Flex
      width="full"
      minHeight="full"
      height="fit-content"
      position="fixed"
      bgColor="#748DB1"
      left={0}
      top={0}
      zIndex={1000}
    >
      <Box marginX="auto" marginY="auto" width={2 / 5}>
        <Box width="100%" bg="#EDF2F7" py={6} px={8} rounded="lg">
          <Flex marginBottom={8}>
            <Box fontSize="4xl" fontWeight="bold" marginY="auto">
              {manageLayoutsResources.popUp.title}
            </Box>
            <Box
              marginLeft="auto"
              onClick={() => {
                setReportId('');
                handlePopupVisibility();
              }}
              cursor="pointer"
              marginY="auto"
            >
              <CloseIcon />
            </Box>
          </Flex>
          {manageLayoutsResources.popUp.description}
          <Flex mt={8} mb={4}>
            <PrimaryButton
              onClick={() => {
                onWizardOpen();
                handlePopupVisibility();
              }}
              ml="auto"
            >
              {manageLayoutsResources.popUp.button}
            </PrimaryButton>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};
