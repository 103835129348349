import React from 'react';
import { Controller, Control } from 'react-hook-form';
import { Input } from '@chakra-ui/react';

const numberInputPlaceholder = 'e.g. 100.50';

interface IControlledNumberInputProps {
  name: string;
  control: Control<Record<string, unknown>>;
  onChangeNumberInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
  placeholder?: string;
}

export const ControlledNumberInput: React.FC<IControlledNumberInputProps> = ({
  name,
  control,
  onChangeNumberInput,
  isDisabled,
  placeholder = numberInputPlaceholder,
}) => (
  <Controller
    name={name}
    control={control}
    render={({ onChange, name: renderName, value, ref }, { invalid }) => (
      <Input
        variant="outline"
        type="number"
        ref={ref}
        name={renderName}
        borderWidth="1px"
        value={value}
        placeholder={placeholder}
        errorBorderColor="utils.alert"
        onChange={(e) => {
          onChange(e);
          if (onChangeNumberInput) {
            onChangeNumberInput(e);
          }
        }}
        isInvalid={invalid}
        isDisabled={isDisabled}
      />
    )}
  />
);
