import React from 'react';
import { PrimaryButton, SecondaryButton } from 'src/components';
import { Flex } from '@chakra-ui/react';

interface IWizardNavProps {
  onBack?: () => void;
  onNext: () => void;
  nextLabel: string;
  backLabel?: string;
  nextDisableCondition?: boolean;
  backDisableCondition?: boolean;
  buttonSize?: string;
  buttonWidth?: string;
  buttonMaxWidth?: string;
  isNextLoading?: boolean;
  isBackLoading?: boolean;
}

export const WizardNav: React.FC<IWizardNavProps> = ({
  onBack,
  onNext,
  nextDisableCondition,
  backDisableCondition,
  nextLabel,
  backLabel,
  buttonSize,
  buttonWidth,
  buttonMaxWidth,
  isNextLoading,
  isBackLoading,
}) => (
  <Flex className="wizard-nav" w="100%" justifyContent={backLabel ? 'space-between' : 'flex-end'}>
    {backLabel && (
      <SecondaryButton
        disabled={backDisableCondition}
        onClick={onBack}
        w={buttonWidth ?? '49%'}
        maxW={buttonMaxWidth ?? '100%'}
        size={buttonSize ?? 'md'}
        isLoading={isBackLoading}
      >
        {backLabel}
      </SecondaryButton>
    )}
    <PrimaryButton
      disabled={nextDisableCondition}
      onClick={onNext}
      w={buttonWidth ?? '49%'}
      maxW={buttonMaxWidth ?? '100%'}
      size={buttonSize ?? 'md'}
      isLoading={isNextLoading}
    >
      {nextLabel}
    </PrimaryButton>
  </Flex>
);
