export const predictionFailedResources = {
  predictionFailedUserAlertHeader: 'We were not able to create your prediction',
  predictionFailedAdminAlertHeader: 'We were not able to generate prediction for this endpoint',
  yourPredictionProcess:
    'Your prediction process for this endpoint has failed. You can try to create this prediction again, if the error still occurs, please contact QSAR at: ',
  pleaseBeAware: 'Please be aware that your license ',
  wasNotConsumed: 'was not consumed.',
  tryAgain: 'Try again, if the error still occurs, please contact QSAR at: ',
  orUse: ' or use the ',
  toReport: ' to report this problem and get help',
  contactForm: 'contact form',
  backToPredictionList: 'Back to prediction list',
};
