import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

interface IParticleSizeDistributionProps {
  particleSizeDistribution50: number | undefined;
  size: string;
}

export const ParticleSizeDistribution: React.FC<IParticleSizeDistributionProps> = ({
  particleSizeDistribution50,
  size,
}) => {
  return (
    <Box
      className="visualisation__size-distribution"
      position="absolute"
      bottom="10%"
      left="50%"
      w={size}
      sx={{ transform: 'translateX(-50%)' }}
    >
      <Flex className="size-distribution__header" position="relative" w="100%" h="20px" alignItems="center">
        <Box
          as="span"
          className="size-distribution__unit"
          color="particles.sizeDistribution"
          position="absolute"
          left="-3px"
        >
          0
        </Box>
        <Box
          as="span"
          className="size-distribution__unit"
          color="particles.sizeDistribution"
          position="absolute"
          left="calc(100% - 6px)"
        >
          {particleSizeDistribution50}
          nm
        </Box>
      </Flex>
      <Flex className="size-distribution__content" pl="1px">
        <Box className="size-distribution__unit-wrapper" flex="1">
          <Box
            className="scale-unit scale-unit--start"
            w="100%"
            h="15px"
            borderWidth="0 2px 2px 2px"
            borderColor="particles.sizeDistribution"
          />
        </Box>
        <Box className="size-distribution__unit-wrapper" flex="1">
          <Box
            className="scale-unit"
            w="100%"
            h="15px"
            borderBottomWidth="2px"
            borderRightWidth="2px"
            borderColor="particles.sizeDistribution"
          />
        </Box>
      </Flex>
    </Box>
  );
};
