import React, { useRef } from 'react';
import { Html } from '@react-three/drei/web/Html';
import { Line } from '@react-three/drei/core/Line';
import { Sphere as SphericalModel } from '@react-three/drei/core/shapes';
import { WhiteGreenDot, ModelText } from 'src/components';
import { INanoformProps } from 'src/model';

export const Spherical: React.FC<INanoformProps> = ({
  core,
  coreModification,
  coreColor,
  surfaceFunctionalization,
  surfaceFunctionalizationColor,
  lineColor,
  baseRotation,
}) => {
  const group = useRef(null);
  const modelGroup = useRef(null);
  const mainSpherical = useRef(null);
  const haloSpherical = useRef(null);

  return (
    <group ref={group}>
      {Boolean(core) && (
        <group position={[0, 0, 0]}>
          <Html className="core" position={[1.4, 1.15, 2]} zIndexRange={[0, 1]}>
            <ModelText
              dotSize="12px"
              text={coreModification ? `${core?.name}/${coreModification}` : (core?.name as string)}
              variant="right"
              dotColor={lineColor}
            />
          </Html>
          <Html className="center" position={[0, 0, 2]} zIndexRange={[0, 1]}>
            <WhiteGreenDot size="18px" dotColor={lineColor} />
          </Html>
          <Line
            points={[
              [0, 0, 2],
              [1, 1.15, 2],
              [1.4, 1.15, 2],
            ]}
            lineWidth={1.5}
            color={lineColor}
          />
        </group>
      )}
      {Boolean(surfaceFunctionalization) && (
        <group position={[0, 0, 0]}>
          <Html className="core--left" position={[-1.4, 0.9, 2]} zIndexRange={[0, 1]}>
            <ModelText dotSize="12px" text={surfaceFunctionalization as string} variant="left" dotColor={lineColor} />
          </Html>
          <Html className="center" position={[-0.72, 0.6, 2]} zIndexRange={[0, 1]}>
            <WhiteGreenDot size="18px" dotColor={lineColor} />
          </Html>
          <Line
            points={[
              [-0.72, 0.6, 2],
              [-1.1, 0.9, 2],
              [-1.4, 0.9, 2],
            ]}
            lineWidth={1.5}
            color={lineColor}
          />
        </group>
      )}
      <group ref={modelGroup} position={[0, 0, 0]} rotation={baseRotation}>
        <SphericalModel args={[1.4, 32, 32]} ref={mainSpherical}>
          <meshStandardMaterial roughness={0} metalness={0.1} color={coreColor} />
        </SphericalModel>
        {Boolean(surfaceFunctionalization) && (
          <SphericalModel ref={haloSpherical} args={[1.6, 32, 32]}>
            <meshStandardMaterial transparent opacity={0.35} color={surfaceFunctionalizationColor} />
          </SphericalModel>
        )}
      </group>
    </group>
  );
};
