import React from 'react';
import { Text } from '@chakra-ui/react';
import { useUserContext } from 'src/context';

export const UserEmail: React.FC = () => {
  const {
    auth0UserData: { email }, // eslint-disable-line @typescript-eslint/naming-convention
  } = useUserContext();

  return (
    <Text fontWeight="500" mr={6}>
      {email}
    </Text>
  );
};
