import React from 'react';
import { Box, Divider, Text } from '@chakra-ui/react';

interface IDataWithLabelProps {
  label: string;
  value: string;
  preformatted?: boolean;
  preserveTextTransform?: boolean;
}

export const DataWithLabel: React.FC<IDataWithLabelProps> = ({ label, value, preformatted, preserveTextTransform }) => (
  <Box fontWeight="500" pb={6}>
    <Text color="typo.grey" pb={2} textTransform={preserveTextTransform ? 'none' : 'uppercase'}>
      {label}
    </Text>
    <Text
      as={value !== '' ? 'p' : 'span'}
      color="custom.glaucous"
      minH="1.5rem"
      display="flex"
      alignItems="center"
      whiteSpace={preformatted ? 'pre' : undefined}
    >
      {value !== '' ? (
        value
      ) : (
        <Divider orientation="horizontal" borderColor="typo.blue" maxW="60px" borderBottomWidth="2px" />
      )}
    </Text>
  </Box>
);
