import dayjs from 'dayjs';
import * as yup from 'yup';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DATE_FORMAT, ORDER_REGEX } from 'src/helpers';
import { maxLengthValidationText, requiredValidationText, patternValidationText } from 'src/utils';
import { newModelWizardResources } from 'src/components';

export const createModelYupSchema = (): yup.AnyObjectSchema =>
  yup.object().shape({
    name: yup
      .string()
      .max(100, ({ max }) => maxLengthValidationText(max))
      .required(({ label }) => requiredValidationText(label))
      .label(newModelWizardResources.createModel.modelName.label),
    endpoint: yup
      .string()
      .max(200, ({ max }) => maxLengthValidationText(max))
      .required(({ label }) => requiredValidationText(label))
      .label(newModelWizardResources.createModel.endpoint.label),
    dependentVariable: yup
      .string()
      .max(50, ({ max }) => maxLengthValidationText(max))
      .required(({ label }) => requiredValidationText(label))
      .label(newModelWizardResources.createModel.dependentVariable.label),
    reportType: yup
      .string()
      .required(({ label }) => requiredValidationText(label))
      .label(newModelWizardResources.createModel.reportType.label),
    coreId: yup
      .string()
      .required(({ label }) => requiredValidationText(label))
      .label(newModelWizardResources.createModel.core.label),
    createdDate: yup
      .date()
      .transform((value, originalValue) => {
        dayjs.extend(customParseFormat);
        // eslint-disable-next-line no-param-reassign
        value = dayjs(originalValue, DATE_FORMAT).toDate();
        return value;
      })
      .required(),
    order: yup
      .string()
      .matches(ORDER_REGEX, { message: () => patternValidationText })
      .required(({ label }) => requiredValidationText(label))
      .default('1')
      .label(newModelWizardResources.createModel.order.label),
    modelGroupId: yup
      .string()
      .required(({ label }) => requiredValidationText(label))
      .label(newModelWizardResources.createModel.propertyGroup.label),
  });
