import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Box, useTheme, HStack, Heading, Flex, Icon } from '@chakra-ui/react';
import { IoArrowBack, IoArrowBackOutline } from 'react-icons/io5';
import { AxiosResponse } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { licenseService, checkoutService, coreService } from 'src/services';
import {
  BuyModelsList,
  LicenseBuySummary,
  LicenseBuyOption,
  Spinner,
  SaveChangesPopup,
  UserInfo,
} from 'src/components';
import {
  IModel,
  ILicenseOptions,
  LicenseTypes,
  BuyLicenceSelector,
  IModelsResponse,
  ILicenseModel,
  ModelsSelectionPerCore,
  ModelStatus,
} from 'src/model';
import {
  buyLicensesViewResources,
  buyLicensesSummaryViewResources,
  oneNanoAllEnpoints,
  allNanoAllEndpoints,
  stripePromise,
} from 'src/helpers';
import { useBuyLicenceContext, useUserActionsContext } from 'src/context';
import { useDocumentTitle } from 'src/hooks';

export interface IBuyLicenseState {
  licenseOptions: ILicenseOptions;
  ownedEndpoints: ILicenseModel[];
  endpointsToBuy: string[];
  coreName: string;
  purchasedDate: string;
  expirationDate: string;
  timeBasedDiscountRate: number;
  discount: number;
  selectedLicenseId?: string;
  userInfo?: UserInfo;
}

export const BuyLicense: React.FC = () => {
  const history = useHistory();

  const {
    state: {
      licenseOptions,
      ownedEndpoints,
      endpointsToBuy,
      purchasedDate,
      expirationDate,
      timeBasedDiscountRate,
      discount,
      selectedLicenseId,
      userInfo,
    },
  } = useLocation<IBuyLicenseState>();

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [redirectLoading, setRedirectLoading] = useState<boolean>(false);
  const [price, setPrice] = useState<number>(0.0);
  const { selector, setSelector, defaultCoreName, setDefaultCoreName, setLicenseAdded } = useBuyLicenceContext();
  const [paymentActive, setPaymentActive] = useState<boolean>(false);
  const [showAdvantageousOffer, setShowAdvantageousOffer] = useState<boolean>(false);
  const [confirmChangesVisible, setConfirmChangesVisible] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth0();
  const { headingBuy, headingExpand } = buyLicensesViewResources;
  const [coresDetails, setCoresDetails] = useState<IModelsResponse[]>([]);
  const [coresDetailsLoading, setCoresDetailsLoading] = useState<boolean>(true);

  const {
    colors: { button, typo },
  } = useTheme();

  const handleConfirmChangesPopupVisibility = () => {
    setConfirmChangesVisible(!confirmChangesVisible);
  };

  const { isAdmin } = useUserActionsContext();

  const isExpanding = (): boolean => ownedEndpoints && ownedEndpoints.length > 0;

  const userFromDemo = (): boolean => endpointsToBuy.length > 0;

  const isAdminAdding = (): boolean => isAdmin() && userInfo?.id !== undefined && !isExpanding();

  const getHeading = (): string => {
    if (isExpanding()) return headingExpand;
    if (isAdminAdding()) return 'Add license';
    return headingBuy;
  };

  useDocumentTitle(getHeading());

  const hasWaitingForPaymentModels = (): boolean =>
    ownedEndpoints.filter((e) => e.hasWaitingPayment === true).length > 0;

  const IsPriceOneNanoform = () => {
    switch (licenseOptions.type) {
      case LicenseTypes.AllSubstancesAllModels:
      case LicenseTypes.ManyNanoformsSpecificModels:
      case LicenseTypes.OneSubstanceAllModels:
        return false;
      default:
        return true;
    }
  };

  const redirectOnGoBack = () => {
    history.goBack();
  };

  const getCoresForLicenseType = (cores: IModelsResponse[]) => {
    if (
      licenseOptions.type === LicenseTypes.ManyNanoformsSpecificModels ||
      licenseOptions.type === LicenseTypes.AllSubstancesAllModels
    ) {
      return cores;
    }
    if (!defaultCoreName) return [];
    return cores.filter((c) => c.name === defaultCoreName);
  };

  const getCoresOnlyWithActiveModels = (cores: IModelsResponse[]) =>
    cores.filter((c) => !c.models.every((g) => g.models.every((m) => m.status === ModelStatus.Deactivated)));

  const getIndexForCurentCore = (coreId: string): number => {
    if (!selector.cores) return -1;
    let idx = -1;
    selector.cores.forEach((model, index) => {
      if (model.details.id !== coreId) return -1;
      idx = index;
      return index;
    });
    return idx;
  };

  const getAvailableCores = (cores: IModelsResponse[]): IModelsResponse[] => {
    if (isExpanding() && isAdmin()) {
      return getCoresForLicenseType(cores);
    }
    if (isExpanding()) {
      return getCoresOnlyWithActiveModels(getCoresForLicenseType(cores));
    }
    if (isAdmin()) {
      return cores;
    }

    return getCoresOnlyWithActiveModels(cores);
  };

  const getAddedModels = (selectedModels: IModel[]) => {
    if (!isExpanding()) return selectedModels;

    const owned = ownedEndpoints.map((m) => m.endpoint);
    const addedModels: IModel[] = [];

    selectedModels.forEach((m) => {
      if (!owned.includes(m.endpoint)) addedModels.push(m);
    });
    return addedModels;
  };

  const getDeletedModels = (curentSelector: BuyLicenceSelector): IModel[] => {
    if (!isExpanding() || !isAdmin()) return [];

    const selected =
      licenseOptions.type === LicenseTypes.ManyNanoformsSpecificModels ||
      licenseOptions.type === LicenseTypes.AllSubstancesAllModels
        ? curentSelector.cores.flatMap((c) => c.selectedItems).map((m) => m.endpoint)
        : curentSelector.cores[curentSelector.curentIndex].selectedItems.map((m) => m.endpoint);
    const owned = ownedEndpoints.map((m) => m.endpoint);
    const deleted: string[] = [];

    owned.forEach((m) => {
      if (!selected.includes(m)) deleted.push(m);
    });

    const deletedIds: IModel[] = [];
    const cores = coresDetails as IModelsResponse[];
    const allModels = cores.flatMap((c) => c.models.flatMap((g) => g.models));
    allModels.forEach((m) => {
      if (deleted.includes(m.endpoint)) deletedIds.push(m);
    });

    return deletedIds;
  };

  const getAllAvailableModels = (curentSelector: BuyLicenceSelector): IModel[] => {
    return curentSelector.cores.flatMap((c) => c.details.models.flatMap((m) => m.models));
  };

  const getAllSelectedModels = (curentSelector: BuyLicenceSelector): IModel[] => {
    if (isAdminAdding() && licenseOptions.type === LicenseTypes.AllSubstancesAllModels) {
      return getAllAvailableModels(curentSelector);
    }
    if (!curentSelector.cores[curentSelector.curentIndex]) return [];
    if (isAdmin() && isExpanding() && licenseOptions.type === LicenseTypes.ManyNanoformsSpecificModels) {
      return getAddedModels(curentSelector.cores.flatMap((c) => c.selectedItems));
    }
    if (isAdmin() && isExpanding()) {
      return getAddedModels(curentSelector.cores[curentSelector.curentIndex].selectedItems);
    }
    if (licenseOptions.type === LicenseTypes.ManyNanoformsSpecificModels) {
      return curentSelector.cores.flatMap((c) => c.selectedItems);
    }
    return curentSelector.cores[curentSelector.curentIndex].selectedItems;
  };

  const areModelsAdded = (curentSelector: BuyLicenceSelector) => getAllSelectedModels(curentSelector).length > 0;

  const areModelsDeleted = (curentSelector: BuyLicenceSelector) => getDeletedModels(curentSelector).length > 0;

  const filterOwnedEndpoints = (models: ILicenseModel[]) => {
    return models.filter((m) => !m.endpoint.includes('_DEMO_'));
  };

  const getManyNanoformSpecificModelsPrice = (newSelector: BuyLicenceSelector, timeBasedDiscount: number): number => {
    let totalPrice = 0;
    newSelector.cores.forEach((elem) => {
      totalPrice += elem.selectedItems.reduce((sum, curent) => sum + curent.manyNanoformsPrice, 0) * timeBasedDiscount;
    });
    return totalPrice;
  };

  const getOneNanoformPriceForOneCore = (core: ModelsSelectionPerCore): number => {
    const discountValue = discount === undefined ? 1 : 1 - discount;
    return Math.ceil(core.selectedItems.reduce((sum, curent) => sum + curent.oneNanoformPrice, 0) * discountValue);
  };

  const getManyNanoformPriceForOneCore = (core: ModelsSelectionPerCore, timeBasedDiscount: number): number => {
    return core.selectedItems.reduce((sum, curent) => sum + curent.manyNanoformsPrice, 0) * timeBasedDiscount;
  };

  const updatePrices = (newSelector: BuyLicenceSelector, models: IModel[], modelGroups?: IModelsResponse) => {
    const newselector = newSelector;
    const core = newselector.cores[newSelector.curentIndex];
    const timeBasedDiscount = timeBasedDiscountRate ?? 1;

    if (isAdmin() && isExpanding()) {
      core.selectedItems = models;
      let totalPrice = 0;
      if (licenseOptions.type === LicenseTypes.ManyNanoformsSpecificModels) {
        totalPrice = getManyNanoformSpecificModelsPrice(newselector, timeBasedDiscount);
      } else {
        totalPrice = IsPriceOneNanoform()
          ? getOneNanoformPriceForOneCore(core)
          : getManyNanoformPriceForOneCore(core, timeBasedDiscount);
      }
      setPrice(totalPrice);
      return newselector;
    }

    if (core && licenseOptions.type === LicenseTypes.OneNanoformSpecificModels) {
      core.selectedItems = models;
      setPrice(getOneNanoformPriceForOneCore(core));
    } else if (core && licenseOptions.type === LicenseTypes.ManyNanoformsSpecificModels) {
      core.selectedItems = models;
      setPrice(getManyNanoformSpecificModelsPrice(newselector, timeBasedDiscount));
    } else if (!isExpanding() && modelGroups && licenseOptions.type === LicenseTypes.OneNanoformAllModels) {
      setPrice(modelGroups.licencePrices.oneNanoformAllModels as number);
    } else if (isExpanding() && licenseOptions.type === LicenseTypes.OneNanoformAllModels) {
      setPrice(Math.ceil(getOneNanoformPriceForOneCore(core) * (1 - discount)));
    } else if (!isExpanding() && modelGroups && licenseOptions.type === LicenseTypes.OneSubstanceAllModels) {
      setPrice((modelGroups.licencePrices.oneSubstanceAllModels as number) * timeBasedDiscount);
    } else if (isExpanding() && modelGroups && licenseOptions.type === LicenseTypes.OneSubstanceAllModels) {
      setPrice(getManyNanoformSpecificModelsPrice(newselector, timeBasedDiscount));
    } else if (isExpanding() && modelGroups && licenseOptions.type === LicenseTypes.AllSubstancesAllModels) {
      setPrice(getManyNanoformSpecificModelsPrice(newselector, timeBasedDiscount));
    }
    return newselector;
  };

  const setModelsForCore = (newSelector: BuyLicenceSelector, newCoreName: string, coreIndex: number) => {
    let newselector = newSelector;
    newselector.curentIndex = coreIndex;
    if (isAdminAdding() && !licenseOptions.modelsSelectable) {
      newselector.cores[coreIndex].selectedItems = newSelector.cores[coreIndex].details.models.flatMap((m) => m.models);
      setPaymentActive(true);
    } else if (!isExpanding() && !licenseOptions.modelsSelectable) {
      newselector.cores[coreIndex].selectedItems = newSelector.cores[coreIndex].details.models
        .flatMap((m) => m.models)
        .filter((m) => m.status !== ModelStatus.Deactivated);
      setPaymentActive(true);
    } else if (!isExpanding() && areModelsAdded(newSelector)) {
      setPaymentActive(true);
    } else if (isAdmin() && isExpanding() && hasWaitingForPaymentModels()) {
      setPaymentActive(true);
    } else if (isAdmin() && isExpanding() && (areModelsAdded(newSelector) || areModelsDeleted(newSelector))) {
      setPaymentActive(true);
    } else if (isAdmin() && isExpanding() && !licenseOptions.modelsSelectable) {
      setPaymentActive(false);
    } else if (isExpanding() && !licenseOptions.modelsSelectable) {
      setPaymentActive(true);
    } else if (isAdminAdding() && licenseOptions.type === LicenseTypes.AllSubstancesAllModels) {
      setPaymentActive(true);
    } else {
      setPaymentActive(false);
    }
    newselector = updatePrices(newSelector, newSelector.cores[coreIndex].selectedItems, coresDetails[coreIndex]);
    newselector.curentName = newCoreName;

    return newselector;
  };

  const onChangeCore = async (changedName: string) => {
    const matchingCore: IModelsResponse | undefined = coresDetails.find(
      (item: IModelsResponse) => item.name === changedName
    );

    if (matchingCore === undefined) return;

    let newSelector = { ...selector };
    const index = getIndexForCurentCore(matchingCore.id);
    newSelector = setModelsForCore(newSelector, changedName, index);

    setSelector(newSelector);
    setDefaultCoreName(matchingCore.name);
  };

  const updateSelectedModels = (models: IModel[]) => {
    let newSelector = { ...selector };
    newSelector = updatePrices(newSelector, models);
    setSelector(newSelector);
    const allSelectedModels =
      licenseOptions.type === LicenseTypes.ManyNanoformsSpecificModels ? getAllSelectedModels(newSelector) : models;
    const allModelsCount = selector.cores[selector.curentIndex].details.models.flatMap((m) => m.models).length;
    if ((!isAdmin() || isAdminAdding()) && allSelectedModels && allSelectedModels.length > 0 && allModelsCount !== 0) {
      setPaymentActive(true);
    } else if (isAdmin() && isExpanding() && !areModelsAdded(newSelector) && !areModelsDeleted(newSelector)) {
      setPaymentActive(hasWaitingForPaymentModels());
    } else if (isAdmin() && isExpanding() && (areModelsAdded(newSelector) || areModelsDeleted(newSelector))) {
      setPaymentActive(true);
    } else {
      setPaymentActive(false);
    }
  };

  const selectionChanged = (selected: boolean, model: IModel) => {
    if (!isAdmin() && !licenseOptions.modelsSelectable) return;
    if (!selected) {
      updateSelectedModels(selector.cores[selector.curentIndex].selectedItems.filter((m) => m.id !== model.id));
      return;
    }
    if (!selector.cores[selector.curentIndex].selectedItems.find((m) => m.id === model.id)) {
      updateSelectedModels([...selector.cores[selector.curentIndex].selectedItems, model]);
    }
  };

  const getPreselectedEndpoints = (modelResponse: IModelsResponse[], index: number): IModel[] => {
    if (isAdmin() && isExpanding()) {
      return modelResponse[index].models.flatMap((g) =>
        g.models.filter((m) => ownedEndpoints.map((e) => e.endpoint).includes(m.endpoint))
      );
    }
    if (isExpanding() && !licenseOptions.modelsSelectable) {
      return modelResponse[index].models.flatMap((g) =>
        g.models
          .filter((m) => m.status !== ModelStatus.Deactivated)
          .filter((m) => !ownedEndpoints.map((e) => e.endpoint).includes(m.endpoint))
      );
    }
    if (userFromDemo() && licenseOptions.modelsSelectable) {
      return modelResponse[index].models.flatMap((g) => g.models.filter((m) => endpointsToBuy.includes(m.endpoint)));
    }
    if (!licenseOptions.modelsSelectable) {
      return modelResponse[index].models.flatMap((g) => g.models);
    }

    return [];
  };

  const redirectToStripe = async (licenseId: string) => {
    setRedirectLoading(true);
    try {
      const stripe = await stripePromise();
      const token = await getAccessTokenSilently();

      if (stripe) {
        const sessionResponse = await checkoutService.createPayment(licenseId, token);
        await stripe.redirectToCheckout({
          sessionId: sessionResponse.data as string,
        });
      }
    } catch (redirectError) {
      console.error(redirectError);
    } finally {
      setRedirectLoading(false);
    }
  };

  const onSubmit = async (confirmed?: boolean) => {
    setSubmitLoading(true);
    try {
      if (
        isAdmin() &&
        hasWaitingForPaymentModels() &&
        getDeletedModels(selector).length !== ownedEndpoints.length &&
        !confirmed
      ) {
        handleConfirmChangesPopupVisibility();
        return;
      }
      const token = await getAccessTokenSilently();
      let licenseData = '';
      if (selectedLicenseId) {
        licenseData = await licenseService
          .expandLicense(getAllSelectedModels(selector), getDeletedModels(selector), selectedLicenseId, token)
          .then((response) => response.data);
      } else {
        licenseData = await licenseService
          .createLicense(getAllSelectedModels(selector), token, licenseOptions?.type, userInfo?.id)
          .then((response) => response.data);
      }
      if (isAdmin()) {
        if (isAdminAdding()) setLicenseAdded(true);
        history.goBack();
        return;
      }
      await redirectToStripe(licenseData);
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    if (!selector.cores[selector.curentIndex]) {
      return;
    }
    const curentCore = selector.cores[selector.curentIndex];
    const count = curentCore.details.models.reduce((curentCount, row) => curentCount + row.models.length, 0);

    if (isAdmin()) {
      setShowAdvantageousOffer(false);
      return;
    }

    if (licenseOptions.type === LicenseTypes.OneSubstanceAllModels) {
      setShowAdvantageousOffer(false);
      return;
    }

    if (licenseOptions.type === LicenseTypes.AllSubstancesAllModels) {
      setShowAdvantageousOffer(false);
      return;
    }

    if (licenseOptions.type === LicenseTypes.ManyNanoformsSpecificModels) {
      const allModelsCount = selector.cores.flatMap((c) => c.details.models).flatMap((g) => g.models).length;
      const selectedModelsCount = selector.cores.flatMap((c) => c.selectedItems).length;
      if (selectedModelsCount !== allModelsCount && allModelsCount !== 0) {
        setShowAdvantageousOffer(false);
        return;
      }
      setShowAdvantageousOffer(true);
      return;
    }

    const allModelsCount = selector.cores[selector.curentIndex].details.models.flatMap((g) => g.models).length;
    const selectedModelsCount = getAllSelectedModels(selector).length;
    if (count === selectedModelsCount && licenseOptions.modelsSelectable && allModelsCount !== 0) {
      setShowAdvantageousOffer(true);
    } else {
      setShowAdvantageousOffer(false);
    }

    if (!isExpanding()) return;

    if (
      allModelsCount - filterOwnedEndpoints(ownedEndpoints).length === selectedModelsCount &&
      licenseOptions.type !== LicenseTypes.OneNanoformAllModels &&
      !isAdmin()
    ) {
      setShowAdvantageousOffer(true);
    } else {
      setShowAdvantageousOffer(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selector, licenseOptions]);

  useEffect(() => {
    if (selector.curentName) {
      onChangeCore(selector.curentName);
      return;
    }
    if (!coresDetails) return;
    if (coresDetails.length === 0) return;

    const cores = getAvailableCores(coresDetails);

    let newSelector = { ...selector };
    newSelector.cores = [];

    for (let i = 0; i < cores.length; i += 1) {
      const newCore = {
        details: cores[i],
        selectedItems: getPreselectedEndpoints(cores, i),
      };
      if (newCore.details.models.flatMap((g) => g.models).length !== 0) newSelector.cores.push(newCore);
    }
    newSelector.cores.sort((a, b) => {
      if (a.details.name.toLocaleLowerCase() > b.details.name.toLocaleLowerCase()) return 1;
      if (a.details.name.toLocaleLowerCase() < b.details.name.toLocaleLowerCase()) return -1;
      return 0;
    });

    if (isAdminAdding() && licenseOptions.type === LicenseTypes.AllSubstancesAllModels) {
      setPaymentActive(true);
    }

    if (!defaultCoreName) {
      setSelector(newSelector);
      return;
    }

    const matchingCore: IModelsResponse | undefined = coresDetails.find(
      (item: IModelsResponse) => item.name === defaultCoreName
    );

    if (matchingCore === undefined) {
      setSelector(newSelector);
      return;
    }

    let index = 0;
    newSelector.cores.forEach((model, idx) => {
      if (model.details.id === matchingCore.id) {
        index = idx;
      }
    });
    newSelector = setModelsForCore(newSelector, defaultCoreName, index);
    setSelector(newSelector);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coresDetails, ownedEndpoints]);

  useEffect(() => {
    const fetchCores = async () => {
      const token = await getAccessTokenSilently();
      const cores: IModelsResponse[] = await coreService
        .getAllCoresDetailsWithoutDemoModels(token)
        .then((response: AxiosResponse) => response.data);

      setCoresDetails(cores);
      setCoresDetailsLoading(false);
    };
    fetchCores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      className="buylicenses-list"
      w="100%"
      minH="100%"
      overflowY="auto"
      id="buylicenses-list"
      p={8}
      flexDirection="column"
    >
      {confirmChangesVisible && (
        <SaveChangesPopup handleVisibility={handleConfirmChangesPopupVisibility} handleResult={() => onSubmit(true)} />
      )}
      <Box as="header" mb={6}>
        <Heading fontWeight="700" size="xl">
          <HStack spacing={4}>
            <Icon as={IoArrowBackOutline} cursor="pointer" onClick={() => redirectOnGoBack()}>
              <IoArrowBack color={button.blue} size="1.5rem" />
            </Icon>
            <Heading fontWeight="700" size="xl">
              {getHeading()}
            </Heading>
          </HStack>
        </Heading>
      </Box>
      {coresDetailsLoading && (
        <Flex w="100%" flex="1" justifyContent="center" alignItems="center">
          <Spinner thickness="8px" speed="1s" size="xl" w="100px" h="100px" label="Loading.." />
        </Flex>
      )}
      {selector && !coresDetailsLoading && (
        <Flex>
          <Box
            flex="8"
            borderColor={typo.grey}
            borderWidth="1px"
            borderRadius="md"
            overflow="hidden"
            bg={typo.white}
            padding={8}
          >
            <BuyModelsList
              modelGroups={
                selector.cores[selector.curentIndex] ? selector.cores[selector.curentIndex].details.models : []
              }
              licenseOptions={licenseOptions}
              selectedItems={
                selector.cores[selector.curentIndex] ? selector.cores[selector.curentIndex].selectedItems : []
              }
              ownedEndpoints={ownedEndpoints || []}
              selectionChanged={selectionChanged}
              isDisabled={submitLoading}
              onChangeCore={(value) => {
                onChangeCore(value as string);
              }}
              cores={selector.cores.flatMap((c) => c.details)}
              defaultCore={defaultCoreName || selector.curentName}
              purchasedDate={purchasedDate}
              expirationDate={expirationDate}
              isAdmin={isAdmin()}
              isAdminAdding={isAdminAdding()}
            />
          </Box>
          <Flex flex={3} flexDirection="column" pt={4} pl={8} alignItems="center">
            <LicenseBuySummary
              summaryInfo={buyLicensesSummaryViewResources}
              onClick={() => onSubmit()}
              price={price.toFixed(2)}
              paymentActive={paymentActive && !submitLoading}
              showAdvantageousOfferText={showAdvantageousOffer}
              licenseType={licenseOptions.type}
              isLoading={submitLoading || redirectLoading}
              modelsSelectable={licenseOptions.modelsSelectable}
              isAdmin={isAdmin()}
              isExpanding={isExpanding()}
              isAdminAdding={isAdminAdding()}
            />
            {showAdvantageousOffer && licenseOptions.type === LicenseTypes.OneNanoformSpecificModels && (
              <LicenseBuyOption
                title={oneNanoAllEnpoints.title}
                priceAfter={oneNanoAllEnpoints.priceAfter}
                listItems={oneNanoAllEnpoints.listItems}
                buttonText={oneNanoAllEnpoints.buttonText}
                path={oneNanoAllEnpoints.path}
                licenseOptions={oneNanoAllEnpoints.licenseOptions}
                advantageousPrice={selector.cores[selector.curentIndex].details.licencePrices.oneNanoformAllModels}
              />
            )}
            {showAdvantageousOffer && licenseOptions.type === LicenseTypes.ManyNanoformsSpecificModels && (
              <LicenseBuyOption
                title={allNanoAllEndpoints.title}
                listItems={allNanoAllEndpoints.listItems}
                buttonText={allNanoAllEndpoints.buttonText}
                extraInfo={allNanoAllEndpoints.extraInfo}
              />
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
