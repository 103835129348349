import { AxiosPromise, AxiosResponse } from 'axios';
import { ICore } from 'src/model';
import { authorizedApiService } from './api.service';

class CoreService {
  getAllCores = (token: string): AxiosPromise => {
    const apiUrl = `/cores`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  createCore = (core: ICore, token: string): AxiosPromise => {
    const apiUrl = `/cores`;
    return authorizedApiService(token)
      .post(apiUrl, { ...core })
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  editCore = (coreId: string, core: ICore, token: string): AxiosPromise => {
    const apiUrl = `/cores/${coreId}`;
    return authorizedApiService(token)
      .put(apiUrl, { ...core })
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getCoreDetails = (coreId: string, token: string): AxiosPromise => {
    const apiUrl = `/cores/${encodeURI(coreId)}`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getAllCoresDetails = (token: string): AxiosPromise => {
    const apiUrl = `/cores/details`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getAllCoresDetailsWithoutDemoModels = (token: string): AxiosPromise => {
    const apiUrl = `/cores/withoutDemoModels`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getAllCoresWithDemoModels = (token: string): AxiosPromise => {
    const apiUrl = `/cores/withDemoModels`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };
}

export const coreService = new CoreService();
