import React from 'react';
import dayjs from 'dayjs';
import InfiniteScroll from 'react-infinite-scroller';
import { Table, Tbody, Box, Tr, Text } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import { TableHeader, TableData, EmptyListState } from 'src/components';
import { DATE_FORMAT, manageModelsVersionsEmptyList } from 'src/helpers';
import { IModelVersionListItem, IModelDetails } from 'src/model';

interface IManageModelVersionsListProps {
  data: IModelVersionListItem[];
  getMoreData: () => void;
  hasMore: boolean;
  model: IModelDetails;
}

export const ModelVersionsList: React.FC<IManageModelVersionsListProps> = ({ data, getMoreData, hasMore, model }) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <Box className="model-versions-list" w="100%">
      {data.length ? (
        <InfiniteScroll pageStart={0} hasMore={hasMore} loadMore={getMoreData} useWindow>
          <Table
            variant="unstyled"
            w="100%"
            h="100%"
            sx={{ borderCollapse: 'separate', borderSpacing: '0 10px', tableLayout: 'fixed' }}
            cellSpacing="0"
          >
            <TableHeader titles={['Version number', 'Author', 'Date']} />
            <Tbody>
              {data.map((version: IModelVersionListItem) => (
                <Tr
                  key={`model-version-${version.versionNumber}-${version.date}`}
                  h="82px"
                  cursor="pointer"
                  onClick={() =>
                    history.push({
                      pathname: `${location.pathname}/versiondetails/${version.id}`,
                      state: {
                        model,
                        versionNumber: version.versionNumberText,
                        isLastVersion: Boolean(data.length === 1),
                      },
                    })
                  }
                >
                  <TableData w="25%">
                    <Text fontWeight="700" fontSize="1.125rem">
                      {version.versionNumberText}
                    </Text>
                  </TableData>
                  <TableData>
                    <Text>{version.author}</Text>
                  </TableData>
                  <TableData w="20%">{dayjs(version.date).format(DATE_FORMAT)}</TableData>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </InfiniteScroll>
      ) : (
        <EmptyListState title={manageModelsVersionsEmptyList} />
      )}
    </Box>
  );
};
