import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/react';
import React from 'react';

interface IInfoAlertProps {
  text: string;
}

export const InfoAlert: React.FC<IInfoAlertProps> = ({ text }) => (
  <Alert status="info" variant="left-accent" mb={10} bg="blue.50" borderStartColor="typo.blue">
    <AlertIcon color="typo.blue" />
    <AlertDescription textColor="gray">{text}</AlertDescription>
  </Alert>
);
