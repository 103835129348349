/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, useCallback } from 'react';
import domtoimage from 'dom-to-image';
import { useForm } from 'react-hook-form';
import {
  Heading,
  Box,
  FormControl,
  FormLabel,
  Flex,
  useDisclosure,
  FormErrorMessage,
  Switch,
  Input,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { scrollbar, particleSizeDistribution50, particleSizeDistributionX } from 'src/helpers';
import {
  Shape,
  INanoFormResponse,
  DefineNanoformInputs,
  LicenseTypes,
  IModelsAttributesResponse,
  INewPrediction,
} from 'src/model';
import {
  Spinner,
  WizardNav,
  NanoformsMenu,
  UncontrolledSelect,
  UncontrolledMultiPicker,
  ShapePickerGroup,
  LicenseConsumeModal,
} from 'src/components';
import { useErrorToast, useDocumentTitle } from 'src/hooks';
import {
  useNewExperimentWizardActionsContext,
  useNanoformActionsContext,
  useUserContext,
  useNewExperimentWizardContext,
  useNanoformContext,
  useLoaderContext,
  useLoaderActionsContext,
  IDynamicAttribute,
  IAttributeInfo,
} from 'src/context';
import { modelService, nanoformService, predictionService } from 'src/services';
import { newExperimentWizardResources } from '../newExperimentWizardResources';

export const DefineNanoform: React.FC = () => {
  useDocumentTitle(newExperimentWizardResources.steps.defineNanoform);

  const {
    defineNanoform: { toasts, shapes, savedNanoform },
  } = newExperimentWizardResources;

  const {
    userActiveLicenseData: { type },
  } = useUserContext();

  const {
    experimentData: { researchName, selectedModelsIds, useShape, onlyDemoModelsSelected },
    attributesInfo,
  } = useNewExperimentWizardContext();

  const { nanoformData, visualisationRef } = useNanoformContext();

  const { getAccessTokenSilently } = useAuth0();

  const isLoading = useLoaderContext();
  const setIsLoading = useLoaderActionsContext();

  const history = useHistory();

  const { setAttributesInfo, setCurrentStep, onWizardClose } = useNewExperimentWizardActionsContext();
  const { chosenLicense } = useNewExperimentWizardContext();

  const { validationErrorToast } = toasts;

  const { spheroidal, elongated, platelet, other } = shapes.options;

  const [selectedNanoform, setSelectedNanoform] = useState<{ id: string; name: string }>({
    id: '',
    name: '',
  });

  const [dynamicAttributesErrors, setDynamicAttributesErrors] = useState<string[]>([]);
  const [shapeAttributeError, setShapeAttributeError] = useState<string>('');

  const { setNanoformData, setIsNanoformImageGenerated } = useNanoformActionsContext();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const errorMessage = (attributeInfo: IAttributeInfo) => `Attribute ${attributeInfo.label} is required`;

  const validateShapeAttribute = (): boolean => {
    if (!useShape || nanoformData.shape) {
      setShapeAttributeError('');
      return true;
    }

    setShapeAttributeError('Shape is required');
    return false;
  };

  const validateDynamicAttributesValues = (): boolean => {
    const newErrors: string[] = dynamicAttributesErrors.map(() => '');
    let validValues = true;

    for (let i = 0; i < attributesInfo.length; i += 1) {
      if (attributesInfo[i].nanoformDynamicAttribute.values.length === 0) {
        newErrors[i] = errorMessage(attributesInfo[i]);
        validValues = false;
      }
    }

    setDynamicAttributesErrors(newErrors);
    return validValues;
  };

  const methods = useForm<DefineNanoformInputs>({
    mode: 'onSubmit',
  });

  const { handleSubmit, control, reset } = methods;

  const formValidationErrorToast = useErrorToast(validationErrorToast.title, validationErrorToast.description);

  const blobToBase64 = async (blob: Blob): Promise<string | undefined> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result?.toString().split(',')[1]);
      reader.readAsDataURL(blob);
    });
  };

  const createPrediction = useCallback(async () => {
    try {
      setIsLoading(true);
      setIsNanoformImageGenerated(true);

      await setTimeout(async () => {
        if (visualisationRef.current) {
          const nanoformImageBlob = await domtoimage.toBlob(visualisationRef.current);
          const nanoformImageBase64 = await blobToBase64(nanoformImageBlob);

          const newPrediction: INewPrediction = {
            name: researchName,
            nanoformImageBase64: nanoformImageBase64 ?? '',
            modelIds: selectedModelsIds,
            nanoformData: {
              core: nanoformData?.core?.name,
              shape: nanoformData.shape,
              dynamicAttributes: attributesInfo.flatMap((a) => a.nanoformDynamicAttribute),
            },
            selectedLicenseId: chosenLicense.id.length === 0 ? null : chosenLicense.id,
          };

          const token = await getAccessTokenSilently();
          const response = await predictionService.newPrediction(newPrediction, token);

          setIsLoading(false);

          if (response) {
            const predictionId = response.data;

            setIsNanoformImageGenerated(false);

            onWizardClose();
            history.replace({
              pathname: `/prediction/${predictionId}`,
            });
          }
        }
      }, 500);
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    visualisationRef,
    setIsNanoformImageGenerated,
    setIsLoading,
    getAccessTokenSilently,
    researchName,
    nanoformData,
    attributesInfo,
    selectedModelsIds,
    history,
    onWizardClose,
  ]);

  const onSubmit = () => {
    if (!validateShapeAttribute() || !validateDynamicAttributesValues()) {
      formValidationErrorToast();
      return;
    }

    if (
      (type === LicenseTypes.OneNanoformAllModels || type === LicenseTypes.OneNanoformSpecificModels) &&
      !onlyDemoModelsSelected
    ) {
      onOpen();
    } else {
      createPrediction();
    }
  };

  const onError = () => {
    validateDynamicAttributesValues();
    formValidationErrorToast();
  };

  // API CALLS AND FETCHING DATA

  const fetchNanoformsNames = async () => {
    const token = await getAccessTokenSilently();
    return nanoformService.getCurrentUserNanoforms(token).then((response: AxiosResponse) => response.data);
  };

  const fetchNanoform = async (id: string) => {
    const token = await getAccessTokenSilently();
    return nanoformService.getNanoformById(id, token).then((response: AxiosResponse) => response.data);
  };

  const fetchModelsAttributes = async (modelIds: string[]): Promise<IModelsAttributesResponse> => {
    const token = await getAccessTokenSilently();
    return modelService.getModelsAttributes(modelIds, token).then((response: AxiosResponse) => response.data);
  };

  const { data: nanoformNamesData, isLoading: nanoformNamesIsLoading } = useQuery<
    { id: string; predictionName: string }[]
  >('user-nanoforms', fetchNanoformsNames);

  const { data: modelsAttributes, isLoading: modelsAttributesLoading } = useQuery('models-attributes', () =>
    fetchModelsAttributes(selectedModelsIds)
  );

  const {
    data: fetchedNanoformData,
    isLoading: nanoformIsLoading,
    refetch: populateNanoform,
  } = useQuery<INanoFormResponse>(['nanoform', selectedNanoform.id], () => fetchNanoform(selectedNanoform.id), {
    enabled: false,
  });

  const checkForVisualisationEffects = (attributeName: string, value: string) => {
    if (attributeName.toLowerCase().replace(/\s/g, '') === 'surfacefunctionalization') {
      setNanoformData((prevState) => ({ ...prevState, surfaceFunctionalization: value }));
    }
    if (attributeName.toLowerCase().replace(/\s/g, '') === 'coremodification') {
      setNanoformData((prevState) => ({ ...prevState, coreModification: value }));
    }
    if (
      attributeName.toLowerCase().replace(/\s/g, '') === particleSizeDistribution50.toLowerCase().replace(/\s/g, '')
    ) {
      setNanoformData((prevState) => ({ ...prevState, particleSizeDistributionValue: Number(value) }));
    }
    if (attributeName.toLowerCase().replace(/\s/g, '') === particleSizeDistributionX.toLowerCase().replace(/\s/g, '')) {
      const scale50 = attributesInfo.find((attr) => attr.nanoformDynamicAttribute.name === particleSizeDistribution50);
      if (scale50 === undefined) {
        setNanoformData((prevState) => ({ ...prevState, particleSizeDistributionValue: Number(value) }));
      }
    }
  };

  // ONCHANGE METHODS

  const onChangeAttributeValue = (
    inputEvent?: React.ChangeEvent<HTMLInputElement>,
    selectEvent?: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (!inputEvent && !selectEvent) return;
    if (inputEvent && selectEvent) return;

    let name = '';
    let value = '';

    if (inputEvent) {
      name = inputEvent.target.name;
      value = inputEvent.target.value;
    }

    if (selectEvent && !inputEvent) {
      name = selectEvent.target.name;
      value = selectEvent.target.value;
    }

    const idx = attributesInfo.findIndex((attr) => attr.nanoformDynamicAttribute.name === name);

    if (dynamicAttributesErrors[idx] && value.length !== 0) {
      const newErrors = [...dynamicAttributesErrors];
      newErrors[idx] = '';
      setDynamicAttributesErrors(newErrors);
    }

    if (value.length === 0) {
      const newErrors = [...dynamicAttributesErrors];
      newErrors[idx] = errorMessage(attributesInfo[idx]);
      setDynamicAttributesErrors(newErrors);
    }

    const newAttributes = [...attributesInfo];

    newAttributes[idx].nanoformDynamicAttribute.values = value.length !== 0 ? [value] : [];

    checkForVisualisationEffects(name, value);

    setAttributesInfo(newAttributes);
  };

  const onChangeShape = (value: string | number) => {
    if (!useShape) return;
    setNanoformData((prevState) => ({ ...prevState, shape: value as Shape }));
  };

  const onChangeIntegerInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!new RegExp(/(^-?\d*$)/g).test(event.target.value)) {
      return;
    }
    onChangeAttributeValue(event);
  };

  const onChangeFloatInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!new RegExp(/(^-?\d*(?:[,.]\d*)?$)/g).test(event.target.value)) {
      return;
    }
    onChangeAttributeValue(event);
  };

  const onChangeSinglePickerInput = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChangeAttributeValue(undefined, event);
  };

  const onChangeMultiPickerInput = (option: string, enable: boolean, inputName: string) => {
    const idx = attributesInfo.findIndex((attr) => attr.nanoformDynamicAttribute.name === inputName);

    const newAttributes = [...attributesInfo];

    if (enable) {
      newAttributes[idx].nanoformDynamicAttribute.values.push(option);
    } else {
      newAttributes[idx].nanoformDynamicAttribute.values.splice(
        newAttributes[idx].nanoformDynamicAttribute.values.indexOf(option),
        1
      );
    }

    if (newAttributes[idx].nanoformDynamicAttribute.values.length === 0) {
      const newErrors = [...dynamicAttributesErrors];
      newErrors[idx] = errorMessage(attributesInfo[idx]);
      setDynamicAttributesErrors(newErrors);
    } else {
      const newErrors = [...dynamicAttributesErrors];
      newErrors[idx] = '';
      setDynamicAttributesErrors(newErrors);
    }

    checkForVisualisationEffects(inputName, newAttributes[idx].nanoformDynamicAttribute.values.join(', '));

    setAttributesInfo(newAttributes);
  };

  const onChangeCheckboxInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const idx = attributesInfo.findIndex((attr) => attr.nanoformDynamicAttribute.name === event.target.name);

    const newAttributes = [...attributesInfo];

    newAttributes[idx].nanoformDynamicAttribute.values = [String(event.target.checked)];

    setAttributesInfo(newAttributes);
  };

  // SET NANOFORM VALUES BY SELECTING SAVED NANOFORM

  useEffect(() => {
    if (!modelsAttributes) return;

    const newAttributes: IAttributeInfo[] = [];

    modelsAttributes.attributeDefinitions.map((attr) => {
      const mappedAttribute: IDynamicAttribute = {
        name: attr.name,
        type: attr.type,
        values: attr.type === 'Boolean' ? ['false'] : [],
      };

      const newAttribute: IAttributeInfo = {
        label: attr.label,
        pickerOptions: attr.pickerOptions,
        nanoformDynamicAttribute: mappedAttribute,
      };

      if (!fetchedNanoformData) {
        newAttributes.push(newAttribute);
        return mappedAttribute;
      }

      for (let j = 0; j < fetchedNanoformData.attributes.length; j += 1) {
        // eslint-disable-next-line no-continue
        if (attr.attributeDefinitionId !== fetchedNanoformData.attributes[j].attributeDefinitionId) continue;
        if (attr.type !== fetchedNanoformData.attributes[j].valueType) break;

        if (attr.type === 'MultiPicker') {
          const res = attr.pickerOptions.find((o) => o === fetchedNanoformData.attributes[j].attributeValue);
          if (res !== undefined) {
            if (
              !newAttribute.nanoformDynamicAttribute.values.includes(fetchedNanoformData.attributes[j].attributeValue)
            )
              newAttribute.nanoformDynamicAttribute.values.push(fetchedNanoformData.attributes[j].attributeValue);
          }
          // eslint-disable-next-line no-continue
          continue;
        }
        if (attr.type === 'SinglePicker') {
          const res = attr.pickerOptions.find((o) => o === fetchedNanoformData.attributes[j].attributeValue);
          newAttribute.nanoformDynamicAttribute.values = res !== undefined ? [res] : [];
          break;
        }

        newAttribute.nanoformDynamicAttribute.values = [fetchedNanoformData.attributes[j].attributeValue];
        break;
      }

      const uniqueValues = mappedAttribute.values.filter((value, index, array) => array.indexOf(value) === index);
      checkForVisualisationEffects(mappedAttribute.name, uniqueValues.join(', '));
      newAttributes.push(newAttribute);

      return mappedAttribute;
    });

    setAttributesInfo(newAttributes);
    setDynamicAttributesErrors([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelsAttributes, fetchedNanoformData]);

  useEffect(() => {
    if (selectedNanoform.id) {
      populateNanoform();
    }
  }, [selectedNanoform.id, populateNanoform]);

  useEffect(() => {
    if (fetchedNanoformData) {
      const { shape } = fetchedNanoformData;
      setNanoformData((prevState) => ({ ...prevState, shape }));

      reset({ shape: shape ?? '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedNanoformData, setNanoformData, reset]);

  const renderAttributesList = useCallback(() => {
    return attributesInfo.map((attribute: IAttributeInfo, index) => {
      if (attribute.nanoformDynamicAttribute.type === 'Float') {
        return (
          <FormControl
            mb={6}
            isInvalid={!!dynamicAttributesErrors[index]}
            key={attribute.nanoformDynamicAttribute.name}
          >
            <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
              {attribute.label}
            </FormLabel>
            <Input
              placeholder="e.g. 100.50"
              type="text"
              value={attribute.nanoformDynamicAttribute.values[0] ? attribute.nanoformDynamicAttribute.values[0] : ''}
              name={attribute.nanoformDynamicAttribute.name}
              onChange={(e) => onChangeFloatInput(e)}
              isInvalid={!!dynamicAttributesErrors[index]}
            />
            <FormErrorMessage>{dynamicAttributesErrors[index]}</FormErrorMessage>
          </FormControl>
        );
      }
      if (attribute.nanoformDynamicAttribute.type === 'Integer') {
        return (
          <FormControl
            mb={6}
            isInvalid={!!dynamicAttributesErrors[index]}
            key={attribute.nanoformDynamicAttribute.name}
          >
            <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
              {attribute.label}
            </FormLabel>
            <Input
              placeholder="e.g. 100"
              type="text"
              value={attribute.nanoformDynamicAttribute.values[0] ? attribute.nanoformDynamicAttribute.values[0] : ''}
              name={attribute.nanoformDynamicAttribute.name}
              onChange={(e) => onChangeIntegerInput(e)}
              isInvalid={!!dynamicAttributesErrors[index]}
            />
            <FormErrorMessage>{dynamicAttributesErrors[index]}</FormErrorMessage>
          </FormControl>
        );
      }
      if (attribute.nanoformDynamicAttribute.type === 'Boolean') {
        return (
          <FormControl
            mb={6}
            isInvalid={!!dynamicAttributesErrors[index]}
            key={attribute.nanoformDynamicAttribute.name}
          >
            <FormLabel fontSize="2xl" fontWeight="bold" mb={5}>
              {attribute.label}
            </FormLabel>
            <Switch
              name={attribute.nanoformDynamicAttribute.name}
              spacing={4}
              mb={5}
              control={control}
              size="lg"
              colorScheme="brand"
              isChecked={attribute.nanoformDynamicAttribute.values[0]?.toLowerCase() === 'true'}
              onChange={onChangeCheckboxInput}
            />
            <FormErrorMessage>{dynamicAttributesErrors[index]}</FormErrorMessage>
          </FormControl>
        );
      }
      if (attribute.nanoformDynamicAttribute.type === 'SinglePicker') {
        return (
          <FormControl
            id={attribute.nanoformDynamicAttribute.name}
            mb={6}
            isInvalid={!!dynamicAttributesErrors[index]}
            key={attribute.nanoformDynamicAttribute.name}
          >
            <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
              {attribute.label}
            </FormLabel>
            <UncontrolledSelect
              name={attribute.nanoformDynamicAttribute.name}
              placeholderName={attribute.label}
              options={attribute.pickerOptions}
              onChangeSelect={onChangeSinglePickerInput}
              value={attribute.nanoformDynamicAttribute.values[0] ? attribute.nanoformDynamicAttribute.values[0] : ''}
              invalid={!!dynamicAttributesErrors[index]}
            />
            <FormErrorMessage>{dynamicAttributesErrors[index]}</FormErrorMessage>
          </FormControl>
        );
      }
      if (attribute.nanoformDynamicAttribute.type === 'MultiPicker') {
        return (
          <FormControl
            mb={6}
            isInvalid={!!dynamicAttributesErrors[index]}
            key={attribute.nanoformDynamicAttribute.name}
          >
            <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
              {attribute.label}
            </FormLabel>
            <UncontrolledMultiPicker
              defaultTitle={attribute.label}
              options={attribute.pickerOptions}
              isInvalid={!!dynamicAttributesErrors[index]}
              onChangeNoIdx={(option, enable) =>
                onChangeMultiPickerInput(option, !enable, attribute.nanoformDynamicAttribute.name)
              }
              values={attribute.nanoformDynamicAttribute.values ? attribute.nanoformDynamicAttribute.values : undefined}
            />
            <FormErrorMessage>{dynamicAttributesErrors[index]}</FormErrorMessage>
          </FormControl>
        );
      }
      return {};
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributesInfo, dynamicAttributesErrors]);

  return (
    <Box className="define-nanoform" px={10} pt={20} h="100%" overflowY="auto" sx={scrollbar}>
      <Box>
        {nanoformNamesIsLoading || nanoformIsLoading ? (
          <Flex w="100%" minH="100%" justifyContent="center" alignItems="center">
            <Spinner thickness="8px" speed="1s" size="xl" w="100px" h="100px" label="Loading.." />
          </Flex>
        ) : (
          <>
            <LicenseConsumeModal
              isOpen={isOpen}
              predictionsAttemptsCount={chosenLicense.predictionAttemptsCount}
              closeModal={onClose}
              modalAction={() => {
                onClose();
                createPrediction();
              }}
            />
            {nanoformNamesData && nanoformNamesData.length !== 0 && (
              <>
                <Heading fontSize="2xl" fontWeight="bold" mb={4}>
                  {savedNanoform.title}
                </Heading>
                <NanoformsMenu
                  defaultMenuLabel={savedNanoform.buttonText}
                  selectedNanoformName={selectedNanoform.name}
                  nanoformsData={nanoformNamesData}
                  onMenuItemClick={setSelectedNanoform}
                />
              </>
            )}
            <form name="define-nanoform-form">
              {useShape && (
                <FormControl isInvalid={!!shapeAttributeError} mb={6} key="shape">
                  <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
                    {shapes.title}
                  </FormLabel>
                  <ShapePickerGroup
                    value={nanoformData.shape as Shape}
                    invalid={!!shapeAttributeError}
                    spheroidalOptions={spheroidal.options}
                    elongatedOptions={elongated.options}
                    plateletOptions={platelet.options}
                    otherOptions={other.options}
                    spheroidalTitle={spheroidal.title}
                    elongatedTitle={elongated.title}
                    plateletTitle={platelet.title}
                    otherTitle={other.title}
                    onChangeShape={onChangeShape}
                  />
                </FormControl>
              )}
              {!modelsAttributesLoading && attributesInfo && renderAttributesList()}
              {modelsAttributesLoading && (
                <Flex flex="1" justifyContent="center" alignItems="center">
                  <Spinner thickness="8px" speed="1s" size="xl" w="100px" h="100px" label="Loading.." />
                </Flex>
              )}
            </form>
            <Box className="spacer-for-firefox" h="120px" w="100%" />
            <Box position="absolute" w="100%" p={5} left="0" bottom="0">
              <WizardNav
                backLabel="Back"
                nextLabel="Next"
                onNext={handleSubmit(onSubmit, onError)}
                onBack={() => {
                  setCurrentStep(3);
                  setNanoformData((prevState) => ({
                    ...prevState,
                    surfaceFunctionalization: '',
                    coreModification: '',
                    shape: undefined,
                    particleSizeDistributionValue: 0,
                  }));
                }}
                isNextLoading={isLoading}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
