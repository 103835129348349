import React, { useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  VStack,
  Flex,
  Heading,
  Modal,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalOverlay,
  ModalContent,
  Checkbox,
  Link as ChakraLink,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';
import { PrimaryButton, SecondaryButton } from 'src/components';
import {
  useUserDataModalContext,
  useUserDataModalActionsContext,
  useUserContext,
  useUserActionsContext,
  useLoaderActionsContext,
  useLoaderContext,
} from 'src/context';
import { userService } from 'src/services';
import { useSuccessToast } from 'src/hooks';
import { privacyPolicyUrl } from 'src/helpers';
import { userDataFormModalResources } from './userDataFormModalResources';

const schema = yup.object().shape({
  name: yup.string(),
  surname: yup.string(),
  companyName: yup.string().required('Required field'),
  companyAddress: yup.string(),
  organizationNumber: yup.string(),
  marketingConsents: yup.boolean(),
});

export type UserDataFormInputs = {
  name: string;
  surname: string;
  companyName: string;
  companyAddress: string;
  organizationNumber: string;
  marketingConsents: boolean;
};

export const UserDataFormModal: React.FC = () => {
  const { userDataFromApi } = useUserContext();
  const { fetchUserData } = useUserActionsContext();
  const { getAccessTokenSilently } = useAuth0();

  const {
    toasts: { success },
    heading,
    inputs,
    buttons,
  } = userDataFormModalResources;

  const { register, handleSubmit, formState, reset } = useForm<UserDataFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: userDataFromApi,
  });

  const isOpen = useUserDataModalContext();
  const { onClose } = useUserDataModalActionsContext();

  const loading = useLoaderContext();
  const setLoading = useLoaderActionsContext();

  const successToast = useSuccessToast(success.title, success.description);

  const onSubmit = async (values: UserDataFormInputs) => {
    const { companyName, companyAddress, organizationNumber, name, surname, marketingConsents } = values;
    setLoading(true);

    try {
      const token = await getAccessTokenSilently();

      const response = await userService.modifyUserData(
        companyName,
        companyAddress,
        organizationNumber,
        name,
        surname,
        marketingConsents,
        token
      );

      if (response) {
        successToast();
        await fetchUserData();
        onClose();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => onClose();

  useEffect(() => {
    reset(userDataFromApi);
  }, [userDataFromApi, reset]);

  return (
    <Modal isOpen={isOpen} onClose={closeModal} isCentered size="xl" autoFocus={false}>
      <ModalOverlay bg="utils.modalOverlay" />
      <ModalContent borderRadius="0.25rem" bg="custom.aliceBlue" borderWidth="1px" borderColor="utils.border">
        <ModalHeader px={8} pt={8} pb={4}>
          <Heading size="xl" isTruncated>
            {heading}
          </Heading>
        </ModalHeader>
        <ModalCloseButton mt={7} mr={7} colorScheme="brand" size="md" onClick={closeModal} disabled={loading} />
        <ModalBody p={8}>
          <form>
            <VStack spacing={4} alignItems="flex-start">
              <FormControl isInvalid={!!formState.errors?.name?.message} errortext={formState.errors?.name?.message}>
                <FormLabel>{inputs.name.label}</FormLabel>
                <Input type="text" name="name" placeholder={inputs.name.placeholder} ref={register} />
                <FormErrorMessage>{formState.errors?.name?.message}</FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={!!formState.errors?.surname?.message}
                errortext={formState.errors?.surname?.message}
              >
                <FormLabel>{inputs.surname.label}</FormLabel>
                <Input type="text" name="surname" placeholder={inputs.surname.placeholder} ref={register} />
                <FormErrorMessage>{formState.errors?.surname?.message}</FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={!!formState.errors?.companyName?.message}
                errortext={formState.errors?.companyName?.message}
                isRequired
              >
                <FormLabel>{inputs.companyName.label}</FormLabel>
                <Input
                  variant="outline"
                  type="text"
                  name="companyName"
                  placeholder={inputs.companyName.placeholder}
                  ref={register}
                />
                <FormErrorMessage>{formState.errors?.companyName?.message}</FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={!!formState.errors?.companyAddress?.message}
                errortext={formState.errors?.companyAddress?.message}
              >
                <FormLabel>{inputs.companyAddress.label}</FormLabel>
                <Input
                  type="text"
                  name="companyAddress"
                  placeholder={inputs.companyAddress.placeholder}
                  ref={register}
                />
                <FormErrorMessage>{formState.errors?.companyAddress?.message}</FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={!!formState.errors?.organizationNumber?.message}
                errortext={formState.errors?.organizationNumber?.message}
              >
                <FormLabel>{inputs.organizationNumber.label}</FormLabel>
                <Input
                  type="text"
                  name="organizationNumber"
                  placeholder={inputs.organizationNumber.placeholder}
                  ref={register}
                />
                <FormErrorMessage>{formState.errors?.organizationNumber?.message}</FormErrorMessage>
              </FormControl>
              <Checkbox
                name="marketingConsents"
                ref={register}
                key="marketingConsents"
                spacing={4}
                pt={3}
                sx={{
                  '&  .chakra-checkbox__label': {
                    fontSize: '14px',
                    fontWeight: '400',
                  },
                }}
              >
                {inputs.marketingConsents.label}
                <ChakraLink
                  href={privacyPolicyUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                  color="typo.green"
                  onClick={closeModal}
                >
                  {inputs.marketingConsents.link}
                </ChakraLink>
              </Checkbox>
              <Flex w="100%" justifyContent="flex-end" pt={8}>
                <SecondaryButton mr={4} onClick={closeModal} disabled={loading}>
                  {buttons.secondary}
                </SecondaryButton>
                <PrimaryButton onClick={handleSubmit(onSubmit)} isLoading={loading} disabled={!formState.isValid}>
                  {buttons.primary}
                </PrimaryButton>
              </Flex>
            </VStack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
