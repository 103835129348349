import React, { useState, createContext, useContext } from 'react';

interface IProps {
  children: JSX.Element;
}

interface IErrorContext {
  error: IError;
  isUserBlocked: boolean;
}

interface IErrorActionsContext {
  setError: React.Dispatch<React.SetStateAction<IError>>;
  setIsUserBlocked: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IError {
  statusCode?: number;
  statusMessage?: string;
}

const ErrorContext = createContext<IErrorContext>({} as IErrorContext);
const ErrorActionsContext = createContext<IErrorActionsContext>({} as IErrorActionsContext);

export const useErrorContext: () => IErrorContext = () => useContext(ErrorContext);
export const useErrorActionsContext: () => IErrorActionsContext = () => useContext(ErrorActionsContext);

export const ErrorContextProvider: React.FC<IProps> = ({ children }) => {
  const [error, setError] = useState<IError>({ statusCode: undefined, statusMessage: undefined });
  const [isUserBlocked, setIsUserBlocked] = useState<boolean>(false);

  return (
    <ErrorContext.Provider value={{ isUserBlocked, error }}>
      <ErrorActionsContext.Provider value={{ setError, setIsUserBlocked }}>{children}</ErrorActionsContext.Provider>
    </ErrorContext.Provider>
  );
};
