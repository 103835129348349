import React from 'react';
import {
  UserContextProvider,
  LoaderContextProvider,
  ErrorContextProvider,
  UserDataModalContextProvider,
  NewExperimentWizardContextProvider,
  NewModelWizardContextProvider,
  NanoformContextProvider,
  CoreWizardContextProvider,
  BuyLicenceContextProvider,
  ManageLicensesContextProvider,
  ReportWizardContextProvider,
} from './index';

interface IProps {
  children: JSX.Element;
}

export const GlobalContext: React.FC<IProps> = ({ children }) => {
  return (
    <LoaderContextProvider>
      <ErrorContextProvider>
        <UserContextProvider>
          <UserDataModalContextProvider>
            <NanoformContextProvider>
              <NewExperimentWizardContextProvider>
                <NewModelWizardContextProvider>
                  <CoreWizardContextProvider>
                    <BuyLicenceContextProvider>
                      <ManageLicensesContextProvider>
                        <ReportWizardContextProvider>{children}</ReportWizardContextProvider>
                      </ManageLicensesContextProvider>
                    </BuyLicenceContextProvider>
                  </CoreWizardContextProvider>
                </NewModelWizardContextProvider>
              </NewExperimentWizardContextProvider>
            </NanoformContextProvider>
          </UserDataModalContextProvider>
        </UserContextProvider>
      </ErrorContextProvider>
    </LoaderContextProvider>
  );
};
