import { stringToHash } from 'src/helpers';

const MODAL_HAS_BEEN_DISPLAYED = 'modalHasBeenDisplayed';
const USER_WAS_REDIRECTED_TO_LICENSES = 'userWasRedirectedToLicenses';

class UserConfig {
  modalDisplayedToUser = (userID: string) =>
    localStorage.getItem(`${MODAL_HAS_BEEN_DISPLAYED}_${stringToHash(userID)}`);

  setModalDisplayedToUser = (userID: string, value: string) =>
    localStorage.setItem(`${MODAL_HAS_BEEN_DISPLAYED}_${stringToHash(userID)}`, value);

  removeModalDisplayedToUser = (userID: string) =>
    localStorage.removeItem(`${MODAL_HAS_BEEN_DISPLAYED}_${stringToHash(userID)}`);

  userRedirectedToLicenses = () => localStorage.getItem(USER_WAS_REDIRECTED_TO_LICENSES);

  setUserRedirectedToLicenses = (value: string) => localStorage.setItem(USER_WAS_REDIRECTED_TO_LICENSES, value);

  removeUserRedirectedToLicenses = () => localStorage.removeItem(USER_WAS_REDIRECTED_TO_LICENSES);
}

export const userConfig = new UserConfig();
