import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

interface IModelTextProps {
  text: string;
  variant: 'left' | 'right';
  dotSize: string;
  dotColor: string;
}

export const ModelText: React.FC<IModelTextProps> = ({ text, variant, dotSize, dotColor }) => (
  <Flex flexDirection={variant === 'right' ? 'row' : 'row-reverse'} minW="20vw">
    <Box borderRadius="50%" minW={dotSize} minH={dotSize} bg={dotColor} my="auto" />
    <Box
      className="model-text"
      position="relative"
      display="flex"
      fontSize="18px"
      fontWeight="600"
      color="#425a70"
      overflowWrap="normal"
      zIndex="base"
      pl={variant !== 'left' ? 10 : 0}
      pr={variant !== 'right' ? 10 : 0}
    >
      {text}
    </Box>
  </Flex>
);
