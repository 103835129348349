import React, { useState, createContext, useContext } from 'react';
import { UserStatus, IUserInfo } from 'src/model';

interface IProps {
  children: JSX.Element;
}

export interface IManageLicensesContext {
  userInfo: IUserInfo;
}

export interface IManageLicensesActionsContext {
  setUserInfo: React.Dispatch<React.SetStateAction<IUserInfo>>;
  resetUserInfo: () => void;
}

const ManageLicensesContext = createContext<IManageLicensesContext>({} as IManageLicensesContext);
const ManageLicensesActionsContext = createContext<IManageLicensesActionsContext>({} as IManageLicensesActionsContext);

export const useManageLicensesContext: () => IManageLicensesContext = () => useContext(ManageLicensesContext);
export const useManageLicensesActionsContext: () => IManageLicensesActionsContext = () =>
  useContext(ManageLicensesActionsContext);

export const ManageLicensesContextProvider: React.FC<IProps> = ({ children }) => {
  const defaultUserInfo: IUserInfo = { userId: '', email: '', status: UserStatus.Active, role: '' };
  const [userInfo, setUserInfo] = useState<IUserInfo>(defaultUserInfo);

  const resetUserInfo = (): void => {
    setUserInfo(defaultUserInfo);
  };

  return (
    <ManageLicensesContext.Provider value={{ userInfo }}>
      <ManageLicensesActionsContext.Provider value={{ setUserInfo, resetUserInfo }}>
        {children}
      </ManageLicensesActionsContext.Provider>
    </ManageLicensesContext.Provider>
  );
};
