import axios, { AxiosInstance } from 'axios';

const baseUrl = window.env !== undefined ? window.env.REACT_APP_SERVER_URL : process.env.REACT_APP_SERVER_URL;

const axiosInstance: AxiosInstance = axios.create({
  baseURL: `${baseUrl}/v1`,
});

export const authorizedApiService = (token: string): AxiosInstance => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axiosInstance;
};

export const apiService = axiosInstance;
