import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { useQueryClient } from 'react-query';
import { motion } from 'framer-motion';
import { Flex, CloseButton, Box } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { Step, CreateEditModel, CreateVersion, Spinner } from 'src/components';
import { useNewModelWizardActionsContext, useNewModelWizardContext } from 'src/context';
import { modelService } from 'src/services';
import { useSuccessToast, useDocumentTitle } from 'src/hooks';
import { modelDetailsViewResources } from 'src/helpers';
import { IAttributeModel, INewAttributeDefinition } from 'src/model';
import { DownloadScript } from './DownloadScript';
import { newModelWizardResources } from './newModelWizardResources';

const newModelWizardVariants = {
  hidden: {
    x: '100vw',
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 50,
    },
  },
  visible: {
    x: 0,
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 50,
    },
  },
};

interface INewModelWizardProps {
  newVersion?: boolean;
  existingModelId?: string;
  onModelUpdated?: () => void;
}

export const NewModelWizard: React.FC<INewModelWizardProps> = ({ newVersion, existingModelId, onModelUpdated }) => {
  const { onWizardClose, setCurrentStep } = useNewModelWizardActionsContext();
  const { currentStep, model, version, attributeModels, isModelEdited } = useNewModelWizardContext();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [modelId, setModelId] = useState<string>('');
  const [versionId, setVersionId] = useState<string>('');
  const header = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const queryClient = useQueryClient();

  const { getAccessTokenSilently, user } = useAuth0();

  const changeDocumentTitle = useDocumentTitle(newModelWizardResources.steps.createModel);

  const modelCreationSuccessToast = useSuccessToast(
    newModelWizardResources.toasts.saveModelSuccess.title,
    newModelWizardResources.toasts.saveModelSuccess.description
  );

  const modelsVersionCreationSuccessToast = useSuccessToast(
    newModelWizardResources.toasts.saveModelVersionSuccess.title,
    newModelWizardResources.toasts.saveModelVersionSuccess.description
  );

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const filterAttributeDefinitions = (): INewAttributeDefinition[] => {
    const filteredAttributeDefinitions: IAttributeModel[] = [...attributeModels];
    for (let i = 0; i < filteredAttributeDefinitions.length; i += 1) {
      filteredAttributeDefinitions[i].attributeDefinition.order = i + 1;
      if (!filteredAttributeDefinitions[i].attributeDefinition.isEnabled) {
        filteredAttributeDefinitions[i].attributeDefinition.attributePickerOptions = [];
      }
    }
    return filteredAttributeDefinitions.map((a) => a.attributeDefinition);
  };

  const saveModel = async () => {
    setLoading(true);

    const newModel = { ...model };
    if (newModel.isDemo) {
      newModel.oneNanoformPrice = 0;
      newModel.manyNanoformsPrice = 0;
    }

    try {
      const token = await getAccessTokenSilently();
      const createModelWithVersionData = await modelService
        .createModelWithVersion(newModel, version, filterAttributeDefinitions(), user.sub, token)
        .then((response) => response.data);

      setModelId(createModelWithVersionData.modelId as string);
      setVersionId(createModelWithVersionData.versionId as string);

      await queryClient.refetchQueries(['manage-models-list']);

      if (createModelWithVersionData) {
        modelCreationSuccessToast();
        nextStep();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const saveNewModelVersion = async () => {
    if (existingModelId) {
      setLoading(true);

      try {
        const token = await getAccessTokenSilently();
        const createModelVersionData = await modelService
          .createModelVersion(existingModelId, version, filterAttributeDefinitions(), token)
          .then((response) => response.data);

        setModelId(existingModelId);
        setVersionId(createModelVersionData as string);

        await queryClient.removeQueries(['model-versions-list', existingModelId]);

        if (createModelVersionData) {
          modelsVersionCreationSuccessToast();
          nextStep();
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const updateModel = async () => {
    if (!isModelEdited) return;
    setLoading(true);

    const newModel = { ...model };
    if (newModel.isDemo) {
      newModel.oneNanoformPrice = 0;
      newModel.manyNanoformsPrice = 0;
    }

    try {
      const token = await getAccessTokenSilently();
      const updateModelData = await modelService
        .updateModel(newModel, user.sub, token)
        .then((response) => response.data);

      setModelId(updateModelData as string);

      await queryClient.refetchQueries(['manage-models-list']);

      if (updateModelData) {
        modelCreationSuccessToast();
        onWizardClose();
        if (onModelUpdated) {
          onModelUpdated();
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const done = async () => {
    onWizardClose();
  };

  const isDisabled = currentStep > 2;

  useEffect(() => {
    return () => {
      onWizardClose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (header.current !== null) {
      setHeaderHeight(header.current.clientHeight);
    }
  }, [setHeaderHeight]);

  return (
    <Flex
      as={motion.div}
      variants={newModelWizardVariants}
      initial="hidden"
      animate="visible"
      exit="hidden"
      className="new-study-wizard"
      position="fixed"
      top="0"
      left="0"
      flexDirection="column"
      h="100vh"
      w="100%"
      bg="utils.background"
      overflowY="hidden"
      zIndex={999}
    >
      <Flex
        className="new-model-wizard__header"
        alignItems="center"
        bg={isDisabled ? 'input.disabledBg' : 'typo.white'}
        boxShadow="0px 3px 12px #00000029"
        zIndex="1"
        ref={header}
      >
        <CloseButton
          className="new-model-wizard__close-button"
          size="lg"
          fontSize="1.5rem"
          mx={5}
          onClick={() => {
            onWizardClose();
            changeDocumentTitle(modelDetailsViewResources.documentTitle);
          }}
          isDisabled={isDisabled}
        />
        <Flex className="new-model-wizard__steps" flex="1">
          <Step
            isActive={Boolean(currentStep === 1)}
            stepNumber={1}
            isCompleted={Boolean(currentStep > 1)}
            title={
              isModelEdited ? newModelWizardResources.steps.modifyModel : newModelWizardResources.steps.createModel
            }
            isDisabled={isDisabled}
            isAnimated={newVersion}
          />
          {!isModelEdited && (
            <Step
              isActive={Boolean(currentStep === 2)}
              isCompleted={Boolean(currentStep > 2)}
              stepNumber={2}
              title={newModelWizardResources.steps.createVersion}
              isDisabled={isDisabled}
            />
          )}
        </Flex>
      </Flex>
      <Flex
        className="new-model-wizard__body"
        flexDirection="column"
        bg="utils.background"
        h={`calc(100% - ${headerHeight}px)`}
      >
        {isLoading && (
          <Flex w="100%" flex="1" justifyContent="center" alignItems="center">
            <Spinner thickness="8px" speed="1s" size="xl" w="100px" h="100px" label="Loading.." />
          </Flex>
        )}
        {!isLoading && (
          <Box className="new-model-wizard__configurator" position="relative" h="100%">
            {currentStep === 1 && (
              <CreateEditModel
                onNext={() => {
                  if (isModelEdited) {
                    updateModel();
                  } else {
                    nextStep();
                  }
                }}
              />
            )}
            {currentStep === 2 && (
              <CreateVersion
                existingModelId={existingModelId}
                onBack={!newVersion ? () => prevStep() : undefined}
                onNext={() => {
                  if (newVersion) {
                    saveNewModelVersion();
                  } else {
                    saveModel();
                  }
                }}
              />
            )}
            {currentStep === 3 && <DownloadScript modelId={modelId} versionId={versionId} onDone={() => done()} />}
          </Box>
        )}
      </Flex>
    </Flex>
  );
};
