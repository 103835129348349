import React from 'react';
import { VStack, Heading, Checkbox, Box, useTheme, Flex, HStack, Text, Tooltip } from '@chakra-ui/react';
import { ICore, ILicenseModel, LicenseStatus, ICoreModel, ICoreModelGroup, ModelStatus } from 'src/model';
import { userLicensesViewResources } from 'src/helpers';
import { UncontrolledCoreRadioGroup } from '../common';
import { LicenseStatusBadge } from './LicenseStatusBadge';

interface ILicenseDetailsModelsList {
  modelGroups: ICoreModelGroup[];
  selectedItems: ILicenseModel[];
  onChangeCore: (value: string) => void;
  cores: ICore[];
  defaultCore?: string;
}

export const LicenseDetailsModelsList: React.FC<ILicenseDetailsModelsList> = ({
  modelGroups,
  selectedItems,
  onChangeCore,
  cores,
  defaultCore,
}) => {
  const {
    colors: { typo },
  } = useTheme();

  const filterModels = (models: ICoreModel[]): ICoreModel[] => {
    const newModels: ICoreModel[] = [];
    models.forEach((m) => {
      if (!m.isDemo) {
        newModels.push(m);
      } else if (selectedItems.some((item) => item.endpoint === m.endpoint)) {
        newModels.push(m);
      }
    });

    return newModels;
  };

  const filterGroups = (groups: ICoreModelGroup[]): ICoreModelGroup[] => {
    return groups.filter(
      (g) =>
        g.models.filter((m) => selectedItems.find((item) => item.endpoint === m.endpoint)).length > 0 ||
        g.models.filter((m) => !m.isDemo).length > 0
    );
  };

  const { modelDeactivated } = userLicensesViewResources;

  return (
    <>
      {modelGroups.length === 0 || cores.length === 0 ? (
        <Heading paddingBottom={0} size="md">
          No models available for this license
        </Heading>
      ) : (
        <Heading paddingBottom={4} size="md">
          Core type
        </Heading>
      )}
      <UncontrolledCoreRadioGroup
        onChange={(value) => onChangeCore(value as string)}
        options={cores}
        defaultValue={defaultCore}
      />
      {modelGroups.length !== 0 && cores.length !== 0 && (
        <Heading paddingBottom={4} paddingTop={8} size="md">
          Your endpoints
        </Heading>
      )}
      <Flex align="baseline" flexWrap="wrap">
        {filterGroups(modelGroups).map(
          (group: ICoreModelGroup) =>
            group.models.length !== 0 && (
              <VStack
                flex="3"
                spacing={4}
                key={`${group.name}`}
                align="start"
                w={`${100 / filterGroups(modelGroups).length}%`}
              >
                <Heading variant="outline" fontWeight="550" size="sm" color={typo.grey} minH="3rem">
                  {group.name.toUpperCase()}
                </Heading>
                <VStack align="start" w="100%" spacing={0}>
                  {filterModels(group.models).map((model: ICoreModel) => {
                    return (
                      <HStack key={`${group.name}_${model.endpoint}`}>
                        <HStack wordBreak="break-all">
                          <Checkbox
                            colorScheme="brand"
                            size="lg"
                            isDisabled
                            isChecked={selectedItems.some((item) => item.endpoint === model.endpoint)}
                          />

                          {model.status === ModelStatus.Deactivated ? (
                            <Box cursor="help" bg="radio.bg" borderRadius="0.25rem" py="1" px="2">
                              <Tooltip
                                hasArrow
                                label={modelDeactivated}
                                bg="typo.blue"
                                borderRadius="0.25rem"
                                maxW="15rem"
                              >
                                <Text color="typo.grey" fontSize="lg">
                                  {model.endpoint}
                                </Text>
                              </Tooltip>
                            </Box>
                          ) : (
                            <Box bg="transparent" borderRadius="0.25rem" py="1" px="2">
                              <Text fontSize="lg">{model.endpoint}</Text>
                            </Box>
                          )}
                        </HStack>
                        {selectedItems.find((item) => item.endpoint === model.endpoint)?.hasWaitingPayment && (
                          <Box mr={2}>
                            <LicenseStatusBadge licenseStatus={LicenseStatus.waiting} />
                          </Box>
                        )}
                      </HStack>
                    );
                  })}
                </VStack>
              </VStack>
            )
        )}
      </Flex>
    </>
  );
};
