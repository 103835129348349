import React from 'react';
import dayjs from 'dayjs';
import InfiniteScroll from 'react-infinite-scroller';
import { Table, Tbody, Box, Tr, Text, Badge } from '@chakra-ui/react';
import { TableHeader, TableData, EmptyListState } from 'src/components';
import { DATE_FORMAT, predictionsEmptyList, laboratoryViewResources } from 'src/helpers';
import { IPrediction, PredictionStatus } from 'src/model';
import { useHistory } from 'react-router-dom';

interface IPredictionsListProps {
  data: IPrediction[];
  getMoreData: () => void;
  hasMore: boolean;
}

const renderBadge = (status: PredictionStatus) => {
  switch (status) {
    case 'Done':
      return (
        <Badge colorScheme="green" py={1} px="12px" fontSize="md" color="typo.green" fontWeight="500">
          {laboratoryViewResources.predictionStatus.done}
        </Badge>
      );
    case 'Failed':
      return (
        <Badge colorScheme="red" py={1} px="12px" fontSize="md" color="utils.alert" fontWeight="500">
          {laboratoryViewResources.predictionStatus.failed}
        </Badge>
      );
    default:
      return (
        <Badge colorScheme="blue" py={1} px="12px" fontSize="md" color="typo.blue" fontWeight="500">
          {laboratoryViewResources.predictionStatus.inprogress}
        </Badge>
      );
  }
};

export const PredictionsList: React.FC<IPredictionsListProps> = ({ data, getMoreData, hasMore }) => {
  const history = useHistory();
  return (
    <Box className="manage-models-list" w="100%">
      {data.length ? (
        <InfiniteScroll pageStart={0} hasMore={hasMore} loadMore={getMoreData} useWindow>
          <Table
            variant="unstyled"
            w="100%"
            h="100%"
            sx={{ borderCollapse: 'separate', borderSpacing: '0 10px', tableLayout: 'fixed' }}
            cellSpacing="0"
          >
            <TableHeader titles={['Predictions', 'Date', 'Status']} />
            <Tbody>
              {data.map((prediction: IPrediction) => (
                <Tr
                  key={`prediction-${prediction.id}`}
                  h="82px"
                  onClick={() =>
                    history.push({
                      pathname: `/prediction/${prediction.id}`,
                    })
                  }
                  cursor="pointer"
                >
                  <TableData>
                    <Text fontWeight="700" fontSize="1.125rem">
                      {prediction.name}
                    </Text>
                  </TableData>
                  <TableData>{dayjs(prediction.createdDate).format(DATE_FORMAT)}</TableData>
                  <TableData>{renderBadge(prediction.status)}</TableData>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </InfiniteScroll>
      ) : (
        <EmptyListState title={predictionsEmptyList} />
      )}
    </Box>
  );
};
