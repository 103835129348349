import { useMemo } from 'react';
import { useToast } from '@chakra-ui/react';
import { apiService } from 'src/services';
import { useErrorActionsContext } from 'src/context';

interface IWithAxiosProps {
  children: JSX.Element;
}

export const WithAxios = ({ children }: IWithAxiosProps): JSX.Element => {
  const toast = useToast();
  const { setIsUserBlocked } = useErrorActionsContext();

  useMemo(() => {
    apiService.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        switch (error.response.status) {
          case 423:
            setIsUserBlocked(true);
            toast({
              title: 'Your account is blocked',
              description: 'If you want to get your access back, reach out to us',
              status: 'error',
              duration: 4000,
              position: 'top-right',
              isClosable: true,
            });
            break;
          default:
            toast({
              title: 'An error occurred',
              description:
                error.response?.data.message ?? 'Try again, if the problem still occurs, please contact QSAR Lab',
              status: 'error',
              duration: 4000,
              position: 'top-right',
              isClosable: true,
            });
        }
      }
    );
  }, [toast, setIsUserBlocked]);

  return children;
};
