import React from 'react';
import { Select } from '@chakra-ui/react';

interface IUncontrolledSelectProps {
  name: string;
  placeholderName: string;
  onChangeSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options: string[];
  value: string;
  invalid: boolean;
}

export const UncontrolledSelect: React.FC<IUncontrolledSelectProps> = ({
  name,
  placeholderName,
  onChangeSelect,
  options,
  value,
  invalid,
}) => {
  return (
    <Select
      onChange={(e) => {
        onChangeSelect(e);
      }}
      name={name}
      value={value}
      errorBorderColor="utils.alert"
      textColor={value === placeholderName || value === '' ? 'dimgrey' : ''}
      isInvalid={invalid}
    >
      <option key={`${name}-option-empty`} value="" style={{ color: 'dimgrey' }}>
        {placeholderName}
      </option>
      {options.map((option: string) => (
        <option key={`${name}-option-${option}`} value={option} style={{ color: '#1B4480' }}>
          {option}
        </option>
      ))}
    </Select>
  );
};
