import React from 'react';
import { useRadioGroup, Flex, Box, Text, useTheme } from '@chakra-ui/react';
import { RadioOption } from 'src/components';
import { ReportType } from 'src/model';

interface IReportTypeRadioOptionGroupProps {
  controllerRef: React.MutableRefObject<HTMLDivElement>;
  reportTypes: ReportType[];
  defaultValue: ReportType | undefined;
  name: string;
  invalid: boolean;
  onChange: (...event: unknown[]) => void;
  onChangeReportType: (value: string | number) => void;
}

const WrapperStyles = {
  '&:not(:last-child)': {
    marginRight: '1rem',
  },
};

export const ReportTypeRadioOptionGroup: React.FC<IReportTypeRadioOptionGroupProps> = ({
  controllerRef,
  defaultValue,
  onChange,
  reportTypes,
  name,
  onChangeReportType,
  invalid,
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    onChange: (value: string | number) => {
      onChange(value);
      onChangeReportType(value);
    },
  });

  const {
    colors: { utils },
  } = useTheme();

  const group = getRootProps();

  return (
    <Flex {...group} flexDirection="row" ref={controllerRef}>
      <Flex wrap="wrap">
        {reportTypes.map((reportType: string) => {
          const radio = getRadioProps({ value: reportType, id: reportType });
          return (
            <Box key={`report-type-${reportType}`} display="inline-block" sx={WrapperStyles}>
              <RadioOption {...radio}>
                <Flex alignItems="center" justifyContent="center">
                  <Text color={invalid && utils.alert}>{reportType}</Text>
                </Flex>
              </RadioOption>
            </Box>
          );
        })}
      </Flex>
    </Flex>
  );
};
