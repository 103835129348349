import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import {
  FormControl,
  FormLabel,
  SimpleGrid,
  VStack,
  Box,
  FormErrorMessage,
  Flex,
  Button,
  useTheme,
  Switch,
  Text,
  Spacer,
} from '@chakra-ui/react';
import { DATE_FORMAT, scrollbar } from 'src/helpers';
import { useNewModelWizardActionsContext, useNewModelWizardContext } from 'src/context';
import {
  CreateModelVersionFormInputs,
  IAttributeDefinition,
  INewAttributeDefinition,
  IActiveVersion,
  IAttributeModel,
  IAtributeValidation,
} from 'src/model';
import { createModelVersionYupSchema } from 'src/utils';
import {
  NanoformAttributeGroup,
  ControlledInput,
  ControlledTextArea,
  WizardNav,
  AttributesAdder,
} from 'src/components';
import { useErrorToast, useDocumentTitle } from 'src/hooks';
import { attributeService, modelService } from 'src/services';
import { useAuth0 } from '@auth0/auth0-react';
import { AxiosResponse } from 'axios';
import { ReactComponent as DragIcon } from 'src/assets/drag.svg';
import { newModelWizardResources } from './newModelWizardResources';

interface ICreateVersionProps {
  onNext: () => void;
  onBack?: () => void;
  existingModelId?: string;
}

export const CreateVersion: React.FC<ICreateVersionProps> = ({ onNext, onBack, existingModelId }) => {
  useDocumentTitle(newModelWizardResources.steps.createVersion);
  const [popup, setPopup] = useState<boolean>(false);
  const { createVersion, toasts } = newModelWizardResources;
  const { validationErrorToast } = toasts;
  const formValidationErrorToast = useErrorToast(validationErrorToast.title, validationErrorToast.description);
  const { getAccessTokenSilently } = useAuth0();

  const {
    colors: { button },
  } = useTheme();

  const {
    version: { version, author, changeLog, qmrfReference, createdDate, functionName, useShape },
    attributeModels,
    attributeTypes,
  } = useNewModelWizardContext();

  const { setVersion, setAttributeDefinition, setAttributeModels } = useNewModelWizardActionsContext();

  const methods = useForm<CreateModelVersionFormInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(createModelVersionYupSchema()),
    defaultValues: {
      version,
      author,
      changeLog,
      qmrfReference,
      createdDate: dayjs(createdDate).format(DATE_FORMAT),
      functionName,
    },
    shouldFocusError: false,
  });

  const pickerErrorMessage = (attributeModel: IAttributeModel) =>
    `'${attributeModel.attributeDefinition.label}' picker options should not be empty`;

  const checkIfPicker = (attributeModel: IAttributeModel): boolean => {
    return (
      attributeModel.attributeDefinition.type === attributeTypes[3] ||
      attributeModel.attributeDefinition.type === attributeTypes[4]
    );
  };

  const { handleSubmit, control, errors } = methods;

  const validatePickerOptions = (): boolean => {
    const newAttributeModels = [...attributeModels];
    let error = false;
    for (let i = 0; i < newAttributeModels.length; i += 1) {
      newAttributeModels[i].pickerErrorMessage = '';
      if (
        newAttributeModels[i].attributeDefinition.attributePickerOptions.length === 0 &&
        checkIfPicker(newAttributeModels[i]) &&
        newAttributeModels[i].attributeDefinition.isEnabled
      ) {
        newAttributeModels[i].pickerErrorMessage = pickerErrorMessage(newAttributeModels[i]);
        error = true;
      }
    }
    setAttributeModels(newAttributeModels);

    if (!error) return true;

    return false;
  };

  const onError = () => {
    validatePickerOptions();
    formValidationErrorToast();
  };

  const onSubmit = async () => {
    if (!validatePickerOptions()) {
      formValidationErrorToast();
      return;
    }
    let validateResponse: IAtributeValidation[] = [];
    try {
      const token = await getAccessTokenSilently();
      validateResponse = await attributeService
        .validateAttributes(
          attributeModels.map((a) => a.attributeDefinition),
          token,
          existingModelId
        )
        .then((response) => response.data);
    } catch (error) {
      console.error(error);
    }

    let error = false;
    const newAttributeModels = [...attributeModels];
    for (let i = 0; i < validateResponse.length; i += 1) {
      const idx = newAttributeModels.findIndex((a) => a.attributeDefinition.id === validateResponse[i].id);
      if (validateResponse[i].wrongType) {
        newAttributeModels[
          idx
        ].errorMessage = `${newAttributeModels[idx].attributeDefinition.label}'s ${newAttributeModels[idx].orginalType} type cannot be changed`;
        error = true;
      }
    }
    setAttributeModels(newAttributeModels);

    if (!error) onNext();
  };

  const onChangeVersion = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setVersion((prevState) => ({ ...prevState, version: value as string }));
  };

  const onChangeAuthor = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setVersion((prevState) => ({ ...prevState, author: value as string }));
  };

  const onChangeChangeLog = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;
    setVersion((prevState) => ({ ...prevState, changeLog: value as string }));
  };

  const onChangeAttribute = (name: string, checked: boolean) => {
    const newAttributeModels = [...attributeModels];
    const idx = newAttributeModels.findIndex((a) => a.attributeDefinition.name === name);
    newAttributeModels[idx].attributeDefinition.isEnabled = checked;

    if (
      newAttributeModels[idx].attributeDefinition.attributePickerOptions.length === 0 &&
      checked &&
      checkIfPicker(newAttributeModels[idx])
    ) {
      newAttributeModels[idx].pickerErrorMessage = pickerErrorMessage(newAttributeModels[idx]);
    }

    if (!checked) {
      newAttributeModels[idx].pickerErrorMessage = '';
    }

    setAttributeModels(newAttributeModels);
  };

  const onChangeQmrfReference = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setVersion((prevState) => ({ ...prevState, qmrfReference: value as string }));
  };

  const onChangeFunctionName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setVersion((prevState) => ({ ...prevState, functionName: value as string }));
  };

  const onChangeUseShape = (value: boolean) => {
    setVersion((prevState) => ({ ...prevState, useShape: value }));
  };

  const showAdder = () => {
    setPopup(!popup);
  };

  const onEditAttribute = (attributeDefinition: INewAttributeDefinition) => {
    setAttributeDefinition(attributeDefinition);
    setPopup(!popup);
  };

  const fetchAllAttributes = async () => {
    try {
      const token = await getAccessTokenSilently();
      const attributes: IAttributeDefinition[] = await attributeService
        .getAllAttributes(token)
        .then((response: AxiosResponse) => response.data);

      for (let i = 0; i < attributes.length; i += 1) {
        if (!attributes[i].attributePickerOptions) {
          attributes[i].attributePickerOptions = [];
        }
        if (!attributes[i].id) {
          attributes[i].id = null;
        }
        attributes[i].isEnabled = true;
      }

      return attributes.sort((a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime());
    } catch (error) {
      console.error(error);
    }

    return [];
  };

  const fetchActiveVersion = async (): Promise<IActiveVersion | null> => {
    if (!existingModelId) return null;
    try {
      const token = await getAccessTokenSilently();
      const activeVersion: IActiveVersion = await modelService
        .getActiveVersionForModel(existingModelId, token)
        .then((response: AxiosResponse) => response.data);

      return activeVersion;
    } catch (error) {
      console.error(error);
    }
    return null;
  };

  const createFullAttributesList = async () => {
    const allAttributes = await fetchAllAttributes();
    const activeVersion = await fetchActiveVersion();
    const activeVersionAttributes = activeVersion ? activeVersion.attributes.sort((x) => x.order) : [];
    const activeVersionUseShape = activeVersion ? activeVersion.useShape : true;

    const attributesNotInVersion = allAttributes.filter(
      (attribute) => !activeVersionAttributes.some((a) => a.name === attribute.name)
    );

    const mergedAttributes = activeVersionAttributes.concat(attributesNotInVersion);

    const attributesModelsNotInVersion: IAttributeModel[] = mergedAttributes.map((a) => {
      return {
        attributeDefinition: a,
        orginalType: a.type,
        errorMessage: '',
        pickerErrorMessage: '',
      };
    });

    setVersion((prevState) => ({ ...prevState, useShape: activeVersionUseShape }));
    setAttributeModels(attributesModelsNotInVersion);
  };

  useEffect(() => {
    if (attributeModels.length !== 0) {
      return;
    }

    createFullAttributesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {popup && <AttributesAdder showAdder={() => showAdder()} />}
      <Box h="100%" pt={16} px="15%" sx={scrollbar} overflow="auto">
        <form name="create-version">
          <SimpleGrid
            className="model-creator-version-fields-grid"
            spacing={16}
            columns={[1, 2, 2, 2, 2]}
            minChildWidth="200px"
          >
            <VStack>
              <FormControl id="version" mb={4} isInvalid={!!errors.version?.message}>
                <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
                  {createVersion.version.label}
                </FormLabel>
                <ControlledInput name="version" control={control} onChangeInput={onChangeVersion} isDisabled />
                <FormErrorMessage>{errors.version?.message}</FormErrorMessage>
              </FormControl>
              <FormControl id="author" mb={4} isInvalid={!!errors.author?.message}>
                <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
                  {createVersion.author.label}
                </FormLabel>
                <ControlledInput
                  name="author"
                  control={control}
                  onChangeInput={onChangeAuthor}
                  placeholder={createVersion.author.placeholder}
                />
                <FormErrorMessage>{errors.author?.message}</FormErrorMessage>
              </FormControl>
              <FormControl id="createdDate" mb={4} isInvalid={!!errors.createdDate?.message}>
                <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
                  {createVersion.date.label}
                </FormLabel>
                <ControlledInput name="createdDate" control={control} isDisabled />
                <FormErrorMessage>{errors.createdDate?.message}</FormErrorMessage>
              </FormControl>
              <FormControl id="functionName" mb={4} isInvalid={!!errors.functionName?.message}>
                <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
                  {createVersion.functionName.label}
                </FormLabel>
                <ControlledInput
                  name="functionName"
                  control={control}
                  onChangeInput={onChangeFunctionName}
                  placeholder={createVersion.functionName.placeholder}
                />
                <FormErrorMessage>{errors.functionName?.message}</FormErrorMessage>
              </FormControl>
              <FormControl id="changeLog" mb={4} isInvalid={!!errors.changeLog?.message}>
                <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
                  {createVersion.changeLog.label}
                </FormLabel>
                <ControlledTextArea name="changeLog" control={control} onChangeTextArea={onChangeChangeLog} />
                <FormErrorMessage>{errors.changeLog?.message}</FormErrorMessage>
              </FormControl>
            </VStack>
            <VStack>
              <FormControl id="qmrfReference" mb={4} isInvalid={!!errors.qmrfReference?.message}>
                <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
                  {createVersion.qmrf.label}
                </FormLabel>
                <ControlledInput
                  name="qmrfReference"
                  control={control}
                  onChangeInput={onChangeQmrfReference}
                  placeholder={createVersion.qmrf.placeholder}
                />
                <FormErrorMessage>{errors.qmrfReference?.message}</FormErrorMessage>
              </FormControl>

              <FormControl id="attributes" mb={4} isInvalid={!!errors.attributes?.message}>
                <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
                  <Flex alignItems="center">
                    {createVersion.attributes.label}
                    <Button
                      marginLeft="auto"
                      marginY="0"
                      py="5"
                      height="2"
                      onClick={() => showAdder()}
                      variant="solid"
                      color={button.white}
                      bg={button.blue}
                      _hover={{ bg: button.hoverPrimary }}
                      _active={{ bg: button.hoverPrimary }}
                    >
                      {newModelWizardResources.buttons.add}
                    </Button>
                  </Flex>
                </FormLabel>

                <Flex
                  bg="white"
                  border="solid"
                  borderWidth="1px"
                  borderColor="input.border"
                  borderRadius="0.35rem"
                  paddingRight={1}
                >
                  <Box
                    userSelect="none"
                    width="100%"
                    minWidth="128px"
                    height="100%"
                    my={0.75}
                    mx={1}
                    px={5}
                    py={3}
                    color="typo.blue"
                    bg="white"
                    fontWeight="700"
                    size="lg"
                  >
                    <Flex alignItems="center">
                      <Switch
                        name="useShape"
                        colorScheme="brand"
                        size="md"
                        onChange={(e) => {
                          onChangeUseShape(e.target.checked);
                        }}
                        isChecked={useShape}
                      />
                      <Text mx="4">Shape</Text>
                      <Spacer />
                    </Flex>
                  </Box>
                  <Flex cursor="not-allowed" borderLeft="solid" borderLeftWidth="1px" borderColor="input.border">
                    <Box my="auto" mx="1rem">
                      <DragIcon style={{ filter: 'contrast(0%)' }} />
                    </Box>
                  </Flex>
                </Flex>
                <NanoformAttributeGroup
                  name="attributes"
                  control={control}
                  attributes={attributeModels}
                  onChangeAttribute={onChangeAttribute}
                  onEdit={onEditAttribute}
                />
                <FormErrorMessage>{errors.attributes?.message}</FormErrorMessage>
              </FormControl>
            </VStack>
          </SimpleGrid>
        </form>
        <Box className="spacer-for-firefox" h="6rem" w="100%" />
        <Box px="15%" py={4} position="absolute" bottom="0" left="0" w="calc(100% - 15px)">
          <Box position="absolute" top="0" left="0" right="0" bottom="0" bg="utils.background" opacity="0.7" />
          <WizardNav
            backLabel={onBack ? newModelWizardResources.buttons.back : undefined}
            nextLabel={newModelWizardResources.buttons.next}
            onNext={() => handleSubmit(onSubmit, onError)()}
            onBack={onBack ? () => onBack() : undefined}
            buttonSize="lg"
            buttonMaxWidth="320px"
          />
        </Box>
      </Box>
    </>
  );
};
