import React from 'react';
import { Box, Flex, useTheme } from '@chakra-ui/react';
import { ICore } from 'src/model';
import { EmptyListState } from 'src/components';
import { coresEmptyList } from 'src/helpers';
import { useCoreWizardActionsContext } from 'src/context';
import { IoEllipse } from 'react-icons/io5';

interface IManageCoreListProps {
  data: ICore[];
}

export const CoreList: React.FC<IManageCoreListProps> = ({ data }) => {
  const { onWizardOpen, setCore } = useCoreWizardActionsContext();

  const handleCoreClick = (core: ICore) => {
    setCore((prevState) => ({ ...prevState, id: core.id, name: core.name, color: core.color }));
    onWizardOpen();
  };

  const {
    colors: { button },
  } = useTheme();

  return (
    <Box
      className="manage-core-list"
      w="50%"
      border="1px"
      borderColor="blackAlpha.300"
      bg="whiteAlpha.900"
      rounded="md"
      padding="6"
      marginTop="-10"
    >
      {data.length ? (
        <Flex flexFlow="row wrap">
          {data.map((core) => (
            <Box
              key={core.id}
              margin={{ md: '4', sm: '2' }}
              rounded="sm"
              bg="blackAlpha.50"
              padding="1"
              border="1px"
              borderColor="blackAlpha.300"
              borderRadius="md"
              fontWeight="700"
              _hover={{ borderColor: 'blackAlpha.600' }}
            >
              <Flex
                flexDir={{ md: 'row', sm: 'column' }}
                alignItems="center"
                onClick={() => handleCoreClick(core)}
                cursor="pointer"
                paddingLeft={4}
                paddingRight={5}
                paddingY={1}
              >
                <IoEllipse color={core.color} size="2rem" style={{ stroke: button.white, strokeWidth: '40' }} />
                <Box marginY="auto">{core.name}</Box>
              </Flex>
            </Box>
          ))}
        </Flex>
      ) : (
        <EmptyListState title={coresEmptyList} />
      )}
    </Box>
  );
};
