import React from 'react';
import { Controller, Control } from 'react-hook-form';
import { Select } from '@chakra-ui/react';

interface IControlledSelectProps {
  name: string;
  placeholderName: string;
  control: Control<Record<string, unknown>>;
  onChangeSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options: string[];
  defaultValue?: string;
}

export const ControlledSelect: React.FC<IControlledSelectProps> = ({
  name,
  placeholderName,
  control,
  onChangeSelect,
  options,
}) => (
  <Controller
    name={name}
    control={control}
    render={({ onChange, name: renderName, value, ref }, { invalid }) => (
      <Select
        onChange={(e) => {
          onChange(e);
          onChangeSelect(e);
        }}
        ref={ref}
        name={renderName}
        value={value}
        errorBorderColor="utils.alert"
        isInvalid={invalid}
      >
        <option key={`${name}-option-empty`} value="">
          {placeholderName}
        </option>
        {options.map((option: string) => (
          <option key={`${name}-option-${option}`} value={option}>
            {option}
          </option>
        ))}
      </Select>
    )}
  />
);
