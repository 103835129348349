import React, { useEffect, useState } from 'react';
import { Box, Flex, Heading, HStack, Icon, Spacer, Spinner, Text, useTheme, VStack } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { checkoutService, coreService, licenseService, userService } from 'src/services';
import { AxiosResponse } from 'axios';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useDocumentTitle } from 'src/hooks';
import {
  ICore,
  ILicenseDetailsResponse,
  LicenseStatus,
  ICoreModelGroup,
  ICoreDetails,
  ILicenseModel,
  PredictionsResponse,
  ILicenseByIdResponse,
  LicenseTypes,
} from 'src/model';
import { Link, useHistory, useParams } from 'react-router-dom';
import { IoArrowBack, IoArrowBackOutline } from 'react-icons/io5';
import {
  LicenseType,
  QUERY_LIMIT,
  stripePromise,
  userLicensesViewResources,
  buyLicensesViewResources,
  laboratoryViewResources,
} from 'src/helpers';
import {
  useBuyLicenceContext,
  useManageLicensesContext,
  useManageLicensesActionsContext,
  useUserActionsContext,
} from 'src/context';
import {
  LicenseDetailsModelsList,
  LicenseStatusBadge,
  CancelButton,
  PrimaryButton,
  PredictionsList,
  Popup,
  PopupBackground,
  InfoAlert,
} from 'src/components';

export const LicenseDetails: React.FC = () => {
  const {
    colors: { button, typo },
  } = useTheme();
  const { payment, licenseDeactivation, outOfDomainAttemptsInfo } = userLicensesViewResources;
  const { heading: predictionsHeading } = laboratoryViewResources;
  const { headingExpand } = buyLicensesViewResources;

  const { getAccessTokenSilently } = useAuth0();
  const { licenseId } = useParams<{ licenseId: string }>();

  const { defaultSelectorValue, setSelector, defaultCoreName, setDefaultCoreName } = useBuyLicenceContext();
  const { userInfo } = useManageLicensesContext();
  const { setUserInfo } = useManageLicensesActionsContext();
  const { isAdmin } = useUserActionsContext();

  const changeDocumentTitle = useDocumentTitle(userInfo.email);

  const [isCancelLoading, setIsCancelLoading] = useState<boolean>(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false);
  const [coresForLicenseType, setCoresForLicenseType] = useState<ICoreDetails[]>([]);
  const [currentCoreName, setCurrentCoreName] = useState<string>();
  const [deactivatePopupVisible, setDeactivatePopupVisible] = useState<boolean>(false);

  const history = useHistory();

  const [groupsForSelectedCore, setGroupsForSelectedCore] = useState<ICoreModelGroup[]>([]);

  const fetchCoresDetails = async (): Promise<ICoreDetails[]> => {
    const token = await getAccessTokenSilently();
    return coreService.getAllCoresDetails(token).then((response: AxiosResponse) => response.data);
  };

  const { data: coresDetails, isLoading: coresDetailsLoading } = useQuery(
    `core-details-list-${licenseId}`,
    fetchCoresDetails
  );

  const fetchLicenseDetails = async (): Promise<ILicenseDetailsResponse> => {
    if (!userInfo || !userInfo.userId || userInfo.userId === undefined) return Promise.reject();
    const token = await getAccessTokenSilently();
    return userService
      .getUserLicenseDetails(userInfo.userId, licenseId, token)
      .then((response: AxiosResponse) => response.data);
  };

  const { data: licenseDetails, isLoading: licenseDetailsLoading, refetch: refetchDetails } = useQuery(
    `license-details-list-${licenseId}`,
    fetchLicenseDetails
  );

  const fetchPredictions = async ({ pageParam = 0 }) => {
    if (!userInfo || !userInfo.userId || userInfo.userId === undefined) return Promise.reject();
    const token = await getAccessTokenSilently();
    return userService
      .getUserPredictions(userInfo.userId, licenseId, QUERY_LIMIT, pageParam, token)
      .then((response: AxiosResponse) => response.data);
  };

  const { data: predictions, isError, hasNextPage, fetchNextPage, refetch: refetchPredictions } = useInfiniteQuery(
    ['predictions-list', licenseId],
    fetchPredictions,
    {
      getNextPageParam: (lastPage: PredictionsResponse) =>
        lastPage.hasNext ? lastPage.offset + QUERY_LIMIT : undefined,
      enabled: Boolean(licenseId),
    }
  );

  const onChangeCore = (value: string) => {
    const selectedCore = coresDetails?.find((c) => c.name === value) as ICoreDetails;

    const matchingGroups = selectedCore.models;

    if (matchingGroups) {
      setGroupsForSelectedCore(matchingGroups);
    }
    setCurrentCoreName(selectedCore.name);
    setDefaultCoreName(selectedCore.name);
  };

  const getLicenseType = () => {
    return LicenseType[licenseDetails?.type as keyof typeof LicenseType];
  };

  const isLicenseSingleUse = (): boolean => {
    const licenseType = getLicenseType();
    return (
      licenseType.type === LicenseTypes.OneNanoformSpecificModels ||
      licenseType.type === LicenseTypes.OneNanoformAllModels
    );
  };

  const filterModels = (models: ILicenseModel[] | undefined): ILicenseModel[] => {
    if (models === undefined || coresDetails === undefined) return [];
    const allModels = coresDetails.flatMap((c) => c.models).flatMap((g) => g.models);
    const newLicenseModels: ILicenseModel[] = [];
    allModels.forEach((model) => {
      const licenseModel = models.find((m) => m.endpoint === model.endpoint);
      if (!model.isDemo && licenseModel) {
        newLicenseModels.push(licenseModel);
      } else if (model.isDemo && licenseModel) {
        newLicenseModels.push({
          endpoint: `_DEMO_${licenseModel.endpoint}`,
          hasWaitingPayment: licenseModel.hasWaitingPayment,
        });
      }
    });

    return newLicenseModels;
  };

  const getLicenseStatus = (): LicenseStatus => {
    if (licenseDetails?.hasWaitingPayment && !licenseDetails.isDeactivated) {
      return LicenseStatus.waiting;
    }

    if (licenseDetails?.isActive === false) {
      return LicenseStatus.inActive;
    }

    return LicenseStatus.active;
  };

  const getDefaultCore = (currentCoresDetails: ICoreDetails[], defaultCore: string | undefined): ICoreDetails => {
    if (!defaultCore) return currentCoresDetails[0];

    const core = currentCoresDetails.find((c) => c.name === defaultCore);
    if (!core) return currentCoresDetails[0];

    return core;
  };

  const getCoresForLicenseType = (
    license: ILicenseDetailsResponse | undefined,
    currentCoresDetails?: ICoreDetails[]
  ): ICoreDetails[] => {
    return (
      currentCoresDetails?.filter((c) =>
        c.models.some((g) => g.models.some((m) => license?.models.some((lm) => lm.endpoint === m.endpoint)))
      ) ?? []
    );
  };

  const getAllAvailableModels = (cores: ICoreDetails[]) => {
    return cores.flatMap((c) =>
      c.models.flatMap((g) =>
        g.models
          .filter((m) => !m.isDemo)
          .map((m) => {
            return { endpoint: m.endpoint, hasWaitingPayment: false } as ILicenseModel;
          })
      )
    );
  };

  const filterLicenseModels = (models: ILicenseModel[] | undefined, cores: ICoreDetails[]) => {
    if (!models) return [];

    const availableModels = getAllAvailableModels(cores);
    const newModels: ILicenseModel[] = [];

    models.forEach((model) => {
      if (availableModels.find((m) => m.endpoint === model.endpoint)) {
        newModels.push(model);
      }
    });

    return newModels;
  };

  const formatDate = (dateToFormat: string): string => {
    const date = new Date(dateToFormat);

    const day = `0${date.getDate()}`.slice(-2);
    const month = `0${date.getMonth() + 1}`.slice(-2);

    return `${day}/${month}/${date.getFullYear()}`;
  };

  const goToPayment = async () => {
    if (licenseDetails?.hasWaitingPayment) {
      setIsPaymentLoading(true);
      const paymentLicenseId = licenseDetails.id;
      const stripe = await stripePromise();
      try {
        if (stripe) {
          const token = await getAccessTokenSilently();
          const sessionResponse = await checkoutService.retryPayment(paymentLicenseId, token);
          await stripe.redirectToCheckout({
            sessionId: sessionResponse.data as string,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsPaymentLoading(false);
      }
    }
  };

  const cancelPayment = async () => {
    if (licenseDetails?.hasWaitingPayment) {
      setIsCancelLoading(true);
      const paymentLicenseId = licenseDetails.id;
      try {
        const token = await getAccessTokenSilently();
        await checkoutService.cancelPayment(paymentLicenseId, token);
        await refetchDetails();
        const licenseResponse = await fetchLicenseDetails();
        if (licenseResponse.models.length === 0) {
          setGroupsForSelectedCore([]);
          return;
        }
        const cores = getCoresForLicenseType(licenseResponse, coresDetails);
        onChangeCore(cores[0].name);
      } catch (error) {
        console.error(error);
      } finally {
        setIsCancelLoading(false);
      }
    }
  };

  const deactivateLicense = async () => {
    const token = await getAccessTokenSilently();
    await licenseService.updateLicenseStatus(licenseId, true, token);
    await refetchDetails();
    setDeactivatePopupVisible(false);
  };

  const reactivateLicense = async () => {
    const token = await getAccessTokenSilently();
    await licenseService.updateLicenseStatus(licenseId, false, token);
    await refetchDetails();
  };

  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    if (!licenseDetails || !coresDetails) return;
    setCoresForLicenseType(getCoresForLicenseType(licenseDetails, coresDetails));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coresDetails, licenseDetails]);

  useEffect(() => {
    if (!licenseDetails) return;
    setSelector(defaultSelectorValue);
    const availableCores = getCoresForLicenseType(licenseDetails, coresDetails);
    setCoresForLicenseType(availableCores);
    if (availableCores.length === 0) return;
    fetchCoresDetails().then((cores) => {
      const core = getDefaultCore(getCoresForLicenseType(licenseDetails, cores), defaultCoreName);
      setGroupsForSelectedCore(core.models);
      setCurrentCoreName(core.name);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenseDetails]);

  useEffect(() => {
    if (userInfo.userId) return;
    const fetchLicenseInfo = async () => {
      const token = await getAccessTokenSilently();
      const license: ILicenseByIdResponse = await licenseService
        .getLicenseById(licenseId, token)
        .then((response) => response.data);

      setUserInfo({
        userId: license.userInfo.userId,
        email: license.userInfo.email,
        status: license.userInfo.status,
        role: license.userInfo.role,
      });
      changeDocumentTitle(license.userInfo.email);
      return license;
    };

    fetchLicenseInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const refetchLicenseDetails = async () => {
      await refetchDetails();
      await refetchPredictions();
    };
    refetchLicenseDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  return (
    <VStack p={8}>
      <Flex
        className="buylicenses-list"
        w="100%"
        minH="100%"
        overflowY="auto"
        id="buylicenses-list"
        flexDirection="column"
      >
        {deactivatePopupVisible && (
          <PopupBackground position="fixed">
            <Popup
              problemMessage={licenseDeactivation.popupProblem}
              solveMessage={licenseDeactivation.popupSolve}
              buttonMessage={licenseDeactivation.deactivate}
              setVisibility={() => setDeactivatePopupVisible(!deactivatePopupVisible)}
              onButtonClick={() => deactivateLicense()}
            />
          </PopupBackground>
        )}
        {licenseDetails && (
          <Flex justifyContent="space-between" mb={6}>
            <Box as="header" my="auto">
              <Heading fontWeight="700" size="xl">
                <HStack>
                  <Icon as={IoArrowBackOutline} onClick={() => handleGoBack()} cursor="pointer">
                    <IoArrowBack color={button.blue} size="1.5rem" />
                  </Icon>
                  {!licenseDetailsLoading && (
                    <>
                      <Heading fontWeight="700" size="xl">
                        {`${getLicenseType().main} / ${getLicenseType().sub}`}
                      </Heading>
                      <LicenseStatusBadge
                        licenseStatus={licenseDetails?.isDeactivated ? LicenseStatus.inActive : getLicenseStatus()}
                      />
                    </>
                  )}
                </HStack>
              </Heading>
            </Box>
            <Flex my="auto">
              {!licenseDetailsLoading && getLicenseStatus() === LicenseStatus.waiting && !isAdmin() && (
                <Flex>
                  <CancelButton
                    className="new-model-button"
                    size="sm"
                    onClick={() => cancelPayment()}
                    hidden={false}
                    isLoading={isCancelLoading}
                    disabled={false}
                  >
                    {payment.cancel}
                  </CancelButton>
                  <Spacer w="1.5rem" />
                  <PrimaryButton
                    className="new-model-button"
                    size="sm"
                    onClick={() => goToPayment()}
                    hidden={false}
                    isLoading={isPaymentLoading}
                    disabled={false}
                  >
                    {payment.goToPayment}
                  </PrimaryButton>
                </Flex>
              )}
              {!licenseDetailsLoading &&
                getLicenseStatus() !== LicenseStatus.inActive &&
                !licenseDetails?.isDeactivated &&
                isAdmin() && (
                  <>
                    <Spacer w="1.5rem" />
                    <CancelButton
                      className="new-model-button"
                      size="sm"
                      onClick={() => setDeactivatePopupVisible(true)}
                      hidden={!isAdmin()}
                      isLoading={licenseDetailsLoading}
                      disabled={!isAdmin()}
                    >
                      {licenseDeactivation.deactivate}
                    </CancelButton>
                  </>
                )}
              {!licenseDetailsLoading && licenseDetails && isAdmin() && licenseDetails.isDeactivated && (
                <>
                  <Spacer w="1.5rem" />
                  <PrimaryButton
                    className="new-model-button"
                    size="sm"
                    onClick={() => reactivateLicense()}
                    hidden={!isAdmin()}
                    isLoading={licenseDetailsLoading}
                    disabled={!isAdmin()}
                  >
                    {licenseDeactivation.reactivate}
                  </PrimaryButton>
                </>
              )}
              {!licenseDetailsLoading &&
                !coresDetailsLoading &&
                (getLicenseStatus() === LicenseStatus.active ||
                  (getLicenseStatus() === LicenseStatus.waiting && isAdmin() && !licenseDetails.isDeactivated)) &&
                coresDetails && (
                  <>
                    <Spacer w="1.5rem" />
                    <Link
                      to={{
                        pathname: '/buylicense',
                        state: {
                          licenseOptions: getLicenseType(),
                          ownedEndpoints: filterModels(licenseDetails?.models),
                          endpointsToBuy: [] as string[],
                          coreName: currentCoreName,
                          selectedLicenseId: licenseId,
                          purchasedDate: licenseDetails?.purchasedDate,
                          expirationDate: licenseDetails?.expiredDate,
                          timeBasedDiscountRate: licenseDetails?.timeBasedDiscountRate,
                          discount: licenseDetails?.discount,
                          userInfo,
                        },
                      }}
                    >
                      <PrimaryButton
                        className="expand-license-button"
                        size="sm"
                        onClick={() => {}}
                        disabled={
                          !isAdmin() &&
                          (getLicenseStatus() !== LicenseStatus.active ||
                            getAllAvailableModels(coresForLicenseType).length ===
                              filterLicenseModels(licenseDetails?.models, coresForLicenseType).length)
                        }
                        isLoading={licenseDetailsLoading}
                      >
                        {headingExpand}
                      </PrimaryButton>
                    </Link>
                  </>
                )}
            </Flex>
          </Flex>
        )}
        {!coresDetailsLoading && !licenseDetailsLoading && licenseDetails && coresDetails && (
          <Flex>
            <Box
              flex="8"
              borderColor={typo.grey}
              borderWidth="1px"
              borderRadius="md"
              overflow="hidden"
              bg={typo.white}
              padding={8}
            >
              {licenseDetails.predictionAttemptsCount > 0 &&
                getLicenseStatus() === LicenseStatus.active &&
                isLicenseSingleUse() && <InfoAlert text={outOfDomainAttemptsInfo} />}

              <HStack spacing="10%">
                {licenseDetails.expiredDate && (
                  <VStack alignItems="start" paddingBottom={2}>
                    <Heading size="md">Expiration</Heading>
                    <Text paddingBottom={4} fontSize="lg">
                      {formatDate(licenseDetails.expiredDate)}
                    </Text>
                  </VStack>
                )}
                {licenseDetails.predictionAttemptsCount > 0 &&
                  getLicenseStatus() === LicenseStatus.active &&
                  isLicenseSingleUse() && (
                    <VStack alignItems="start" paddingBottom={2}>
                      <Heading size="md">Out of domain tries</Heading>
                      <Text paddingBottom={4} fontSize="lg">
                        {`Remaining: ${licenseDetails.predictionAttemptsCount}`}
                      </Text>
                    </VStack>
                  )}
              </HStack>
              <LicenseDetailsModelsList
                modelGroups={groupsForSelectedCore}
                selectedItems={licenseDetails.models}
                onChangeCore={onChangeCore}
                cores={
                  coresForLicenseType
                    ?.filter((c) => c.models.some((group) => group.models.length > 0))
                    ?.map((c) => {
                      return { id: c.id, name: c.name, color: c.color } as ICore;
                    }) as ICore[]
                }
                defaultCore={
                  defaultCoreName ||
                  (coresForLicenseType.length > 0 ? coresForLicenseType[0].name : coresDetails[0].name)
                }
              />
            </Box>
          </Flex>
        )}
      </Flex>
      <Box height="100%">
        {(coresDetailsLoading || !licenseDetails) && (
          <Flex w="100%" flex="1" justifyContent="center" alignItems="center">
            <Spinner thickness="8px" speed="1s" size="xl" w="100px" h="100px" label="Loading.." />
          </Flex>
        )}
      </Box>
      {predictions &&
        predictions.pages.length > 0 &&
        (predictions.pages[0] as PredictionsResponse).list.length > 0 &&
        hasNextPage !== undefined &&
        !isError &&
        !licenseDetailsLoading &&
        !coresDetailsLoading && (
          <Flex flexDirection="column" w="100%">
            <Heading size="lg" m={4} mt={6}>
              {predictionsHeading}
            </Heading>
            <PredictionsList
              data={[...predictions.pages.map((page: PredictionsResponse) => page.list)].flat()}
              getMoreData={fetchNextPage}
              hasMore={hasNextPage}
            />
          </Flex>
        )}
    </VStack>
  );
};
