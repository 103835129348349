import React from 'react';
import { Box, Text } from '@chakra-ui/react';

interface IEndpointOptionProps {
  isActive: boolean;
  onClick: () => void;
  endpointName: string;
}

export const EndpointOption: React.FC<IEndpointOptionProps> = ({ isActive, onClick, endpointName }) => (
  <Box
    px={6}
    py={3}
    mb={2}
    bg={isActive ? 'utils.greenGradient' : 'radio.bg'}
    borderRadius="md"
    borderWidth="1px"
    borderColor="radio.border"
    cursor="pointer"
    onClick={onClick}
  >
    <Text fontWeight="700" color={isActive ? 'typo.white' : 'typo.blue'} isTruncated>
      {endpointName}
    </Text>
  </Box>
);
