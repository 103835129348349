import React from 'react';
import { Td, Box, TableCellProps } from '@chakra-ui/react';

const TableDataStyles = {
  '&:first-of-type': {
    paddingLeft: '40px',
    borderRadius: '0.25rem 0 0 0.25rem',
    borderWidth: '1px 0 1px 1px',
  },
  '&:last-of-type': {
    borderRadius: '0 0.25rem 0.25rem 0',
    borderWidth: '1px 1px 1px 0',
  },
  '&:not(:first-of-type) .flag': {
    display: 'none',
  },
  borderWidth: '1px 0 1px 0',
  marginBottom: '20px',
};

interface ITableDataProps extends Omit<TableCellProps, 'children'> {
  children?: React.ReactNode;
  flagColor?: string;
  minWidth?: string;
}

export const TableData: React.FC<ITableDataProps> = ({ children, flagColor, minWidth, ...restProps }) => (
  <Td
    bg="typo.white"
    borderColor="input.border"
    sx={TableDataStyles}
    position="relative"
    minW={minWidth ?? 0}
    {...restProps}
  >
    {flagColor && (
      <Box className="flag" as="span" position="absolute" top="0" left="0" width="15px" h="100%" bg={flagColor} />
    )}
    {children}
  </Td>
);
