import React from 'react';
import { Flex } from '@chakra-ui/react';
import { useUserContext } from 'src/context';
import { navigationRoutes, IRoute } from 'src/helpers';
import { TabLink } from 'src/components';
import { RoleTypes } from 'src/model';

export const Navigation: React.FC = () => {
  const { roles } = useUserContext();

  return (
    <Flex as="nav" h="90px">
      {navigationRoutes.map((route: IRoute) => {
        return (
          Boolean(roles.some((role: RoleTypes) => route.requiredRoles?.includes(role))) && (
            <TabLink key={route.name} to={route.path}>
              {route.name}
            </TabLink>
          )
        );
      })}
    </Flex>
  );
};
