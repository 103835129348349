import React, { FC, useEffect, useState } from 'react';
import { Box, Flex, Table, Tbody, Tr, Text, Heading, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import { ReactComponent as CloseIcon } from 'src/assets/close.svg';
import {
  TableHeader,
  TableData,
  EmptyListState,
  PrimaryButton,
  chooseReportVersionModalResources,
} from 'src/components';
import { IReport, ReportTypeEnum } from 'src/model';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'src/helpers';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';

interface ChooseReportVersionModalProps {
  changeVisibility(): void;
  downloadReports(qsarReportId: string, raReportId: string): Promise<void>;
  qsarReports: IReport[];
  raReports: IReport[];
  isDownloading: boolean;
  predictionCompletedDate: Date | undefined;
}

export const ChooseReportVersionModal: FC<ChooseReportVersionModalProps> = ({
  changeVisibility,
  downloadReports,
  qsarReports,
  raReports,
  isDownloading,
  predictionCompletedDate,
}) => {
  const [activeIndexQsar, setActiveIndexQsar] = useState<number>(0);
  const [activeIndexRa, setActiveIndexRa] = useState<number>(0);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [type, setType] = useState<ReportTypeEnum>(qsarReports ? ReportTypeEnum.QSAR : ReportTypeEnum.RA);

  const handleReportClick = (index: number) => {
    if (type === ReportTypeEnum.QSAR) setActiveIndexQsar(index);
    else if (type === ReportTypeEnum.RA) setActiveIndexRa(index);
  };

  const getSelectedQsarReportId = (): string => {
    const selectedReport = qsarReports.find((_, index) => index === activeIndexQsar);

    return selectedReport ? selectedReport.id : '';
  };

  const getSelectedRaReportId = (): string => {
    const selectedReport = raReports.find((_, index) => index === activeIndexRa);

    return selectedReport ? selectedReport.id : '';
  };

  const getContentText = (): string => {
    if (qsarReports.length > 0 && raReports.length > 0) return chooseReportVersionModalResources.contentBoth;
    if (qsarReports.length > 0) return chooseReportVersionModalResources.contentQsar;
    if (raReports.length > 0) return chooseReportVersionModalResources.contentRa;
    return '';
  };

  useEffect(() => {
    if (!predictionCompletedDate) return;

    const olderReports = qsarReports.filter((r) => r.createdDate <= predictionCompletedDate);

    if (olderReports.length === 0) return;

    const defaultReport = olderReports.sort(
      (a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
    )[0];

    const defaultIndex = qsarReports.findIndex((r) => r.id === defaultReport.id);

    setActiveIndexQsar(defaultIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!predictionCompletedDate) return;

    const olderReports = raReports.filter((r) => r.createdDate <= predictionCompletedDate);

    if (olderReports.length === 0) return;

    const defaultReport = olderReports.sort(
      (a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
    )[0];

    const defaultIndex = raReports.findIndex((r) => r.id === defaultReport.id);

    setActiveIndexRa(defaultIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (type === ReportTypeEnum.QSAR) setActiveIndex(activeIndexQsar);
    if (type === ReportTypeEnum.RA) setActiveIndex(activeIndexRa);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, activeIndexQsar, activeIndexRa]);

  const reportVersionList = (data: IReport[]): JSX.Element => {
    if (data.length)
      return (
        <Table
          variant="unstyled"
          w="100%"
          h="100%"
          sx={{ borderCollapse: 'separate', borderSpacing: '0 10px', tableLayout: 'auto' }}
          cellSpacing="0"
        >
          <TableHeader titles={['Publish date', 'Last modified', 'Changed by']} />
          <Tbody>
            {data.map((report: IReport, idx: number) => (
              <Tr key={`report-${report.id}`} onClick={() => handleReportClick(idx)}>
                <TableData borderColor={activeIndex === idx ? 'typo.green' : ''}>
                  <Text mx="auto">
                    {report.createdDate == null ? '-' : dayjs(report.createdDate).format(DATE_FORMAT)}
                  </Text>
                </TableData>
                <TableData borderColor={activeIndex === idx ? 'typo.green' : ''}>
                  <Text>{report.modifiedDate == null ? '-' : dayjs(report.modifiedDate).format(DATE_FORMAT)}</Text>
                </TableData>
                <TableData borderColor={activeIndex === idx ? 'typo.green' : ''}>
                  <Text>{report.userName ?? '-'}</Text>
                </TableData>
                <TableData w="1%" borderColor={activeIndex === idx ? 'typo.green' : ''}>
                  <Box
                    textColor="typo.green"
                    marginY="auto"
                    marginLeft="auto"
                    fontSize="2xl"
                    opacity={activeIndex === idx ? 1 : 0}
                  >
                    <IoCheckmarkCircleSharp />
                  </Box>
                </TableData>
              </Tr>
            ))}
          </Tbody>
        </Table>
      );

    return <EmptyListState title="No data" />;
  };

  return (
    <Flex
      width="full"
      height="100vh"
      maxH="100vh"
      position="fixed"
      background="rgba(27, 68, 128, 0.6)"
      left={0}
      top={0}
      zIndex={1000}
    >
      <Box marginX="auto" marginY={6} width="50%" h="95%" overflow="hidden">
        <Box width="100%" bg="#EDF2F7" py={6} px={8} rounded="lg" h="100%">
          <Flex marginBottom={8}>
            <Box fontSize="3xl" fontWeight="bold" marginY="auto">
              {chooseReportVersionModalResources.title}
            </Box>
            <Box marginLeft="auto" onClick={() => changeVisibility()} cursor="pointer" marginY="auto">
              <CloseIcon />
            </Box>
          </Flex>
          <Box mb={8}>{getContentText()}</Box>
          <Flex direction="column" h="90%">
            {qsarReports.length > 0 && raReports.length > 0 ? (
              <Tabs
                variant="unstyled"
                h="70%"
                onChange={(index: number) => setType(index === 0 ? ReportTypeEnum.QSAR : ReportTypeEnum.RA)}
              >
                <TabList>
                  <Tab
                    _selected={{ fontWeight: '650' }}
                    _focus={{ boxShadow: 'none' }}
                    transition="none"
                    mr={4}
                    onClick={() => setType(ReportTypeEnum.QSAR)}
                  >
                    <Heading className="header__title" fontWeight="inherit" size="lg">
                      {ReportTypeEnum.QSAR}
                    </Heading>
                  </Tab>
                  <Tab
                    _selected={{ fontWeight: '650' }}
                    _focus={{ boxShadow: 'none' }}
                    transition="none"
                    onClick={() => setType(ReportTypeEnum.RA)}
                  >
                    <Heading className="header__title" fontWeight="inherit" size="lg">
                      {ReportTypeEnum.RA}
                    </Heading>
                  </Tab>
                </TabList>

                <TabPanels overflow="auto" h="90%">
                  <TabPanel>{reportVersionList(qsarReports)}</TabPanel>
                  <TabPanel>{reportVersionList(raReports)}</TabPanel>
                </TabPanels>
              </Tabs>
            ) : (
              <Box overflow="auto" h="70%">
                {reportVersionList(qsarReports.length > 0 ? qsarReports : raReports)}
              </Box>
            )}
            <Flex mt={8} h="10%">
              <PrimaryButton
                ml="auto"
                onClick={() => downloadReports(getSelectedQsarReportId(), getSelectedRaReportId())}
                isLoading={isDownloading}
              >
                {chooseReportVersionModalResources.actionButtonText}
              </PrimaryButton>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};
