import { useToast } from '@chakra-ui/react';

export const useSuccessToast = (
  title: string,
  description: string,
  duration = 4000,
  id?: string
): ReturnType<typeof useToast> => {
  const successToast = useToast({
    id,
    title,
    description,
    status: 'success',
    variant: 'solid',
    duration,
    position: 'top-right',
    isClosable: true,
  });

  return successToast;
};
