import React, { useEffect, useState } from 'react';
import { Redirect, Route, RouteProps, RouteComponentProps } from 'react-router-dom';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import { RedirectLoading } from 'src/views';
import { RoleTypes } from 'src/model';
import { laboratoryRoute, ROLE_CLAIM } from 'src/helpers';

interface IProtectedRoute extends Omit<RouteProps, 'component'> {
  component: React.ComponentType<RouteComponentProps>;
  requiredRoles?: RoleTypes[];
}

export const ProtectedRoute: React.FC<IProtectedRoute> = ({ component, requiredRoles, ...routeProps }) => {
  const { user, error } = useAuth0();
  const [isAuthorized, setIsAuthorized] = useState<boolean>(true);
  const ProtectedComponent = withAuthenticationRequired(component, { onRedirecting: () => <RedirectLoading /> });
  useEffect(() => {
    const userRoles = user[ROLE_CLAIM] as RoleTypes[];
    if (requiredRoles?.length && userRoles) {
      const authorizedRoles = userRoles.filter((role) => requiredRoles?.includes(role));
      setIsAuthorized(authorizedRoles.length > 0);
    } else {
      setIsAuthorized(true);
    }
  }, [requiredRoles, user]);

  return (
    <Route
      {...routeProps}
      render={(props) =>
        isAuthorized && !error ? (
          <ProtectedComponent {...props} />
        ) : (
          <Redirect to={{ pathname: laboratoryRoute.path }} />
        )
      }
    />
  );
};
