import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  VStack,
  Checkbox,
  Flex,
  Alert,
  AlertDescription,
  AlertIcon,
  Tooltip,
  Text,
} from '@chakra-ui/react';
import {
  useNanoformContext,
  useNewExperimentWizardActionsContext,
  useNewExperimentWizardContext,
  useUserContext,
} from 'src/context';
import { scrollbar, userLicensesViewResources } from 'src/helpers';
import { WizardNav, LicensePopup } from 'src/components';
import { IActiveLicenseModel, IActiveLicenseModelGroup, ModelStatus } from 'src/model';
import { useDocumentTitle } from 'src/hooks';
import { newExperimentWizardResources } from '../newExperimentWizardResources';

export const SelectEndpoints: React.FC = () => {
  useDocumentTitle(newExperimentWizardResources.steps.selectEndpoints);

  const { userActiveLicenseData } = useUserContext();

  const { experimentData, groupSelectors, disablingCondition } = useNewExperimentWizardContext();

  const { setExperimentData, setCurrentStep, setGroupSelectors } = useNewExperimentWizardActionsContext();

  const { selectEndpoints } = newExperimentWizardResources;

  const { modelDeactivated } = userLicensesViewResources;

  const [licensePopup, setLicensePopup] = useState<boolean>(false);

  const [availableModelGroups, setavailableModelGroups] = useState<IActiveLicenseModelGroup[]>([]);

  const {
    nanoformData: { core: selectedCore },
  } = useNanoformContext();

  const getDeepCopyOfModelGroups = (modelGroups: IActiveLicenseModelGroup[]): IActiveLicenseModelGroup[] => {
    return JSON.parse(JSON.stringify(modelGroups));
  };

  const filterGroupsForSelectedCore = (modelGroups: IActiveLicenseModelGroup[]): IActiveLicenseModelGroup[] => {
    const matchingGroups: IActiveLicenseModelGroup[] = getDeepCopyOfModelGroups(modelGroups);

    for (let i = 0; i < matchingGroups.length; i += 1) {
      matchingGroups[i].models = matchingGroups[i].models.filter(
        (model: IActiveLicenseModel) => model.coreName === selectedCore?.name
      );
    }

    return matchingGroups;
  };

  const areDemoModelsPresent = (): boolean => {
    return groupSelectors.flatMap((gs) => gs.group.models.filter((m) => m.isDemo)).length !== 0;
  };

  const areAllDemoModelsSelected = (index: number): boolean => {
    return (
      groupSelectors[index].selectedDemoModelsIds.length ===
      groupSelectors[index].group.models.filter((m) => m.isDemo).length
    );
  };

  const areAllSelected = (index: number): boolean => {
    const models = groupSelectors[index].group.models.filter((m) => m.status !== ModelStatus.Deactivated);
    return (
      groupSelectors[index].selectedModelsIds.length === models.filter((m) => !m.isDemo).length &&
      groupSelectors[index].selectedModelsIds.length > 0 &&
      areAllDemoModelsSelected(index)
    );
  };

  const checkIfGroupHasOnlyDemoModels = (group: IActiveLicenseModelGroup) => {
    const demoModelsCount = group.models.filter((m) => m.isDemo).length;
    return demoModelsCount === group.models.length;
  };

  const onChangeCheckboxModel = (modelId: string, modelGroupId: string, isDemo: boolean) => {
    const newGroupSelectors = [...groupSelectors];
    const groupIndex = newGroupSelectors.findIndex((g) => g.group.id === modelGroupId);

    if (isDemo) {
      if (newGroupSelectors[groupIndex].selectedDemoModelsIds.includes(modelId)) {
        newGroupSelectors[groupIndex].selectedDemoModelsIds = newGroupSelectors[
          groupIndex
        ].selectedDemoModelsIds.filter((id) => !(id === modelId));
      } else {
        newGroupSelectors[groupIndex].selectedDemoModelsIds.push(modelId);
      }
      setGroupSelectors(newGroupSelectors);
      return;
    }

    if (newGroupSelectors[groupIndex].selectedModelsIds.includes(modelId)) {
      newGroupSelectors[groupIndex].selectedModelsIds = newGroupSelectors[groupIndex].selectedModelsIds.filter(
        (id) => !(id === modelId)
      );
    } else {
      newGroupSelectors[groupIndex].selectedModelsIds.push(modelId);
    }

    setGroupSelectors(newGroupSelectors);
  };

  const onChangeAllCheckbox = (modelGroupId: string) => {
    const newGroupSelectors = [...groupSelectors];
    const groupIndex = newGroupSelectors.findIndex((g) => g.group.id === modelGroupId);

    const models = newGroupSelectors[groupIndex].group.models.filter((m) => m.status !== ModelStatus.Deactivated);

    if (experimentData.showOnlyFreeModels && !areAllDemoModelsSelected(groupIndex)) {
      newGroupSelectors[groupIndex].selectedDemoModelsIds = models.filter((m) => m.isDemo).map((m) => m.id);
    } else if (experimentData.showOnlyFreeModels) {
      newGroupSelectors[groupIndex].selectedDemoModelsIds = [];
    }

    if (!experimentData.showOnlyFreeModels && !areAllSelected(groupIndex)) {
      newGroupSelectors[groupIndex].selectedModelsIds = models.filter((m) => !m.isDemo).map((m) => m.id);
      newGroupSelectors[groupIndex].selectedDemoModelsIds = models.filter((m) => m.isDemo).map((m) => m.id);
    } else if (!experimentData.showOnlyFreeModels) {
      newGroupSelectors[groupIndex].selectedModelsIds = [];
      newGroupSelectors[groupIndex].selectedDemoModelsIds = [];
    }

    setGroupSelectors(newGroupSelectors);
  };

  const onChangeShowDemoModels = () => {
    setExperimentData((prevState) => ({ ...prevState, showOnlyFreeModels: !experimentData.showOnlyFreeModels }));
  };

  const showPopup = () => {
    setLicensePopup(!licensePopup);
  };

  const onNextStep = () => {
    const normalModelsCount = groupSelectors.flatMap((g) => g.selectedModelsIds).length;
    const demoModelsCount = groupSelectors.flatMap((g) => g.selectedDemoModelsIds).length;
    if (
      !userActiveLicenseData.isActive &&
      ((normalModelsCount !== 0 && demoModelsCount !== 0) || normalModelsCount !== 0)
    ) {
      setLicensePopup(true);
      return;
    }

    const selectedModelsIds = groupSelectors.flatMap((gs) => gs.selectedModelsIds.map((str) => str));
    const selectedDemoModelsIds = groupSelectors.flatMap((gs) => gs.selectedDemoModelsIds.map((str) => str));

    const newExperimentData = { ...experimentData };

    newExperimentData.onlyDemoModelsSelected =
      (selectedModelsIds.length === 0 && selectedDemoModelsIds.length > 0) || experimentData.showOnlyFreeModels;
    newExperimentData.selectedModelsIds = experimentData.showOnlyFreeModels
      ? selectedDemoModelsIds
      : selectedDemoModelsIds.concat(selectedModelsIds);

    setExperimentData(newExperimentData);
    setCurrentStep(4);
  };

  const checkDisablingCondition = () => {
    if (!experimentData.showOnlyFreeModels) return !experimentData.selectedModelsIds.length;

    const selectedDemoModelsIds = groupSelectors.flatMap((gs) => gs.selectedDemoModelsIds.map((str) => str));
    return selectedDemoModelsIds.length === 0;
  };

  const shouldHideSelf = (modelGroup: IActiveLicenseModelGroup) => {
    return experimentData.showOnlyFreeModels && modelGroup.models.filter((m) => m.isDemo === true).length === 0;
  };

  const filterModelList = (models: IActiveLicenseModel[]) => {
    if (!experimentData.showOnlyFreeModels) return models;
    return models.filter((m) => m.isDemo);
  };

  useEffect(() => {
    const filteredGroups = filterGroupsForSelectedCore(userActiveLicenseData.modelGroups);

    setavailableModelGroups(filteredGroups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userActiveLicenseData]);

  useEffect(() => {
    if (!disablingCondition) return;

    const newGroups = [...groupSelectors];

    newGroups.forEach((ng, i) => {
      if (newGroups[i].selectedModelsIds.length === 0) {
        newGroups[i].selectedModelsIds = ng.group.models.filter((m) => !m.isDemo).map((m) => m.id);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableModelGroups, disablingCondition]);

  useEffect(() => {
    const newExperimentData = { ...experimentData };

    newExperimentData.selectedModelsIds = groupSelectors.flatMap((g) => g.selectedModelsIds);
    groupSelectors
      .flatMap((g) => g.selectedDemoModelsIds)
      .forEach((demoModelId) => {
        newExperimentData.selectedModelsIds.push(demoModelId);
      });

    const selectedModels = availableModelGroups.flatMap((g) =>
      g.models.filter((m) => {
        return newExperimentData.selectedModelsIds.includes(m.id);
      })
    );

    newExperimentData.selectedModelsNames = selectedModels.map((l) => l.endpoint);

    newExperimentData.useShape = selectedModels.some((m) => m.useShape);

    setExperimentData(newExperimentData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupSelectors, setExperimentData, availableModelGroups]);

  return (
    <Box className="select-endpoints" px={10} pt={20} h="100%" overflowY="auto" sx={scrollbar}>
      {licensePopup && <LicensePopup showPopup={showPopup} />}
      <Flex>
        <Heading fontSize="2xl" fontWeight="bold">
          {selectEndpoints.title}
        </Heading>
        <Checkbox
          my="auto"
          ml="auto"
          mr="4"
          size="lg"
          isChecked={experimentData.showOnlyFreeModels}
          onChange={() => onChangeShowDemoModels()}
        />
        <Box fontWeight="bold" my="auto">
          {selectEndpoints.showOnlyFreeOfChargeModels}
        </Box>
      </Flex>
      {experimentData.showOnlyFreeModels && !areDemoModelsPresent() && (
        <Box mt={10}>
          <Alert status="warning" variant="left-accent">
            <AlertIcon />
            <AlertDescription textColor="gray">{selectEndpoints.noDemoModelsAvailable}</AlertDescription>
          </Alert>
        </Box>
      )}
      {userActiveLicenseData && (
        <>
          {groupSelectors.map((groupSelector, idx) => (
            <Box
              key={`${groupSelector.group.name}-group`}
              sx={{
                '&:not(:first-of-type)': {
                  marginTop: shouldHideSelf(groupSelector.group) ? '0rem' : '5rem',
                },
              }}
            >
              <Heading
                fontSize="md"
                fontWeight="500"
                color="typo.grey"
                textTransform="uppercase"
                mb={7}
                hidden={shouldHideSelf(groupSelector.group)}
              >
                {groupSelector.group.name}
              </Heading>
              <Checkbox
                isDisabled={
                  disablingCondition &&
                  !experimentData.showOnlyFreeModels &&
                  !checkIfGroupHasOnlyDemoModels(groupSelector.group)
                }
                spacing={shouldHideSelf(groupSelector.group) ? 0 : 4}
                mb={shouldHideSelf(groupSelector.group) ? 0 : 5}
                isChecked={
                  (areAllDemoModelsSelected(idx) && experimentData.showOnlyFreeModels) ||
                  areAllSelected(idx) ||
                  (disablingCondition &&
                    !experimentData.showOnlyFreeModels &&
                    !checkIfGroupHasOnlyDemoModels(groupSelector.group))
                }
                onChange={() => onChangeAllCheckbox(groupSelector.group.id)}
                hidden={shouldHideSelf(groupSelector.group)}
                sx={{
                  '&  .chakra-checkbox__label': {
                    fontSize: '1rem',
                    fontWeight: '700',
                  },
                }}
              >
                All
              </Checkbox>
              <VStack spacing={5} alignItems="flex-start" ml={8}>
                {filterModelList(groupSelector.group.models).map((model: IActiveLicenseModel) => (
                  <Box key={model.id}>
                    {(!experimentData.showOnlyFreeModels || (model.isDemo && experimentData.showOnlyFreeModels)) && (
                      <Flex>
                        <Checkbox
                          isDisabled={(disablingCondition && !model.isDemo) || model.status === ModelStatus.Deactivated}
                          onChange={() => onChangeCheckboxModel(model.id, groupSelector.group.id, model.isDemo)}
                          isChecked={
                            model.isDemo
                              ? groupSelector.selectedDemoModelsIds.includes(model.id)
                              : groupSelector.selectedModelsIds.includes(model.id)
                          }
                          sx={{
                            '&  .chakra-checkbox__label': {
                              fontSize: '1rem',
                              fontWeight: '700',
                            },
                          }}
                        >
                          {model.status === ModelStatus.Deactivated ? (
                            <Box cursor="help" borderRadius="0.25rem" py="1" px="2">
                              <Tooltip
                                hasArrow
                                label={modelDeactivated}
                                bg="typo.blue"
                                borderRadius="0.25rem"
                                maxW="15rem"
                              >
                                <Text color="typo.grey" fontSize="lg">
                                  {model.endpoint}
                                </Text>
                              </Tooltip>
                            </Box>
                          ) : (
                            <Box bg="transparent" borderRadius="0.25rem" py="1" px="2">
                              <Text fontSize="lg">{model.endpoint}</Text>
                            </Box>
                          )}
                        </Checkbox>
                        {model.isDemo && (
                          <Box ml="2" fontStyle="italic" fontWeight="500" color="typo.green" my="auto">
                            free
                          </Box>
                        )}
                      </Flex>
                    )}
                  </Box>
                ))}
              </VStack>
            </Box>
          ))}
        </>
      )}
      <Box className="spacer-for-firefox" h="120px" w="100%" />
      <Box position="absolute" w="100%" p={5} left="0" bottom="0">
        <WizardNav
          backLabel="Back"
          nextLabel="Next"
          nextDisableCondition={checkDisablingCondition()}
          onNext={onNextStep}
          onBack={() => setCurrentStep(2)}
        />
      </Box>
    </Box>
  );
};
