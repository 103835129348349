import React, { useState } from 'react';
import _ from 'lodash';
import { Box, Flex, Heading, InputGroup, InputLeftElement, Input, Icon } from '@chakra-ui/react';
import { IoSearch } from 'react-icons/io5';
import { useInfiniteQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Spinner, UsersList } from 'src/components';
import { userService } from 'src/services';
import { QUERY_LIMIT, usersViewResources } from 'src/helpers';
import { UsersResponse } from 'src/model';
import { useDocumentTitle } from 'src/hooks';

export const Users: React.FC = () => {
  useDocumentTitle(usersViewResources.documentTitle);

  const { heading } = usersViewResources;

  const [filterValue, setFilterValue] = useState<string>('');

  const { getAccessTokenSilently } = useAuth0();

  const fetchUsers = async ({ pageParam = 0 }) => {
    const token = await getAccessTokenSilently();
    return userService
      .getUsers(QUERY_LIMIT, pageParam, filterValue, token)
      .then((response: AxiosResponse) => response.data);
  };

  const { data, isLoading, isError, hasNextPage, fetchNextPage } = useInfiniteQuery(
    ['users', filterValue],
    fetchUsers,
    {
      getNextPageParam: (lastPage: UsersResponse) => (lastPage.hasNext ? lastPage.offset + QUERY_LIMIT : undefined),
    }
  );

  const debouncedSetFilerValue = _.debounce((value) => setFilterValue(value), 900);

  const onChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    debouncedSetFilerValue(value);
  };

  return (
    <Flex className="users" w="100%" minH="100%" overflowY="auto" id="users" p={10} flexDirection="column">
      <Box as="header" mb={4}>
        <Heading fontWeight="700" size="xl">
          {heading}
        </Heading>
      </Box>
      <Box w="25%" minW="350px" mb={8}>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon as={IoSearch} />
          </InputLeftElement>
          <Input type="email" placeholder="E-mail" onChange={onChangeFilter} />
        </InputGroup>
      </Box>
      {isLoading && (
        <Flex w="100%" flex="1" justifyContent="center" alignItems="center">
          <Spinner thickness="8px" speed="1s" size="xl" w="100px" h="100px" label="Loading.." />
        </Flex>
      )}
      {data && hasNextPage !== undefined && !isError && (
        <UsersList
          data={[...data.pages.map((page: UsersResponse) => page.list)].flat()}
          getMoreData={fetchNextPage}
          hasMore={hasNextPage}
        />
      )}
    </Flex>
  );
};
