export const userDataFormModalResources = {
  heading: 'Your company info',
  inputs: {
    name: {
      label: 'Name',
      placeholder: 'Name',
    },
    surname: {
      label: 'Surname',
      placeholder: 'Surname',
    },
    companyName: {
      label: 'Company name',
      placeholder: 'Company name*',
    },
    companyAddress: {
      label: 'Company address',
      placeholder: 'Address',
    },
    organizationNumber: {
      label: 'Organization number',
      placeholder: 'Number',
    },
    marketingConsents: {
      label:
        'I consent to NQTB processing my personal data in order to send personalized marketing material in accordance with the  ',
      link: 'Privacy Policy',
    },
  },
  buttons: {
    primary: 'Submit',
    secondary: 'Skip',
  },
  toasts: {
    success: {
      title: 'Success',
      description: 'Data has been changed correctly',
    },
    error: {
      title: 'An error occurred',
      description: 'Data has not been changed',
    },
  },
} as const;
