import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { AiOutlineDownload } from 'react-icons/ai';
import { Flex, HStack, Heading, useTheme, Box, Grid, GridItem, Button, useDisclosure } from '@chakra-ui/react';
import { IoArrowBack } from 'react-icons/io5';
import { useHistory, useParams } from 'react-router-dom';
import { laboratoryRoute, predictionDetailsViewResources, downloadFile, getFilenameFromHeader } from 'src/helpers';
import {
  PredictionEndpointsList,
  PrimaryButton,
  DataWithLabel,
  PredictionLoader,
  Spinner,
  DeletePredictionModal,
  PredictionFailedUserAlert,
  structuralAnaloguesTableStyles,
  PredictionEndpointFailedAlert,
  NanoformShowcase,
  EndpointDetails,
  PredictionDetailsPerEndpoint,
  InfoAlert,
  SecondaryButton,
  ReportsWizard,
  ChooseReportVersionModal,
} from 'src/components';
import { predictionService, reportService } from 'src/services';
import {
  IPredictionDetails,
  IQsarPredictionExecution,
  PredictionStatus,
  IRaPredictionExecution,
  IPredictionModel,
  ReportTypeEnum,
  IReport,
} from 'src/model';
import { useSuccessToast, useDocumentTitle, useIsAdmin, useErrorToast } from 'src/hooks';
import {
  useLoaderActionsContext,
  useLoaderContext,
  useReportWizardActionsContext,
  useReportWizardContext,
  useUserActionsContext,
  useUserContext,
} from 'src/context';
import { AnimatePresence } from 'framer-motion';

export const PredictionDetails: React.FC = () => {
  const changeDocumentTitle = useDocumentTitle(predictionDetailsViewResources.documentTitle);

  const [showWaitingForPredictionLoader, setShowWaitingForPredictionLoader] = useState<boolean>(true);
  const [predictionStatus, setPredictionStatus] = useState<PredictionStatus>('Initial');
  const [endpointStatus, setEndpointStatus] = useState<PredictionStatus>('Initial');
  const [selectedEndpoint, setSelectedEndpoint] = useState<IPredictionModel>({} as IPredictionModel);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [predictionInDomain, setPredictionInDomain] = useState<boolean>(false);
  const [wasErrorToastDisplayed, setWasErrorToastDisplayed] = useState<boolean>(false);
  const [chooseReportVersionModalVisible, setChooseReportVersionModalVisible] = useState<boolean>(false);

  const { fetchUserActiveLicenseData, fetchUserData } = useUserActionsContext();
  const { onWizardOpen, reset, setReportType, setIsTesting } = useReportWizardActionsContext();
  const { isWizardOpen } = useReportWizardContext();

  const { userDataFromApi } = useUserContext();

  const initialMount = useRef(true);

  const history = useHistory();

  const isLoading = useLoaderContext();

  const setIsLoading = useLoaderActionsContext();

  const isAdmin = useIsAdmin();

  const { getAccessTokenSilently } = useAuth0();

  const { predictionId } = useParams<{ predictionId: string }>();

  const {
    colors: { button },
  } = useTheme();

  const { outOfDomain } = predictionDetailsViewResources;

  const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useDisclosure();

  const deletePredictionSuccessToast = useSuccessToast(
    predictionDetailsViewResources.toasts.deleteSuccess.title,
    predictionDetailsViewResources.toasts.deleteSuccess.description
  );

  const adminEndpointFailedToast = useErrorToast(
    predictionDetailsViewResources.toasts.adminSomeEndpointsFailed.title,
    predictionDetailsViewResources.toasts.adminSomeEndpointsFailed.description,
    undefined,
    predictionDetailsViewResources.toasts.adminSomeEndpointsFailed.id
  );

  const fetchPredictionDetails = async (): Promise<IPredictionDetails> => {
    const token = await getAccessTokenSilently();
    return predictionService.getPredictionDetails(predictionId, token).then((response: AxiosResponse) => response.data);
  };

  const fetchQsarTypeEndpointDetails = async (): Promise<IQsarPredictionExecution> => {
    const token = await getAccessTokenSilently();
    return predictionService
      .getPredictionQsarExecutionDetails(predictionId, selectedEndpoint.id, token)
      .then((response: AxiosResponse) => response.data);
  };

  const fetchRaTypeEndpointDetails = async (): Promise<IRaPredictionExecution> => {
    const token = await getAccessTokenSilently();
    return predictionService
      .getPredictionRaExecutionDetails(predictionId, selectedEndpoint.id, token)
      .then((response: AxiosResponse) => response.data);
  };

  const fetchReports = async (type: ReportTypeEnum): Promise<IReport[]> => {
    const token = await getAccessTokenSilently();
    return reportService.getReports(type, token).then((response: AxiosResponse) => response.data);
  };

  const { data: predictionDetails, isLoading: predictionDetailsLoading } = useQuery<IPredictionDetails>(
    ['fetchPredictionDetails', predictionId],
    fetchPredictionDetails,
    {
      enabled: Boolean(predictionId),
      refetchInterval: predictionStatus !== 'Done' && predictionStatus !== 'Failed' ? 1500 : false,
      onSuccess: (data: IPredictionDetails) => {
        if (data.status === 'Done' && initialMount.current) {
          fetchUserData();
        }
      },
    }
  );

  const { data: qsarEndpointDetails, isLoading: qsarDetailsLoading } = useQuery<IQsarPredictionExecution>(
    ['fetchQsarEndpointDetails', selectedEndpoint.id],
    fetchQsarTypeEndpointDetails,
    {
      enabled: Boolean(selectedEndpoint.id) && selectedEndpoint?.reportType === 'QSAR',
    }
  );

  const { data: raEndpointDetails, isLoading: raDetailsLoading } = useQuery<IRaPredictionExecution>(
    ['fetchRaEndpointDetails', selectedEndpoint.id],
    fetchRaTypeEndpointDetails,
    {
      enabled: Boolean(selectedEndpoint.id) && selectedEndpoint?.reportType === 'RA',
    }
  );

  const { data: qsarReports, isLoading: qsarIsLoading } = useQuery(`reports-${ReportTypeEnum.QSAR}`, () =>
    fetchReports(ReportTypeEnum.QSAR)
  );

  const { data: raReports, isLoading: raIsLoading } = useQuery(`reports-${ReportTypeEnum.RA}`, () =>
    fetchReports(ReportTypeEnum.RA)
  );

  const changeChooseReportVersionModalVisibility = () => {
    setChooseReportVersionModalVisible(!chooseReportVersionModalVisible);
  };

  const selectEndpoint = (endpoint: IPredictionModel) => {
    setSelectedEndpoint(endpoint);
  };

  const backToPredictionList = () => {
    fetchUserActiveLicenseData();
    history.goBack();
  };

  const deletePrediction = useCallback(async () => {
    setIsLoading(true);

    try {
      const token = await getAccessTokenSilently();
      const deletePredictionResponse = await predictionService.deletePrediction(predictionId, token);

      if (deletePredictionResponse) {
        onDeleteModalClose();
        history.replace({
          pathname: laboratoryRoute.path,
        });
        deletePredictionSuccessToast();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [predictionId, getAccessTokenSilently, history, setIsLoading, onDeleteModalClose, deletePredictionSuccessToast]);

  const downloadReports = async (qsarReportId: string, raReportId: string) => {
    try {
      setIsDownloading(true);
      const token = await getAccessTokenSilently();
      const getPredictionReports = await predictionService.getPredictionReports(
        predictionId,
        null,
        null,
        !qsarReportId ? null : qsarReportId,
        !raReportId ? null : raReportId,
        token
      );

      if (getPredictionReports) {
        const contentDispositionHeader = getPredictionReports.headers['content-disposition'];

        const filename = getFilenameFromHeader(contentDispositionHeader);

        await downloadFile(getPredictionReports, `${filename}.zip`);
        setIsDownloading(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownloading(false);
    }
  };

  const getQsarReports = (): IReport[] => {
    if (!predictionDetails || !qsarReports) return [];

    if (
      qsarReports &&
      predictionDetails.modelGroups.some((g) => g.models.some((m) => m.reportType === ReportTypeEnum.QSAR))
    )
      return qsarReports;

    return [];
  };

  const getRaReports = (): IReport[] => {
    if (!predictionDetails || !raReports) return [];

    if (
      raReports &&
      predictionDetails.modelGroups.some((g) => g.models.some((m) => m.reportType === ReportTypeEnum.RA))
    )
      return raReports;

    return [];
  };

  useEffect(() => {
    if (
      predictionDetails &&
      predictionDetails.modelGroups.length &&
      !selectedEndpoint.id &&
      predictionDetails.status !== 'Initial' &&
      predictionDetails.status !== 'InProgress'
    ) {
      setSelectedEndpoint(predictionDetails.modelGroups[0].models[0]);
    }
  }, [predictionDetails, setSelectedEndpoint, selectedEndpoint]);

  useEffect(() => {
    if (predictionStatus === 'Initial' || predictionStatus === 'InProgress') {
      setShowWaitingForPredictionLoader(true);
    } else {
      setShowWaitingForPredictionLoader(false);
    }
  }, [predictionStatus]);

  useEffect(() => {
    if (predictionDetails) {
      setPredictionStatus(predictionDetails?.status);
    }
  }, [predictionDetails]);

  useEffect(() => {
    if (
      predictionStatus === 'Failed' &&
      isAdmin &&
      !wasErrorToastDisplayed &&
      !adminEndpointFailedToast.isActive(predictionDetailsViewResources.toasts.adminSomeEndpointsFailed.id)
    ) {
      setWasErrorToastDisplayed(true);
      adminEndpointFailedToast();
    }
  }, [predictionStatus, isAdmin, wasErrorToastDisplayed, adminEndpointFailedToast]);

  useEffect(() => {
    if (qsarEndpointDetails) {
      setPredictionInDomain(qsarEndpointDetails.inDomain);
      setEndpointStatus(qsarEndpointDetails.state);
    }
  }, [qsarEndpointDetails]);

  useEffect(() => {
    if (raEndpointDetails) {
      setPredictionInDomain(raEndpointDetails.inDomain);
      setEndpointStatus(raEndpointDetails.state);
    }
  }, [raEndpointDetails]);

  useEffect(() => {
    if (isLoading || qsarDetailsLoading || predictionDetailsLoading || raDetailsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [setLoading, isLoading, qsarDetailsLoading, predictionDetailsLoading, raDetailsLoading]);

  return (
    <Box overflowY={chooseReportVersionModalVisible ? 'hidden' : 'hidden'}>
      {chooseReportVersionModalVisible && (
        <ChooseReportVersionModal
          changeVisibility={changeChooseReportVersionModalVisibility}
          qsarReports={getQsarReports()}
          raReports={getRaReports()}
          downloadReports={downloadReports}
          isDownloading={isDownloading}
          predictionCompletedDate={predictionDetails?.completedDate}
        />
      )}
      {showWaitingForPredictionLoader ? (
        <PredictionLoader onBack={backToPredictionList} />
      ) : (
        <Flex className="models-version-details" w="100%" overflowY="auto" p={10} flexDirection="column" h="100%">
          {loading && (
            <Flex w="100%" flex="1" justifyContent="center" alignItems="center">
              <Spinner thickness="8px" speed="1s" size="xl" w="100px" h="100px" label="Loading.." />
            </Flex>
          )}
          {!loading && predictionDetails && selectedEndpoint && (
            <>
              <Flex
                as="header"
                className="models-version__header"
                justifyContent="space-between"
                alignItems="center"
                pb={10}
              >
                <AnimatePresence
                  exitBeforeEnter
                  onExitComplete={() => {
                    reset();
                    changeDocumentTitle(predictionDetailsViewResources.documentTitle);
                  }}
                >
                  {isWizardOpen && <ReportsWizard />}
                </AnimatePresence>
                <HStack spacing={4}>
                  <IoArrowBack
                    color={button.blue}
                    size="1.5rem"
                    cursor="pointer"
                    onClick={() => backToPredictionList()}
                  />
                  <Heading fontWeight="700" size="xl">
                    {predictionDetails.name}
                  </Heading>
                </HStack>
                <Flex>
                  {(!isAdmin || userDataFromApi.id === predictionDetails.userId) && (
                    <Button mr={4} colorScheme="red" variant="outline" onClick={onDeleteModalOpen}>
                      {predictionDetailsViewResources.deletePrediction}
                    </Button>
                  )}
                  {isAdmin && (
                    <SecondaryButton
                      mr={4}
                      onClick={() => {
                        setIsTesting(true);
                        onWizardOpen();
                        setReportType(
                          ReportTypeEnum[selectedEndpoint.reportType.toString() as keyof typeof ReportTypeEnum]
                        );
                      }}
                    >
                      {predictionDetailsViewResources.testReportTemplates}
                    </SecondaryButton>
                  )}
                  {(predictionStatus !== 'Failed' || isAdmin) && (
                    <PrimaryButton
                      size="sm"
                      leftIcon={<AiOutlineDownload size="1.5rem" />}
                      onClick={() => changeChooseReportVersionModalVisibility()}
                      isLoading={isDownloading || raIsLoading || qsarIsLoading}
                    >
                      {predictionDetailsViewResources.downloadReport}
                    </PrimaryButton>
                  )}
                </Flex>
              </Flex>
              <DeletePredictionModal
                modalAction={() => deletePrediction()}
                isOpen={isDeleteModalOpen}
                closeModal={onDeleteModalClose}
              />
              {predictionDetails.outOfDomainExecutionsPresent && !isAdmin && predictionDetails.isLicenseSingleUse && (
                <InfoAlert
                  text={
                    predictionDetails.retryAttemptsLeft > 0
                      ? outOfDomain.attemptsAvailable.replace('-1', predictionDetails.retryAttemptsLeft.toString())
                      : outOfDomain.attemptsNotAvailable
                  }
                />
              )}
              {(predictionStatus === 'Done' || isAdmin) && (
                <>
                  <Grid
                    w="100%"
                    templateColumns="repeat(4, 1fr)"
                    templateRows={selectedEndpoint.reportType === 'QSAR' ? '1fr auto' : '1fr'}
                    gap={4}
                  >
                    <GridItem rowSpan={selectedEndpoint.reportType === 'QSAR' ? 2 : 1}>
                      <PredictionEndpointsList
                        modelGroups={predictionDetails.modelGroups}
                        onEndpointSelect={selectEndpoint}
                        activeEndpointId={selectedEndpoint.id}
                      />
                    </GridItem>
                    {endpointStatus !== 'Failed' ? (
                      <>
                        <GridItem display="flex" flexDirection="column">
                          <EndpointDetails selectedEndpoint={selectedEndpoint} />
                          <PredictionDetailsPerEndpoint
                            predictionInDomain={predictionInDomain}
                            qsarEndpointDetails={qsarEndpointDetails}
                            raEndpointDetails={raEndpointDetails}
                            selectedEndpoint={selectedEndpoint}
                          />
                        </GridItem>
                        <GridItem colSpan={2}>
                          <NanoformShowcase
                            image={predictionDetails.image}
                            core={predictionDetails.core}
                            shape={selectedEndpoint.shape}
                            attributeValues={selectedEndpoint.attributeValues}
                          />
                        </GridItem>
                        {selectedEndpoint.reportType === 'QSAR' && !!qsarEndpointDetails && (
                          <GridItem colStart={2}>
                            <Box
                              p={5}
                              bg="typo.white"
                              borderColor="utils.boxBorder"
                              borderWidth="1px"
                              borderRadius="md"
                              w="100%"
                            >
                              <Heading as="h3" mb={8} fontSize="1.5rem">
                                {predictionDetailsViewResources.descriptorValues}
                              </Heading>
                              {qsarEndpointDetails.descriptors &&
                                qsarEndpointDetails.descriptors.map((descriptor: { label: string; value: string }) => (
                                  <DataWithLabel
                                    key={`${descriptor.label}_${descriptor.value}`}
                                    label={descriptor.label}
                                    value={descriptor.value}
                                  />
                                ))}
                            </Box>
                          </GridItem>
                        )}
                      </>
                    ) : (
                      <GridItem colSpan={3} rowSpan={2}>
                        <PredictionEndpointFailedAlert />
                      </GridItem>
                    )}
                  </Grid>
                  {!!raEndpointDetails && endpointStatus !== 'Failed' && (
                    <Box
                      p={5}
                      bg="typo.white"
                      borderColor="utils.boxBorder"
                      borderWidth="1px"
                      borderRadius="md"
                      w="100%"
                      mt={5}
                    >
                      <Heading as="h3" mb={6} fontSize="1.5rem">
                        {predictionDetailsViewResources.structuralAnalogues}
                      </Heading>
                      <Box
                        dangerouslySetInnerHTML={{ __html: raEndpointDetails.structuralAnaloguesHTML }}
                        sx={structuralAnaloguesTableStyles}
                      />
                    </Box>
                  )}
                </>
              )}
              {predictionStatus === 'Failed' && !isAdmin && <PredictionFailedUserAlert />}
            </>
          )}
        </Flex>
      )}
    </Box>
  );
};
