import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import InfiniteScroll from 'react-infinite-scroller';
import { Table, Tbody, Box, Tr, Badge, Text, Tooltip, Flex } from '@chakra-ui/react';
import { TableHeader, TableData, EmptyListState } from 'src/components';
import {
  DATE_FORMAT,
  userLicensesViewResources,
  LicenseType,
  userLicensesEmptyList,
  adminUserLicensesEmptyList,
} from 'src/helpers';
import { License, LabModel, LicenseStatus } from 'src/model';
import { useHistory } from 'react-router-dom';

interface IUserLicensesListProps {
  data: License[];
  getMoreData: () => void;
  hasMore: boolean;
  userRole: 'Admin' | 'User';
  licensesUserId: string;
  userEmail?: string;
  userStatus?: string;
}

export const UserLicensesList: React.FC<IUserLicensesListProps> = ({
  data,
  getMoreData,
  hasMore,
  userRole,
  licensesUserId,
  userEmail,
  userStatus,
}) => {
  const history = useHistory();

  const [sortedData, setSortedData] = useState<License[]>([]);

  const licenseStatus = useCallback((license: License) => {
    if (license.hasWaitingPayment && !license.isDeactivated) {
      return LicenseStatus.waiting;
    }
    if (license.isActive) {
      return LicenseStatus.active;
    }
    return LicenseStatus.inActive;
  }, []);

  const flagColor = useCallback((status: LicenseStatus | undefined) => {
    switch (status) {
      case LicenseStatus.active:
        return 'utils.greenGradient';
      case LicenseStatus.waiting:
        return 'utils.goldGradient';
      default:
        return undefined;
    }
  }, []);

  const renderBadge = useCallback((status: LicenseStatus | undefined) => {
    switch (status) {
      case LicenseStatus.active:
        return (
          <Badge colorScheme="green" py={1} px="12px" fontSize="md" color="typo.green" fontWeight="500">
            {userLicensesViewResources.active}
          </Badge>
        );
      case LicenseStatus.waiting:
        return (
          <Badge colorScheme="orange" py={1} px="12px" fontSize="md" color="utils.gold" fontWeight="500">
            {userLicensesViewResources.waiting}
          </Badge>
        );
      default:
        return (
          <Badge colorScheme="red" py={1} px="12px" fontSize="md" color="utils.alert" fontWeight="500">
            {userLicensesViewResources.inActive}
          </Badge>
        );
    }
  }, []);

  const sortByDate = (dataToSort: License[]): License[] => {
    return dataToSort.sort((a, b) => {
      const date1 = new Date(a.purchasedDate);
      const date2 = new Date(b.purchasedDate);

      return date2.getTime() - date1.getTime();
    });
  };

  const sortData = (): License[] => {
    const active = data.filter((license) => license.isActive);
    const inActive = data.filter(
      (license) => !license.isActive && (!license.hasWaitingPayment || license.isDeactivated)
    );
    const waiting = data.filter((license) => license.hasWaitingPayment && !license.isDeactivated);

    return sortByDate(active).concat(sortByDate(waiting)).concat(sortByDate(inActive));
  };

  useEffect(() => {
    setSortedData(sortData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box className="user-licenses-list" w="100%">
      {data.length ? (
        <InfiniteScroll pageStart={0} hasMore={hasMore} loadMore={getMoreData} useWindow>
          <Table
            variant="unstyled"
            w="100%"
            h="100%"
            sx={{ borderCollapse: 'separate', borderSpacing: '0 10px', tableLayout: 'fixed' }}
            cellSpacing="0"
          >
            <TableHeader titles={['License', 'Models', 'Purchased', 'Expiration', 'License Status']} />
            <Tbody>
              {sortedData.map((license: License) => (
                <Tr
                  key={`user-license-${license.id}-${license.type}-${license.purchasedDate}`}
                  h="82px"
                  cursor="pointer"
                  onClick={() =>
                    history.push(`/licensedetails/${license.id}`, {
                      userId: licensesUserId,
                      userRole,
                      email: userEmail,
                      status: userStatus,
                    })
                  }
                >
                  <TableData flagColor={flagColor(licenseStatus(license))}>
                    <Text fontWeight="700" fontSize="1.125rem" whiteSpace="nowrap">
                      {LicenseType[license.type as keyof typeof LicenseType].main}
                    </Text>
                    <Text whiteSpace="nowrap">{LicenseType[license.type as keyof typeof LicenseType].sub}</Text>
                  </TableData>
                  <TableData>
                    <Flex flexWrap="wrap">
                      {license.models.map((model: LabModel, index: number) => (
                        <Box key={`${model.endpoint}-${model.isDeleted}`}>
                          {model.isDeleted ? (
                            <Box cursor="help" bg="radio.bg" borderRadius="0.25rem" py="0.2rem" px="0.1rem">
                              <Tooltip
                                hasArrow
                                label="This model has been deleted"
                                bg="typo.blue"
                                borderRadius="0.25rem"
                                maxW="15rem"
                              >
                                <Text as="span" color="typo.grey" fontSize="md">
                                  {`${model.endpoint}${index !== license.models.length - 1 ? ', ' : ''}`}
                                </Text>
                              </Tooltip>
                            </Box>
                          ) : (
                            <Box bg="transparent" borderRadius="0.25rem" py="0.2rem" px="0.1rem">
                              <Text as="span" fontSize="md">
                                {`${model.endpoint}${index !== license.models.length - 1 ? ', ' : ''}`}
                              </Text>
                            </Box>
                          )}
                        </Box>
                      ))}
                    </Flex>
                  </TableData>
                  <TableData>{dayjs(license.purchasedDate).format(DATE_FORMAT)}</TableData>
                  <TableData>{license.expiredDate ? dayjs(license.expiredDate).format(DATE_FORMAT) : ''}</TableData>
                  <TableData>{renderBadge(licenseStatus(license))}</TableData>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </InfiniteScroll>
      ) : (
        <EmptyListState title={userRole === 'Admin' ? adminUserLicensesEmptyList : userLicensesEmptyList} />
      )}
    </Box>
  );
};
