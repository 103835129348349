import { AxiosPromise, AxiosResponse } from 'axios';
import { IModelVersion, INewModel, INewAttributeDefinition } from 'src/model';
import { authorizedApiService } from './api.service';

class ModelService {
  getAllModels = (limit: number, offset: number, token: string): AxiosPromise => {
    const apiUrl = `/models?limit=${limit}&offset=${offset}`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  createModelWithVersion = (
    model: INewModel,
    version: IModelVersion,
    attributeDefinitions: INewAttributeDefinition[],
    userId: string,
    token: string
  ): AxiosPromise => {
    const apiUrl = `/models`;
    return authorizedApiService(token)
      .post(apiUrl, { ...model, ...version, ...{ attributeDefinitions }, userId })
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  updateModel = (model: INewModel, userId: string, token: string): AxiosPromise => {
    const apiUrl = `/models/${encodeURI(model.id)}`;
    return authorizedApiService(token)
      .put(apiUrl, { ...model, userId })
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getConfigurationFiles = (modelId: string, versionId: string, token: string): AxiosPromise => {
    const apiUrl = `/models/${encodeURI(modelId)}/versions/${encodeURI(versionId)}/configurationFiles`;
    return authorizedApiService(token)
      .get(apiUrl, { responseType: 'arraybuffer' })
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getModelDetails = (modelId: string, token: string): AxiosPromise => {
    const apiUrl = `/models/${encodeURI(modelId)}`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getModelVersions = (modelId: string, limit: number, offset: number, token: string): AxiosPromise => {
    const apiUrl = `/models/${encodeURI(modelId)}/versions?limit=${limit}&offset=${offset}`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getModelLicenses = (modelId: string, limit: number, offset: number, token: string): AxiosPromise => {
    const apiUrl = `/models/${encodeURI(modelId)}/licenses?limit=${limit}&offset=${offset}`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getModelsVersionDetails = (versionId: string, token: string): AxiosPromise => {
    const apiUrl = `models/version/${encodeURI(versionId)}`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  deleteModelsVersion = (modelId: string, versionId: string, token: string): AxiosPromise => {
    const apiUrl = `models/${encodeURI(modelId)}/version/${encodeURI(versionId)}`;
    return authorizedApiService(token)
      .delete(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  deleteModel = (modelId: string, token: string): AxiosPromise => {
    const apiUrl = `models/${encodeURI(modelId)}`;
    return authorizedApiService(token)
      .delete(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  createModelVersion = (
    modelId: string,
    version: IModelVersion,
    attributeDefinitions: INewAttributeDefinition[],
    token: string
  ): AxiosPromise => {
    const apiUrl = `/models/${encodeURI(modelId)}/versions`;
    return authorizedApiService(token)
      .post(apiUrl, { ...version, ...{ attributeDefinitions } })
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getActiveVersionForModel = (modelId: string, token: string): AxiosPromise => {
    const apiUrl = `models/${encodeURI(modelId)}/activeversion`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getNextVersionsNumber = (modelId: string, token: string): AxiosPromise => {
    const apiUrl = `models/${encodeURI(modelId)}/next-version-number`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  validateNameUniqueness = (modelName: string, endpointName: string, token: string, modelId?: string): AxiosPromise => {
    let apiUrl = `models/validate-name-uniqueness?name=${modelName}&endpoint=${endpointName}`;
    apiUrl = modelId ? `${apiUrl}&modelId=${modelId}` : apiUrl;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getModelsAttributes = (modelIds: string[], token: string): AxiosPromise => {
    const apiUrl = `models/attributes?modelIds=${encodeURI(modelIds.join('&modelIds='))}`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };
}

export const modelService = new ModelService();
