import React from 'react';
import { Heading, Flex, Box, Icon, useRadioGroup } from '@chakra-ui/react';
import { IShapeOption } from 'src/model';
import { RadioOption } from 'src/components';

interface IShapePickerCategoryProps {
  title: string;
  options: IShapeOption[];
  invalid: boolean;
  getRadioProps: ReturnType<typeof useRadioGroup>['getRadioProps'];
}

const WrapperStyles = {
  '&:not(:last-child)': {
    marginRight: '1rem',
  },
} as const;

export const ShapePickerCategory: React.FC<IShapePickerCategoryProps> = ({
  title,
  options,
  invalid,
  getRadioProps,
}) => {
  return (
    <>
      <Heading
        as="h3"
        color={invalid ? 'utils.alert' : 'typo.grey'}
        fontSize="md"
        fontWeight="500"
        textTransform="uppercase"
        w="100%"
      >
        {title}
      </Heading>
      <Flex mb={6} wrap="wrap">
        {options.map((option: IShapeOption) => {
          const radio = getRadioProps({ value: option.shape });
          return (
            <Box key={`${option.shape}`} display="inline-block" sx={WrapperStyles} mt={4}>
              <RadioOption {...radio}>
                <Flex alignItems="center">
                  <Icon as={option.icon} fill="currentColor" mr={4} w={6} h={6} />
                  {option.shape}
                </Flex>
              </RadioOption>
            </Box>
          );
        })}
      </Flex>
    </>
  );
};
