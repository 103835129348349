import React, { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import { Flex, HStack, Heading, useTheme, useDisclosure } from '@chakra-ui/react';
import { IoArrowBack } from 'react-icons/io5';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { IModelDetails, IModelVersionDetailsResponse } from 'src/model';
import { modelsVersionDetailsViewResources, DATE_FORMAT, downloadFile, getFilenameFromHeader } from 'src/helpers';
import {
  CancelButton,
  ModelDetails,
  Spinner,
  DataWithLabel,
  DeleteModelsVersionModal,
  PrimaryButton,
} from 'src/components';
import { modelService } from 'src/services';
import { useLoaderActionsContext } from 'src/context';
import { useSuccessToast, useDocumentTitle } from 'src/hooks';

export const ManageModelsVersion: React.FC = () => {
  useDocumentTitle(modelsVersionDetailsViewResources.documentTitle);

  const {
    colors: { button },
  } = useTheme();

  const history = useHistory();
  const {
    state: { model, versionNumber, isLastVersion },
  } = useLocation<{ model: IModelDetails; versionNumber: string; isLastVersion: boolean }>();

  const { versionId } = useParams<{ versionId: string }>();

  const { getAccessTokenSilently } = useAuth0();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const setLoading = useLoaderActionsContext();

  const {
    heading,
    deleteButton,
    downloadFunctionButton,
    versionAttributesHeading,
    attributesLabels: {
      versionNumber: versionNumberText,
      date,
      functionName,
      author,
      changeLog,
      referenceToQmrf,
      usedAttributes,
    },
    toasts: {
      success: { title, description },
    },
  } = modelsVersionDetailsViewResources;

  const deleteVersionSuccessToast = useSuccessToast(title, description);

  const fetchModelsVersionDetails = async (id: string): Promise<IModelVersionDetailsResponse> => {
    const token = await getAccessTokenSilently();
    return modelService.getModelsVersionDetails(id, token).then((response: AxiosResponse) => response.data);
  };

  const { data: modelsVersionDetails, isLoading } = useQuery(['models-version-details', versionId], () =>
    fetchModelsVersionDetails(versionId)
  );

  const deleteModelsVersion = useCallback(async () => {
    setLoading(true);

    try {
      const token = await getAccessTokenSilently();
      const response = await modelService.deleteModelsVersion(model.id, versionId, token);

      if (response) {
        onClose();
        history.replace({
          pathname: `/modeldetails/${model.id}`,
        });
        deleteVersionSuccessToast();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      onClose();
    }
  }, [model.id, versionId, getAccessTokenSilently, history, setLoading, onClose, deleteVersionSuccessToast]);

  const downloadFunction = useCallback(async () => {
    setLoading(true);

    try {
      const token = await getAccessTokenSilently();

      const getConfigurationFiles = await modelService.getConfigurationFiles(model.id, versionId, token);

      const contentDispositionHeader = getConfigurationFiles.headers['content-disposition'];

      const filename = getFilenameFromHeader(contentDispositionHeader);

      await downloadFile(getConfigurationFiles, `${filename}.zip`);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [model, versionId, getAccessTokenSilently, setLoading]);

  return (
    <Flex className="models-version-details" w="100%" minH="100%" overflowY="auto" p={8} flexDirection="column">
      <DeleteModelsVersionModal modalAction={() => deleteModelsVersion()} isOpen={isOpen} closeModal={onClose} />
      <Flex as="header" className="models-version__header" justifyContent="space-between" alignItems="center" pb={10}>
        <HStack spacing={4} minH="52px">
          <IoArrowBack color={button.blue} size="1.5rem" cursor="pointer" onClick={() => history.goBack()} />
          <Heading fontWeight="700" size="xl">
            {`${heading} ${versionNumber}`}
          </Heading>
        </HStack>
        <HStack>
          {!isLastVersion && (
            <CancelButton className="delete-version-button" size="sm" onClick={onOpen}>
              {deleteButton}
            </CancelButton>
          )}
          <PrimaryButton className="download-azure-function-button" size="sm" onClick={() => downloadFunction()}>
            {downloadFunctionButton}
          </PrimaryButton>
        </HStack>
      </Flex>
      <HStack spacing={6} alignItems="flex-start">
        <Flex
          flex={1}
          flexDirection="column"
          bg="typo.white"
          borderRadius="0.25rem"
          borderWidth="1px"
          borderColor="input.border"
          maxW="400px"
          minW="300px"
          p={6}
        >
          <ModelDetails model={model} />
        </Flex>
        {isLoading && (
          <Flex flex={1} alignItems="center" justifyContent="center" minH="400px">
            <Spinner thickness="8px" speed="1s" size="xl" w="100px" h="100px" label="Loading.." />
          </Flex>
        )}
        {!isLoading && modelsVersionDetails && (
          <Flex
            flex={1}
            p={6}
            flexDirection="column"
            bg="typo.white"
            borderRadius="0.25rem"
            borderWidth="1px"
            borderColor="input.border"
          >
            <Heading fontSize="1.5rem" fontWeight="700" pb={8}>
              {versionAttributesHeading}
            </Heading>
            <HStack spacing={10}>
              <DataWithLabel label={versionNumberText} value={modelsVersionDetails.versionNumberText} />
              <DataWithLabel label={date} value={dayjs(modelsVersionDetails.date).format(DATE_FORMAT)} />
            </HStack>
            <DataWithLabel label={functionName} value={modelsVersionDetails.functionName} />
            <DataWithLabel label={author} value={modelsVersionDetails.author} />
            <DataWithLabel label={changeLog} value={modelsVersionDetails.changeLog} preformatted />
            {modelsVersionDetails.useShape ? (
              <DataWithLabel
                label={usedAttributes}
                value={['Shape'].concat(modelsVersionDetails.attributeLabels).join(', ')}
              />
            ) : (
              <DataWithLabel label={usedAttributes} value={modelsVersionDetails.attributeLabels.join(', ')} />
            )}
            <DataWithLabel label={referenceToQmrf} value={modelsVersionDetails.qmrfReference} />
          </Flex>
        )}
      </HStack>
    </Flex>
  );
};
