import React from 'react';
import { Box, Flex, Heading, Text, Badge } from '@chakra-ui/react';
import { predictionDetailsViewResources } from 'src/helpers';
import { IPredictionModel } from 'src/model';

interface IEndpointDetailsProps {
  selectedEndpoint: IPredictionModel;
}

export const EndpointDetails: React.FC<IEndpointDetailsProps> = ({ selectedEndpoint }) => {
  return (
    <Box p={5} bg="typo.white" borderColor="utils.boxBorder" borderWidth="1px" borderRadius="md" mb={2} w="100%">
      <Flex h="100%" flexDirection="column" justifyContent="space-between">
        <Heading as="h3" mb={2} fontSize="1.5rem">
          {predictionDetailsViewResources.endpointDetails}
        </Heading>
        {selectedEndpoint.isDeleted && (
          <Box>
            <Badge colorScheme="gray" color="gray" py={1} px="12px" fontSize="md" fontWeight="500">
              Deleted
            </Badge>
          </Box>
        )}
        <Box flex={1} display="flex" flexDirection="column" justifyContent="center" py={5}>
          <Text fontWeight="500" color="custom.glaucous" mb={5}>
            {selectedEndpoint.endpoint}
          </Text>
          <Text fontWeight="500" color="typo.grey" textTransform="uppercase" mb={2}>
            {predictionDetailsViewResources.dependentVariable}
          </Text>
          <Text fontWeight="500" color="custom.glaucous">
            {selectedEndpoint.dependentVariable}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};
