import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { HexColorPicker } from 'react-colorful';
import 'react-colorful/dist/index.css';
import { coreService } from 'src/services';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';
import { useErrorToast, useDocumentTitle, useSuccessToast } from 'src/hooks';
import { Box, Flex, FormControl, FormLabel, Input, FormErrorMessage } from '@chakra-ui/react';
import { ControlledInput, WizardNav } from 'src/components';
import { scrollbar } from 'src/helpers';
import { useCoreWizardActionsContext, useCoreWizardContext } from 'src/context';
import { CreateCoreFormInputs, ICore } from 'src/model';
import { createEditCoreYupSchema } from 'src/utils';
import { coreWizardResources } from './coreWizardResources';

interface ICreateCoreProps {
  CoreId: string | undefined;
}

export const CreateCore: React.FC<ICreateCoreProps> = ({ CoreId }) => {
  useDocumentTitle(coreWizardResources.steps.createCore);
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const [newCore] = useState<boolean>(!!CoreId);
  const { onWizardClose, setCurrentStep, setCoresList } = useCoreWizardActionsContext();
  const { core, coresList, currentStep } = useCoreWizardContext();
  const { createCore, toasts } = coreWizardResources;
  const { validationErrorToast } = toasts;
  const formValidationErrorToast = useErrorToast(validationErrorToast.title, validationErrorToast.description);
  const onError = () => formValidationErrorToast();
  const { setCore } = useCoreWizardActionsContext();

  const {
    core: { name, color },
  } = useCoreWizardContext();

  const methods = useForm<CreateCoreFormInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(createEditCoreYupSchema()),
    defaultValues: {
      name,
      color,
    },
    shouldFocusError: false,
  });

  const { handleSubmit, control, setValue, errors } = methods;

  const coreCreationSuccessToast = useSuccessToast(
    coreWizardResources.toasts.saveCoreSuccess.title,
    coreWizardResources.toasts.saveCoreSuccess.description
  );

  const done = async () => {
    onWizardClose();
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const sortByName = (array: ICore[]) => {
    array.sort((a, b) => {
      if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
        return 1;
      }
      if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
        return -1;
      }
      return 0;
    });
    return array;
  };

  const saveNewCore = async () => {
    try {
      const token = await getAccessTokenSilently();
      const createCoreData = await coreService.createCore(core, token).then((response) => response.data);

      await queryClient.refetchQueries(['core-list']);

      if (createCoreData) {
        coreCreationSuccessToast();
        if (coresList) {
          const temp = core;
          temp.id = createCoreData;
          const newData = [...coresList];
          newData.push(temp);
          setCoresList(sortByName(newData));
        }
        nextStep();
        done();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const saveEditedCore = async () => {
    if (!CoreId) {
      return;
    }

    try {
      const token = await getAccessTokenSilently();

      const editCoreData = await coreService.editCore(CoreId, core, token).then((response) => response.data);

      await queryClient.removeQueries(['core-list', CoreId]);

      if (editCoreData) {
        coreCreationSuccessToast();
        nextStep();
        if (coresList && coresList?.length) {
          for (let i = 0; i < coresList.length; i += 1) {
            if (coresList[i].id === editCoreData) {
              const newData = [...coresList];
              newData[i] = core;
              setCoresList(sortByName(newData));
              done();
              return;
            }
          }
        }
        done();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async () => {
    if (newCore) {
      saveEditedCore();
    } else {
      saveNewCore();
    }
  };

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setCore((prevState) => ({ ...prevState, name: value }));
  };

  const onChangeColor = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length === 0) {
      return;
    }
    if (value.length === 8) {
      return;
    }
    const char = value[value.length - 1];
    const reg = /^[0-9A-F]/i;
    if (!reg.test(char) && value.length > 1) {
      return;
    }
    setCore((prevState) => ({ ...prevState, color: value }));
  };

  const onChangeColors = (value: string) => {
    setCore((prevState) => ({ ...prevState, color: value }));
    setValue('color', value);
  };

  return (
    <Box h="100%" pt={16} px="15%" sx={scrollbar} overflow="auto">
      <form name="create-edit-core">
        <FormControl id="name" mb={4} w="50%" isInvalid={!!errors.name?.message}>
          <FormLabel fontSize="2xl" fontWeight="bold" mb={4} w="50%">
            {createCore.name.label}
          </FormLabel>
          <ControlledInput
            name="name"
            control={control}
            onChangeInput={onChangeName}
            placeholder={createCore.name.placeholder}
          />
          {errors.name?.message && <FormErrorMessage>{errors.name?.message}</FormErrorMessage>}
        </FormControl>
        <Box w="50%">
          <HexColorPicker color={core.color} onChange={onChangeColors} />
          <Flex flexDirection="row">
            <Box fontWeight="700" fontSize="xl" marginY={4} marginX="auto">
              <FormControl id="color" mb={4} marginX="auto" isInvalid={!!errors.color?.message}>
                <Controller
                  name="color"
                  control={control}
                  render={({ onChange, name: renderName, ref }, { invalid }) => (
                    <Input
                      variant="flushed"
                      type="text"
                      ref={ref}
                      name={renderName}
                      value={core.color}
                      placeholder={createCore.color.placeholder}
                      errorBorderColor="utils.alert"
                      onChange={(e) => {
                        onChange(e);
                        if (onChangeColor) {
                          onChangeColor(e);
                        }
                      }}
                      isInvalid={invalid}
                      isDisabled={false}
                    />
                  )}
                />
                {errors.color?.message && <FormErrorMessage>{errors.color?.message}</FormErrorMessage>}
              </FormControl>
            </Box>
          </Flex>
        </Box>
      </form>
      <Box className="spacer-for-firefox" h="6rem" w="100%" />
      <Box px="15%" py={4} position="absolute" bottom="0" left="0" w="calc(100% - 15px)">
        <Box position="absolute" top="0" left="0" right="0" bottom="0" bg="utils.background" opacity="0.7" />
        <WizardNav
          nextLabel={coreWizardResources.buttons.done}
          onNext={() => {
            handleSubmit(onSubmit, onError)();
          }}
          buttonSize="lg"
          buttonMaxWidth="320px"
          isNextLoading={false}
        />
      </Box>
    </Box>
  );
};
