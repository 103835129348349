import React, { useState, createContext, useContext } from 'react';

interface IProps {
  children: JSX.Element;
}

const LoaderContext = createContext<boolean>(false);
const LoaderActionsContext = createContext({} as React.Dispatch<React.SetStateAction<boolean>>);

export const useLoaderContext: () => boolean = () => useContext(LoaderContext);
export const useLoaderActionsContext: () => React.Dispatch<React.SetStateAction<boolean>> = () =>
  useContext(LoaderActionsContext);

export const LoaderContextProvider: React.FC<IProps> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <LoaderContext.Provider value={loading}>
      <LoaderActionsContext.Provider value={setLoading}>{children}</LoaderActionsContext.Provider>
    </LoaderContext.Provider>
  );
};
