import { ReportType } from 'src/model';

export const newModelWizardResources = {
  buttons: {
    next: 'Next',
    back: 'Back',
    save: 'Save',
    done: 'Done',
    add: 'Add',
    addNewOption: '+ Add option',
  },
  steps: {
    createModel: 'Create model',
    createVersion: 'Create version',
    modifyModel: 'Modify model',
    downloadScript: 'Download a script',
  },
  createModel: {
    status: {
      label: 'Status',
      placeholder: 'Pick new model status',
    },
    core: {
      label: 'Core',
    },
    modelName: {
      label: 'Model name',
      placeholder: 'Name visible to you',
      unique: 'Model with this name already exists',
    },
    endpoint: {
      label: 'Endpoint',
      placeholder: 'Name visible to clients',
      unique: 'Endpoint with this name already exists',
    },
    dependentVariable: {
      label: 'Dependent variable',
      placeholder: 'e.g. LD50[mg/kg bdwt]',
    },
    date: {
      label: 'Date',
    },
    propertyGroup: {
      label: 'Property group',
      placeholder: 'Pick property group',
    },
    priceOneNanoform: {
      label: 'Price - one nanoform',
    },
    priceManyNanoforms: {
      label: 'Price - many nanoforms',
    },
    reportType: {
      label: 'Report type',
      options: <ReportType[]>['QSAR', 'RA'],
    },
    order: {
      label: 'Order',
    },
  },
  createVersion: {
    version: {
      label: 'Version number',
    },
    author: {
      label: 'Author',
      placeholder: 'Name',
    },
    date: {
      label: 'Date',
    },
    qmrf: {
      label: 'Reference to QMRF',
      placeholder: 'Paste link here',
    },
    changeLog: {
      label: 'Change log',
    },
    attributes: {
      label: 'Use attributes:',
    },
    functionName: {
      label: 'Function name',
      placeholder: 'Name',
    },
  },
  createAttribute: {
    title: 'Add new attribute',
    name: {
      label: 'Attribute name',
      placeholder: 'Name',
    },
    label: {
      label: 'Label',
      placeholer: 'label',
    },
    type: {
      label: 'Attribute type',
      placeholer: 'Pick attribute type',
    },
  },
  downloadScript: {
    download: 'Download Azure function',
    doTheFollowing: 'Now do the following:',
    instructionList: [
      'Download the new model template we generated for you',
      'Put the folder from the template into „PredictionModels” in „nqtb-prediction” repository',
      'Add the script source file to the same folder and modify init.py file (add a script function call)',
      'Commit and push the new files to the repository',
    ],
    toasts: {
      error: {
        title: 'An error occurred',
        description: 'Function download failed',
      },
    },
  },
  toasts: {
    getModelGroupsError: {
      title: 'An error occurred',
      description: 'Data download failed',
    },
    saveModelError: {
      title: 'An error occurred',
    },
    saveModelSuccess: {
      title: 'Success',
      description: 'Model has been saved correctly',
    },
    saveModelVersionSuccess: {
      title: 'Success',
      description: "Model's version has been created correctly",
    },
    validationErrorToast: {
      title: '',
      description: 'Please fill in all required fields',
    },
  },
} as const;
