import React, { useState, createContext, useContext, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { userService, userConfig } from 'src/services';
import { RoleTypes, IUserActiveLicenseResponse, IUserActiveLicense, UserStatus } from 'src/model';
import { ROLE_CLAIM, licensesRoute } from 'src/helpers';

export interface IUserFromApi {
  id: string;
  companyName: string;
  companyAddress: string;
  organizationNumber: string;
  name: string;
  surname: string;
  isRequiredDataFilled: boolean;
  canPurchaseLicense: boolean;
  marketingConsents: boolean;
  status: UserStatus;
  email: string;
}

export interface IUserContextModel {
  auth0UserData: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  userDataFromApi: IUserFromApi;
  roles: RoleTypes[];
  licenseId: string;
  userActiveLicenseData: IUserActiveLicenseResponse;
  userActiveLicenses: IUserActiveLicense[];
}

export interface IUserActionsContext {
  setUserData: React.Dispatch<React.SetStateAction<IUserContextModel>>;
  fetchUserData: () => Promise<void>;
  fetchUserActiveLicenseData: () => void;
  fetchUserActiveLicenses: () => void;
  isAdmin: () => boolean;
}

interface IProps {
  children: JSX.Element;
}

const UserContext = createContext<IUserContextModel>({} as IUserContextModel);
const UserActionsContext = createContext({} as IUserActionsContext);

export const useUserContext: () => IUserContextModel = () => useContext(UserContext);
export const useUserActionsContext: () => IUserActionsContext = () => useContext(UserActionsContext);

export const UserContextProvider: React.FC<IProps> = ({ children }) => {
  const [userData, setUserData] = useState<IUserContextModel>({
    auth0UserData: {},
    userDataFromApi: {
      id: '',
      companyName: '',
      companyAddress: '',
      organizationNumber: '',
      name: '',
      surname: '',
      isRequiredDataFilled: true,
      canPurchaseLicense: false,
      marketingConsents: false,
      status: UserStatus.Active,
      email: '',
    },
    roles: [],
    wasModalDisplayed: false,
    licenseId: '',
    userActiveLicenseData: {} as IUserActiveLicenseResponse,
    userActiveLicenses: [],
  } as IUserContextModel);

  const { user, getAccessTokenSilently } = useAuth0();

  const history = useHistory();

  const isAdmin = (): boolean => (user[ROLE_CLAIM] as string[]).includes('Admin');

  const fetchUserActiveLicenses = async () => {
    const token = await getAccessTokenSilently();
    const userActiveLicenses: IUserActiveLicense[] = await userService
      .getUserActiveLicenses(user.sub, token)
      .then((response) => response.data);
    setUserData((prevState) => ({ ...prevState, userActiveLicenses }));
  };

  const fetchUserActiveLicenseData = useCallback(async () => {
    if (!user && !user.sub && !userData.licenseId) {
      return;
    }
    try {
      const token = await getAccessTokenSilently();
      const userActiveLicenseData: IUserActiveLicenseResponse = await userService
        .getUserActiveLicense(user.sub, userData.licenseId, token)
        .then((response) => response.data);

      userActiveLicenseData.modelGroups?.sort((a, b) => (a.order > b.order ? 1 : -1));
      userActiveLicenseData.modelGroups?.forEach((modelGroup) =>
        modelGroup.models.sort((a, b) => (a.order > b.order ? 1 : -1))
      );
      setUserData((prevState) => ({
        ...prevState,
        userActiveLicenseData,
      }));
    } catch (error) {
      console.error(error);
    }
  }, [getAccessTokenSilently, user, userData.licenseId]);

  const fetchUserData = useCallback(async () => {
    if (user && user.sub) {
      try {
        const token = await getAccessTokenSilently();

        const userDataFromApi = await userService.getUserById(user.sub, token).then((response) => response.data);
        await fetchUserActiveLicenses();

        const wasModalDisplayed = userConfig.modalDisplayedToUser(user.sub);
        if (!wasModalDisplayed) {
          userConfig.setModalDisplayedToUser(user.sub, JSON.stringify(false));
        }

        setUserData((prevState) => ({
          ...prevState,
          auth0UserData: user,
          userDataFromApi,
          roles: user[ROLE_CLAIM],
        }));

        if (!userData.userActiveLicenseData.isActive && !user[ROLE_CLAIM].includes('Admin')) {
          const userWasRedirectedToLicenses = userConfig.userRedirectedToLicenses();

          if (!userWasRedirectedToLicenses) {
            userConfig.setUserRedirectedToLicenses(JSON.stringify(true));
            history.push(licensesRoute.path);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, user, history, fetchUserActiveLicenseData, userData.userActiveLicenseData.isActive]);

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <UserContext.Provider value={userData}>
      <UserActionsContext.Provider
        value={{ setUserData, fetchUserData, fetchUserActiveLicenseData, fetchUserActiveLicenses, isAdmin }}
      >
        {children}
      </UserActionsContext.Provider>
    </UserContext.Provider>
  );
};
