import { Box, Flex, useTheme } from '@chakra-ui/react';
import type { Identifier, XYCoord } from 'dnd-core';
import React, { FC, useRef, useState } from 'react';
import { ReactComponent as DragIcon } from 'src/assets/drag.svg';
import { useDrag, useDrop } from 'react-dnd';

export const ItemTypes = {
  CONTAINER: 'container',
};

export interface DraggableItemProps {
  id: number | string;
  element: JSX.Element;
  index: number;
  error: string;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const DraggableItem: FC<DraggableItemProps> = ({ id, element, index, error, moveItem }) => {
  const {
    colors: { input },
  } = useTheme();

  const ref = useRef<HTMLDivElement>(null);
  const [draggable, setDraggable] = useState<boolean>(true);
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.CONTAINER,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveItem(dragIndex, hoverIndex);
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CONTAINER,
    item: () => {
      return { id, index };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <Box ref={ref} opacity={opacity} data-handler-id={handlerId} draggable={draggable}>
      <Flex
        bg="white"
        border="solid"
        borderWidth={error ? '2px' : '1px'}
        borderColor={error ? 'utils.alert' : input.border}
        borderRadius="0.35rem"
        paddingRight={1}
        marginY={2}
      >
        <Box
          userSelect="none"
          onMouseOver={() => setDraggable(false)}
          onMouseLeave={() => setDraggable(true)}
          width="100%"
          height="100%"
          my={0.75}
          mx={1}
        >
          {element}
        </Box>
        <Flex cursor="move" borderLeft="solid" borderLeftWidth="1px" borderColor={input.border}>
          <Box my="auto" onMouseOver={() => setDraggable(true)} mx="1rem">
            <DragIcon />
          </Box>
        </Flex>
      </Flex>
      {!!error && (
        <Box textColor="#E53E3E" fontSize="sm">
          {error}
        </Box>
      )}
    </Box>
  );
};
