import { IDynamicAttribute } from 'src/context';
import { ICore } from './coreResponse.model';

export type Shape = 'Spherical' | 'Cubic' | 'Polyhedral' | 'Rod' | 'Tube' | 'Wire' | 'Platelet' | 'Inconclusive';
export type NanoformAttributes =
  | 'Core'
  | 'Crystal structure'
  | 'Core modification'
  | 'Shape'
  | 'Particle size distribution'
  | 'Aspect ratio'
  | 'Surface area'
  | 'Surface functionalization';

export interface INanoForm {
  core: string | undefined;
  shape: Shape | undefined;
  dynamicAttributes: IDynamicAttribute[];
}

export interface INanoFormResponse {
  id: string;
  name: string;
  core: ICore;
  shape: Shape;
  attributes: AttributeValueDto[];
}

export interface AttributeValueDto {
  attributeDefinitionId: string;
  attributeType: string;
  attributeValue: string;
  valueType: string;
}

export enum ShapeParticleSizeDistributionSize {
  'Spherical' = '310px',
  'Cubic' = '352px',
  'Polyhedral' = '306px',
  'Platelet' = '352px',
  'Rod' = '127px',
  'Tube' = '127px',
  'Wire' = '67px',
  'Inconclusive' = '200px',
}

export const SurfaceFunctionalizationColor = '#718B9F';
