import { ReportType } from './modelResponse.model';

export enum LayoutNames {
  Content,
  Footer,
}

export interface ReportResponse {
  list: IReport[];
  hasNext: boolean;
  offset: number;
}

export interface IReport {
  id: string;
  userName: string;
  createdDate: Date;
  modifiedDate: Date;
  reportType: ReportType;
}
