import React from 'react';
import { Button, ButtonProps, MenuButtonProps, useTheme, MenuButton, Icon } from '@chakra-ui/react';
import { IoPersonOutline } from 'react-icons/io5';

interface IButton extends Omit<ButtonProps, 'children'> {
  children: React.ReactNode;
}

interface IMenuButton extends Omit<MenuButtonProps, 'children'> {
  children?: React.ReactNode;
}

export const PrimaryButton: React.FC<IButton> = ({ children, ...restProps }) => {
  const {
    colors: { button },
  } = useTheme();
  return (
    <Button
      variant="solid"
      color={button.white}
      bg={button.blue}
      _hover={{ bg: button.hoverPrimary }}
      _active={{ bg: button.hoverPrimary }}
      _disabled={{ bg: button.disabledPrimaryBg, color: button.disabledText, cursor: 'not-allowed' }}
      {...restProps}
    >
      {children}
    </Button>
  );
};

export const CancelButton: React.FC<IButton> = ({ children, ...restProps }) => {
  const {
    colors: { button },
  } = useTheme();
  return (
    <Button
      variant="solid"
      color={button.white}
      bg={button.red}
      _hover={{ bg: button.hoverCancel }}
      _active={{ bg: button.hoverCancel }}
      _disabled={{ bg: button.disabledPrimaryBg, color: button.disabledText, cursor: 'not-allowed' }}
      {...restProps}
    >
      {children}
    </Button>
  );
};

export const SecondaryButton: React.FC<IButton> = ({ children, ...restProps }) => {
  const {
    colors: { button },
  } = useTheme();
  return (
    <Button
      variant="outline"
      color={button.blue}
      borderColor={button.blue}
      borderWidth="2px"
      bg={button.white}
      _hover={{ bg: button.hoverSecondary }}
      _active={{ bg: button.hoverSecondary }}
      _disabled={{
        color: button.disabledSecondaryText,
        borderColor: button.disabledSecondaryColor,
        cursor: 'not-allowed',
      }}
      {...restProps}
    >
      {children}
    </Button>
  );
};

export const ProfileMenuButton: React.FC<IMenuButton> = ({ children, ...restProps }) => {
  const {
    colors: { button },
  } = useTheme();
  return (
    <MenuButton
      aria-label="Options"
      bg={button.blue}
      size="md"
      variant="solid"
      _hover={{ bg: button.hoverPrimary }}
      _active={{ bg: button.hoverPrimary }}
      borderRadius="50%"
      width="2.5rem"
      height="2.5rem"
      justifyContent="center"
      p={0}
      {...restProps}
    >
      <Icon as={IoPersonOutline} color={button.white} w="1.5rem" h="1.5rem" />
      {children}
    </MenuButton>
  );
};
