import { AxiosPromise, AxiosResponse } from 'axios';
import { ReportType } from 'src/model';
import { authorizedApiService } from './api.service';

class ReportService {
  getReports = (reportType: ReportType, token: string): AxiosPromise => {
    const apiUrl = `/reports?reportType=${reportType}`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  validateReportLayout = (fileName: string, fileData: string, token: string): AxiosPromise => {
    const apiUrl = `/reports/validate`;
    return authorizedApiService(token)
      .post(apiUrl, { fileName, fileData })
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  createReportLayout = (
    userId: string,
    contentFileData: string,
    footerFileData: string,
    reportType: ReportType,
    token: string
  ): AxiosPromise => {
    const apiUrl = `/reports`;
    return authorizedApiService(token)
      .post(apiUrl, { contentFileData, footerFileData, userId, reportType })
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  updateReportVersion = (
    reportId: string,
    userId: string,
    token: string,
    contentFileData?: string,
    footerFileData?: string
  ): AxiosPromise => {
    const apiUrl = `/reports/${encodeURI(reportId)}`;
    return authorizedApiService(token)
      .put(apiUrl, { contentFileData, footerFileData, userId })
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getContentFile = (id: string, token: string): AxiosPromise => {
    const apiUrl = `/reports/${encodeURI(id)}/content`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getFooterFile = (id: string, token: string): AxiosPromise => {
    const apiUrl = `/reports/${encodeURI(id)}/footer`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };
}

export const reportService = new ReportService();
