import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Box, Text } from '@chakra-ui/react';

interface IStepProps {
  isCompleted: boolean;
  isActive: boolean;
  stepNumber: number;
  title: string;
  isDisabled?: boolean;
  isAnimated?: boolean;
}

const ProgressVariants = {
  hidden: {
    scaleX: 0,
  },
  visible: {
    scaleX: 1,
    transition: {
      duration: 1,
    },
  },
};

const STEP_TEXT = 'Step';

export const Step: React.FC<IStepProps> = ({ isCompleted, isActive, stepNumber, title, isDisabled, isAnimated }) => (
  <Box
    className="step"
    flex="1"
    position="relative"
    sx={{
      padding: '15px 25px 15px',
      '&:first-of-type': {
        paddingLeft: 0,
      },
    }}
  >
    <Text
      className="step__number"
      fontSize="0.875rem"
      fontWeight="500"
      textTransform="uppercase"
      opacity={0.6}
    >{`${STEP_TEXT} ${stepNumber}`}</Text>
    <Text
      className="step__title"
      fontWeight="500"
      color={isActive ? 'typo.green' : 'typo.blue'}
      opacity={isActive || isCompleted ? 1 : 0.6}
    >
      {title}
    </Text>
    <AnimatePresence exitBeforeEnter>
      {(isActive || isCompleted) && !isDisabled && (
        <Box
          as={motion.div}
          variants={ProgressVariants}
          initial={isAnimated ? false : 'hidden'}
          animate="visible"
          exit="hidden"
          className="step__progress"
          position="absolute"
          left="0"
          bottom="0"
          w="100%"
          h="10px"
          bg="utils.greenGradient"
          transformOrigin="0 50%"
        />
      )}
      {isDisabled && (
        <Box
          className="step__progress"
          position="absolute"
          left="0"
          bottom="0"
          w="100%"
          h="10px"
          bg="button.blue"
          transformOrigin="0 50%"
        />
      )}
    </AnimatePresence>
  </Box>
);
