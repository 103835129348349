import React, { useState, createContext, useContext, useCallback } from 'react';
import { ReportTypeEnum } from 'src/model';

interface IProps {
  children: JSX.Element;
}

export interface IReportWizardContextModel {
  isWizardOpen: boolean;
  currentStep: number;
  reportType?: ReportTypeEnum;
  reportWasSaved: boolean;
  popupVisible: boolean;
  reportId: string;
  isTesting: boolean;
}

export interface IReportWizardActionsContextModel {
  onWizardOpen: () => void;
  onWizardClose: () => void;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setReportType: React.Dispatch<React.SetStateAction<ReportTypeEnum | undefined>>;
  setIsTesting: React.Dispatch<React.SetStateAction<boolean>>;
  setReportWasSaved: React.Dispatch<React.SetStateAction<boolean>>;
  setReportId: React.Dispatch<React.SetStateAction<string>>;
  handlePopupVisibility: () => void;
  reset: () => void;
}

const ReportWizardContext = createContext<IReportWizardContextModel>({} as IReportWizardContextModel);
const ReportWizardActionsContext = createContext({} as IReportWizardActionsContextModel);

export const useReportWizardContext: () => IReportWizardContextModel = () => useContext(ReportWizardContext);
export const useReportWizardActionsContext: () => IReportWizardActionsContextModel = () =>
  useContext(ReportWizardActionsContext);

export const ReportWizardContextProvider: React.FC<IProps> = ({ children }) => {
  const [isWizardOpen, setIsWizardOpen] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [reportType, setReportType] = useState<ReportTypeEnum>();
  const [reportWasSaved, setReportWasSaved] = useState<boolean>(false);
  const [popupVisible, setPopupVisible] = useState<boolean>(false);
  const [reportId, setReportId] = useState<string>('');
  const [isTesting, setIsTesting] = useState<boolean>(false);

  const reset = () => {
    setCurrentStep(1);
    setReportWasSaved(false);
    setReportId('');
    setIsTesting(false);
  };

  const onWizardOpen = useCallback(() => {
    document.body.style.overflowY = 'hidden';
    setIsWizardOpen(true);
  }, []);

  const onWizardClose = useCallback(() => {
    document.body.style.overflowY = 'auto';
    setIsWizardOpen(false);
  }, []);

  const handlePopupVisibility = () => {
    setPopupVisible(!popupVisible);
  };

  return (
    <ReportWizardContext.Provider
      value={{ isWizardOpen, currentStep, reportType, reportWasSaved, popupVisible, reportId, isTesting }}
    >
      <ReportWizardActionsContext.Provider
        value={{
          onWizardOpen,
          onWizardClose,
          setCurrentStep,
          setReportType,
          setIsTesting,
          setReportWasSaved,
          handlePopupVisibility,
          setReportId,
          reset,
        }}
      >
        {children}
      </ReportWizardActionsContext.Provider>
    </ReportWizardContext.Provider>
  );
};
