import React, { createContext, useContext } from 'react';
import { useDisclosure } from '@chakra-ui/react';

export interface IUserDataModalActionContext {
  onToggle: () => void;
  onOpen: () => void;
  onClose: () => void;
}

interface IProps {
  children: JSX.Element;
}

const UserDataModalContext = createContext<boolean>(false);
const UserDataModalActionsContext = createContext({} as IUserDataModalActionContext);

export const useUserDataModalContext: () => boolean = () => useContext(UserDataModalContext);
export const useUserDataModalActionsContext: () => IUserDataModalActionContext = () =>
  useContext(UserDataModalActionsContext);

export const UserDataModalContextProvider: React.FC<IProps> = ({ children }) => {
  const { isOpen, onToggle, onOpen, onClose } = useDisclosure();
  return (
    <UserDataModalContext.Provider value={isOpen}>
      <UserDataModalActionsContext.Provider value={{ onToggle, onOpen, onClose }}>
        {children}
      </UserDataModalActionsContext.Provider>
    </UserDataModalContext.Provider>
  );
};
