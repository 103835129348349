import { AxiosPromise, AxiosResponse } from 'axios';
import { IModel, LicenseTypes } from 'src/model';
import { authorizedApiService } from './api.service';

class LicenseService {
  getLicenseById = (licenseId: string, token: string): AxiosPromise => {
    const apiUrl = `/licenses/${licenseId}`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  createLicense = (models: IModel[], token: string, type?: LicenseTypes, receiverUserId?: string): AxiosPromise => {
    const apiUrl = `/licenses`;
    return authorizedApiService(token)
      .post(apiUrl, { type, modelIds: models.map((m) => m.id), receiverUserId })
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  expandLicense = (models: IModel[], deletedModels: IModel[], licenseId: string, token: string): AxiosPromise => {
    const apiUrl = `/licenses/expand`;
    return authorizedApiService(token)
      .post(apiUrl, {
        licenseId,
        addedModelIds: models.map((m) => m.id),
        deletedModelIds: deletedModels.map((m) => m.id),
      })
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  updateLicenseStatus = (licenseId: string, isDeactivated: boolean, token: string): AxiosPromise => {
    const apiUrl = `/licenses/status`;
    return authorizedApiService(token)
      .put(apiUrl, {
        licenseId,
        isDeactivated,
      })
      .catch((error: AxiosResponse) => Promise.reject(error));
  };
}

export const licenseService = new LicenseService();
