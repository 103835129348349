import React, { useEffect, useState } from 'react';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import dayjs from 'dayjs';
import { Box, Heading, Flex } from '@chakra-ui/react';
import { scrollbar, DATE_FORMAT, LicenseType } from 'src/helpers';
import { WizardNav } from 'src/components';
import { useNewExperimentWizardActionsContext, useUserContext, useNewExperimentWizardContext } from 'src/context';
import { useDocumentTitle } from 'src/hooks';
import { IUserActiveLicense } from 'src/model';
import { newExperimentWizardResources } from '../newExperimentWizardResources';

interface ILicenseInfo {
  userActiveLicense: IUserActiveLicense;
  mainTitle: string;
  subTitle: string;
  allEndpoints: boolean;
  allCores: boolean;
}

export const SelectLicense: React.FC = () => {
  useDocumentTitle(newExperimentWizardResources.steps.selectLicense);
  const {
    setChosenLicense,
    fetchUserChosenLicense,
    setAvailableCores,
    setAvailableModelGroups,
    setDemoCores,
    setActiveIndex,
  } = useNewExperimentWizardActionsContext();
  const { chosenLicense, activeIndex } = useNewExperimentWizardContext();
  const { selectLicense } = newExperimentWizardResources;
  const { userActiveLicenses } = useUserContext();
  const [licensesInfo, setLicensesInfo] = useState<ILicenseInfo[]>([]);

  const onNext = async () => {
    await fetchUserChosenLicense(chosenLicense.id);
  };

  const getLicenseType = (license: IUserActiveLicense) => {
    return LicenseType[license.type as keyof typeof LicenseType];
  };

  const checkIfAllEndpoints = (license: IUserActiveLicense) => {
    return license.type.toLocaleLowerCase().replaceAll(' ', '').replace('endpoints', 'models').includes('allmodels');
  };

  const checkIfAllCores = (license: IUserActiveLicense) => {
    return license.type.toLocaleLowerCase().replaceAll(' ', '').includes('allsubstances');
  };

  const limitListSize = (strings: string[]): string => {
    const characterLimit = 40;
    const joinedStrings = strings.join(', ');
    if (joinedStrings.length < characterLimit) return joinedStrings;

    let totalLength = 0;
    let index = -1;
    const visibleStrings: string[] = [];
    for (let i = 0; i < strings.length; i += 1) {
      if (totalLength + strings[i].length >= characterLimit) break;
      visibleStrings.push(strings[i]);
      totalLength += strings[i].length;
      index = i;
    }

    const slicedStrings = strings.slice(0, index + 1);
    if (strings.length - slicedStrings.length === 0) {
      return `${slicedStrings.join(', ')}`;
    }

    return `${slicedStrings.join(', ')} and ${strings.length - slicedStrings.length} more.`;
  };

  const onLicenseClick = (index: number, licenseId: string) => {
    if (activeIndex === index) {
      setActiveIndex(-1);
      setChosenLicense({ id: '', type: '', cores: [], models: [], predictionAttemptsCount: 0 });
      return;
    }
    setActiveIndex(index);
    const selectedLicense = userActiveLicenses.find((l) => l.id === licenseId);
    if (!selectedLicense) {
      setChosenLicense({ id: '', type: '', cores: [], models: [], predictionAttemptsCount: 0 });
      return;
    }

    setChosenLicense(selectedLicense);
  };

  useEffect(() => {
    setAvailableCores([]);
    setDemoCores([]);
    setAvailableModelGroups([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!userActiveLicenses) return;

    const newLicensesInfo: ILicenseInfo[] = userActiveLicenses.map<ILicenseInfo>((l) => {
      return {
        userActiveLicense: l,
        mainTitle: getLicenseType(l).main,
        subTitle: getLicenseType(l).sub,
        allEndpoints: checkIfAllEndpoints(l),
        allCores: checkIfAllCores(l),
      };
    });
    setLicensesInfo(newLicensesInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userActiveLicenses]);

  return (
    <Box px={10} py={20} h="100%" overflowY="auto" sx={scrollbar}>
      <Heading fontSize="2xl" fontWeight="bold" mb={4}>
        {selectLicense.title}
      </Heading>
      {licensesInfo &&
        licensesInfo.map((license, index) => (
          <Flex
            key={license.userActiveLicense.id}
            flexDirection="column"
            border="solid"
            borderColor={activeIndex === index ? 'typo.green' : 'input.border'}
            borderWidth={activeIndex === index ? 2 : 1}
            position="relative"
            bg="white"
            mt={4}
            padding={activeIndex === index ? '15px' : '16px'}
            cursor="pointer"
            rounded="md"
            userSelect="none"
            transition="border-color 100ms linear"
            onClick={() => onLicenseClick(index, license.userActiveLicense.id)}
          >
            <Flex>
              <Flex flexDirection="column">
                <Box fontSize="large" fontWeight="semibold">
                  {license.mainTitle}
                </Box>
                <Box fontSize="md">{license.subTitle}</Box>
              </Flex>
              <Box
                textColor="typo.green"
                marginY="auto"
                marginLeft="auto"
                fontSize="2xl"
                opacity={activeIndex === index ? 1 : 0}
                transition="opacity 75ms linear"
              >
                <IoCheckmarkCircleSharp />
              </Box>
            </Flex>
            {license.userActiveLicense.cores && (
              <Box marginTop={4}>
                {license.allCores && index !== activeIndex
                  ? limitListSize(license.userActiveLicense.cores)
                  : license.userActiveLicense.cores.join(', ')}
              </Box>
            )}
            {license.userActiveLicense.models && (
              <Box marginTop={4}>
                {license.allEndpoints && index !== activeIndex
                  ? limitListSize(license.userActiveLicense.models)
                  : license.userActiveLicense.models.join(', ')}
              </Box>
            )}
            <Flex width="full" marginTop={4}>
              {license.userActiveLicense.purchasedDate && (
                <Box>
                  <Box textColor="gray" fontSize="large">
                    Purchased
                  </Box>
                  <Box>{dayjs(license.userActiveLicense.purchasedDate).format(DATE_FORMAT)}</Box>
                </Box>
              )}
              {license.userActiveLicense.expiredDate && (
                <Box marginX="auto">
                  <Box textColor="gray" fontSize="large">
                    Expiring
                  </Box>
                  <Box>{dayjs(license.userActiveLicense.expiredDate).format(DATE_FORMAT)}</Box>
                </Box>
              )}
            </Flex>
          </Flex>
        ))}
      <Box position="absolute" w="100%" p={5} left="0" bottom="0">
        <WizardNav nextLabel="Next" onNext={onNext} nextDisableCondition={!chosenLicense.type} />
      </Box>
    </Box>
  );
};
