import * as yup from 'yup';
import { HEX_REGEX } from 'src/helpers';
import {
  maxLengthValidationText,
  minLengthValidationText,
  requiredValidationText,
  hexPatternValidationText,
} from 'src/utils';
import { coreWizardResources } from 'src/components';

export const createEditCoreYupSchema = (): yup.AnyObjectSchema =>
  yup.object().shape({
    name: yup
      .string()
      .max(50, ({ max }) => maxLengthValidationText(max))
      .required(({ label }) => requiredValidationText(label))
      .label(coreWizardResources.createCore.name.label),
    color: yup
      .string()
      .matches(HEX_REGEX, { message: () => hexPatternValidationText })
      .min(4, ({ min }) => minLengthValidationText(min))
      .max(8, ({ max }) => maxLengthValidationText(max))
      .required(({ label }) => requiredValidationText(label))
      .label(coreWizardResources.createCore.color.label),
  });
