import { useToast } from '@chakra-ui/react';

export const useErrorToast = (
  title: string,
  description: string,
  duration = 4000,
  id?: string
): ReturnType<typeof useToast> => {
  const errorToast = useToast({
    id,
    title,
    description,
    status: 'error',
    variant: 'solid',
    duration,
    position: 'top-right',
    isClosable: true,
  });

  return errorToast;
};
