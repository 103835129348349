import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Heading,
  ModalCloseButton,
  ModalBody,
  Image,
} from '@chakra-ui/react';
import { useLoaderContext } from 'src/context';

interface IImageModalProps {
  isOpen: boolean;
  closeModal: () => void;
  imageUrl: string;
  nanoformName: string;
}

export const ImageModal: React.FC<IImageModalProps> = ({ isOpen, closeModal, imageUrl, nanoformName }) => {
  const isLoading = useLoaderContext();

  return (
    <Modal isOpen={isOpen} onClose={closeModal} isCentered size="5xl" autoFocus={false}>
      <ModalOverlay bg="utils.modalOverlay" />
      <ModalContent borderRadius="0.25rem" bg="custom.aliceBlue" borderWidth="1px" borderColor="utils.border">
        <ModalHeader px={8} pt={8} pb={0}>
          <Heading size="lg">{nanoformName}</Heading>
          <ModalCloseButton mt={7} mr={7} colorScheme="brand" size="md" onClick={closeModal} disabled={isLoading} />
        </ModalHeader>
        <ModalBody p={8}>
          <Image
            src={imageUrl}
            objectFit="cover"
            w="100%"
            alt="Nanoform visualization"
            borderStyle="solid"
            borderWidth="1px"
            borderColor="custom.glaceous"
            cursor="pointer"
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
