import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { Table, Tbody, Box, Tr, Badge, Text } from '@chakra-ui/react';
import { TableHeader, TableData, EmptyListState } from 'src/components';
import { DATE_FORMAT, usersViewResources, adminUserLicensesEmptyList } from 'src/helpers';
import { User, UserStatus } from 'src/model';
import { useManageLicensesActionsContext } from 'src/context';

interface IUsersListProps {
  data: User[];
  getMoreData: () => void;
  hasMore: boolean;
}

export const UsersList: React.FC<IUsersListProps> = ({ data, getMoreData, hasMore }) => {
  const history = useHistory();

  const { setUserInfo, resetUserInfo } = useManageLicensesActionsContext();

  const handleOnUserClicked = (user: User) => {
    setUserInfo({ userId: user.id, status: user.status, email: user.email, role: 'User' });
    history.push(`/manageLicenses/${user.id}`);
  };

  useEffect(() => {
    resetUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="users-list" w="100%">
      {data.length ? (
        <InfiniteScroll pageStart={0} hasMore={hasMore} loadMore={getMoreData} useWindow>
          <Table
            variant="unstyled"
            w="100%"
            h="100%"
            sx={{ borderCollapse: 'separate', borderSpacing: '0 10px', tableLayout: 'fixed' }}
            cellSpacing="0"
          >
            <TableHeader
              titles={[
                'Company',
                'Company address',
                'Organization number',
                'E-mail',
                'Created date',
                'Active license',
                'Account Status',
              ]}
            />
            <Tbody>
              {data.map((user: User) => (
                <Tr key={`user-${user.id}`} cursor="pointer" onClick={() => handleOnUserClicked(user)}>
                  <TableData flagColor={user.hasActiveLicense ? 'utils.greenGradient' : undefined}>
                    <Text fontWeight="700" fontSize="1.125rem">
                      {user.companyName ?? '-'}
                    </Text>
                  </TableData>
                  <TableData>
                    <Text>{user.companyAddress ?? '-'}</Text>
                  </TableData>
                  <TableData>
                    <Text>{user.organizationNumber ?? '-'}</Text>
                  </TableData>
                  <TableData>
                    <Text>{user.email ?? '-'}</Text>
                  </TableData>
                  <TableData>{user.createdDate == null ? '-' : dayjs(user.createdDate).format(DATE_FORMAT)}</TableData>
                  <TableData>
                    {user.hasActiveLicense ? (
                      <Badge colorScheme="green" py={1} px="12px" fontSize="md" color="typo.green" fontWeight="500">
                        {usersViewResources.yes}
                      </Badge>
                    ) : (
                      <Badge colorScheme="red" py={1} px="12px" fontSize="md" color="utils.alert" fontWeight="500">
                        {usersViewResources.no}
                      </Badge>
                    )}
                  </TableData>
                  <TableData>
                    {user.status === UserStatus.Active ? (
                      <Badge colorScheme="green" py={1} px="12px" fontSize="md" color="typo.green" fontWeight="500">
                        {UserStatus.Active}
                      </Badge>
                    ) : (
                      <Badge colorScheme="red" py={1} px="12px" fontSize="md" color="utils.alert" fontWeight="500">
                        {UserStatus.Blocked}
                      </Badge>
                    )}
                  </TableData>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </InfiniteScroll>
      ) : (
        <EmptyListState title={adminUserLicensesEmptyList} />
      )}
    </Box>
  );
};
