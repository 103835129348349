import React, { FC } from 'react';
import { Box, Flex, HStack } from '@chakra-ui/react';
import { ReactComponent as CloseIcon } from 'src/assets/close.svg';
import { PrimaryButton, CancelButton, SecondaryButton } from 'src/components/common';

export interface IPopupProps {
  iconSrc?: string;
  problemMessage: string;
  buttonMessage: string;
  solveMessage: string;
  buttonType?: 'primary' | 'cancel';
  canClose?: boolean;
  isBackButtonVisible?: boolean;
  setVisibility(isVisible: boolean): void;
  onButtonClick(): void;
}

export const Popup: FC<IPopupProps> = ({
  iconSrc,
  problemMessage,
  buttonMessage,
  solveMessage,
  buttonType = 'primary',
  canClose = true,
  isBackButtonVisible = false,
  setVisibility,
  onButtonClick,
}) => {
  return (
    <Flex width="full" height="100%" position="fixed" bg="rgba(0,0,0,0)" left={0} top={0} flexDirection="column">
      <Box width={5 / 20} textAlign="center" position="static" my="auto" mx="auto" boxShadow="xl">
        <Box
          width="100%"
          bg="#EDF2F7"
          px={6}
          borderRadius="0.25rem"
          borderWidth="1px"
          borderColor="input.border"
          borderStyle="solid"
        >
          {canClose && (
            <Flex mr={-5}>
              <Box ml="auto" onClick={() => setVisibility(false)} cursor="pointer" my="auto">
                <CloseIcon />
              </Box>
            </Flex>
          )}
          {!canClose && <Box my={8} />}
          <Flex>
            <Box mx="auto" mb={4}>
              {iconSrc && (
                <Flex>
                  <Box mx="auto" mt={4} mb={4}>
                    <img src={iconSrc} alt="" />
                  </Box>
                </Flex>
              )}
              <Box fontSize="2xl" fontWeight="bold" mx={4} mb={4}>
                {problemMessage}
              </Box>
              <Box mx={10} mb={8}>
                {solveMessage}
              </Box>
              <HStack mt={6} mb={4}>
                {isBackButtonVisible && (
                  <SecondaryButton ml="auto" onClick={() => setVisibility(false)}>
                    Back
                  </SecondaryButton>
                )}
                {buttonType === 'primary' ? (
                  <PrimaryButton
                    minW={isBackButtonVisible ? '' : '50%'}
                    mx={isBackButtonVisible ? 0 : 'auto'}
                    onClick={() => onButtonClick()}
                  >
                    {buttonMessage}
                  </PrimaryButton>
                ) : (
                  <CancelButton mx={isBackButtonVisible ? 0 : 'auto'} onClick={() => onButtonClick()}>
                    {buttonMessage}
                  </CancelButton>
                )}
              </HStack>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};
