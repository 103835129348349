import React, { FC, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Flex, Heading, TabList, Tab, TabPanels, TabPanel, Tabs } from '@chakra-ui/react';
import { PrimaryButton, ReportsList, Spinner, EditReportPopupMessage } from 'src/components';
import { useDocumentTitle } from 'src/hooks';
import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { manageLayoutsResources } from 'src/helpers';
import { AnimatePresence } from 'framer-motion';
import { ReportTypeEnum } from 'src/model';
import { reportService } from 'src/services';
import { useReportWizardActionsContext, useReportWizardContext } from 'src/context';
import { ReportsWizard } from 'src/components/reports/ReportsWizard';

export const ManageReportLayouts: FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const changeDocumentTitle = useDocumentTitle(manageLayoutsResources.documentTitle);

  const { onWizardOpen, reset, setReportType } = useReportWizardActionsContext();
  const { isWizardOpen, reportType, reportWasSaved, popupVisible } = useReportWizardContext();

  const fetchReports = async (type: ReportTypeEnum) => {
    const token = await getAccessTokenSilently();
    return reportService.getReports(type, token).then((response: AxiosResponse) => response.data);
  };

  const {
    data: qsarReports,
    isLoading: qsarIsLoading,
    isError: qsarIsError,
    refetch: qsarRefetch,
  } = useQuery(`reports-${ReportTypeEnum.QSAR}`, () => fetchReports(ReportTypeEnum.QSAR));

  const {
    data: raReports,
    isLoading: raIsLoading,
    isError: raIsError,
    refetch: raRefetch,
  } = useQuery(`reports-${ReportTypeEnum.RA}`, () => fetchReports(ReportTypeEnum.RA));

  useEffect(() => {
    if (!reportWasSaved || !reportType) return;

    if (reportType === ReportTypeEnum.QSAR) {
      qsarRefetch();
    } else {
      raRefetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportWasSaved]);

  useEffect(
    () => () => {
      reset();
      setReportType(undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getActiveTabIndex = (): number => {
    if (!reportType) return 0;
    return reportType === ReportTypeEnum.RA ? 1 : 0;
  };

  return (
    <>
      {popupVisible && <EditReportPopupMessage />}
      <Flex
        className="manage-layouts"
        w="100%"
        minH="100%"
        overflowY="auto"
        id="manage-layouts"
        p={10}
        flexDirection="column"
      >
        <Flex as="header" className="layouts__header" justifyContent="space-between" alignItems="center" pb={16}>
          <Heading className="header__title" fontWeight="700" size="xl" pl={4}>
            {manageLayoutsResources.heading}
          </Heading>
          <AnimatePresence
            exitBeforeEnter
            onExitComplete={() => {
              reset();
              changeDocumentTitle(manageLayoutsResources.documentTitle);
            }}
          >
            {isWizardOpen && <ReportsWizard />}
          </AnimatePresence>
          <Flex pr={4}>
            <PrimaryButton
              size="sm"
              onClick={() => {
                onWizardOpen();
                setReportType(ReportTypeEnum.RA);
              }}
            >
              {manageLayoutsResources.newRaReport}
            </PrimaryButton>
            <PrimaryButton
              ml={4}
              size="sm"
              onClick={() => {
                onWizardOpen();
                setReportType(ReportTypeEnum.QSAR);
              }}
            >
              {manageLayoutsResources.newQsarReport}
            </PrimaryButton>
          </Flex>
        </Flex>
        <Flex flexDirection="column" flex={1}>
          <Tabs variant="unstyled" index={getActiveTabIndex()}>
            <TabList>
              <Tab
                _selected={{ fontWeight: '650' }}
                _focus={{ boxShadow: 'none' }}
                transition="none"
                mr={4}
                onClick={() => setReportType(ReportTypeEnum.QSAR)}
              >
                <Heading className="header__title" fontWeight="inherit" size="lg" pb={8}>
                  {ReportTypeEnum.QSAR} reports
                </Heading>
              </Tab>
              <Tab
                _selected={{ fontWeight: '650' }}
                _focus={{ boxShadow: 'none' }}
                transition="none"
                onClick={() => setReportType(ReportTypeEnum.RA)}
              >
                <Heading className="header__title" fontWeight="inherit" size="lg" pb={8}>
                  {ReportTypeEnum.RA} reports
                </Heading>
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Flex>
                  {qsarReports && !qsarIsError && <ReportsList data={qsarReports} />}
                  {(!qsarReports || qsarIsLoading) && (
                    <Spinner thickness="8px" speed="1s" size="xl" w="100px" h="100px" mx="auto" my="auto" />
                  )}
                </Flex>
              </TabPanel>
              <TabPanel>
                <Flex>
                  {raReports && !raIsError && <ReportsList data={raReports} />}
                  {(!raReports || raIsLoading) && (
                    <Spinner thickness="8px" speed="1s" size="xl" w="100px" h="100px" mx="auto" my="auto" />
                  )}
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </Flex>
    </>
  );
};
