import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Heading,
  ModalCloseButton,
  ModalBody,
  Flex,
  Text,
} from '@chakra-ui/react';
import { SecondaryButton, CancelButton } from 'src/components';
import { useLoaderContext } from 'src/context';
import { deletePredictionModalResources } from './deletePredictionModalResources';

interface IDeletePredictionModalProps {
  isOpen: boolean;
  closeModal: () => void;
  modalAction: () => void;
}

export const DeletePredictionModal: React.FC<IDeletePredictionModalProps> = ({ isOpen, closeModal, modalAction }) => {
  const { title, secondaryButtonText, actionButtonText, content } = deletePredictionModalResources;
  const isLoading = useLoaderContext();

  return (
    <Modal isOpen={isOpen} onClose={closeModal} isCentered size="3xl" autoFocus={false}>
      <ModalOverlay bg="utils.modalOverlay" />
      <ModalContent borderRadius="0.25rem" bg="custom.aliceBlue" borderWidth="1px" borderColor="utils.border">
        <ModalHeader px={8} pt={8} pb={4}>
          <Heading size="xl">{title}</Heading>
          <ModalCloseButton mt={7} mr={7} colorScheme="brand" size="md" onClick={closeModal} disabled={isLoading} />
        </ModalHeader>
        <ModalBody p={8}>
          <Text fontWeight="500" whiteSpace="pre">
            {content}
          </Text>
          <Flex w="100%" justifyContent="flex-end" pt={12}>
            <SecondaryButton mr={4} onClick={closeModal} disabled={isLoading}>
              {secondaryButtonText}
            </SecondaryButton>
            <CancelButton onClick={modalAction} isLoading={isLoading}>
              {actionButtonText}
            </CancelButton>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
