import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { ReactComponent as CloseIcon } from 'src/assets/close.svg';
import { PrimaryButton, CancelButton } from 'src/components/common';

export interface ISaveChangesPopupProps {
  handleVisibility(): void;
  handleResult(confirm?: boolean): Promise<void>;
}

export const SaveChangesPopup: FC<ISaveChangesPopupProps> = ({ handleVisibility, handleResult }) => {
  const onConfirm = () => {
    handleVisibility();
    handleResult(true);
  };

  const onClose = () => {
    handleVisibility();
  };

  return (
    <Flex
      width="full"
      minHeight="full"
      height="fit-content"
      position="fixed"
      bg="rgba(0,0,0,0.5)"
      left={0}
      top={0}
      zIndex="popover"
    >
      <Box marginX="auto" marginY="auto" width={5 / 20} textAlign="center" rounded="full" boxShadow="xl">
        <Box width="100%" bg="#EDF2F7" px={6} py={6}>
          <Flex marginBottom={2}>
            <Box marginLeft="auto" onClick={() => onClose()} cursor="pointer" marginY="auto">
              <CloseIcon />
            </Box>
          </Flex>
          <Flex>
            <Flex marginX="auto" flexDir="column" mx={6}>
              <Box fontSize="2xl" fontWeight="bold" marginY={4}>
                License status will be changed to active
              </Box>
              <Box>There are still some endpoints waiting for payment. If you save changes license will be active</Box>
              <Flex marginTop={6} marginBottom={4} mx="auto" width="100%">
                <CancelButton mr="3" ml="auto" onClick={() => onClose()} w="50%">
                  Cancel
                </CancelButton>
                <PrimaryButton ml="3" mr="auto" onClick={() => onConfirm()} w="50%">
                  Save Changes
                </PrimaryButton>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};
