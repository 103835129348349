import { Shape } from 'src/model';
import { ReactComponent as SphericalIcon } from 'src/assets/shapes/spherical.svg';
import { ReactComponent as CubicIcon } from 'src/assets/shapes/cubic.svg';
import { ReactComponent as PolyhedralIcon } from 'src/assets/shapes/polyhedral.svg';
import { ReactComponent as RodIcon } from 'src/assets/shapes/rod.svg';
import { ReactComponent as TubeIcon } from 'src/assets/shapes/tube.svg';
import { ReactComponent as WiresIcon } from 'src/assets/shapes/wires.svg';
import { ReactComponent as PlateletIcon } from 'src/assets/shapes/platelet.svg';
import { ReactComponent as InconclusiveIcon } from 'src/assets/shapes/inconclusive.svg';

export const newExperimentWizardResources = {
  steps: {
    selectLicense: 'Select license',
    selectSubstance: 'Select substance',
    selectEndpoints: 'Select endpoints',
    defineNanoform: 'Define nanoform',
  },
  selectLicense: {
    title: 'Choose license type',
  },
  selectSubstance: {
    core: {
      label: 'Core',
    },
    researchName: {
      label: 'Prediction name',
      placeholder: 'Name',
    },
    showOnlyFreeOfChargeModels: 'Show only free of charge models',
    tryAgainLaterPopup: {
      titleMessage: 'Try again later',
      descriptionMessage:
        'Unfortunately, one of the models you have the license for has been temporarily deactivated as we`re currently improving it. You cannot perform the prediction now. We`re sorry for the inconvenience',
      buttonMessage: 'OK',
    },
  },
  selectEndpoints: {
    title: 'Endpoints',
    humanToxicity: 'Human toxicity',
    environmental: 'Environmental',
    physicochemicalProperties: 'Physicochemical properties',
    showOnlyFreeOfChargeModels: 'Show only free of charge models',
    noDemoModelsAvailable: 'No free models are available for this configuration.',
  },
  defineNanoform: {
    savedNanoform: {
      title: 'Use previously defined nanoform?',
      buttonText: 'Choose',
    },
    shapes: {
      title: 'Shape',
      options: {
        spheroidal: {
          title: 'Spheroidal',
          options: <
            {
              shape: Shape;
              icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
            }[]
          >[
            { shape: 'Spherical', icon: SphericalIcon },
            { shape: 'Cubic', icon: CubicIcon },
            { shape: 'Polyhedral', icon: PolyhedralIcon },
          ],
        },
        elongated: {
          title: 'Elongated',
          options: <
            {
              shape: Shape;
              icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
            }[]
          >[
            { shape: 'Rod', icon: RodIcon },
            { shape: 'Tube', icon: TubeIcon },
            { shape: 'Wire', icon: WiresIcon },
          ],
        },
        platelet: {
          title: 'Platelet',
          options: <
            {
              shape: Shape;
              icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
            }[]
          >[{ shape: 'Platelet', icon: PlateletIcon }],
        },
        other: {
          title: 'Other',
          options: <
            {
              shape: Shape;
              icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
            }[]
          >[{ shape: 'Inconclusive', icon: InconclusiveIcon }],
        },
      },
    },
    toasts: {
      nanoformNamesErrorToast: {
        title: 'An error occurred',
        description: 'Getting saved nanoforms failed',
      },
      nanoformErrorToast: {
        title: 'An error occurred',
        description: 'Setting nanoform failed',
      },
      validationErrorToast: {
        title: '',
        description: 'Please fill in all required fields',
      },
    },
  },
  licensePopup: {
    problemMessage: 'Ooops... you can`t continue without active license',
    solveMessage: 'You are just one step away from getting your prediction results',
    buttonMessage: 'Explore license types',
  },
  saveNanoform: {
    toasts: {
      saveNanoformSuccess: {
        title: 'Success',
        description: 'Nanoform has been saved correctly',
      },
    },
    saveText: 'Save nanoform',
  },
} as const;
