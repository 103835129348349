import React from 'react';
import { Box, useRadio } from '@chakra-ui/react';

export const RadioOption: React.FC = ({ children, ...restProps }) => {
  const { getInputProps, getCheckboxProps } = useRadio(restProps);

  const input = getInputProps();
  const checkbox = getCheckboxProps();
  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        color="typo.blue"
        fontWeight="700"
        bg="radio.bg"
        borderWidth="0.5px"
        borderRadius="0.35rem"
        borderColor="radio.border"
        _checked={{
          bg: 'utils.greenGradient',
          color: 'white',
        }}
        minWidth="128px"
        px={5}
        py={2}
      >
        {children}
      </Box>
    </Box>
  );
};
