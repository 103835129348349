import React, { FC, useCallback } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';

interface IFileDropzoneProps {
  id: string;
  onUpload: (fileName: string, fileData: string) => void;
}

export const FileDropzone: FC<IFileDropzoneProps> = ({ onUpload, id }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      const { name } = file;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result) {
          onUpload(name, reader.result.toString());
        }
      };
    },
    [onUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/html': ['.html', '.htm'],
    },
  });

  return (
    <div {...getRootProps()}>
      <Flex
        border="dashed 2px"
        borderColor="#E3E2E2"
        minH="180px"
        w="full"
        px={16}
        py={14}
        bgColor="white"
        cursor="pointer"
      >
        <Box my="auto" mx="auto" textAlign="center">
          {isDragActive ? (
            <Text textColor="#179744">drop files here</Text>
          ) : (
            <>
              <Text>Drag and drop your .html files or</Text>
              <Text textColor="#179744">click to browse</Text>
            </>
          )}
          <input
            id={id}
            type="file"
            style={{ display: 'none' }}
            multiple={false}
            accept=".html"
            value=""
            {...getInputProps()}
          />
        </Box>
      </Flex>
    </div>
  );
};
