export enum UserStatus {
  Active = 'Active',
  Blocked = 'Blocked',
}

export interface UsersResponse {
  list: User[];
  hasNext: boolean;
  offset: number;
}

export interface User {
  id: string;
  companyName: string;
  companyAddress: string;
  email: string;
  createdDate: string;
  hasActiveLicense: boolean;
  organizationNumber: string;
  status: UserStatus;
}
