import React, { useState } from 'react';
import { Flex, List, ListItem, Text, Heading, Box } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { downloadFile, getFilenameFromHeader } from 'src/helpers';
import { modelService } from 'src/services';
import { PrimaryButton, WizardNav } from 'src/components';
import { useDocumentTitle } from 'src/hooks';
import { newModelWizardResources } from './newModelWizardResources';

interface IDownloadScriptProps {
  modelId: string;
  versionId: string;
  onDone: () => void;
}

export const DownloadScript: React.FC<IDownloadScriptProps> = ({ modelId, versionId, onDone }) => {
  useDocumentTitle(newModelWizardResources.steps.downloadScript);

  const { downloadScript } = newModelWizardResources;
  const [isLoading, setLoading] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth0();

  const downloadFunction = async () => {
    setLoading(true);

    try {
      const token = await getAccessTokenSilently();

      const getConfigurationFiles = await modelService.getConfigurationFiles(modelId, versionId, token);

      const contentDispositionHeader = getConfigurationFiles.headers['content-disposition'];

      const filename = getFilenameFromHeader(contentDispositionHeader);

      await downloadFile(getConfigurationFiles, `${filename}.zip`);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex flexDirection="column" h="100%">
      <Flex px="15%" direction="column" flex="1" justifyContent="center">
        {downloadScript.instructionList && (
          <Flex direction="column">
            <Heading fontSize="2xl" fontWeight="bold">
              {downloadScript.doTheFollowing}
            </Heading>
            <Flex mb={8} mt={8} alignItems="flex-end">
              <List className="create_model_upload_script__list" mt="auto">
                {downloadScript.instructionList.map((item: string, index: number) => (
                  <ListItem
                    color="custom.glaucaus"
                    fontWeight="500"
                    display="flex"
                    key={`create_model_upload_script_list_${item}`}
                  >
                    <Text>{`${index + 1}) ${item}`}</Text>
                  </ListItem>
                ))}
              </List>
            </Flex>
          </Flex>
        )}
        <Flex>
          <PrimaryButton isLoading={isLoading} flex={1} maxW="250px" onClick={() => downloadFunction()}>
            {downloadScript.download}
          </PrimaryButton>
        </Flex>
      </Flex>
      <Box px="15%" py={16} position="absolute" bottom="0" left="0" w="calc(100% - 15px)">
        <Box position="absolute" top="0" left="0" right="0" bottom="0" bg="utils.background" opacity="0.7" />
        <WizardNav
          nextLabel={newModelWizardResources.buttons.done}
          onNext={() => onDone()}
          buttonSize="lg"
          buttonMaxWidth="320px"
        />
      </Box>
    </Flex>
  );
};
