import * as yup from 'yup';
import { newModelWizardResources } from 'src/components';
import { maxLengthValidationText, requiredValidationText } from 'src/utils';

export const createModelVersionYupSchema = (): yup.AnyObjectSchema =>
  yup.object().shape({
    author: yup
      .string()
      .max(100, ({ max }) => maxLengthValidationText(max))
      .required(({ label }) => requiredValidationText(label))
      .label(newModelWizardResources.createVersion.author.label),
    functionName: yup
      .string()
      .max(100, ({ max }) => maxLengthValidationText(max))
      .required(({ label }) => requiredValidationText(label))
      .label(newModelWizardResources.createVersion.functionName.label),
    changeLog: yup.string(),
    qmrfReference: yup.string(),
  });
