import React, { useState, createContext, useContext, useCallback } from 'react';
import { ICore } from 'src/model';

interface IProps {
  children: JSX.Element;
}

export interface ICoreWizardContextModel {
  isWizardOpen: boolean;
  currentStep: number;
  core: ICore;
  coresList: ICore[];
  initialCoreStage: ICore;
}

export interface ICoreWizardActionsContextModel {
  onWizardOpen: () => void;
  onWizardClose: () => void;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setCore: React.Dispatch<React.SetStateAction<ICore>>;
  setCoresList: React.Dispatch<React.SetStateAction<ICore[]>>;
  reset: () => void;
}

const initialCoreStage: ICore = {
  id: '',
  name: '',
  color: '#ffffff',
};

const CoreWizardContext = createContext<ICoreWizardContextModel>({} as ICoreWizardContextModel);
const CoreWizardActionsContext = createContext({} as ICoreWizardActionsContextModel);

export const useCoreWizardContext: () => ICoreWizardContextModel = () => useContext(CoreWizardContext);
export const useCoreWizardActionsContext: () => ICoreWizardActionsContextModel = () =>
  useContext(CoreWizardActionsContext);

export const CoreWizardContextProvider: React.FC<IProps> = ({ children }) => {
  const [isWizardOpen, setIsWizardOpen] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [core, setCore] = useState<ICore>(initialCoreStage);
  const [coresList, setCoresList] = useState<ICore[]>([]);

  const reset = () => {
    setCore(initialCoreStage);
    setCurrentStep(1);
  };

  const onWizardOpen = useCallback(() => {
    document.body.style.overflowY = 'hidden';
    setIsWizardOpen(true);
  }, []);

  const onWizardClose = useCallback(() => {
    document.body.style.overflowY = 'auto';
    setIsWizardOpen(false);
  }, []);

  return (
    <CoreWizardContext.Provider value={{ isWizardOpen, currentStep, core, coresList, initialCoreStage }}>
      <CoreWizardActionsContext.Provider
        value={{
          onWizardOpen,
          onWizardClose,
          setCurrentStep,
          setCore,
          setCoresList,
          reset,
        }}
      >
        {children}
      </CoreWizardActionsContext.Provider>
    </CoreWizardContext.Provider>
  );
};
