import React from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, MenuList, MenuItem, useTheme } from '@chakra-ui/react';
import { IoLogOutOutline, IoLogInOutline, IoPencilOutline, IoMailOutline, IoHelp } from 'react-icons/io5';
import { useUserDataModalActionsContext } from 'src/context';
import { useAuth0 } from '@auth0/auth0-react';
import { UserDataFormModal, ProfileMenuButton } from 'src/components';
import { userConfig } from 'src/services';
import { useIsAdmin } from 'src/hooks';
import { contactUsRoute, faqRoute } from 'src/helpers';

export const UserMenuProfile: React.FC = () => {
  const { isAuthenticated, logout, loginWithRedirect } = useAuth0();
  const { onOpen } = useUserDataModalActionsContext();
  const {
    colors: {
      utils: { alert },
    },
  } = useTheme();
  const isAdmin = useIsAdmin();

  const history = useHistory();

  const accountOptions: JSX.Element | null = isAuthenticated ? (
    <MenuList>
      {!isAdmin && (
        <MenuItem icon={<IoPencilOutline size="1.5rem" />} onClick={onOpen}>
          Edit company data
        </MenuItem>
      )}
      <MenuItem icon={<IoMailOutline size="1.5rem" />} onClick={() => history.push({ pathname: contactUsRoute.path })}>
        Contact us
      </MenuItem>
      <MenuItem
        icon={<IoHelp size="1.5rem" />}
        onClick={() =>
          window.location.assign(`${window.location.protocol}//${faqRoute.path}.${window.location.hostname}`)
        }
      >
        FAQ
      </MenuItem>
      <MenuItem
        color={alert}
        icon={<IoLogOutOutline size="1.5rem" />}
        onClick={() => {
          logout({ returnTo: window.location.origin });
          userConfig.removeUserRedirectedToLicenses();
        }}
      >
        Logout
      </MenuItem>
    </MenuList>
  ) : (
    <MenuList>
      <MenuItem icon={<IoLogInOutline size="1.5rem" />} onClick={() => loginWithRedirect()}>
        Login
      </MenuItem>
    </MenuList>
  );

  return (
    <div>
      <Menu isLazy placement="bottom-end">
        <ProfileMenuButton />
        {accountOptions}
      </Menu>
      <UserDataFormModal />
    </div>
  );
};
