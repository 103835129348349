import * as yup from 'yup';
import { maxLengthValidationText, requiredValidationText } from 'src/utils';
import { contactUsViewResources } from 'src/helpers';

export const contactUsYupSchema = (): yup.AnyObjectSchema =>
  yup.object().shape({
    name: yup
      .string()
      .max(500, ({ max }) => maxLengthValidationText(max))
      .required(({ label }) => requiredValidationText(label))
      .label(contactUsViewResources.inputs.name.label),
    email: yup
      .string()
      .email()
      .max(100, ({ max }) => maxLengthValidationText(max))
      .required(({ label }) => requiredValidationText(label))
      .label(contactUsViewResources.inputs.email.label),
    category: yup
      .string()
      .ensure()
      .required(({ label }) => requiredValidationText(label))
      .label(contactUsViewResources.inputs.category.label),
    message: yup
      .string()
      .max(10000, ({ max }) => maxLengthValidationText(max))
      .required(({ label }) => requiredValidationText(label))
      .label(contactUsViewResources.inputs.message.label),
  });
