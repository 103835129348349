import React, { useCallback, useEffect, useState } from 'react';
import { Controller, Control } from 'react-hook-form';
import { Flex, Box, Text, Switch, Spacer, Select } from '@chakra-ui/react';
import { INewAttributeDefinition, IAttributeModel } from 'src/model';
import { ReactComponent as EditIcon } from 'src/assets/edit.svg';
import { useNewModelWizardContext, useNewModelWizardActionsContext } from 'src/context';
import { DraggableItem } from 'src/components';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

interface INanoformAttributeGroupProps {
  name: string;
  control: Control;
  attributes: IAttributeModel[];
  onChangeAttribute: (name: string, checked: boolean) => void;
  onEdit: (attributeDefinition: INewAttributeDefinition) => void;
  onChangeOption?: () => void;
}

export const NanoformAttributeGroup: React.FC<INanoformAttributeGroupProps> = ({
  control,
  attributes,
  onChangeAttribute,
  onEdit,
  onChangeOption,
}) => {
  const [mouseOverState, setMouseOverState] = useState<boolean[]>([]);

  const handleMouseOver = (index: number, value: boolean) => {
    const mouseOver = [...mouseOverState];
    for (let i = 0; i < mouseOver.length; i += 1) {
      mouseOver[i] = false;
    }
    mouseOver[index] = value;
    setMouseOverState(mouseOver);
  };

  const { attributeTypes } = useNewModelWizardContext();
  const { setAttributeModels } = useNewModelWizardActionsContext();
  const pickerDefaultOption = (type: string): string => {
    if (type === attributeTypes[3]) {
      return 'Single picker';
    }
    if (type === attributeTypes[4]) {
      return 'Multi picker';
    }
    return '';
  };

  const renderOptions = (type: string): boolean => {
    return type === attributeTypes[3] || type === attributeTypes[4];
  };

  const renderNanoformAttribute = useCallback(
    (index: number): JSX.Element => {
      const attribute = attributes[index];
      return (
        <Box
          key={`attribute-${attribute.attributeDefinition.label}`}
          onMouseEnter={() => handleMouseOver(index, true)}
          onMouseLeave={() => handleMouseOver(index, false)}
          width="100%"
        >
          <Box color="typo.blue" fontWeight="700" bg="white" minWidth="128px" px={5} py={3} size="lg">
            <Flex alignItems="center">
              <Controller
                name={attribute.attributeDefinition.name}
                control={control}
                defaultValue={attribute.attributeDefinition.isEnabled}
                render={({ onChange, name: renderName, ref, value }) => (
                  <Switch
                    ref={ref}
                    name={renderName}
                    colorScheme="brand"
                    size="md"
                    onChange={(e) => {
                      onChange(e.target.checked);
                      onChangeAttribute(attribute.attributeDefinition.name, e.target.checked);
                    }}
                    defaultChecked={value}
                  />
                )}
              />
              <Text marginX="4">{attribute.attributeDefinition.label}</Text>
              <Spacer />
              {mouseOverState[index]}
              <Box
                cursor="pointer"
                onClick={() => onEdit(attribute.attributeDefinition)}
                visibility={mouseOverState[index] ? 'visible' : 'hidden'}
              >
                <EditIcon height={24} />
              </Box>
            </Flex>
            {renderOptions(attribute.attributeDefinition.type) && (
              <Box marginTop="4">
                <Select
                  onChange={onChangeOption ? () => onChangeOption() : () => {}}
                  errorBorderColor="utils.alert"
                  isInvalid={!!attribute.pickerErrorMessage}
                >
                  <option key={`${attribute.attributeDefinition.name}-option-empty`} value="">
                    {pickerDefaultOption(attribute.attributeDefinition.type)}
                  </option>
                  {attribute.attributeDefinition.attributePickerOptions.map((option: string, idx) => (
                    <option
                      key={`${attribute.attributeDefinition.name}-option-${option}-${idx.toString()}`}
                      value={option}
                    >
                      {option}
                    </option>
                  ))}
                </Select>
                {!!attribute.pickerErrorMessage && (
                  <Box textColor="#E53E3E" fontWeight={500} fontSize="sm" marginTop={2}>
                    {attribute.pickerErrorMessage}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attributes, mouseOverState]
  );

  useEffect(() => {
    const state: boolean[] = attributes.map(() => false);
    setMouseOverState(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributes]);

  const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
    setAttributeModels((prevCards: IAttributeModel[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as IAttributeModel],
        ],
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDraggable = (attribute: IAttributeModel, index: number) => {
    return (
      <DraggableItem
        id={attribute.attributeDefinition.name}
        key={attribute.attributeDefinition.name}
        error={attribute.errorMessage}
        index={index}
        element={renderNanoformAttribute(index)}
        moveItem={moveItem}
      />
    );
  };

  return (
    <Flex flexDirection="column" flex={1}>
      <DndProvider backend={HTML5Backend}>
        <Box>{attributes.map((attr, i) => renderDraggable(attr, i))}</Box>
      </DndProvider>
    </Flex>
  );
};
