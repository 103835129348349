export const coreWizardResources = {
  buttons: {
    next: 'Next',
    back: 'Back',
    save: 'Save',
    done: 'Done',
  },
  steps: {
    createCore: 'Create new core',
    editCore: 'Edit core',
    downloadScript: 'Download a script',
  },
  createCore: {
    core: {
      label: 'Core',
    },
    name: {
      label: 'Core name',
      placeholder: 'Enter core name',
      unique: 'Core with this name already exists',
    },
    color: {
      label: 'Color value',
      placeholder: 'Enter color value',
    },
    endpoint: {
      label: 'Endpoint',
      placeholder: 'Name visible to clients',
      unique: 'Endpoint with this name already exists',
    },
  },
  toasts: {
    saveCoreError: {
      title: 'An error occurred',
    },
    saveCoreSuccess: {
      title: 'Success',
      description: 'Core has been saved correctly',
    },
    validationErrorToast: {
      title: '',
      description: 'Please fill in all required fields',
    },
    hexValidationErrorToast: {
      title: '',
      description: 'Please provide correct HEX value',
    },
  },
} as const;
