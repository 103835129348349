import React from 'react';
import { Box } from '@chakra-ui/react';

interface IWhiteGreenDotProps {
  size: string;
  dotColor: string;
}

export const WhiteGreenDot: React.FC<IWhiteGreenDotProps> = ({ size, dotColor }) => (
  <Box
    display="block"
    position="relative"
    w={size}
    h={size}
    bg="white"
    borderRadius="50%"
    sx={{
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '66%',
        height: '66%',
        bg: dotColor,
        borderRadius: '50%',
        transform: 'translate(-50%, -50%)',
      },
    }}
  />
);
