import React, { useState, useEffect } from 'react';
import { Badge, Box, Flex, Heading, HStack, Icon, Spacer } from '@chakra-ui/react';
import { IoArrowBackOutline } from 'react-icons/io5';
import { useInfiniteQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { CancelButton, PrimaryButton, Spinner, UserLicensesList } from 'src/components';
import { userService } from 'src/services';
import { chooseLicenseAdmin, QUERY_LIMIT } from 'src/helpers';
import { LicensesResponse, UserStatus, User } from 'src/model';
import { useDocumentTitle } from 'src/hooks';
import { Popup } from 'src/components/common/Popup';
import { PopupBackground } from 'src/components/common/PopupBackground';
import { useBuyLicenceContext, useManageLicensesActionsContext, useManageLicensesContext } from 'src/context';

export const LicensesForAdmin: React.FC = () => {
  const history = useHistory();
  const { userId } = useParams<{ userId: string }>();
  const { userInfo } = useManageLicensesContext();
  const { setUserInfo } = useManageLicensesActionsContext();
  const { setDefaultCoreName } = useBuyLicenceContext();

  const [userStatus, setUserStatus] = useState<UserStatus>(userInfo.status);
  const [blockConfirmationPopup, setBlockConfirmationPopup] = useState<boolean>(false);

  const changeDocumentTitle = useDocumentTitle(userInfo.email);

  const { getAccessTokenSilently } = useAuth0();

  const fetchLicenses = async ({ pageParam = 0 }) => {
    const token = await getAccessTokenSilently();
    return userService
      .getUserLicenses(userId, QUERY_LIMIT, pageParam, token)
      .then((response: AxiosResponse) => response.data);
  };

  const { data, isLoading, isError, hasNextPage, fetchNextPage } = useInfiniteQuery(
    ['user-licenses-for-admin', userId],
    fetchLicenses,
    {
      getNextPageParam: (lastPage: LicensesResponse) => (lastPage.hasNext ? lastPage.offset + QUERY_LIMIT : undefined),
    }
  );

  const updateUserStatus = async () => {
    const newStatus = userStatus === UserStatus.Active ? UserStatus.Blocked : UserStatus.Active;

    const token = await getAccessTokenSilently();
    await userService.updateUserStatus(userId, newStatus, token).then(() => {
      setUserStatus(newStatus);
    });
    setUserStatus(newStatus);
    setBlockConfirmationPopup(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setDefaultCoreName(undefined);
    if (userInfo.email) return;
    const fetchUserInfo = async () => {
      const token = await getAccessTokenSilently();
      const user: User = await userService.getUserById(userId, token).then((response) => response.data);
      setUserInfo({ userId: user.id, email: user.email, status: user.status, role: 'User' });
      changeDocumentTitle(user.email);
    };
    fetchUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      className="user-licenses-for-admin"
      w="100%"
      minH="100%"
      overflowY="auto"
      id="user-licenses-for-admin"
      p={10}
      flexDirection="column"
    >
      {blockConfirmationPopup && (
        <PopupBackground position="fixed">
          <Popup
            problemMessage="This user will be blocked"
            buttonMessage="Block the user"
            solveMessage="User will loose the access to the system"
            setVisibility={() => setBlockConfirmationPopup(!blockConfirmationPopup)}
            onButtonClick={() => updateUserStatus()}
          />
        </PopupBackground>
      )}
      {userInfo.email && (
        <>
          <Flex justifyContent="space-between" mb={6}>
            <Box as="header" my="auto">
              <HStack>
                <Heading fontWeight="700" size="xl">
                  <Icon as={IoArrowBackOutline} onClick={() => history.goBack()} cursor="pointer" mr={4} />
                  {userInfo.email}
                </Heading>
                {userStatus === UserStatus.Blocked && (
                  <Badge colorScheme="red" py={1} px="12px" fontSize="md" color="utils.alert" fontWeight="500">
                    {UserStatus.Blocked}
                  </Badge>
                )}
              </HStack>
            </Box>
            <Flex my="auto">
              {userStatus === UserStatus.Active ? (
                <CancelButton
                  className="new-model-button"
                  size="sm"
                  onClick={() => setBlockConfirmationPopup(true)}
                  hidden={false}
                  isLoading={false}
                  disabled={false}
                >
                  Block User
                </CancelButton>
              ) : (
                <PrimaryButton
                  className="new-model-button"
                  size="sm"
                  onClick={() => updateUserStatus()}
                  hidden={false}
                  isLoading={false}
                  disabled={false}
                >
                  Unblock User
                </PrimaryButton>
              )}

              {userStatus === UserStatus.Active && (
                <>
                  <Spacer w="1.5rem" />
                  <Link
                    to={{
                      pathname: `${chooseLicenseAdmin.basePath}/${userId}`,
                      state: { email: userInfo.email, status: userInfo.status },
                    }}
                  >
                    <PrimaryButton
                      className="new-model-button"
                      size="sm"
                      onClick={() => {}}
                      hidden={false}
                      isLoading={false}
                      disabled={false}
                    >
                      Add License
                    </PrimaryButton>
                  </Link>
                </>
              )}
            </Flex>
          </Flex>
        </>
      )}
      {(isLoading || !userInfo.email) && (
        <Flex w="100%" flex="1" justifyContent="center" alignItems="center">
          <Spinner thickness="8px" speed="1s" size="xl" w="100px" h="100px" label="Loading.." />
        </Flex>
      )}
      {userInfo.email && (
        <>
          {data && hasNextPage !== undefined && !isError && (
            <UserLicensesList
              data={[...data.pages.map((page: LicensesResponse) => page.list)].flat()}
              getMoreData={fetchNextPage}
              hasMore={hasNextPage}
              userRole="Admin"
              licensesUserId={userId}
              userEmail={userInfo.email}
              userStatus={userInfo.status}
            />
          )}
        </>
      )}
    </Flex>
  );
};
