import React from 'react';
import { Box, Heading, Flex, Image, Text, useDisclosure } from '@chakra-ui/react';
import { ImageModal, DataWithLabel, ManyDataWithLabel, CoreLabel } from 'src/components';
import { IAttributeValue, ICore, Shape } from 'src/model';
import { predictionDetailsViewResources } from 'src/helpers';
import { coreService } from 'src/services';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { AxiosResponse } from 'axios';

interface INanoformShowcaseProps {
  image: string;
  core: string;
  shape: Shape;
  attributeValues: IAttributeValue[];
}

export const NanoformShowcase: React.FC<INanoformShowcaseProps> = ({ image, core, shape, attributeValues }) => {
  const { isOpen: isImageModalOpen, onOpen: onImageModalOpen, onClose: onImageModalClose } = useDisclosure();
  const { getAccessTokenSilently } = useAuth0();

  const groupValuesByAttribute = (): { [name: string]: string[] } => {
    const groups: { [name: string]: string[] } = {};

    for (let i = 0; i < attributeValues.length; i += 1) {
      if (!groups[attributeValues[i].name]) groups[attributeValues[i].name] = [];

      if (!groups[attributeValues[i].name].includes(attributeValues[i].value))
        groups[attributeValues[i].name].push(attributeValues[i].value);
    }

    return groups;
  };

  const fetchCoresDetails = async () => {
    const token = await getAccessTokenSilently();
    return coreService.getAllCoresDetails(token).then((response: AxiosResponse) => response.data);
  };

  const { data: coresDetails, isLoading: coresDetailsLoading } = useQuery('core-details-list', fetchCoresDetails);

  return (
    <Box p={5} bg="typo.white" borderColor="utils.boxBorder" borderWidth="1px" borderRadius="md" h="100%">
      <Heading as="h3" fontSize="1.5rem" mb={8}>
        {`${core} Nanoform`}
      </Heading>
      <Flex>
        {image && shape && (
          <>
            <Flex flex={1} justifyContent="center" alignItems="flex-start">
              <Image
                src={image}
                objectFit="cover"
                w="100%"
                alt="Nanoform visualization"
                borderStyle="solid"
                borderWidth="1px"
                borderColor="custom.glaceous"
                cursor="pointer"
                onClick={onImageModalOpen}
              />
              <ImageModal
                isOpen={isImageModalOpen}
                closeModal={onImageModalClose}
                imageUrl={image}
                nanoformName={`${core} Nanoform`}
              />
            </Flex>
            <Box w="2rem" />
          </>
        )}
        <Box flex={1}>
          <Box fontWeight="500" pb={6}>
            <Text color="typo.grey" pb={2}>
              Core
            </Text>
            {!coresDetailsLoading && (
              <CoreLabel value={(coresDetails as ICore[]).filter((item: ICore) => item.name === core)[0]} />
            )}
          </Box>

          {shape && <DataWithLabel label={predictionDetailsViewResources.shape} value={shape} preserveTextTransform />}

          {attributeValues &&
            Object.entries(groupValuesByAttribute()).map((attributeValue) => {
              if (attributeValue[1].length > 1) {
                return <ManyDataWithLabel label={attributeValue[0]} values={attributeValue[1]} preserveTextTransform />;
              }
              return <DataWithLabel label={attributeValue[0]} value={attributeValue[1][0]} preserveTextTransform />;
            })}
        </Box>
      </Flex>
    </Box>
  );
};
