import { AxiosPromise, AxiosResponse } from 'axios';
import { authorizedApiService } from './api.service';

class CheckoutService {
  createPayment = (licenseId: string, token: string): AxiosPromise => {
    const apiUrl = `/checkout/create-payment`;
    return authorizedApiService(token)
      .post(apiUrl, { licenseId })
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  cancelPayment = (licenseId: string, token: string): AxiosPromise => {
    const apiUrl = `/checkout/cancel-payment`;
    return authorizedApiService(token)
      .post(apiUrl, { licenseId })
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  retryPayment = (licenseId: string, token: string): AxiosPromise => {
    const apiUrl = `/checkout/retry-payment`;
    return authorizedApiService(token)
      .post(apiUrl, { licenseId })
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  sendResult = (result: string, token: string): AxiosPromise => {
    const apiUrl = `/checkout/result`;
    return authorizedApiService(token)
      .post(apiUrl, { paymentResult: result })
      .catch((error: AxiosResponse) => Promise.reject(error));
  };
}

export const checkoutService = new CheckoutService();
