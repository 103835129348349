import { INewAttributeDefinition } from 'src/model';
import { AxiosPromise, AxiosResponse } from 'axios';
import { authorizedApiService } from './api.service';

class AttributeService {
  getAllAttributes = (token: string): AxiosPromise => {
    const apiUrl = `/attributeDefinitions`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getAttribute = (id: string, token: string): AxiosPromise => {
    const apiUrl = `/attributeDefinitions/${id}`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  validateAttributes = (
    attributeDefinitions: INewAttributeDefinition[],
    token: string,
    modelId?: string
  ): AxiosPromise => {
    const apiUrl = `/attributeDefinitions/validate-attributes`;
    return authorizedApiService(token)
      .post(apiUrl, { ...{ attributeDefinitions }, modelId })
      .catch((error: AxiosResponse) => Promise.reject(error));
  };
}

export const attributeService = new AttributeService();
