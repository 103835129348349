import React, { useMemo } from 'react';
import { useFrame, useResource } from 'react-three-fiber';
import * as THREE from 'three';
import { makeNoise3D } from 'open-simplex-noise';

interface IBlobProps {
  coreColor: string;
  surfaceFunctionalization: string;
  surfaceFunctionalizationColor: string;
}

export const Blob: React.FC<IBlobProps> = ({ coreColor, surfaceFunctionalization, surfaceFunctionalizationColor }) => {
  const mesh = useResource();

  const k = 1.3;

  const material = useMemo(
    () =>
      new THREE.MeshStandardMaterial({
        color: coreColor,
        roughness: 0,
        metalness: 0.5,
        opacity: 1,
      }),
    [coreColor]
  );

  const surfaceMaterial = useMemo(
    () =>
      new THREE.MeshStandardMaterial({
        color: surfaceFunctionalizationColor,
        roughness: 0,
        metalness: 0.5,
        opacity: 0.35,
        transparent: true,
      }),
    [surfaceFunctionalizationColor]
  );

  const bloblSphericalGeometry = useMemo(() => new THREE.SphereGeometry(1.5, 100, 100), []);
  const blob = useMemo(() => new THREE.Mesh(bloblSphericalGeometry, material), [bloblSphericalGeometry, material]);

  const surfaceSphericalGeometry = useMemo(() => new THREE.SphereGeometry(1.7, 100, 100), []);
  const surface = useMemo(() => new THREE.Mesh(surfaceSphericalGeometry, surfaceMaterial), [
    surfaceSphericalGeometry,
    surfaceMaterial,
  ]);

  const noise = useMemo(() => makeNoise3D(Date.now()), []);

  useFrame(() => {
    const time = performance.now() * 0.0002;

    blob.geometry.vertices.forEach((vertex: THREE.Vector3) =>
      vertex.normalize().multiplyScalar(1.5 + 0.3 * noise(vertex.x * k, vertex.y * k, vertex.z * k + time))
    );
    blob.geometry.computeVertexNormals();
    blob.geometry.normalsNeedUpdate = true;
    blob.geometry.verticesNeedUpdate = true;

    surface.geometry.vertices.forEach((vertex: THREE.Vector3) =>
      vertex.normalize().multiplyScalar(1.7 + 0.3 * noise(vertex.x * k, vertex.y * k, vertex.z * k + time))
    );
    surface.geometry.computeVertexNormals();
    surface.geometry.normalsNeedUpdate = true;
    surface.geometry.verticesNeedUpdate = true;
  });

  return (
    <group>
      <primitive ref={mesh} object={blob} />
      {Boolean(surfaceFunctionalization) && <primitive ref={mesh} object={surface} />}
    </group>
  );
};
