import { RoleTypes } from 'src/model';

export interface IRoute {
  name: string;
  path: string;
  basePath?: string;
  requiredRoles?: RoleTypes[];
}

export const laboratoryRoute: IRoute = {
  name: 'Laboratory',
  path: '/',
  requiredRoles: ['User', 'Admin'],
};

export const licensesRoute: IRoute = {
  name: 'Licenses',
  path: '/licenses',
  requiredRoles: ['User'],
};

export const licensesForAdminRoute: IRoute = {
  name: 'User licenses',
  basePath: '/manageLicenses',
  path: '/manageLicenses/:userId',
  requiredRoles: ['Admin'],
};

export const chooseLicenseAdmin: IRoute = {
  name: 'Choose license',
  basePath: '/chooseLicense',
  path: '/chooseLicense/:userId',
  requiredRoles: ['Admin'],
};

export const usersRoute: IRoute = {
  name: 'Manage licenses',
  path: '/manageLicenses',
  requiredRoles: ['Admin'],
};

export const buyLicenseRoute: IRoute = {
  name: 'Buy License',
  path: '/buylicense',
  requiredRoles: ['User'],
};

export const manageModelsRoute: IRoute = {
  name: 'Manage models',
  path: '/managemodels',
  requiredRoles: ['Admin'],
};

export const manageCoresRoute: IRoute = {
  name: 'Manage cores',
  path: '/managecores',
  requiredRoles: ['Admin'],
};

export const modelDetailsRoute: IRoute = {
  name: 'Model details',
  path: '/modeldetails/:modelId',
  requiredRoles: ['Admin'],
};

export const checkoutRoute: IRoute = {
  name: 'Checkout',
  path: '/checkout',
  requiredRoles: ['User'],
};

export const modelsVersionDetailsRoute: IRoute = {
  name: 'Version details',
  path: '/modeldetails/:modelId/versiondetails/:versionId',
  requiredRoles: ['Admin'],
};

export const predictionDetailsRoute: IRoute = {
  name: 'Prediction details',
  path: '/prediction/:predictionId',
  requiredRoles: ['Admin', 'User'],
};

export const faqRoute: IRoute = {
  name: 'FAQ',
  path: 'www.faq',
};

export const contactUsRoute: IRoute = {
  name: 'Contact us',
  path: '/contact-us',
  requiredRoles: ['Admin', 'User'],
};

export const manageReportLayoutsRoute: IRoute = {
  name: 'Reports',
  path: '/manageReportLayouts',
  requiredRoles: ['Admin'],
};

export const routes: IRoute[] = [
  laboratoryRoute,
  licensesRoute,
  buyLicenseRoute,
  usersRoute,
  licensesForAdminRoute,
  manageModelsRoute,
  modelDetailsRoute,
  checkoutRoute,
  modelsVersionDetailsRoute,
  predictionDetailsRoute,
  contactUsRoute,
  faqRoute,
];

export const navigationRoutes: IRoute[] = [
  laboratoryRoute,
  licensesRoute,
  usersRoute,
  manageModelsRoute,
  manageCoresRoute,
  manageReportLayoutsRoute,
];
