import React, { useMemo, useRef } from 'react';
import { useFrame } from 'react-three-fiber';
import { Html } from '@react-three/drei/web/Html';
import { Line } from '@react-three/drei/core/Line';
import * as THREE from 'three';
import { CSG } from 'three-csg-ts';
import { WhiteGreenDot, ModelText } from 'src/components';
import { INanoformProps } from 'src/model';

export const Tube: React.FC<INanoformProps> = ({
  core,
  coreModification,
  coreColor,
  surfaceFunctionalization,
  surfaceFunctionalizationColor,
  lineColor,
  baseRotation,
  isNanoformImageGenerated,
}) => {
  // eslint-disable-next-line
  const modelGroup = useRef<any>();

  const y = baseRotation[1];

  useFrame(() => {
    if (modelGroup.current) {
      if (isNanoformImageGenerated) {
        modelGroup.current.rotation.y = y;
      } else {
        modelGroup.current.rotation.y += 0.005;
      }
    }
  });

  const outerGeometry = useMemo(() => new THREE.Mesh(new THREE.CylinderGeometry(0.4, 0.4, 2.9, 30, 1)), []);

  const innerGeometry = useMemo(() => new THREE.Mesh(new THREE.CylinderGeometry(0.3, 0.3, 2.9, 30, 1)), []);

  const outerHaloGeometry = useMemo(() => new THREE.Mesh(new THREE.CylinderGeometry(0.5, 0.5, 2.89, 30, 1)), []);

  const innerHaloGeometry = useMemo(() => new THREE.Mesh(new THREE.CylinderGeometry(0.25, 0.25, 2.89, 30, 1)), []);

  const bspA = CSG.fromMesh(outerGeometry);
  const bspB = CSG.fromMesh(innerGeometry);

  const bspHaloA = CSG.fromMesh(outerHaloGeometry);
  const bspHaloB = CSG.fromMesh(innerHaloGeometry);

  const generated = bspA.subtract(bspB);
  const generatedHalo = bspHaloA.subtract(bspHaloB);

  const generatedGeometry = CSG.toGeometry(generated, outerGeometry.matrix);
  const generatedHaloGeometry = CSG.toGeometry(generatedHalo, outerHaloGeometry.matrix);

  return (
    <group position={[0, -0.7, 0]}>
      {Boolean(core) && (
        <group>
          <Html className="core" position={[1, 1, 2]} zIndexRange={[0, 1]}>
            <ModelText
              dotSize="12px"
              text={coreModification ? `${core?.name}/${coreModification}` : (core?.name as string)}
              variant="right"
              dotColor={lineColor}
            />
          </Html>
          <Html className="center" position={[0, 0.38, 2]} zIndexRange={[0, 1]}>
            <WhiteGreenDot size="18px" dotColor={lineColor} />
          </Html>
          <Line
            points={[
              [0, 0.37, 2],
              [0.7, 1, 2],
              [1, 1, 2],
            ]}
            lineWidth={1.5}
            color={lineColor}
          />
        </group>
      )}
      {Boolean(surfaceFunctionalization) && (
        <group>
          <Html className="core--left" position={[-0.8, 0.85, 2]} zIndexRange={[0, 1]}>
            <ModelText dotSize="12px" text={surfaceFunctionalization as string} variant="left" dotColor={lineColor} />
          </Html>
          <Html className="center" position={[-0.05, 0.58, 2]} zIndexRange={[0, 1]}>
            <WhiteGreenDot size="18px" dotColor={lineColor} />
          </Html>
          <Line
            points={[
              [-0.05, 0.58, 2],
              [-0.5, 0.85, 2],
              [-0.8, 0.85, 2],
            ]}
            lineWidth={1.5}
            color={lineColor}
          />
        </group>
      )}
      <group ref={modelGroup} rotation={baseRotation}>
        <mesh geometry={generatedGeometry}>
          <meshStandardMaterial attach="material" roughness={0} metalness={0.1} color={coreColor} />
        </mesh>
        {Boolean(surfaceFunctionalization) && (
          <mesh geometry={generatedHaloGeometry}>
            <meshStandardMaterial transparent opacity={0.35} color={surfaceFunctionalizationColor} />
          </mesh>
        )}
      </group>
    </group>
  );
};
