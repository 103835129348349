import React from 'react';
import dayjs from 'dayjs';
import { Box, Text, Flex } from '@chakra-ui/react';
import { DATE_FORMAT, modelDetailsViewResources } from 'src/helpers';
import { IModelDetails } from 'src/model';

interface IModelDetailsProps {
  model: IModelDetails;
}

export const ModelDetails: React.FC<IModelDetailsProps> = ({ model }) => {
  const { fields } = modelDetailsViewResources;

  return (
    <Flex flexDirection="column">
      <Box pb={4}>
        <Text size="sm">{fields.core}</Text>
        <Text size="md" fontWeight="bold">
          {model.coreName}
        </Text>
      </Box>
      <Box pb={4}>
        <Text size="sm">{fields.endpoint}</Text>
        <Text size="md" fontWeight="bold">
          {model.endpoint}
        </Text>
      </Box>
      <Box pb={4}>
        <Text size="sm">{fields.dependentVariable}</Text>
        <Text size="md" fontWeight="bold">
          {model.dependentVariable}
        </Text>
      </Box>
      <Box pb={4}>
        <Text size="sm">{fields.date}</Text>
        <Text size="md" fontWeight="bold">
          {dayjs(model.createdDate).format(DATE_FORMAT)}
        </Text>
      </Box>

      <Box pb={4}>
        <Text>{fields.group}</Text>
        <Text size="md" fontWeight="bold">
          {model.groupName}
        </Text>
      </Box>
      {!model.isDemo && (
        <Box pb={4}>
          <Text size="sm">{fields.oneNanoformPrice}</Text>
          <Text size="md" fontWeight="bold">
            € {model.oneNanoformPrice?.toFixed(2)}
          </Text>
        </Box>
      )}
      {!model.isDemo && (
        <Box pb={4}>
          <Text size="sm">{fields.manyNanoformsPrice}</Text>
          <Text size="md" fontWeight="bold">
            € {model.manyNanoformsPrice?.toFixed(2)}
          </Text>
        </Box>
      )}
      <Box pb={4}>
        <Text size="sm">{fields.type}</Text>
        <Text size="md" fontWeight="bold">
          {model.reportType}
        </Text>
      </Box>
      <Box pb={model.isDemo ? 4 : 0}>
        <Text size="sm">{fields.order}</Text>
        <Text size="md" fontWeight="bold">
          {model.order}
        </Text>
      </Box>
      {model.isDemo && (
        <Box>
          <Text size="sm">{fields.isDemo}</Text>
          <Text size="md" fontWeight="bold">
            Yes
          </Text>
        </Box>
      )}
    </Flex>
  );
};
