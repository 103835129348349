import React, { FC } from 'react';
import { useTheme, ButtonProps, Button, Flex, Box } from '@chakra-ui/react';

interface IFileButton extends Omit<ButtonProps, 'onClick' | 'children' | 'id'> {
  children: React.ReactNode;
  id: string;
  onClick: (fileName: string, fileData: string) => void;
}

export const UploadFileButton: FC<IFileButton> = ({ onClick, id, children, ...restProps }) => {
  const {
    colors: { button },
  } = useTheme();

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || !event.target.files[0]) return;

    const file = event.target.files[0];
    const { name } = file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result) {
        onClick(name, reader.result.toString());
      }
    };
  };

  return (
    <Button
      variant="solid"
      color={button.white}
      bg={button.blue}
      _hover={{ bg: button.hoverPrimary }}
      _active={{ bg: button.hoverPrimary }}
      _disabled={{ bg: button.disabledPrimaryBg, color: button.disabledText, cursor: 'not-allowed' }}
      padding="0"
      {...restProps}
    >
      <label htmlFor={id} style={{ height: '100%', width: '100%' }}>
        <Flex h="100%" w="100%" cursor="pointer" px={4}>
          <Box my="auto" mx="auto">
            {children}
          </Box>
        </Flex>
      </label>
      <input
        id={id}
        type="file"
        style={{ display: 'none' }}
        multiple={false}
        accept=".html"
        onChange={(e) => handleFileUpload(e)}
        value=""
      />
    </Button>
  );
};
