import React, { useMemo, useRef } from 'react';
import { useFrame } from 'react-three-fiber';
import { Html } from '@react-three/drei/web/Html';
import { Line } from '@react-three/drei/core/Line';
import { WhiteGreenDot, ModelText } from 'src/components';
import { INanoformProps } from 'src/model';

export const Wires: React.FC<INanoformProps> = ({
  core,
  coreModification,
  coreColor,
  surfaceFunctionalization,
  surfaceFunctionalizationColor,
  lineColor,
  baseRotation,
  isNanoformImageGenerated,
}) => {
  // eslint-disable-next-line
  const modelGroup = useRef<any>();

  const y = baseRotation[1];

  const positions: [number, number, number][] = useMemo(
    () => [
      [0, 0.2, -0.4],
      [0, 0, 0],
      [0, -0.2, 0.4],
    ],
    []
  );

  useFrame(() => {
    if (modelGroup.current) {
      if (isNanoformImageGenerated) {
        modelGroup.current.rotation.y = y;
      } else {
        modelGroup.current.rotation.y += 0.005;
      }
    }
  });

  return (
    <group position={[0, -0.7, 0]}>
      {Boolean(core) && (
        <group>
          <Html className="core" position={[1, 1, 2]} zIndexRange={[0, 1]}>
            <ModelText
              dotSize="12px"
              text={coreModification ? `${core?.name}/${coreModification}` : (core?.name as string)}
              variant="right"
              dotColor={lineColor}
            />
          </Html>
          <Html className="center" position={[0, 0.3, 2]} zIndexRange={[0, 1]}>
            <WhiteGreenDot size="12px" dotColor={lineColor} />
          </Html>
          <Line
            points={[
              [0, 0.3, 2],
              [0.7, 1, 2],
              [1, 1, 2],
            ]}
            lineWidth={1.5}
            color={lineColor}
          />
        </group>
      )}
      {Boolean(surfaceFunctionalization) && (
        <group>
          <Html className="core--left" position={[-0.8, 0.85, 2]} zIndexRange={[0, 1]}>
            <ModelText dotSize="12px" text={surfaceFunctionalization as string} variant="left" dotColor={lineColor} />
          </Html>
          <Html className="center" position={[-0.1, 0.38, 2]} zIndexRange={[0, 1]}>
            <WhiteGreenDot size="12px" dotColor={lineColor} />
          </Html>
          <Line
            points={[
              [-0.1, 0.38, 2],
              [-0.5, 0.85, 2],
              [-0.8, 0.85, 2],
            ]}
            lineWidth={1.5}
            color={lineColor}
          />
        </group>
      )}
      <group ref={modelGroup} rotation={baseRotation}>
        {positions.map((position) => (
          <group key={`wire-${Math.random() * 1000}`}>
            <mesh position={position}>
              <cylinderBufferGeometry args={[0.12, 0.12, 6, 30]} />
              <meshStandardMaterial attach="material" roughness={0} metalness={0.1} color={coreColor} />
            </mesh>
            {Boolean(surfaceFunctionalization) && (
              <mesh position={position}>
                <cylinderBufferGeometry args={[0.17, 0.17, 5.99, 30]} />
                <meshStandardMaterial transparent opacity={0.35} color={surfaceFunctionalizationColor} />
              </mesh>
            )}
          </group>
        ))}
      </group>
    </group>
  );
};
