import React from 'react';
import {
  HStack,
  VStack,
  Heading,
  Checkbox,
  Box,
  useTheme,
  Flex,
  Alert,
  AlertIcon,
  AlertDescription,
  Tooltip,
  Text,
} from '@chakra-ui/react';
import {
  IModelGroup,
  IModel,
  ILicenseOptions,
  ICore,
  LicenseTypes,
  ILicenseModel,
  LicenseStatus,
  ModelStatus,
} from 'src/model';
import { LicenseStatusBadge } from 'src/components/licenses';
import { useBuyLicenceContext } from 'src/context';
import { userLicensesViewResources } from 'src/helpers';
import { UncontrolledCoreRadioGroup } from '../common';

interface IModelsListProps {
  modelGroups: IModelGroup[];
  licenseOptions?: ILicenseOptions;
  selectedItems: IModel[];
  ownedEndpoints: ILicenseModel[];
  selectionChanged: (checked: boolean, model: IModel) => void;
  isDisabled: boolean;
  onChangeCore: (value: string) => void;
  cores: ICore[];
  defaultCore?: string;
  purchasedDate: string;
  expirationDate: string;
  isAdmin: boolean;
  isAdminAdding: boolean;
}

export const BuyModelsList: React.FC<IModelsListProps> = ({
  modelGroups,
  licenseOptions,
  selectedItems,
  ownedEndpoints,
  selectionChanged,
  isDisabled,
  onChangeCore,
  cores,
  defaultCore,
  purchasedDate,
  expirationDate,
  isAdmin,
  isAdminAdding,
}) => {
  const {
    colors: { typo },
  } = useTheme();

  const { selector } = useBuyLicenceContext();
  const { modelDeactivated } = userLicensesViewResources;

  const getCoresForLicenseType = () => {
    if (
      licenseOptions?.type === LicenseTypes.ManyNanoformsSpecificModels ||
      licenseOptions?.type === LicenseTypes.AllSubstancesAllModels
    ) {
      return cores;
    }
    return cores.filter((c) => c.name === defaultCore);
  };

  const formatDate = (dateToFormat: string): string => {
    const date = new Date(dateToFormat);

    const day = `0${date.getDate()}`.slice(-2);
    const month = `0${date.getMonth() + 1}`.slice(-2);

    return `${day}/${month}/${date.getFullYear()}`;
  };

  const isChecked = (model: IModel) => {
    return (
      selectedItems.includes(model) || (!isAdmin && ownedEndpoints.map((e) => e.endpoint).includes(model.endpoint))
    );
  };

  return (
    <>
      <HStack paddingBottom={6}>
        <Heading size="sm" color={typo.blue}>
          {licenseOptions?.main}
        </Heading>
        <Heading size="sm" fontWeight="550" color={typo.green}>
          / {licenseOptions?.sub.toLowerCase()}
        </Heading>
      </HStack>
      <UncontrolledCoreRadioGroup
        onChange={(value) => onChangeCore(value as string)}
        options={ownedEndpoints.length > 0 ? getCoresForLicenseType() : cores}
        defaultValue={defaultCore}
      />
      {selector.curentName && (
        <Heading paddingBottom={8} paddingTop={8} size="md">
          {licenseOptions?.heading}
        </Heading>
      )}

      {purchasedDate && expirationDate && ownedEndpoints.length > 0 && (
        <Alert status="warning" mb={4}>
          <AlertIcon />
          <AlertDescription textColor="gray">
            {`New models will be available during the term of the license: ${formatDate(purchasedDate)} – ${formatDate(
              expirationDate
            )}`}
          </AlertDescription>
        </Alert>
      )}
      <Flex align="baseline">
        {modelGroups.map(
          (group: IModelGroup) =>
            group.models.length !== 0 && (
              <VStack flex="3" spacing={4} key={`${group.name}`} align="start" w={`${100 / modelGroups.length}%`}>
                <Heading variant="outline" fontWeight="550" size="sm" color={typo.grey} minH="2.5rem">
                  {group.name.toUpperCase()}
                </Heading>
                <VStack align="start" w="100%" spacing={0}>
                  {group.models.map((model: IModel) => {
                    if (
                      licenseOptions?.modelsSelectable ||
                      ownedEndpoints.length > 0 ||
                      (isAdmin && !isAdminAdding && !licenseOptions?.modelsSelectable)
                    ) {
                      const disabled =
                        !isAdmin && (isDisabled || ownedEndpoints.map((e) => e.endpoint).includes(model.endpoint));
                      const cannotUseDeactivatedModels = !isAdmin && model.status === ModelStatus.Deactivated;
                      return (
                        <HStack w="100%" wordBreak="break-all" key={`${group.name}_${model.id}`}>
                          <Checkbox
                            colorScheme="brand"
                            size="lg"
                            isChecked={isChecked(model)}
                            isDisabled={disabled || cannotUseDeactivatedModels}
                            opacity={disabled || cannotUseDeactivatedModels ? 0.4 : 1}
                            onChange={(e) => selectionChanged(e.target.checked, model)}
                          />
                          {model.status === ModelStatus.Deactivated ? (
                            <Box cursor="help" bg="radio.bg" borderRadius="0.25rem" py="1" px="2">
                              <Tooltip
                                hasArrow
                                label={modelDeactivated}
                                bg="typo.blue"
                                borderRadius="0.25rem"
                                maxW="15rem"
                              >
                                <Text color="typo.grey" fontSize="lg">
                                  {model.endpoint}
                                </Text>
                              </Tooltip>
                            </Box>
                          ) : (
                            <Box bg="transparent" borderRadius="0.25rem" py="1" px="2">
                              <Text fontSize="lg">{model.endpoint}</Text>
                            </Box>
                          )}
                          {ownedEndpoints.find((item) => item.endpoint === model.endpoint)?.hasWaitingPayment && (
                            <Box mr={2} my="auto">
                              <LicenseStatusBadge licenseStatus={LicenseStatus.waiting} />
                            </Box>
                          )}
                        </HStack>
                      );
                    }
                    return (
                      <HStack w="100%" wordBreak="break-all" key={`${group.name}_${model.id}`}>
                        <Checkbox
                          spacing="1rem"
                          colorScheme="brand"
                          size="lg"
                          key={`${group.name}_${model.id}`}
                          isDisabled
                          isChecked={selectedItems.includes(model)}
                        />
                        {model.status === ModelStatus.Deactivated ? (
                          <Box cursor="help" bg="radio.bg" borderRadius="0.25rem" py="1" px="2">
                            <Tooltip
                              hasArrow
                              label={modelDeactivated}
                              bg="typo.blue"
                              borderRadius="0.25rem"
                              maxW="15rem"
                            >
                              <Text color="typo.grey" fontSize="lg">
                                {model.endpoint}
                              </Text>
                            </Tooltip>
                          </Box>
                        ) : (
                          <Box bg="transparent" borderRadius="0.25rem" py="1" px="2">
                            <Text fontSize="lg">{model.endpoint}</Text>
                          </Box>
                        )}
                      </HStack>
                    );
                  })}
                </VStack>
              </VStack>
            )
        )}
      </Flex>
    </>
  );
};
