import { ICore } from 'src/model';

export type ReportType = 'QSAR' | 'RA';

export enum ReportTypeEnum {
  QSAR = 'QSAR',
  RA = 'RA',
}

export interface IModel {
  id: string;
  name: string;
  order: number;
  core: ICore;
  endpoint: string;
  dependentVariable: string;
  reportType: ReportType;
  oneNanoformPrice: number;
  manyNanoformsPrice: number;
  modelGroupId: string;
  createdDate: Date;
  status: ModelStatus;
}

export interface INewModel {
  id: string;
  status: ModelStatus;
  name: string;
  order: number;
  coreId: string;
  coreName: string;
  isDemo: boolean;
  endpoint: string;
  dependentVariable: string;
  reportType: ReportType;
  oneNanoformPrice: number;
  manyNanoformsPrice: number;
  modelGroupId: string;
  groupName: string;
  createdDate: Date;
}

export interface IModelVersion {
  id: string;
  author: string;
  version: string;
  changeLog: string;
  qmrfReference: string;
  createdDate: Date;
  functionName: string;
  useShape: boolean;
}

export interface IModelGroup {
  id: string;
  name: string;
  models: IModel[];
  order: number;
}

export enum ModelStatus {
  Active = 'Active',
  Deactivated = 'Deactivated',
  ToRemove = 'ToRemove',
}

export interface IModelListItem {
  id: string;
  name: string;
  endpoint: string;
  createdDate: Date;
  status: ModelStatus;
}

export interface IModelsListResponse {
  list: IModelListItem[];
  hasNext: boolean;
  offset: number;
}

export interface IModelVersionListResponse {
  list: IModelVersionListItem[];
  hasNext: boolean;
  offset: number;
}

export interface IModelVersionListItem {
  id: string;
  versionNumber: string;
  versionNumberText: string;
  author: string;
  date: Date;
}

export interface IModelLicenseListResponse {
  list: IModelLicenseListItem[];
  hasNext: boolean;
  offset: number;
}

export interface IModelLicenseUser {
  email: string;
  name: string;
  surname: string;
}

export interface IModelLicenseListItem {
  id: string;
  user: IModelLicenseUser;
  type: string;
  purchaseDate: Date;
  expirationDate: Date;
  isActive: boolean;
  isDeactivated: boolean;
  hasWaitingPayment: boolean;
}

export interface IModelDetails {
  id: string;
  name: string;
  endpoint: string;
  reportType: ReportType;
  dependentVariable: string;
  createdDate: Date;
  groupName: string;
  order: number;
  coreName: string;
  oneNanoformPrice: number;
  manyNanoformsPrice: number;
  isDemo: boolean;
}

export interface IModelVersionDetailsResponse {
  id: string;
  versionNumber: number;
  versionNumberText: string;
  author: string;
  date: string;
  changeLog: string;
  endpointAddress: string;
  functionName: string;
  qmrfReference: string;
  attributeLabels: string[];
  useShape: boolean;
}

export interface IAtributeValidation {
  id: string;
  wrongType: boolean;
}

export interface IAttributeModel {
  attributeDefinition: INewAttributeDefinition;
  orginalType: string;
  errorMessage: string;
  pickerErrorMessage: string;
}

export interface INewAttributeDefinition {
  id: string | null;
  name: string;
  label: string;
  type: string;
  attributePickerOptions: string[];
  order: number;
  isEnabled: boolean;
}

export interface IAttributeDefinition {
  id: string | null;
  name: string;
  label: string;
  type: string;
  attributePickerOptions: string[];
  order: number;
  isEnabled: boolean;
  createdDate: Date;
}

export interface IActiveVersion {
  id: string;
  author: string;
  changeLog: string;
  date: Date;
  endpointAddress: string;
  functionName: string;
  qmrfReference: string;
  versionNumber: number;
  versionNumberText: string;
  attributes: IAttributeDefinition[];
  useShape: boolean;
}

export interface IModelVersionAttribute {
  attributeDefinitionId: string;
  name: string;
  label: string;
  type: string;
  order: number;
  pickerOptions: string[];
}

export interface IModelsAttributesResponse {
  attributeDefinitions: IModelVersionAttribute[];
}

export interface IDemoModel {
  id: string;
  name: string;
  endpoint: string;
  order: number;
  coreName: string;
  isDemo: boolean;
  useShape: boolean;
  status: ModelStatus;
}
