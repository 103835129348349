import React from 'react';
import { IoArrowBack } from 'react-icons/io5';
import { Flex, Text, Box } from '@chakra-ui/react';
import { LogoHeader, Spinner } from 'src/components';
import { predictionDetailsViewResources } from 'src/helpers';

interface IPredictionLoaderProps {
  onBack: () => void;
}

export const PredictionLoader: React.FC<IPredictionLoaderProps> = ({ onBack }) => (
  <Flex position="fixed" top="0" left="0" w="100%" h="100%" bg="utils.background" pt="90px" flexDirection="column">
    <LogoHeader withLink />
    <Flex flex={1} justifyContent="center" alignItems="center" flexDirection="column">
      <Spinner thickness="6px" speed="1s" size="xl" w="110px" h="110px" label="Waiting for prediction's results" />
      <Text fontWeight="700" fontSize="1.5rem" maxW="310px" pt={12} textAlign="center">
        {predictionDetailsViewResources.waitingForPrediction}
      </Text>
      <Box
        color="typo.green"
        fontWeight="700"
        fontSize="1rem"
        pt={3}
        display="inline-flex"
        alignItems="center"
        onClick={() => onBack()}
        cursor="pointer"
        _hover={{ textDecoration: 'underline' }}
      >
        <IoArrowBack size="1rem" />
        <Text pl={1}>{predictionDetailsViewResources.backToPredictionList}</Text>
      </Box>
    </Flex>
  </Flex>
);
