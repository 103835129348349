import React from 'react';
import { Box, Heading, Badge, Text } from '@chakra-ui/react';
import { predictionDetailsViewResources } from 'src/helpers';
import { DataWithLabel } from 'src/components';
import { IPredictionModel, IQsarPredictionExecution, IRaPredictionExecution } from 'src/model';

interface IPredictionDetailsPerEndpointProps {
  predictionInDomain: boolean;
  qsarEndpointDetails: IQsarPredictionExecution | undefined;
  raEndpointDetails: IRaPredictionExecution | undefined;
  selectedEndpoint: IPredictionModel;
}

export const PredictionDetailsPerEndpoint: React.FC<IPredictionDetailsPerEndpointProps> = ({
  predictionInDomain,
  qsarEndpointDetails,
  raEndpointDetails,
  selectedEndpoint,
}) => {
  return (
    <Box
      p={5}
      bg="typo.white"
      borderColor="utils.boxBorder"
      borderWidth="1px"
      borderRadius="md"
      mt={2}
      w="100%"
      flex={1}
    >
      <Heading as="h3" fontSize="1.5rem" mb={3}>
        {predictionDetailsViewResources.predictionDetails}
      </Heading>
      {(qsarEndpointDetails || raEndpointDetails) && predictionInDomain && (
        <Badge colorScheme="green" px="12px" fontSize="md" color="typo.green" fontWeight="500" mb={8}>
          {predictionDetailsViewResources.predictionInDomain}
        </Badge>
      )}
      {(qsarEndpointDetails || raEndpointDetails) && !predictionInDomain && (
        <Badge colorScheme="red" px="12px" fontSize="md" color="utils.alert" fontWeight="500" mb={8}>
          {predictionDetailsViewResources.predictionOutOfDomain}
        </Badge>
      )}
      {selectedEndpoint.reportType === 'QSAR' && qsarEndpointDetails && (
        <DataWithLabel
          label={predictionDetailsViewResources.predictedValue}
          value={qsarEndpointDetails.predictedValue}
        />
      )}
      {selectedEndpoint.reportType === 'RA' && raEndpointDetails && (
        <>
          <Text fontWeight="500" color="custom.glaucous" textTransform="uppercase" my={5}>
            {predictionDetailsViewResources.readAcrossAssessmentResult}
          </Text>
          <Text fontWeight="700" fontSize="1.5rem" color={predictionInDomain ? 'typo.green' : 'utils.alert'}>
            {raEndpointDetails.readAcrossAssessmentResult}
          </Text>
        </>
      )}
    </Box>
  );
};
