import React, { useState, useEffect } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { Box, Text, Flex, Fade } from '@chakra-ui/react';

interface ITabLinkProps extends LinkProps {
  to: string;
}

export const TabLink: React.FC<ITabLinkProps> = ({ children, to, ...restProps }) => {
  const { pathname } = useLocation();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  useEffect(() => {
    if (pathname === to) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [pathname, to]);

  return (
    <Flex className="tab-link" minH="100%" justifyContent="center" alignItems="center" position="relative" mx="10px">
      <Link to={to} {...restProps}>
        <Text
          fontWeight="600"
          _hover={{ color: 'typo.green' }}
          color={isActive ? 'typo.green' : 'typo.blue'}
          px={5}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {children}
        </Text>
      </Link>
      <Fade in={isActive || isHovered} unmountOnExit>
        <Box
          position="absolute"
          bottom="0"
          left="0"
          w="100%"
          h={isActive ? '10px' : '8px'}
          bg={isHovered || isActive ? 'utils.greenGradient' : 'transparent'}
        />
      </Fade>
    </Flex>
  );
};
