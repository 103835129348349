import React, { FC, useEffect } from 'react';
import { Flex, Heading, HStack, Icon, useTheme } from '@chakra-ui/react';
import { LicenseBuyOptionsGrid } from 'src/components';
import { useDocumentTitle } from 'src/hooks';
import { IoArrowBack, IoArrowBackOutline } from 'react-icons/io5';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
  useUserActionsContext,
  useBuyLicenceContext,
  useManageLicensesContext,
  useManageLicensesActionsContext,
} from 'src/context';
import { User } from 'src/model';
import { userService } from 'src/services';

export interface ChooseLicenseAdminState {
  userId: string;
}

export const ChooseLicenseAdmin: FC = () => {
  useDocumentTitle('Choose license');
  const {
    colors: { button },
  } = useTheme();
  const { getAccessTokenSilently } = useAuth0();
  const { isAdmin } = useUserActionsContext();
  const {
    defaultSelectorValue,
    setSelector,
    setDefaultCoreName,
    setLicenseAdded,
    licenseAdded,
  } = useBuyLicenceContext();
  const { userInfo } = useManageLicensesContext();
  const { setUserInfo } = useManageLicensesActionsContext();

  const history = useHistory();
  const { userId } = useParams<{ userId: string }>();

  useEffect(() => {
    window.scrollTo(0, 0);
    setSelector(defaultSelectorValue);
    setDefaultCoreName(undefined);

    if (licenseAdded) {
      setLicenseAdded(false);
      history.goBack();
      return;
    }

    const fetchUserInfo = async () => {
      const token = await getAccessTokenSilently();
      const user: User = await userService.getUserById(userId, token).then((response) => response.data);
      setUserInfo({ userId: user.id, email: user.email, status: user.status, role: 'User' });
    };

    fetchUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      className="user-licenses"
      w="100%"
      minH="100%"
      overflowY="auto"
      id="user-licenses"
      p={10}
      flexDirection="column"
    >
      <Heading fontWeight="700" size="xl">
        <HStack spacing={4}>
          <Icon as={IoArrowBackOutline} cursor="pointer" onClick={() => history.goBack()}>
            <IoArrowBack color={button.blue} size="1.5rem" />
          </Icon>
          <Heading fontWeight="700" size="xl">
            Choose license
          </Heading>
        </HStack>
      </Heading>
      <LicenseBuyOptionsGrid
        isAdmin={isAdmin()}
        userInfo={{ id: userId, status: userInfo.status, email: userInfo.email }}
      />
    </Flex>
  );
};
