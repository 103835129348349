import React, { useEffect, useCallback, useState } from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { checkoutService } from 'src/services';
import { licensesRoute, afterCheckoutViewResources, laboratoryRoute } from 'src/helpers';
import { Spinner } from 'src/components';

export const AfterCheckout: React.FC = () => {
  const { result } = useParams<{ result: string }>();
  const [resultHandled, setResultHandled] = useState<boolean>(false);
  const { infoText } = afterCheckoutViewResources;
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();

  const handleResponse = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      await checkoutService.sendResult(result, token);
    } catch (error) {
      console.error(error);
    } finally {
      const item = localStorage.getItem('demo');
      const demo: boolean = item ? JSON.parse(item) : false;
      const callback: { Success: boolean; LicenseId: string } = JSON.parse(atob(result));
      localStorage.setItem('stripeCallbackStatus', JSON.stringify(callback.Success));
      history.push(demo && callback.Success ? laboratoryRoute.path : licensesRoute.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  useEffect(() => {
    if (result && !resultHandled) {
      setResultHandled(true);
      handleResponse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, resultHandled]);

  return (
    <Flex
      className="checkout"
      w="100%"
      minH="100%"
      id="checkout"
      p={10}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner thickness="8px" speed="1s" size="xl" w="100px" h="100px" label="Loading.." />
      <Heading size="md" pt={8}>
        {infoText}
      </Heading>
    </Flex>
  );
};
