import { useState, useEffect } from 'react';

export const useDocumentTitle = (title: string): React.Dispatch<React.SetStateAction<string>> => {
  const [docTitle, setDocTitle] = useState<string>(title);
  useEffect(() => {
    document.title = `NQTB - ${docTitle}`;
  }, [docTitle]);

  return setDocTitle;
};
