export const ROLE_CLAIM = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
export const QUERY_LIMIT = 15;
export const DATE_FORMAT = 'DD.MM.YYYY';
export const qsarMail =
  window.env !== undefined && window.env.REACT_APP_QSAR_EMAIL
    ? window.env.REACT_APP_QSAR_EMAIL
    : process.env.REACT_APP_QSAR_EMAIL;
export const privacyPolicyUrl =
  window.env !== undefined && window.env.REACT_APP_PRIVACY_POLICY_URL
    ? window.env.REACT_APP_PRIVACY_POLICY_URL
    : process.env.REACT_APP_PRIVACY_POLICY_URL;

export const userLicensesEmptyList = 'Your licenses will appear here';
export const adminUserLicensesEmptyList = "User's licenses will appear here";
export const manageModelsEmptyList = 'Your endpoints will appear here';
export const manageModelsVersionsEmptyList = 'Your model verions will appear here';
export const manageModelsLicensesEmptyList = 'Licenses using this model will appear here';
export const predictionsEmptyList = 'Your predictions will appear here';
export const coresEmptyList = 'Your cores will appear here';

export const particleSizeDistribution50 =
  window.env !== undefined && window.env.REACT_APP_NANOFORM_SCALE50
    ? window.env.REACT_APP_NANOFORM_SCALE50
    : process.env.REACT_APP_NANOFORM_SCALE50;

export const particleSizeDistributionX =
  window.env !== undefined && window.env.REACT_APP_NANOFORM_SCALE_X
    ? window.env.REACT_APP_NANOFORM_SCALE_X
    : process.env.REACT_APP_NANOFORM_SCALE_X;
