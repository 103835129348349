import React, { useEffect } from 'react';
import { Flex, Box, useRadioGroup, useTheme } from '@chakra-ui/react';
import { IoEllipse } from 'react-icons/io5';
import { RadioOption } from 'src/components';
import { ICore } from 'src/model';

interface ICoreRadioOptionGroupProps {
  defaultValue?: string | number;
  onChange: (...event: unknown[]) => void;
  options: ICore[];
}

const WrapperStyles = {
  '&:not(:last-child)': {
    marginRight: '1rem',
  },
};

export const UncontrolledCoreRadioGroup: React.FC<ICoreRadioOptionGroupProps> = ({
  defaultValue,
  onChange,
  options,
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    defaultValue,
    onChange: (value: string) => {
      onChange(value);
    },
  });

  useEffect(() => {
    if (options.length === 0) return;
    if (!defaultValue) return;

    const value = options.find((option) => option.name === defaultValue);

    const radio = getRadioProps({ value: value ? defaultValue : options[0].name });

    if (!radio) return;
    if (!radio.onChange) return;

    radio.onChange(value ? defaultValue : options[0].name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.length, defaultValue]);

  const {
    colors: { button },
  } = useTheme();

  const group = getRootProps();
  return (
    <Flex {...group} flexDirection="row">
      <Flex wrap="wrap">
        {options &&
          options.map((option: ICore) => {
            const radio = getRadioProps({ value: option.name });
            return (
              <Box key={`core-${option.name}`} display="inline-block" sx={WrapperStyles} mb={2} mr={2}>
                <RadioOption {...radio}>
                  <Flex alignItems="center">
                    <IoEllipse color={option.color} size="2rem" style={{ stroke: button.white, strokeWidth: '40' }} />
                    <Box>{option.name}</Box>
                  </Flex>
                </RadioOption>
              </Box>
            );
          })}
      </Flex>
    </Flex>
  );
};
