/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { Switch, Link, useLocation, useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Flex, Box } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import {
  RedirectLoading,
  Laboratory,
  Licenses,
  BuyLicense,
  LicensesForAdmin,
  Users,
  ManageModels,
  ManageModel,
  ManageCores,
  AfterCheckout,
  ManageModelsVersion,
  PredictionDetails,
  ContactUs,
  ChooseLicenseAdmin,
  ManageReportLayouts,
} from 'src/views';
import { Navbar, MainWrapper, AppLogo, Navigation, UserMenuProfile, UserEmail, LicenseDetails } from 'src/components';
import { ProtectedRoute } from 'src/auth';
import { contactUsRoute, faqRoute, laboratoryRoute } from 'src/helpers';
import { GlobalContext, IUserFromApi, useErrorContext } from 'src/context';
import { userService, WithAxios } from 'src/services';
import NoAccessIcon from 'src/assets/no_access_icon.svg';
import { UserStatus } from './model';
import { Popup } from './components/common/Popup';
import { PopupBackground } from './components/common/PopupBackground';

const AppContent: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  const { isUserBlocked } = useErrorContext();

  const { isLoading, user, getAccessTokenSilently } = useAuth0();

  const tokenHasRole = (token: string): boolean => {
    if (!process.env.REACT_APP_ROLE_KEY_IN_TOKEN) {
      return true;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join('')
    );
    const parsedToken = JSON.parse(jsonPayload);
    const roles = parsedToken[process.env.REACT_APP_ROLE_KEY_IN_TOKEN];
    return roles && roles.length > 0;
  };

  const fetchUserData = async (): Promise<IUserFromApi> => {
    if (!user) return Promise.reject();

    const token = await getAccessTokenSilently();
    if (!tokenHasRole(token)) {
      // if token has no roles app won't work, this happens just after sign-up
      console.log('referer is ', document.referrer);
      if (document.referrer === '') {
        window.location.reload();
      }
    }

    return userService.getUserById(user.sub, token).then((response) => response.data);
  };

  const { data: userData, isLoading: isUserDataLoading, refetch: refetchUserData } = useQuery(
    `fetch-user-data`,
    fetchUserData
  );

  useEffect(() => {
    refetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (isLoading || isUserDataLoading) {
    return <RedirectLoading />;
  }

  return (
    <Flex className="App" direction="column" w="100%" h="100vh">
      <Navbar>
        <Box
          flex={1}
          alignItems="center"
          sx={{
            '& a': {
              display: 'inline-block',
            },
          }}
        >
          <Link to={laboratoryRoute.path}>
            <AppLogo h="50px" />
          </Link>
        </Box>
        <Navigation />
        <Flex flex={1} justifyContent="flex-end" alignItems="center">
          <UserEmail />
          <UserMenuProfile />
        </Flex>
      </Navbar>
      <MainWrapper>
        <Switch>
          <ProtectedRoute key="laboratory" exact path="/" component={Laboratory} requiredRoles={['User', 'Admin']} />
          <ProtectedRoute key="licenses" path="/licenses" component={Licenses} requiredRoles={['User']} />
          <ProtectedRoute key="buylicense" path="/buylicense" component={BuyLicense} requiredRoles={['User']} />
          <ProtectedRoute
            key="chooseLicense"
            path="/chooseLicense/:userId"
            component={ChooseLicenseAdmin}
            requiredRoles={['Admin']}
          />
          <ProtectedRoute
            key="license details"
            path="/licensedetails/:licenseId"
            component={LicenseDetails}
            requiredRoles={['User', 'Admin']}
          />
          <ProtectedRoute
            key="user licenses"
            exact
            path="/manageLicenses/:userId"
            component={LicensesForAdmin}
            requiredRoles={['Admin']}
          />
          <ProtectedRoute
            key="manage licenses"
            exact
            path="/manageLicenses"
            component={Users}
            requiredRoles={['Admin']}
          />
          <ProtectedRoute key="managemodels" path="/managemodels" component={ManageModels} requiredRoles={['Admin']} />
          <ProtectedRoute
            key="modeldetails"
            exact
            path="/modeldetails/:modelId"
            component={ManageModel}
            requiredRoles={['Admin']}
          />
          <ProtectedRoute
            key="models version details"
            exact
            path="/modeldetails/:modelId/versiondetails/:versionId"
            component={ManageModelsVersion}
            requiredRoles={['Admin']}
          />
          <ProtectedRoute
            key="prediction details"
            exact
            path="/prediction/:predictionId"
            component={PredictionDetails}
            requiredRoles={['Admin', 'User']}
          />
          <ProtectedRoute
            key="checkout"
            exact
            path="/checkout/:result"
            component={AfterCheckout}
            requiredRoles={['User']}
          />
          <ProtectedRoute
            key="contact-us"
            exact
            path="/contact-us"
            component={ContactUs}
            requiredRoles={['User', 'Admin']}
          />
          <ProtectedRoute
            key="manage Core"
            exact
            path="/managecores"
            component={ManageCores}
            requiredRoles={['Admin']}
          />
          <ProtectedRoute
            key="manage report layouts"
            exact
            path="/managereportlayouts"
            component={ManageReportLayouts}
            requiredRoles={['Admin']}
          />
        </Switch>
        {!isUserDataLoading &&
          userData &&
          (userData.status === UserStatus.Blocked || isUserBlocked) &&
          location.pathname !== contactUsRoute.path &&
          location.pathname !== faqRoute.path && (
            <PopupBackground position="absolute">
              <Popup
                iconSrc={NoAccessIcon}
                problemMessage="Your account is blocked"
                solveMessage="If you want to get your access back, reach out to us"
                buttonMessage="Contact us"
                canClose={false}
                setVisibility={() => {}}
                onButtonClick={() => {
                  history.push('/contact-us');
                }}
              />
            </PopupBackground>
          )}
      </MainWrapper>
    </Flex>
  );
};
const App: React.FC = () => {
  return (
    <GlobalContext>
      <WithAxios>
        <AppContent />
      </WithAxios>
    </GlobalContext>
  );
};

export default App;
