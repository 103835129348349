import React, { FC, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useAuth0 } from '@auth0/auth0-react';
import { Table, Tbody, Box, Tr, Text, Flex, Image } from '@chakra-ui/react';
import { TableHeader, TableData, EmptyListState, Spinner } from 'src/components';
import { DATE_FORMAT, downloadFile, getFilenameFromHeader, manageLayoutsResources } from 'src/helpers';
import { IReport, LayoutNames, ReportTypeEnum } from 'src/model';
import { reportService } from 'src/services';
import { ReactComponent as EditIcon } from 'src/assets/edit.svg';
import DownloadIcon from 'src/assets/download.svg';
import { useReportWizardActionsContext } from 'src/context';

interface IReportsListProps {
  data: IReport[];
}

export const ReportsList: FC<IReportsListProps> = ({ data }) => {
  const { getAccessTokenSilently } = useAuth0();

  const { handlePopupVisibility, setReportType, setReportId } = useReportWizardActionsContext();

  const [contentLoadings, setContentLoadings] = useState<boolean[]>([]);
  const [footerLoadings, setFooterLoadings] = useState<boolean[]>([]);

  const onDownload = async (id: string, layoutName: LayoutNames, index: number) => {
    if (layoutName === LayoutNames.Content) {
      const loadings = [...contentLoadings];
      loadings[index] = true;
      setContentLoadings(loadings);
    } else {
      const loadings = [...footerLoadings];
      loadings[index] = true;
      setFooterLoadings(loadings);
    }
    try {
      const token = await getAccessTokenSilently();

      const response =
        layoutName === LayoutNames.Content
          ? await reportService.getContentFile(id, token)
          : await reportService.getFooterFile(id, token);

      const contentDispositionHeader = response.headers['content-disposition'];

      const filename = getFilenameFromHeader(contentDispositionHeader);

      await downloadFile(response, filename);
    } catch (error) {
      console.error(error);
    } finally {
      setContentLoadings([]);
      setFooterLoadings([]);
    }
  };

  const getReportType = (report: IReport) => {
    if (report.reportType.toString() === ReportTypeEnum.QSAR) return ReportTypeEnum.QSAR;
    return ReportTypeEnum.RA;
  };

  useEffect(() => {
    const newContentLoadings: boolean[] = [];
    const newFooterLoadings: boolean[] = [];

    data.forEach(() => {
      newContentLoadings.push(false);
      newFooterLoadings.push(false);
    });

    setContentLoadings(newContentLoadings);
    setFooterLoadings(newFooterLoadings);
  }, [data]);

  return (
    <Box className="users-list" w="100%">
      {data.length ? (
        <Table
          variant="unstyled"
          w="100%"
          h="100%"
          sx={{ borderCollapse: 'separate', borderSpacing: '0 10px', tableLayout: 'auto' }}
          cellSpacing="0"
        >
          <TableHeader titles={['Publish date', 'Last modified', 'Changed by']} />
          <Tbody>
            {data.map((report: IReport, idx: number) => (
              <Tr key={`report-${report.id}`}>
                <TableData>
                  <Text mx="auto">
                    {report.createdDate == null ? '-' : dayjs(report.createdDate).format(DATE_FORMAT)}
                  </Text>
                </TableData>
                <TableData>
                  <Text>{report.modifiedDate == null ? '-' : dayjs(report.modifiedDate).format(DATE_FORMAT)}</Text>
                </TableData>
                <TableData>
                  <Text>{report.userName ?? '-'}</Text>
                </TableData>
                <TableData w="18%">
                  <Flex
                    bg="#F4F4F4"
                    minH="52px"
                    w="full"
                    h="full"
                    onClick={() => onDownload(report.id, LayoutNames.Content, idx)}
                    cursor="pointer"
                    px={2}
                    rounded="lg"
                    border="solid 1px"
                    borderColor="#E3E2E2"
                    fontWeight="semibold"
                    justifyContent="center"
                    _hover={{
                      bg: '#E3E2E2',
                    }}
                  >
                    <Box my="auto" mr={2}>
                      {contentLoadings[idx] && <Spinner />}
                      {!contentLoadings[idx] && <Image src={DownloadIcon} />}
                    </Box>
                    <Text my="auto">{manageLayoutsResources.downloadContent}</Text>
                  </Flex>
                </TableData>
                <TableData w="17%">
                  <Flex
                    bg="#F4F4F4"
                    w="full"
                    h="full"
                    onClick={() => onDownload(report.id, LayoutNames.Footer, idx)}
                    cursor="pointer"
                    px={2}
                    rounded="lg"
                    border="solid 1px"
                    borderColor="#E3E2E2"
                    fontWeight="semibold"
                    justifyContent="center"
                    _hover={{
                      bg: '#E3E2E2',
                    }}
                  >
                    <Box my="auto" mr={2}>
                      {footerLoadings[idx] && <Spinner />}
                      {!footerLoadings[idx] && <Image src={DownloadIcon} />}
                    </Box>
                    <Text my="auto">{manageLayoutsResources.downloadFooter}</Text>
                  </Flex>
                </TableData>
                <TableData w="5%">
                  <Box
                    cursor="pointer"
                    onClick={() => {
                      setReportId(report.id);
                      setReportType(getReportType(report));
                      handlePopupVisibility();
                    }}
                  >
                    <EditIcon />
                  </Box>
                </TableData>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <EmptyListState title={manageLayoutsResources.noReports} />
      )}
    </Box>
  );
};
