import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { AxiosResponse } from 'axios';
import {
  HStack,
  Heading,
  Flex,
  useTheme,
  Spacer,
  Badge,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Tabs,
} from '@chakra-ui/react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';
import { useQuery, useInfiniteQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { manageModelsRoute, QUERY_LIMIT, modelDetailsViewResources } from 'src/helpers';
import { modelService } from 'src/services';
import {
  ModelDetails,
  ModelVersionsList,
  PrimaryButton,
  Spinner,
  NewModelWizard,
  ModelLicensesList,
  PopupBackground,
  Popup,
  CancelButton,
} from 'src/components';
import { IModelLicenseListResponse, IModelVersionListResponse, ModelStatus } from 'src/model';
import { useNewModelWizardActionsContext, useNewModelWizardContext } from 'src/context';
import { useDocumentTitle, useSuccessToast } from 'src/hooks';

export const ManageModel: React.FC = () => {
  const changeDocumentTitle = useDocumentTitle(modelDetailsViewResources.documentTitle);
  const { modelId } = useParams<{ modelId: string }>();

  const { newVersionText, versionsHeading, licensesHeading, modifyModelText, deleteModel } = modelDetailsViewResources;

  const { getAccessTokenSilently } = useAuth0();

  const history = useHistory();

  const deletedSuccessfullyToast = useSuccessToast(
    deleteModel.toasts.success.title,
    deleteModel.toasts.success.description
  );

  const {
    colors: { button },
  } = useTheme();

  const { isWizardOpen, activeTabIndex } = useNewModelWizardContext();
  const {
    onWizardOpen,
    reset,
    setCurrentStep,
    setVersion,
    setModel,
    setIsModelEdited,
    setActiveIndex,
  } = useNewModelWizardActionsContext();

  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);

  const fetchModelDetails = async (id: string) => {
    const token = await getAccessTokenSilently();
    return modelService.getModelDetails(id, token).then((response: AxiosResponse) => response.data);
  };

  const { data: modelDetails, isLoading: isModelLoading, refetch: refetchModel } = useQuery(
    ['model-details', modelId],
    () => fetchModelDetails(modelId)
  );

  const fetchVersions = async ({ pageParam = 0 }) => {
    const token = await getAccessTokenSilently();
    return modelService
      .getModelVersions(modelId, QUERY_LIMIT, pageParam, token)
      .then((response: AxiosResponse) => response.data);
  };

  const {
    data: versionsList,
    isLoading: isLoadingVersions,
    isError: isErrorVersions,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(['model-versions-list', modelId], fetchVersions, {
    getNextPageParam: (lastPage: IModelVersionListResponse) =>
      lastPage.hasNext ? lastPage.offset + QUERY_LIMIT : undefined,
  });

  const fetchModelLicenses = async ({ pageParam = 0 }) => {
    const token = await getAccessTokenSilently();
    return modelService
      .getModelLicenses(modelId, QUERY_LIMIT, pageParam, token)
      .then((response: AxiosResponse) => response.data);
  };

  const {
    data: licensesList,
    isLoading: isLoadingLicenses,
    isError: isErrorLicenses,
    hasNextPage: hasMoreLicenses,
    fetchNextPage: fetchMoreLicenses,
  } = useInfiniteQuery(['model-licenses-list', modelId], fetchModelLicenses, {
    getNextPageParam: (lastPage: IModelLicenseListResponse) =>
      lastPage.hasNext ? lastPage.offset + QUERY_LIMIT : undefined,
  });

  const openNewVersionWizard = async () => {
    if (modelDetails) {
      try {
        setCurrentStep(2);

        const token = await getAccessTokenSilently();
        const {
          data: { nextVersionNumber },
        } = await modelService.getNextVersionsNumber(modelId, token);

        setVersion((prevState) => ({
          ...prevState,
          createdDate: new Date(),
          version: nextVersionNumber,
        }));

        setModel(modelDetails);
        onWizardOpen();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const openModifyModelWizzard = () => {
    setCurrentStep(1);
    setModel(modelDetails);
    setIsModelEdited(true);
    onWizardOpen();
  };

  const statusBadge = (status: ModelStatus) => {
    if (status === ModelStatus.Active)
      return (
        <Badge colorScheme="green" color="green" py={1} px="12px" fontSize="md" fontWeight="500">
          Active
        </Badge>
      );
    if (status === ModelStatus.ToRemove)
      return (
        <Badge colorScheme="gray" color="gray" py={1} px="12px" fontSize="md" fontWeight="500">
          To Remove
        </Badge>
      );
    return (
      <Badge colorScheme="red" color="utils.alert" py={1} px="12px" fontSize="md" fontWeight="500">
        Deactivated
      </Badge>
    );
  };

  const onDeleteModel = async () => {
    const token = await getAccessTokenSilently();
    modelService.deleteModel(modelDetails.id, token).then((response) => {
      setIsDeletePopupOpen(false);

      if (response) {
        history.push(manageModelsRoute.path);
        deletedSuccessfullyToast();
      }
    });
  };

  return (
    <>
      {isModelLoading && (
        <Flex w="100%" flex="1" justifyContent="center" alignItems="center">
          <Spinner thickness="8px" speed="1s" size="xl" w="100px" h="100px" label="Loading.." />
        </Flex>
      )}
      {!isModelLoading && modelDetails && (
        <Flex
          className="model-details"
          w="100%"
          minH="100%"
          overflowY="auto"
          id="model-details"
          p={8}
          flexDirection="column"
        >
          <Flex
            as="header"
            className="model__header"
            justifyContent="space-between"
            alignItems="center"
            pb={10}
            w="100%"
          >
            <HStack spacing={4} maxW="calc(100% - 130px)">
              <Link to={manageModelsRoute.path}>
                <IoArrowBack color={button.blue} size="1.5rem" />
              </Link>
              <Heading fontWeight="700" size="xl" isTruncated>
                {modelDetails.name}
              </Heading>
              {statusBadge(modelDetails.status)}
            </HStack>
            <Flex>
              {modelDetails.canBeDeleted && (
                <>
                  {isDeletePopupOpen && (
                    <PopupBackground position="fixed">
                      <Popup
                        problemMessage={deleteModel.popupProblem}
                        solveMessage={deleteModel.popupSolve}
                        buttonMessage={deleteModel.popupButton}
                        buttonType="cancel"
                        isBackButtonVisible
                        setVisibility={setIsDeletePopupOpen}
                        onButtonClick={onDeleteModel}
                      />
                    </PopupBackground>
                  )}
                  <CancelButton onClick={() => setIsDeletePopupOpen(true)}>{deleteModel.button}</CancelButton>
                  <Spacer w="1.5rem" />
                </>
              )}
              <PrimaryButton className="new-model-button" size="sm" onClick={openModifyModelWizzard}>
                {modifyModelText}
              </PrimaryButton>
              <Spacer w="1.5rem" />
              <PrimaryButton className="new-model-button" size="sm" onClick={openNewVersionWizard}>
                {newVersionText}
              </PrimaryButton>
            </Flex>
          </Flex>
          <AnimatePresence
            exitBeforeEnter
            onExitComplete={() => {
              reset();
              changeDocumentTitle(modelDetailsViewResources.documentTitle);
            }}
          >
            {isWizardOpen && (
              <NewModelWizard newVersion existingModelId={modelId} onModelUpdated={() => refetchModel()} />
            )}
          </AnimatePresence>
          <Flex>
            <Flex
              flexDirection="column"
              bg="typo.white"
              borderRadius="0.25rem"
              borderWidth="1px"
              borderColor="input.border"
              maxW="400px"
              minW="300px"
              p={6}
            >
              <ModelDetails model={modelDetails} />
            </Flex>
            <Flex flex={1} pl={8}>
              {(isLoadingVersions || isLoadingLicenses) && (
                <Flex w="100%" flex="1" justifyContent="center" alignItems="center">
                  <Spinner thickness="8px" speed="1s" size="xl" w="100px" h="100px" label="Loading.." />
                </Flex>
              )}
              {!isLoadingVersions && versionsList && (
                <Flex flexDirection="column" flex={1}>
                  <Tabs variant="unstyled" index={activeTabIndex}>
                    <TabList>
                      <Tab
                        _selected={{ fontWeight: '650' }}
                        _focus={{ boxShadow: 'none' }}
                        transition="none"
                        mr={4}
                        onClick={() => setActiveIndex(0)}
                      >
                        <Heading className="header__title" fontWeight="inherit" size="lg" pb={8}>
                          {versionsHeading}
                        </Heading>
                      </Tab>
                      <Tab
                        _selected={{ fontWeight: '650' }}
                        _focus={{ boxShadow: 'none' }}
                        transition="none"
                        onClick={() => setActiveIndex(1)}
                      >
                        <Heading className="header__title" fontWeight="inherit" size="lg" pb={8}>
                          {licensesHeading}
                        </Heading>
                      </Tab>
                    </TabList>

                    <TabPanels>
                      <TabPanel>
                        {versionsList && hasNextPage !== undefined && !isErrorVersions && (
                          <ModelVersionsList
                            data={[...versionsList.pages.map((page: IModelVersionListResponse) => page.list)].flat()}
                            getMoreData={fetchNextPage}
                            hasMore={hasNextPage}
                            model={modelDetails}
                          />
                        )}
                      </TabPanel>
                      <TabPanel>
                        {licensesList && hasMoreLicenses !== undefined && !isErrorLicenses && (
                          <ModelLicensesList
                            data={[...licensesList.pages.map((page: IModelLicenseListResponse) => page.list)].flat()}
                            getMoreData={fetchMoreLicenses}
                            hasMore={hasMoreLicenses}
                          />
                        )}
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
};
