import { AxiosPromise, AxiosResponse } from 'axios';
import { INanoForm } from 'src/model';
import { authorizedApiService } from './api.service';

class NanoformService {
  // GET

  getCurrentUserNanoforms = (token: string): AxiosPromise => {
    const apiUrl = '/nanoforms';
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getNanoformById = (id: string, token: string): AxiosPromise => {
    const apiUrl = `/nanoforms/${encodeURI(id)}`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  create = (nanoform: INanoForm, token: string): AxiosPromise => {
    const apiUrl = `/nanoforms/`;
    return authorizedApiService(token)
      .post(apiUrl, nanoform)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };
}

export const nanoformService = new NanoformService();
