import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { ResizeObserver } from '@juggle/resize-observer';
import { Box, useTheme } from '@chakra-ui/react';
import { Canvas } from 'react-three-fiber';
import { useNanoformContext } from 'src/context';
import { Cubic, Platelet, Polyhedral, Rod, Spherical, Tube, Wires } from 'src/components';
import { Shape, SurfaceFunctionalizationColor, ShapeParticleSizeDistributionSize } from 'src/model';
import { Floor } from './Floor';
import { ParticleSizeDistribution } from './ParticleSizeDistribution';
import { Inconclusive } from './shapes/Inconclusive';

const Visualisation: React.FC = () => {
  const {
    nanoformData: { particleSizeDistributionValue, shape, surfaceFunctionalization, core, coreModification },
    visualisationRef,
    isNanoformImageGenerated,
  } = useNanoformContext();

  const {
    colors: {
      particles: { line },
    },
  } = useTheme();

  const [coreColor, setCoreColor] = useState('');

  useEffect(() => {
    if (core !== undefined) {
      setCoreColor(core?.color);
    }
  }, [core]);

  const renderShape = useCallback(
    (modelShape: Shape | undefined) => {
      switch (modelShape) {
        case 'Cubic':
          return (
            <Cubic
              core={core}
              coreModification={coreModification}
              surfaceFunctionalization={surfaceFunctionalization}
              coreColor={coreColor}
              surfaceFunctionalizationColor={SurfaceFunctionalizationColor}
              lineColor={line}
              baseRotation={[Math.PI / 18, Math.PI / 4, 0]}
              isNanoformImageGenerated={isNanoformImageGenerated}
            />
          );
        case 'Spherical':
          return (
            <Spherical
              core={core}
              coreModification={coreModification}
              surfaceFunctionalization={surfaceFunctionalization}
              coreColor={coreColor}
              surfaceFunctionalizationColor={SurfaceFunctionalizationColor}
              lineColor={line}
              baseRotation={[0, 0, 0]}
              isNanoformImageGenerated={isNanoformImageGenerated}
            />
          );
        case 'Platelet':
          return (
            <Platelet
              core={core}
              coreModification={coreModification}
              surfaceFunctionalization={surfaceFunctionalization}
              coreColor={coreColor}
              surfaceFunctionalizationColor={SurfaceFunctionalizationColor}
              lineColor={line}
              baseRotation={[Math.PI / 18, Math.PI / 4, 0]}
              isNanoformImageGenerated={isNanoformImageGenerated}
            />
          );
        case 'Polyhedral':
          return (
            <Polyhedral
              core={core}
              coreModification={coreModification}
              surfaceFunctionalization={surfaceFunctionalization}
              coreColor={coreColor}
              surfaceFunctionalizationColor={SurfaceFunctionalizationColor}
              lineColor={line}
              baseRotation={[Math.PI / 10, 0, 0]}
              isNanoformImageGenerated={isNanoformImageGenerated}
            />
          );
        case 'Rod':
          return (
            <Rod
              core={core}
              coreModification={coreModification}
              surfaceFunctionalization={surfaceFunctionalization}
              coreColor={coreColor}
              surfaceFunctionalizationColor={SurfaceFunctionalizationColor}
              lineColor={line}
              baseRotation={[0, Math.PI / 4, Math.PI / 2]}
              isNanoformImageGenerated={isNanoformImageGenerated}
            />
          );
        case 'Tube':
          return (
            <Tube
              core={core}
              coreModification={coreModification}
              surfaceFunctionalization={surfaceFunctionalization}
              coreColor={coreColor}
              surfaceFunctionalizationColor={SurfaceFunctionalizationColor}
              lineColor={line}
              baseRotation={[0, Math.PI / 4, Math.PI / 2]}
              isNanoformImageGenerated={isNanoformImageGenerated}
            />
          );
        case 'Wire':
          return (
            <Wires
              core={core}
              coreModification={coreModification}
              surfaceFunctionalization={surfaceFunctionalization}
              coreColor={coreColor}
              surfaceFunctionalizationColor={SurfaceFunctionalizationColor}
              lineColor={line}
              baseRotation={[Math.PI / 18, Math.PI / 4, Math.PI / 2]}
              isNanoformImageGenerated={isNanoformImageGenerated}
            />
          );
        case 'Inconclusive':
        default:
          return (
            <Inconclusive
              core={core}
              coreModification={coreModification}
              surfaceFunctionalization={surfaceFunctionalization}
              coreColor={coreColor}
              surfaceFunctionalizationColor={SurfaceFunctionalizationColor}
              lineColor={line}
              baseRotation={[Math.PI / 18, Math.PI / 4, Math.PI / 2]}
              isNanoformImageGenerated={isNanoformImageGenerated}
            />
          );
      }
    },
    [core, coreModification, coreColor, surfaceFunctionalization, line, isNanoformImageGenerated]
  );

  return (
    <Box className="visualisation" h="100%" w="100%" position="relative" ref={visualisationRef}>
      <Canvas
        className="visualisation__scene"
        pixelRatio={window.devicePixelRatio}
        style={{ width: '100%', height: '100%' }}
        resize={{ polyfill: ResizeObserver }}
        concurrent
        gl={{ preserveDrawingBuffer: true }}
      >
        <ambientLight castShadow color="white" intensity={1.5} />
        <pointLight position={[0, 5, 10]} color="#fff" />
        <Suspense fallback={null}>
          <Floor />
          {renderShape(shape)}
        </Suspense>
      </Canvas>
      {particleSizeDistributionValue > 0 && (
        <ParticleSizeDistribution
          particleSizeDistribution50={particleSizeDistributionValue}
          size={
            shape ? ShapeParticleSizeDistributionSize[shape as keyof typeof ShapeParticleSizeDistributionSize] : '200px'
          }
        />
      )}
    </Box>
  );
};

export const MemoizedVisualisation = React.memo(Visualisation);
