import React from 'react';
import { Controller, Control } from 'react-hook-form';
import { Input } from '@chakra-ui/react';

interface IControlledInputProps {
  name: string;
  control: Control<Record<string, unknown>>;
  onChangeInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
  placeholder?: string;
}

export const ControlledInput: React.FC<IControlledInputProps> = ({
  name,
  control,
  onChangeInput,
  isDisabled,
  placeholder,
}) => (
  <Controller
    name={name}
    control={control}
    render={({ onChange, name: renderName, value, ref }, { invalid }) => (
      <Input
        variant="outline"
        type="text"
        ref={ref}
        name={renderName}
        borderWidth="1px"
        value={value}
        placeholder={placeholder}
        errorBorderColor="utils.alert"
        onChange={(e) => {
          onChange(e);
          if (onChangeInput) {
            onChangeInput(e);
          }
        }}
        isInvalid={invalid}
        isDisabled={isDisabled}
      />
    )}
  />
);
