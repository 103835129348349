import React from 'react';
import { Menu, MenuButton, Icon, MenuList, MenuItem, Button } from '@chakra-ui/react';
import { IoChevronDown } from 'react-icons/io5';
import { scrollbar } from 'src/helpers';

interface INanoformsMenuProps {
  selectedNanoformName: string;
  defaultMenuLabel: string;
  nanoformsData: { id: string; predictionName: string }[];
  onMenuItemClick: (
    value: React.SetStateAction<{
      id: string;
      name: string;
    }>
  ) => void;
}

export const NanoformsMenu: React.FC<INanoformsMenuProps> = ({
  selectedNanoformName,
  defaultMenuLabel,
  nanoformsData,
  onMenuItemClick,
}) => (
  <Menu>
    <MenuButton
      as={Button}
      mb={6}
      bg="menuButton.bg"
      borderColor="menuButton.border"
      borderWidth="1px"
      borderRadius="0.25rem"
    >
      {selectedNanoformName || defaultMenuLabel}
      <Icon as={IoChevronDown} ml={2} w="1.3rem" h="1.3rem" />
    </MenuButton>
    <MenuList maxH="400px" sx={scrollbar} overflowY="auto">
      {nanoformsData.map((dataItem) => (
        <MenuItem key={dataItem.id} onClick={() => onMenuItemClick({ id: dataItem.id, name: dataItem.predictionName })}>
          {dataItem.predictionName}
        </MenuItem>
      ))}
    </MenuList>
  </Menu>
);
