import React from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import { LicenseBuyOption } from 'src/components';
import { licenseBuyOptions } from 'src/helpers';
import { ILicenseBuyOption, UserStatus } from 'src/model';

export interface UserInfo {
  email: string;
  status: UserStatus;
  id: string;
}

export interface LicenseBuyOptionsGridProps {
  isAdmin?: boolean;
  userInfo?: UserInfo;
}

export const LicenseBuyOptionsGrid: React.FC<LicenseBuyOptionsGridProps> = ({ isAdmin, userInfo }) => (
  <SimpleGrid
    className="license-buy-options-grid"
    spacing="40px"
    columns={[2, 2, 2, 3, 4]}
    minChildWidth="320px"
    mt={5}
    mb={10}
  >
    {licenseBuyOptions.map((licenseOption: ILicenseBuyOption) => (
      <LicenseBuyOption
        key={`license_buy_option_${licenseOption.title.main}_${licenseOption.title.sub}`}
        title={licenseOption.title}
        priceBefore={licenseOption.priceBefore}
        priceAfter={licenseOption.priceAfter}
        listItems={licenseOption.listItems}
        buttonText={licenseOption.buttonText}
        extraInfo={licenseOption.extraInfo}
        path={licenseOption.path}
        licenseOptions={licenseOption.licenseOptions}
        isAdmin={isAdmin}
        userInfo={userInfo}
      />
    ))}
  </SimpleGrid>
);
