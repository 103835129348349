import React from 'react';
import { Box, Divider, Text } from '@chakra-ui/react';

interface IManyDataWithLabelProps {
  label: string;
  values: string[];
  preformatted?: boolean;
  preserveTextTransform?: boolean;
}

export const ManyDataWithLabel: React.FC<IManyDataWithLabelProps> = ({
  label,
  values,
  preformatted,
  preserveTextTransform,
}) => (
  <Box fontWeight="500" pb={6}>
    <Text color="typo.grey" pb={2} textTransform={preserveTextTransform ? 'none' : 'uppercase'}>
      {label}
    </Text>
    {values.map((value) => (
      <Text
        id={value}
        as={value !== '' ? 'p' : 'span'}
        color="custom.glaucous"
        minH="1.5rem"
        display="flex"
        alignItems="center"
        whiteSpace={preformatted ? 'pre' : undefined}
      >
        {value}
      </Text>
    ))}

    {values.length === 0 && (
      <Divider orientation="horizontal" borderColor="typo.blue" maxW="60px" borderBottomWidth="2px" />
    )}
  </Box>
);
