import React from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider as Provider, AppState } from '@auth0/auth0-react';

interface IAuth0ProviderProps {
  children?: React.ReactNode;
}

export const Auth0Provider: React.FC = ({ children }: IAuth0ProviderProps) => {
  const domain =
    window.env !== undefined && window.env.REACT_APP_AUTH0_DOMAIN
      ? window.env.REACT_APP_AUTH0_DOMAIN
      : process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId =
    window.env !== undefined && window.env.REACT_APP_AUTH0_CLIENT_ID
      ? window.env.REACT_APP_AUTH0_CLIENT_ID
      : process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience =
    window.env !== undefined && window.env.REACT_APP_AUTH0_AUDIENCE
      ? window.env.REACT_APP_AUTH0_AUDIENCE
      : process.env.REACT_APP_AUTH0_AUDIENCE;

  const history = useHistory();

  const onRedirectCallback = (appState: AppState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
    >
      {children}
    </Provider>
  );
};
