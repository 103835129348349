import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Heading,
  ModalCloseButton,
  ModalBody,
  Flex,
  Text,
  Spacer,
} from '@chakra-ui/react';
import { PrimaryButton, SecondaryButton } from 'src/components';
import { licenseConsumeModalResources } from './licenseConsumeModalResources';

interface ILicenseConsumeModalProps {
  isOpen: boolean;
  predictionsAttemptsCount: number;
  closeModal: () => void;
  modalAction: () => void;
}

export const LicenseConsumeModal: React.FC<ILicenseConsumeModalProps> = ({
  isOpen,
  predictionsAttemptsCount,
  closeModal,
  modalAction,
}) => {
  const {
    title,
    secondaryButtonText,
    primaryButtonText,
    content,
    outOfDomainInfo,
    inDomainInfo,
  } = licenseConsumeModalResources;

  const formattedOutOfDomainInfo = (): string => {
    const withInserted = outOfDomainInfo.replace('-1', predictionsAttemptsCount.toString());
    return predictionsAttemptsCount === 1 ? withInserted.replace('predictions', 'prediction') : withInserted;
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} isCentered size="3xl" autoFocus={false}>
      <ModalOverlay bg="utils.modalOverlay" />
      <ModalContent borderRadius="0.25rem" bg="custom.aliceBlue" borderWidth="1px" borderColor="utils.border">
        <ModalHeader px={8} pt={8} pb={4}>
          <Heading size="xl">{title}</Heading>
          <ModalCloseButton mt={7} mr={7} colorScheme="brand" size="md" onClick={closeModal} />
        </ModalHeader>
        <ModalBody p={8}>
          {predictionsAttemptsCount > 0 ? (
            <>
              <Text fontWeight="500" overflowWrap="normal">
                {formattedOutOfDomainInfo()}
              </Text>
              <Spacer h="1.5rem" />
              <Text fontWeight="500" overflowWrap="normal">
                {inDomainInfo}
              </Text>
            </>
          ) : (
            <Text fontWeight="500" whiteSpace="pre">
              {content}
            </Text>
          )}
          <Flex w="100%" justifyContent="flex-end" pt={12}>
            <SecondaryButton mr={4} onClick={closeModal}>
              {secondaryButtonText}
            </SecondaryButton>
            <PrimaryButton onClick={modalAction}>{primaryButtonText}</PrimaryButton>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
