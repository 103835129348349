import React, { FC, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AxiosResponse } from 'axios';
import { Box, Text, Flex, Heading, Image } from '@chakra-ui/react';
import { downloadFile, getFilenameFromHeader, scrollbar } from 'src/helpers';
import { WizardNav, reportsWizardResources, FileDropzone, UploadFileButton } from 'src/components';
import { predictionService, reportService } from 'src/services';
import { ReportTypeEnum } from 'src/model';
import { useSuccessToast } from 'src/hooks';
import { useReportWizardActionsContext, useReportWizardContext } from 'src/context';
import HtmlFile from 'src/assets/html-file.svg';
import { useParams } from 'react-router-dom';

interface IFile {
  name: string;
  data: string;
}

interface IUploadZoneProps {
  title: string;
  onUpload: (name: string, data: string) => Promise<void>;
}

interface IUploadedFileProps {
  title: string;
  file: IFile;
  errors: string[];
  onUpload: (name: string, data: string) => Promise<void>;
}

export const CreateEditReport: FC = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { predictionId: testedPredictionId } = useParams<{ predictionId: string }>();

  const successToast = useSuccessToast('Success', 'Files uploaded');

  const { setReportWasSaved, onWizardClose } = useReportWizardActionsContext();
  const { reportType, reportId, isTesting } = useReportWizardContext();

  const [content, setContent] = useState<IFile>();
  const [contentErrors, setContentErrors] = useState<string[]>([]);
  const [footer, setFooter] = useState<IFile>();
  const [footerErrors, setFooterErrors] = useState<string[]>([]);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const getHeading = (): string => {
    if (!reportType) return reportsWizardResources.createEditReport.heading;

    if (reportId) {
      return reportType === ReportTypeEnum.QSAR
        ? reportsWizardResources.createEditReport.qsarEditHeading
        : reportsWizardResources.createEditReport.raEditHeading;
    }

    return reportType === ReportTypeEnum.QSAR
      ? reportsWizardResources.createEditReport.qsarHeading
      : reportsWizardResources.createEditReport.raHeading;
  };

  const isDisablingCondition = (): boolean => {
    if (contentErrors.length > 0 || footerErrors.length > 0) return true;

    if ((reportId || isTesting) && !content && !footer) return true;

    if (!isTesting && !reportId && (!content || !footer)) return true;

    return false;
  };

  const mockFile = (name: string): IFile => {
    return { name, data: 'empty' };
  };

  const validateFile = async (name: string, data: string) => {
    const token = await getAccessTokenSilently();
    return reportService.validateReportLayout(name, data, token).then((response: AxiosResponse) => response.data);
  };

  const checkIfHtml = (data: string): boolean => {
    if (!data.includes('data:text/html;base64,')) return false;

    return true;
  };

  const onContentUpload = async (name: string, data: string) => {
    if (!checkIfHtml(data)) {
      setContent({ name, data });
      setContentErrors([reportsWizardResources.createEditReport.wrongFileFormat]);
      return;
    }

    try {
      const error: string[] = await validateFile(name, data.replace('data:text/html;base64,', ''));
      setContent({ name, data });
      if (error.length > 0) {
        setContentErrors(error);
        return;
      }
      setContentErrors([]);
    } catch (error) {
      console.error(error);
    }
  };

  const onFooterUpload = async (name: string, data: string) => {
    if (!checkIfHtml(data)) {
      setFooter({ name, data });
      setFooterErrors([reportsWizardResources.createEditReport.wrongFileFormat]);
      return;
    }

    try {
      const error = await validateFile(name, data.replace('data:text/html;base64,', ''));
      setFooter({ name, data });
      if (error.length > 0) {
        setFooterErrors(error);
        return;
      }
      setFooterErrors([]);
    } catch (error) {
      console.error(error);
    }
  };

  const onSave = async () => {
    if (!content && !reportId) {
      setContentErrors([reportsWizardResources.createEditReport.noFile]);
    }
    if (!footer && !reportId) {
      setFooterErrors([reportsWizardResources.createEditReport.noFile]);
    }

    const token = await getAccessTokenSilently();

    let response;

    if (!reportId && content && footer && reportType) {
      response = await reportService.createReportLayout(
        user.sub,
        content.data.replace('data:text/html;base64,', ''),
        footer.data.replace('data:text/html;base64,', ''),
        reportType,
        token
      );
    } else {
      response = await reportService.updateReportVersion(
        reportId,
        user.sub,
        token,
        content ? content.data.replace('data:text/html;base64,', '') : undefined,
        footer ? footer.data.replace('data:text/html;base64,', '') : undefined
      );
    }

    if (!response) return;

    successToast();
    setReportWasSaved(true);
    onWizardClose();
  };

  const onTest = async () => {
    try {
      setIsDownloading(true);

      const contentToSend = content ? content.data.replace('data:text/html;base64,', '') : null;
      const footerToSend = footer ? footer.data.replace('data:text/html;base64,', '') : null;
      const token = await getAccessTokenSilently();
      const getPredictionReports = await predictionService.getPredictionReports(
        testedPredictionId,
        contentToSend,
        footerToSend,
        null,
        null,
        token
      );

      if (getPredictionReports) {
        const contentDispositionHeader = getPredictionReports.headers['content-disposition'];

        const filename = getFilenameFromHeader(contentDispositionHeader);

        await downloadFile(getPredictionReports, `${filename}.zip`);
        setIsDownloading(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownloading(false);
    }
  };

  const UploadZone: FC<IUploadZoneProps> = ({ title, onUpload }) => {
    return (
      <Box>
        <Text fontWeight="bold" fontSize="xl">
          {title}
        </Text>
        <Box w="70%" mt={4}>
          <FileDropzone id={title} onUpload={onUpload} />
        </Box>
      </Box>
    );
  };

  const UploadedFile: FC<IUploadedFileProps> = ({ title, file, errors, onUpload }): JSX.Element => {
    return (
      <Box w="fit" h="fit">
        <Text fontWeight="bold" fontSize="xl">
          {title}
        </Text>
        <Flex w="70%" maxH="fit" mt={8}>
          <Box my="auto" h="fit">
            <Image src={HtmlFile} alt="" boxSize="70px" />
          </Box>
          <Flex flexDirection="column" w="full" ml={4}>
            <Flex>
              <Flex flexDirection="column">
                <Text fontWeight="bold">{file.name}</Text>
                <Text fontSize="sm" mt="auto" mb={2}>
                  {reportId && file.data === 'empty' ? '' : reportsWizardResources.createEditReport.uploaded}
                </Text>
              </Flex>
              <UploadFileButton ml="auto" mb={2} id={title} onClick={onUpload}>
                {reportsWizardResources.createEditReport.changeButton}
              </UploadFileButton>
            </Flex>
            <Box
              w="100%"
              h="5px"
              bg={errors.length > 0 ? 'red.500' : ''}
              bgGradient={errors.length > 0 ? '' : 'linear(to-r, #149645, #74AF16)'}
            />
            <Box mt={2} textColor="red.500">
              {errors.map((error) => (
                <Text key={error}>{error}</Text>
              ))}
            </Box>
          </Flex>
        </Flex>
      </Box>
    );
  };

  return (
    <>
      <Heading className="header__title" fontWeight="700" size="xl" p={12}>
        {getHeading()}
      </Heading>
      <Box h="100%" pt={16} px="15%" sx={scrollbar} overflow="auto">
        <Flex justifyContent="center">
          {!content && !reportId && (
            <Box w="50%">
              <UploadZone title={reportsWizardResources.createEditReport.contentTemplate} onUpload={onContentUpload} />
            </Box>
          )}
          {(content || reportId) && (
            <Box w="50%">
              <UploadedFile
                title={reportsWizardResources.createEditReport.contentTemplate}
                file={content ?? mockFile('Content.html')}
                errors={contentErrors}
                onUpload={onContentUpload}
              />
            </Box>
          )}
          {!footer && !reportId && (
            <Box w="50%">
              <UploadZone title={reportsWizardResources.createEditReport.footerTemplate} onUpload={onFooterUpload} />
            </Box>
          )}
          {(footer || reportId) && (
            <Box w="50%">
              <UploadedFile
                title={reportsWizardResources.createEditReport.footerTemplate}
                file={footer ?? mockFile('Footer.html')}
                errors={footerErrors}
                onUpload={onFooterUpload}
              />
            </Box>
          )}
        </Flex>
        <Box className="spacer-for-firefox" h="6rem" w="100%" />
        <Box px="15%" py={4} position="absolute" bottom="0" left="0" w="calc(100% - 15px)">
          <Box position="absolute" top="0" left="0" right="0" bottom="0" bg="utils.background" opacity="0.7" />
          <WizardNav
            nextLabel={isTesting ? reportsWizardResources.buttons.test : reportsWizardResources.buttons.create}
            nextDisableCondition={isDisablingCondition()}
            onNext={() => (isTesting ? onTest() : onSave())}
            buttonSize="lg"
            buttonMaxWidth="320px"
            isNextLoading={isDownloading}
          />
        </Box>
      </Box>
    </>
  );
};
