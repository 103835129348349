import React, { useState } from 'react';
import { VStack, HStack, Heading, Text, useTheme, Flex, Box, Checkbox, Link } from '@chakra-ui/react';
import { PrimaryButton } from 'src/components';
import { LicenseTypes } from 'src/model';

interface SummaryInfo {
  main: string;
  sub: string;
  buyText: string;
  advantageousOfferText: string;
}

interface IBuySummaryProps {
  summaryInfo: SummaryInfo;
  price: string;
  paymentActive: boolean;
  onClick: () => void;
  isLoading: boolean;
  showAdvantageousOfferText: boolean;
  licenseType: LicenseTypes;
  modelsSelectable: boolean;
  isAdmin: boolean;
  isExpanding: boolean;
  isAdminAdding: boolean;
}

export const LicenseBuySummary: React.FC<IBuySummaryProps> = ({
  summaryInfo,
  price,
  onClick,
  paymentActive,
  isLoading,
  showAdvantageousOfferText,
  licenseType,
  modelsSelectable,
  isAdmin,
  isExpanding,
  isAdminAdding,
}) => {
  const {
    colors: { custom },
  } = useTheme();

  const [eula, setEula] = useState<boolean>(false);

  const handleEulaChange = () => {
    setEula(!eula);
  };

  const getButtonText = () => {
    if (isAdmin && isExpanding) return 'Expand license';
    if (isAdminAdding) return 'Add license';
    return summaryInfo.buyText;
  };

  return (
    <Flex w="100%" size="md" pb={2} flexDirection="column">
      {!isAdmin && (
        <VStack align="start" mb={7}>
          <Heading size="md" fontWeight="700">
            {summaryInfo.main}
          </Heading>
          {modelsSelectable && <Text size="16px">{summaryInfo.sub}</Text>}
        </VStack>
      )}
      <VStack align="center">
        {!isAdmin && (
          <Box className="price-box" position="relative" paddingBottom={8}>
            <Flex className="price-box__price">
              <Box as="span" className="price-box__currency" color={custom.glaucous} fontSize="1.5rem" fontWeight="500">
                €
              </Box>
              <Box as="span" className="price-box__amount" fontSize="4.5rem" fontWeight="700">
                {price}
              </Box>
            </Flex>
          </Box>
        )}
        {!(showAdvantageousOfferText && licenseType === LicenseTypes.OneNanoformSpecificModels) && (
          <VStack align="center">
            <HStack wordBreak="break-all">
              <Checkbox colorScheme="brand" size="lg" isChecked={eula} onChange={handleEulaChange} />
              <Box bg="transparent" borderRadius="0.25rem" py="1" px="2">
                <Text fontSize="lg">
                  I have read and accepted the{' '}
                  <Link href="https://nqtbdevelopmentsa.blob.core.windows.net/misc/QL_EULA.pdf" color="typo.green">
                    EULA
                  </Link>
                </Text>
              </Box>
            </HStack>
            <PrimaryButton
              size="lg"
              w="100%"
              onClick={onClick}
              isDisabled={!paymentActive || !eula}
              isLoading={isLoading}
            >
              {getButtonText()}
            </PrimaryButton>
          </VStack>
        )}
      </VStack>
      {showAdvantageousOfferText && (
        <Text color="utils.alert" fontWeight="500" pt={8}>
          {summaryInfo.advantageousOfferText}
        </Text>
      )}
    </Flex>
  );
};
