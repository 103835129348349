import { INewPrediction } from 'src/model/createPrediction.model';
import { AxiosPromise, AxiosResponse } from 'axios';
import { authorizedApiService } from './api.service';

class PredictionService {
  newPrediction = (prediction: INewPrediction, token: string): AxiosPromise => {
    const apiUrl = `predictions`;
    return authorizedApiService(token)
      .post(apiUrl, prediction)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getPredictionDetails = (predictionId: string, token: string): AxiosPromise => {
    const apiUrl = `predictions/${encodeURI(predictionId)}/details`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getPredictionQsarExecutionDetails = (predictionId: string, modelId: string, token: string): AxiosPromise => {
    const apiUrl = `predictions/${encodeURI(predictionId)}/execution/qsar/${encodeURI(modelId)}`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getPredictionRaExecutionDetails = (predictionId: string, modelId: string, token: string): AxiosPromise => {
    const apiUrl = `predictions/${encodeURI(predictionId)}/execution/ra/${encodeURI(modelId)}`;
    return authorizedApiService(token)
      .get(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  deletePrediction = (predictionId: string, token: string): AxiosPromise => {
    const apiUrl = `predictions/${encodeURI(predictionId)}`;
    return authorizedApiService(token)
      .delete(apiUrl)
      .catch((error: AxiosResponse) => Promise.reject(error));
  };

  getPredictionReports = (
    predictionId: string,
    contentFileData: string | null,
    footerFileData: string | null,
    qsarReportId: string | null,
    raReportId: string | null,
    token: string
  ): AxiosPromise => {
    const apiUrl = `predictions/${encodeURI(predictionId)}/files`;
    return authorizedApiService(token)
      .post(apiUrl, { contentFileData, footerFileData, qsarReportId, raReportId }, { responseType: 'arraybuffer' })
      .catch((error: AxiosResponse) => Promise.reject(error));
  };
}

export const predictionService = new PredictionService();
