import { useState, useEffect } from 'react';
import { useUserContext } from 'src/context';

export const useIsAdmin: () => boolean = () => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const { roles } = useUserContext();

  useEffect(() => {
    return roles && roles.includes('Admin') ? setIsAdmin(true) : setIsAdmin(false);
  }, [roles]);

  return isAdmin;
};
